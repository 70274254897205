/** @prettier */
/* eslint-disable import/prefer-default-export */
import {
  UPDATE_QUERY_JSON,
  GET_SEARCH_LIST,
  GET_SEARCH_LIST_FAILURE,
  GET_SEARCH_LIST_SUCCESS,
  ADD_SEARCH,
  ADD_SEARCH_SUCCESS,
  ADD_SEARCH_FAILURE,
  EDIT_SEARCH,
  EDIT_SEARCH_SUCCESS,
  EDIT_SEARCH_FAILURE,
  DELETE_SEARCH,
  DELETE_SEARCH_SUCCESS,
  DELETE_SEARCH_FAILURE,
  UPDATE_CALL_LENGTH,
  TOGGLE_SAVE_SEARCH,
  SEARCH_CALLS,
  SEARCH_CALLS_SUCCESS,
  SEARCH_CALLS_FAILURE,
  SET_COLLAPSED_QUERIES,
  TOGGLE_LIST_TYPE,
  TOGGLE_PREVIEW,
  SET_MULTI_TEXT_FIELDS,
} from './constants';

export const updateQueryJSON = (json) => ({
  type: UPDATE_QUERY_JSON,
  json,
});

export const getSearchList = (data) => ({
  type: GET_SEARCH_LIST,
  data,
});

export const getSearchListSuccess = (data) => ({
  type: GET_SEARCH_LIST_SUCCESS,
  data,
});

export const getSearchListFailure = () => ({
  type: GET_SEARCH_LIST_FAILURE,
});

export const addSearch = (data) => ({
  type: ADD_SEARCH,
  data,
});

export const addSearchSuccess = (data) => ({
  type: ADD_SEARCH_SUCCESS,
  data,
});

export const addSearchFailure = () => ({
  type: ADD_SEARCH_FAILURE,
});

export const editSearchList = (data) => ({
  type: EDIT_SEARCH,
  data,
});

export const editSearchSuccess = (data) => ({
  type: EDIT_SEARCH_SUCCESS,
  data,
});

export const editSearchFailure = () => ({
  type: EDIT_SEARCH_FAILURE,
});

export const deleteSearch = (data) => ({
  type: DELETE_SEARCH,
  data,
});

export const deleteSearchSuccess = (data) => ({
  type: DELETE_SEARCH_SUCCESS,
  data,
});

export const deleteSearchFailure = () => ({
  type: DELETE_SEARCH_FAILURE,
});

export const updateCallLength = (value) => ({
  type: UPDATE_CALL_LENGTH,
  value,
});

export const toggleSaveSearch = (bool) => ({
  type: TOGGLE_SAVE_SEARCH,
  bool,
});

export const searchCalls = (query, onFinish = () => {}) => ({
  type: SEARCH_CALLS,
  query,
  onFinish,
});

export const searchCallsSuccess = (query) => ({
  type: SEARCH_CALLS_SUCCESS,
  query,
});

export const clearSearchCalls = () => ({
  type: SEARCH_CALLS_SUCCESS,
  data: [],
});

export const searchCallsFailure = () => ({
  type: SEARCH_CALLS_FAILURE,
});

export const setCollapsedQueries = (bool) => ({
  type: SET_COLLAPSED_QUERIES,
  bool,
});

export const toggleListType = (listType) => ({
  type: TOGGLE_LIST_TYPE,
  listType,
});

export const togglePreview = (bool) => ({
  type: TOGGLE_PREVIEW,
  bool,
});

export const setMultiTextFields = (multiTextFields) => ({
  type: SET_MULTI_TEXT_FIELDS,
  multiTextFields,
});
