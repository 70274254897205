export const APPLY_TEAM_FILTER = 'APPLY_TEAM_FILTER';
export const FETCH_MONTH_AGENT_CALL_AVOIDANCE = 'FETCH_MONTH_AGENT_CALL_AVOIDANCE';
export const FETCH_MONTH_AGENT_CALL_AVOIDANCE_SUCCESS = 'FETCH_MONTH_AGENT_CALL_AVOIDANCE_SUCCESS';
export const FETCH_MONTH_AGENT_CALL_AVOIDANCE_FAILURE = 'FETCH_MONTH_AGENT_CALL_AVOIDANCE_FAILURE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const FETCH_TRACKING_SILENCE = 'FETCH_TRACKING_SILENCE';
export const FETCH_TRACKING_SILENCE_SUCCESS = 'FETCH_TRACKING_SILENCE_SUCCESS';
export const FETCH_TRACKING_SILENCE_FAILURE = 'FETCH_TRACKING_SILENCE_FAILURE';
export const FETCH_AGENTS_DATA = 'FETCH_AGENTS_DATA';
export const FETCH_AGENTS_DATA_SUCCESS = 'FETCH_AGENTS_DATA_SUCCESS';
export const FETCH_AGENTS_DATA_FAILURE = 'FETCH_AGENTS_DATA_FAILURE';
export const FETCH_CALL_DRIVERS_DATA = 'FETCH_CALL_DRIVERS_DATA';
export const FETCH_CALL_DRIVERS_DATA_SUCCESS = 'FETCH_CALL_DRIVERS_DATA_SUCCESS';
export const FETCH_CALL_DRIVERS_DATA_FAILURE = 'FETCH_CALL_DRIVERS_DATA_FAILURE';
export const UPDATE_PAST_VALUE = 'UPDATE_PAST_VALUE';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';
export const FETCH_CALLS = 'FETCH_CALLS';
export const FETCH_CALLS_SUCCESS = 'FETCH_CALLS_SUCCESS';
export const FETCH_CALLS_FAILURE = 'FETCH_CALLS_FAILURE';
export const ADD_AGENT_TO_FILTER = 'ADD_AGENT_TO_FILTER';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
