import fields from './fields';

const collectionModel = [
  fields.status,
  fields.label,
  {
    field: 'id',
    primaryKey: true,
    source: 'server',
  },
  {
    field: 'orgType',
    required: true,
    defaultValue: 'TEAM',
  },
  {
    field: 'extid',
    required: true,
  },
];

export default collectionModel;
