/** @prettier */
/* eslint-disable no-param-reassign */
import { all, takeEvery, takeLatest, fork, put, call, select, take } from 'redux-saga/effects';
import { get, uniq } from 'lodash';
import api from 'services';
import { saveState } from 'utils/localStorage';
import settings from 'settings';
import { showToast } from 'utils/toast';
import tabActions from 'redux/tabs/actions';
import actions from './actions';
import { CLEAR_AGENT_STATUS } from '../status/constants';
import accountActions from '../accounts/actions';
import userActions from '../users/actions';
import categoryActions from '../categories/actions';
import streamActions from '../streams/actions';
import schemaActions from '../schema/actions';
import bearerTokenActions from '../bearerTokens/actions';
import jobsActions from '../jobs/actions';
import vocabularyListsActions from '../vocabularyLists/actions';
import communicationActions from '../communications/actions';
import spottingGroupsActions from '../spottingGroups/actions';
import connectionsActions from '../connections/actions';
import mappingsActions from '../mappings/actions';
import checklistsActions from '../checklists/actions';
import vboDashboardActions from '../vboDashboards/actions';

export function* changeActiveAccountSaga() {
  yield takeEvery(actions.CHANGE_ACTIVE_ACCOUNT, function* ({ activeAccountId, activeAccount, clear = true }) {
    if (activeAccountId == null) {
      activeAccountId = activeAccount?.id;
    }
    saveState('activeAccountId', activeAccountId);
    const state = yield select();
    let retrievedActiveAccount = activeAccount;
    if (get(state, 'Profile.hasAdminPrivileges')) {
      // TODO: Save this account when retrieved in state
      // The Superadmin will lazy load via the api for accounts
      retrievedActiveAccount = yield call(api.fetchItem, { entityType: 'accounts', id: activeAccountId });
      retrievedActiveAccount.roles = uniq([...retrievedActiveAccount.roles, ...settings.adminRoles]);
    }
    // TODO: We have to trim the activeAccount.roles because they may have spaces in them.
    // Its a bug from backend
    if (retrievedActiveAccount?.roles) {
      retrievedActiveAccount.roles = retrievedActiveAccount.roles.map((i) => i.trim());
    }

    if (clear) {
      yield put({ type: actions.CLEAR_DATA });
      yield take(actions.CLEAR_DATA_SUCCESS); // Wait until account is ready
    }

    yield put({
      type: actions.CHANGE_ACTIVE_ACCOUNT_SUCCESS,
      activeAccountId,
      activeAccount: retrievedActiveAccount,
    });
  });
}

/**
 * For use only by AccountSwitcher Component at the moment
 */
export function* changeActiveAccountByIdSaga() {
  yield takeEvery(actions.CHANGE_ACTIVE_ACCOUNT_BY_ID, function* ({ activeAccountId }) {
    const state = yield select();
    let activeAccount =
      state.Accounts.accounts.find((i) => i.id === activeAccountId) ||
      state.Accounts?.source.find((i) => i.id === activeAccountId);

    if (get(state, 'Profile.hasAdminPrivileges')) {
      // The Superadmin will lazy load via the api for accounts
      activeAccount = yield call(api.fetchItem, { entityType: 'accounts', id: activeAccountId });
    } else if (!activeAccount) {
      activeAccount = state.Accounts.source.find((i) => i.id === activeAccountId);
    }

    // const hasEAProduct = activeAccount?.products?.find((i) => i.name === 'EA');
    // const hasRoleAccess = ['AccountAdmin'].some((i) => activeAccount.roles.includes(i));
    // if (hasEAProduct && hasRoleAccess && activeAccount.etlConfig == null) {
    //   const etlConfig = yield call(api.fetch, {
    //     entityType: 'etl-destination/config',
    //     activeAccountIdOverride: activeAccount.id,
    //   });
    //   if (etlConfig[0]?.type === 'AZURE') {
    //     activeAccount.etlConfigAzure = etlConfig[0];
    //   } else if (etlConfig[0]?.type === 'S3') {
    //     activeAccount.etlConfig = etlConfig[0];
    //   } else {
    //     activeAccount.etlConfig = {};
    //     activeAccount.etlConfigAzure = {};
    //   }
    // }
    yield put({ type: actions.CHANGE_ACTIVE_ACCOUNT, activeAccount, activeAccountId });
    showToast('success', { task: `Account changed to ${activeAccount.name}` });
  });
}

export function* fetchBackendVersion() {
  yield takeLatest(actions.FETCH_BACKEND_VERSION, function* () {
    const version = yield call(api.fetch, { entityType: 'version', disableNotification: true });
    yield put({ type: actions.FETCH_BACKEND_VERSION_SUCCESS, version });
  });
}

export function* fetchPlatformVersionSaga() {
  yield takeLatest(actions.FETCH_PLATFORM_VERSION, function* () {
    const data = yield call(api.fetch, { entityType: 'vp', disableNotification: true });
    if (data?.version) {
      yield put({ type: actions.FETCH_PLATFORM_VERSION_SUCCESS, version: data?.version });
    }
  });
}

export function* clearDataSaga() {
  yield takeEvery(actions.CLEAR_DATA, function* () {
    yield put({ type: accountActions.CLEAR_ACCOUNTS });
    yield put({ type: userActions.CLEAR_USERS });
    yield put({ type: categoryActions.CLEAR_CATEGORIES });
    yield put({ type: streamActions.CLEAR_STREAMS });
    yield put({ type: schemaActions.CLEAR_SCHEMA });
    yield put({ type: bearerTokenActions.CLEAR_BEARERTOKENS });
    yield put({ type: jobsActions.CLEAR_JOBS });
    yield put({ type: vocabularyListsActions.CLEAR_VOCABULARYLISTS });
    yield put({ type: spottingGroupsActions.CLEAR_SPOTTINGGROUPS });
    yield put({ type: connectionsActions.CLEAR_CONNECTIONS });
    yield put({ type: communicationActions.CLEAR_COMMUNICATIONS });
    yield put({ type: mappingsActions.CLEAR_MAPPINGS });
    yield put({ type: checklistsActions.CLEAR_CHECKLISTS });
    yield put({ type: CLEAR_AGENT_STATUS });
    yield put({ type: vboDashboardActions.CLEAR_MY_REPORTS_SELECTED_VIEW_FILTER });
    yield put({ type: actions.CLEAR_DATA_SUCCESS });
    yield put({ type: tabActions.CLEAR_TABS });
    yield put(categoryActions.clearVisualizationChart());
  });
}

export default function* rootSaga() {
  yield all([
    fork(changeActiveAccountSaga),
    fork(clearDataSaga),
    fork(fetchBackendVersion),
    fork(changeActiveAccountByIdSaga),
    fork(fetchPlatformVersionSaga),
  ]);
}
