class Tab {
  constructor(tabGroupId, name, content, position) {
    this.tabGroupId = tabGroupId;
    this.name = name || 'Query 1';
    this.position = position || 0;
    this.type = 'QUERY_TAB';
    this.content = content || 'SELECT * FROM media';
    this.original = content || 'SELECT * FROM media';
  }
}

export { Tab };
