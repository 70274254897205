import * as Yup from 'yup';
import fields from './fields';
import validations from './validations';

const bearerTokenModel = [
  fields.id,
  fields.name,
  {
    field: 'createdDate',
    type: 'date',
    server: true,
  },
  {
    field: 'updatedDate',
    type: 'date',
    server: true,
    defaultSort: true,
    sortDirection: 'desc',
  },
];

const bearerTokenSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'Name is limited to 255 characters')
    .test(...validations.stringWithSpaces)
    .test(...validations.trimSpaces),
});

export { bearerTokenModel, bearerTokenSchema };
