const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_OKTA_SUCCESS: 'LOGIN_OKTA_SUCCESS',
  LOGIN_IN_PROGRESS: 'LOGIN_IN_PROGRESS',
  TOKEN_SET: 'TOKEN_SET',
  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION,
  }),
  loginInProgress: () => ({
    type: actions.LOGIN_IN_PROGRESS,
  }),
  loginSuccess: (payload = {}) => ({
    type: actions.LOGIN_SUCCESS,
    payload,
  }),
  loginOktaSuccess: ({ userinfo, token }) => ({
    type: actions.LOGIN_OKTA_SUCCESS,
    userinfo,
    token,
  }),
  tokenSet: (token) => ({
    type: actions.TOKEN_SET,
    token,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
