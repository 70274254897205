import { getBaseReducer } from 'utils';
import { vocabularyListModel as model } from 'models/vocabularyList';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  entityType: 'vocabularyLists',
  existingNames: [],
};

export default function vocabularyListReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_VOCABULARYLISTS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_VOCABULARYLISTS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_VOCABULARYLISTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        existingNames: action.existingNames,
        init: true,
      };
    case actions.SELECT_VOCABULARYLIST:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_VOCABULARYLIST_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_VOCABULARYLIST_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__VOCABULARYLIST:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_VOCABULARYLIST_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    default:
      return state;
  }
}
