/** @prettier */
import { all, takeEvery, fork, call, put } from 'redux-saga/effects';
import api from 'services';
import { showToast } from 'utils/toast';
import categoryActions from 'redux/categories/actions';
import { prepJob } from 'transform/job';
import actions from './actions';

export function* categoryLibrarySaga() {
  yield takeEvery(actions.FETCH_CATEGORY_LIBRARY, function* () {
    const res = yield call(api.get, {
      url: 'category-library',
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_CATEGORY_LIBRARY_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_CATEGORY_LIBRARY_FAILURE });
    }
  });
}
export function* importCategoryLibrarySaga() {
  yield takeEvery(actions.IMPORT_DRAFT_CATEGORY, function* ({ data, publish, date, cb }) {
    const res = yield call(api.post, {
      url: `category-library/copy?publishedCategory=${publish}`,
      data,
      disableNotification: true,
    });
    if (res) {
      if (publish) {
        yield put({
          type: actions.REAPPLY_JOB_CATEGORIZATION,
          data: { categories: res.data, dateBegin: date.dateBegin, dateEnd: date.dateEnd, toastAction: 'Created' },
        });
        cb?.();
      } else {
        showToast('success', { task: 'Category imported successfully' });
        yield put({
          type: actions.IMPORT_DRAFT_CATEGORY_SUCCESS,
          data: res.data,
        });
        yield put({
          type: actions.FETCH_CATEGORY_LIBRARY,
        });
      }
    } else {
      showToast('error', {
        task: 'Category imported failed! The selected category might have already been imported to the account.',
      });
      yield put({ type: actions.IMPORT_DRAFT_CATEGORY_FAILURE });
    }
  });
}

export function* importJobCategorizationSaga() {
  yield takeEvery(actions.REAPPLY_JOB_CATEGORIZATION, function* ({ data }) {
    const payload = {
      categories: data.categories,
      dateBegin: data.dateBegin,
      dateEnd: data.dateEnd,
      jobType: 'BOTH',
    };

    const preppedData = prepJob(payload);

    const job = yield call(api.editItem, {
      entityType: 'jobsBatch',
      data: preppedData,
      toastAction: data.toastAction,
    });

    if (job) {
      if (data.jobType === 'BOTH') {
        yield put({ type: categoryActions.FETCH_CATEGORIES });
      }
    }

    if (job) {
      showToast('success', { task: 'Category imported successfully' });
      yield put({
        type: actions.IMPORT_DRAFT_CATEGORY_SUCCESS,
        data: job.data,
      });
      yield put({
        type: actions.FETCH_CATEGORY_LIBRARY,
        data: job.data,
      });
    } else {
      showToast('error', {
        task: 'Category imported failed! The selected category might have already been imported to the account.',
      });
      yield put({ type: actions.IMPORT_DRAFT_CATEGORY_FAILURE });
    }
  });
}

export function* importCategoryOverwriteSaga() {
  yield takeEvery(actions.IMPORT_CATEGORY_OVERWRITE, function* ({ data }) {
    const res = yield call(api.post, {
      url: 'category-library/bulk',
      disableNotification: true,
      data,
    });
    if (res) {
      yield put({
        type: actions.FETCH_CATEGORY_LIBRARY,
        data: res.data,
      });
    } else {
      yield put({ type: actions.IMPORT_DRAFT_CATEGORY_FAILURE });
    }
  });
}

export function* addCategoriesSaga() {
  yield takeEvery(actions.ADD_CATEGORIES, function* ({ data }) {
    yield put({ type: actions.SET_VIEW_CATEGORY_ADD_FORM_LOADING, viewCategoryAddFormLoading: true });
    const res = yield call(api.post, {
      url: 'category-library/bulk',
      data,
      msg: 'Categories has been successfully saved',
    });
    if (res) {
      yield put({
        type: actions.FETCH_CATEGORY_LIBRARY,
        data: res.data,
      });
      yield put({ type: actions.SET_VIEW_CATEGORY_ADD_FORM, viewCategoryAddForm: false });
    } else {
      yield put({ type: actions.IMPORT_DRAFT_CATEGORY_FAILURE });
    }
    yield put({ type: actions.SET_VIEW_CATEGORY_ADD_FORM_LOADING, viewCategoryAddFormLoading: false });
  });
}

export function* deleteCategoriesSaga() {
  yield takeEvery(actions.DELETE_CATEGORIES, function* ({ ids }) {
    const res = yield call(api.delete, {
      url: `category-library/${ids.join(',')}`,
      msg: 'Categories has been successfully deleted',
    });
    if (res) {
      yield put({
        type: actions.FETCH_CATEGORY_LIBRARY,
        data: res.data,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(categoryLibrarySaga),
    fork(importCategoryLibrarySaga),
    fork(importJobCategorizationSaga),
    fork(importCategoryOverwriteSaga),
    fork(addCategoriesSaga),
    fork(deleteCategoriesSaga),
  ]);
}
