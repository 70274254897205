import * as Yup from 'yup';
import validations from './validations';

const dataSourceModel = [
  {
    field: 'id',
    primaryKey: true,
    source: 'server',
  },
  {
    field: 'name',
    required: true,
  },
  {
    field: 'updatedDate',
    type: 'date',
    server: true,
    defaultSort: true,
    sortDirection: 'desc',
  },
  /*
  {
    field: 'speechDataFormat',
    type: 'enum',
    options: [
      {value: 'V3API', label: 'V3API'},
      {value: 'DS', label: 'DS'},
      {value: 'UNSET', label: 'Not set'},
    ],
    defaultValue: 'V3API',
  },
  {
    field: 'speechDataSource',
    type: 'enum',
    options: [
      {value: 'UNSET', label: 'Not set'},
      {value: 'API', label: 'API'},
      {value: 'SNOWFLAKE', label: 'Snowflake'},
      {value: 'RESOURCE_FILE', label: 'File'},
      {value: 'OS', label: 'OS'},
      {value: 'S3', label: 'Amazon S3'},
      {value: 'KINESIS', label: 'Kinesis'},
    ],
    defaultValue: 'UNSET',
    required: true,
  },
  {
    field: 'speechDataConfig',
  },
  {
    field: 'speechOntologySource',
    type: 'enum',
    options: [
      {value: 'API', label: 'API'},
      {value: 'RESOURCE_FILE', label: 'Resource File'},
      {value: 'OS', label: 'OS'},
      {value: 'S3', label: 'Amazon S3'},
    ],
    defaultValue: 'API',
    required: true,
  },
  {
    field: 'speechOntologyConfig',
  },
  {
    field: 'onlineSchema',
  },
  {
    field: 'onlineTableSet',
  },
  */
];

const dataSourceSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .max(255, 'Name is limited to 255 characters')
    .test(...validations.stringWithSpaces)
    .test(...validations.trimSpaces),
});

export { dataSourceModel, dataSourceSchema };
