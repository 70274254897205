/** @prettier */

import React from 'react';

import moment from 'moment';

import { truncate, floor, startCase } from 'lodash';
import { Tooltip } from 'antd';
import { ChartLegendStyle } from '../../styledComponent';

const screenWidth = 160; // approximiate number of characters that can show in legend

export const renderLegend = (props, thresholds = null, showOnlyThresholds = false) => {
  const { payload } = props;
  const labelLength = floor(screenWidth / (payload.length + 1));
  const isDate = (title) => !!Date.parse(title);
  return (
    <ul
      style={{
        display: 'flex',
        color: '#677791',
      }}
    >
      {!showOnlyThresholds &&
        payload.map((entry, index) => {
          const fullName = isDate(entry.value) ? entry.value : startCase(entry.value);
          const truncatedName = isDate(entry.value)
            ? truncate(entry.value, { length: labelLength, omission: '...' })
            : startCase(truncate(entry.value, { length: labelLength, omission: '...' }));

          return (
            // eslint-disable-next-line react/no-array-index-key
            <ChartLegendStyle key={`item-${index}`} color={entry.color}>
              <span />
              <Tooltip title={fullName}>
                <li>{truncatedName}</li>
              </Tooltip>
            </ChartLegendStyle>
          );
        })}

      <div style={{ marginLeft: '3em' }} />
      {thresholds &&
        Array.isArray(thresholds) &&
        thresholds.map((thr) =>
          thr.enabled ? (
            <ChartLegendStyle style={{ marginLeft: '6px' }} key={thr.label} color={thr.color}>
              <span />
              <li key={thr.label}>{startCase(truncate(thr.label, { length: labelLength, omission: '...' }))}</li>
            </ChartLegendStyle>
          ) : (
            ''
          )
        )}
    </ul>
  );
};

export const toMomentDateString = (dateName) => moment.utc(dateName).toISOString();

export const isNested = (rows) => rows.some((row) => !!row?.rows); // return true nested else normal

export const getUnit = (text) =>
  ({
    percent: '%',
    minutes: ' min',
  }[text] || '');
