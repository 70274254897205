import { getBaseReducer } from 'utils';
import { streamModel as model } from 'models/stream';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  init: false,
  newStream: null,
  entityType: 'streams',
};

export default function streamReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_STREAMS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_STREAMS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_STREAMS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
        filters: action.filters,
      };
    case actions.SELECT_STREAM:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_STREAM_SUCCESS:
      return {
        ...state,
        newStream: action.newStream,
        data: [
          action.newStream,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.CLEAR_NEW_STREAM_SUCCESS:
      return {
        ...state,
        newStream: null,
      };
    case actions.EDIT_STREAM_SUCCESS:
      return {
        ...state,
        newStream: null,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__STREAM:
      return {
        ...state,
        newStream: null,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_STREAM_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    default:
      return state;
  }
}
