import { getBaseReducer } from 'utils';
import actions from './actions';
// import { tabModel as model } from 'models/tab';

const initState = {
  ...(getBaseReducer()), // model
  entityType: 'tabs',
  cache: [],
  selectLast: false,
  isAddingTab: false,
};

export default function tabReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_TABS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_TABS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_TABS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
      };
    case actions.SELECT_TAB:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_TAB:
      return {
        ...state,
        isAddingTab: true,
      };
    case actions.ADD_TAB_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data,
          action.selectedItem,
        ],
        selectedItem: action.selectedItem,
        view: false,
        isAddingTab: false,
      };
    case actions.ADD_TAB_FAILURE:
      return {
        ...state,
        isAddingTab: false,
      };
    case actions.EDIT_TAB:
      return {
        ...state,
        isEditingTab: true,
      };
    case actions.EDIT_TAB_SUCCESS:
      const index = state.data.findIndex((x) => x.id === action.selectedItem.id);
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          action.selectedItem,
          ...state.data.slice(index + 1),
        ],
        selectedItem: action.selectedItem,
        view: false,
        isEditingTab: false,
      };
    case actions.EDIT_TAB_FAILURE:
      return {
        ...state,
        isEditingTab: false,
      };
    case actions.EDIT_TAB_CACHE:
      const { id, content, query } = action?.data || {};
      return {
        ...state,
        cache: [...(state.cache.filter((x) => x.id !== id)), { id, content, query }],
      };
    case actions.DELETE_TAB_CACHE:
      return {
        ...state,
        cache: state.cache ? state.cache.filter((x) => x.id !== action.id) : [],
      };
    case actions.DELETE_TAB:
      return {
        ...state,
        cache: state.cache ? state.cache.filter((x) => x.id !== action.id) : [],
        selectedId: null,
        isDeletingTab: true,
      };
    case actions.DELETE_TAB_SUCCESS:
      return {
        ...state,
        data: (state?.data || []).filter((x) => x.id !== action.id),
        isDeletingTab: false,
      };
    case actions.DELETE_TAB_FAILURE:
      return {
        ...state,
        isDeletingTab: false,
      };
    case actions.CHANGE_TAB_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    case actions.SET_SELECT_LAST:
      return {
        ...state,
        selectLast: action.data,
      };
    default:
      return state;
  }
}
