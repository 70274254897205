import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import api from 'services';
import actions from './actions';

const entityType = 'tabGroups';

export function* fetchTabGroupsSaga() {
  yield takeEvery(actions.FETCH_TABGROUPS, function* () {
    const data = yield call(api.fetchAll, { entityType });
    if (data) {
      yield put({ type: actions.FETCH_TABGROUPS_SUCCESS, data });
    } else {
      yield put({ type: actions.FETCH_TABGROUPS_FAILURE });
    }
  });
}

export function* addTabGroupSaga() {
  yield takeEvery(actions.ADD_TABGROUP, function* ({ data }) {
    const tabGroup = yield call(api.createItem, { entityType, data });
    if (tabGroup) {
      yield put({ type: actions.ADD_TABGROUP_SUCCESS, selectedItem: tabGroup });
      yield put({ type: actions.FETCH_TABGROUPS });
    } else {
      yield put({ type: actions.ADD_TABGROUP_FAILURE });
    }
  });
}

export function* patchTabGroupSaga() {
  yield takeEvery(actions.PATCH_TABGROUP, function* ({ data, id }) {
    const tabGroup = yield call(api.patchItem, { entityType, data, id });
    if (tabGroup) {
      yield put({ type: actions.EDIT_TABGROUP_SUCCESS, selectedItem: tabGroup });
      yield put({ type: actions.FETCH_TABGROUPS });
    } else {
      yield put({ type: actions.EDIT_TABGROUP_FAILURE });
    }
  });
}

export function* editTabGroupSaga() {
  yield takeEvery(actions.EDIT_TABGROUP, function* ({ data, id }) {
    const tabGroup = yield call(api.editItem, { entityType, data, id, disableNotification: true });
    if (tabGroup) {
      yield put({ type: actions.EDIT_TABGROUP_SUCCESS, selectedItem: tabGroup });
      yield put({ type: actions.FETCH_TABGROUPS });
    } else {
      yield put({ type: actions.EDIT_TABGROUP_FAILURE });
    }
    
  });
}

export function* deleteTabGroupSaga() {
  yield takeEvery(actions.DELETE_TABGROUP, function* ({ id }) {
    yield call(api.deleteItem, { entityType, id });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchTabGroupsSaga),
    fork(addTabGroupSaga),
    fork(editTabGroupSaga),
    fork(patchTabGroupSaga),
    fork(deleteTabGroupSaga),
  ]);
}