import { cloneDeep, omit, keyBy } from 'lodash';
import { arrayFromString } from '../utils';

const fieldsToRemove = ['count'];

export const transformChecklist = (data) => {
  const itemsById = keyBy(data.items, 'id');
  return {
    ...data,
    count: data.items?.length,
    items: data.items?.map((x) => (x.parentId && x.parentId in itemsById ? { ...x, parentName: itemsById[x.parentId].name } : x))
      .sort((a, b) => a.position - b.position),
  };
};

export const transformChecklists = (data) => data.map((x) => transformChecklist(x));

export const prepChecklist = (data) => omit({
  ...data,
}, fieldsToRemove);

export const prepTermsForCsv = (t) => ({
  ...(omit({
    ...t,
    weight: t.weight || 0,
  }, ['soundsLikeStr', 'inEdit', 'id'])),
});

/**
 * Removes temp objects that are not meant to be sent to server for saving
 * @param {*} data
 * @returns
 */
export const sanitizeTempData = (data) => {
  const cloned = cloneDeep(data);
  cloned.items = cloned.items.filter((x) => x.id !== 'ciTempID');
  cloned.items.map((x) => {
    x.popups = x.popups.filter((i) => i.id !== 'tempID');
    return { ...x };
  });
  cloned.alerts = cloned.alerts.filter((x) => x.id !== 'aTempID');
  cloned.globalPopups = cloned.globalPopups.filter((x) => x.id !== 'tempID');
  return cloned;
};

export const importTermsFromCsv = (t, i) => (omit({
  ...t,
  soundsLikeStr: t.soundslike,
  soundsLike: arrayFromString(t.soundslike),
  id: `${i}_${t.term}`,
  inEdit: true,
}, 'soundslike')); // the import process converts column name to all smalls
