import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import api from 'services';
import actions from './actions';


export function* fetchTemplateDashboardsSaga() {
  yield takeEvery(actions.FETCH_TEMPLATE_DASHBOARDS, function* () {
    const res = yield call(api.get, {
      url: 'templates/dashboards',
      disableNotification: true,
    });
    if (res) {
      const imageUrls = res.data.map(el => el.imageUrl);
      const thumbnailImageUrls = res.data.map(el => el.thumbnailImageUrl);
      const images = yield all(imageUrls.map(url => call(api.getImageReq, {
        url,
        disableNotification: true,
        appendUrl: false,
      })))

      const thubnailImages = yield all(thumbnailImageUrls.map(url => call(api.getImageReq, {
        url,
        disableNotification: true,
        appendUrl: false,
      })))
      const data = res.data.map((el, i) => {
        if (images[i]) {
          const b64Data = btoa(
            new Uint8Array(images[i].data).reduce(
              (dataArray, byte) => {
                return dataArray + String.fromCharCode(byte);
              },
              ''
            )
          );
          el.imageUrlExact = 'data:image/png;base64,' + b64Data;
        }

        if (thubnailImages[i]) {
          const b64Data = btoa(
            new Uint8Array(thubnailImages[i].data).reduce(
              (dataArray, byte) => {
                return dataArray + String.fromCharCode(byte);
              },
              ''
            )
          );
          el.imageUrlExactThumb = 'data:image/png;base64,' + b64Data;
        }
        return el
      })
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARDS_SUCCESS,
        data: data.sort((currDashboard, nextDashboard) => currDashboard.label.localeCompare(nextDashboard.label)),
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_DASHBOARDS_FAILURE });
    }
  });
}

export function* fetchTemplateDashboardDataSaga() {
  yield takeEvery(actions.FETCH_TEMPLATE_DASHBOARD_DATA, function* ({
    dashboardId,
  }) {
    // const res = yield call(api.get, {
    //   url: `'templates/dashboards/${dashboardId}`,
    //   disableNotification: true,
    // });
    const res = null;
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARD_DATA_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_DASHBOARD_DATA_FAILURE });
    }
  });
}

export function* fetchTemplateDashboardChartsSaga() {
  yield takeEvery(actions.FETCH_TEMPLATE_DASHBOARD_DATA, function* ({
    dashboardId,
  }) {
    // const res = yield call(api.get, {
    //   url: `'templates/dashboards/${dashboardId}/charts`,
    //   disableNotification: true,
    // });
    const res = null;
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARD_CHARTS_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_DASHBOARD_CHARTS_FAILURE });
    }
  });
}

export function* fetchTemplateDashboardChartsDataSaga() {
  yield takeEvery(actions.FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA, function* ({
    chartId,
  }) {
    // const res = yield call(api.get, {
    //   url: `'templates/charts/${chartId}/scores`,
    //   disableNotification: true,
    // });
    const res = null;
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA_FAILURE });
    }
  });
}

export function* updateTemplateDashboardDataSaga() {
  yield takeEvery(actions.UPDATE_TEMPLATE_DASHBOARD, function* ({
    dashboardId,
    data,
  }) {
    const res = yield call(api.put, {
      url: `dashboards/${dashboardId}`,
      data,
      msg: 'Template updated successfully.',
    });
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARD_DATA_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_DASHBOARD_DATA_FAILURE });
    }
  });
}

export function* deleteTemplateDashboardDataSaga() {
  yield takeEvery(actions.DELETE_TEMPLATE_DASHBOARD, function* ({
    dashboardId,
  }) {
    const res = yield call(api.delete, {
      url: `templates/dashboards/${dashboardId}`,
      msg: 'Template deleted successfully.',
    });
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARDS,
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_DASHBOARD_DATA_FAILURE });
    }
  });
}

export function* duplicateTemplateDashboardDataSaga() {
  yield takeEvery(actions.DUPLICATE_TEMPLATE_DASHBOARD, function* ({
    dashboardId,
  }) {
    const res = yield call(api.post, {
      url: 'templates/dashboards/clone',
      data: { id: dashboardId },
      msg: 'Template duplicated successfully.',
    });
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARDS,
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_DASHBOARD_DATA_FAILURE });
    }
  });
}

export function* uploadTemplateSaga() {
  yield takeEvery(actions.UPLOAD_TEMPLATE, function* ({ data }) {
    // TODO: update to actual API
    // const res = yield call(api.get, {
    //   url: 'templates/dashboards/import',
    //   disableNotification: true,
    // });
    const res = null;
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARDS,
      });
    } else {
      yield put({ type: actions.UPLOAD_TEMPLATE_FAILURE });
    }
  });
}

export function* exportDashboardTemplateSaga() {
  yield takeEvery(actions.EXPORT_DASHBOARD_TEMPLATE, function* ({
    dashboardId,
    history,
  }) {
    yield put({ type: actions.TEMPLATE_DASHBOARD_LOADING, data: true });
    const res = yield call(api.post, {
      url: 'dashboards/clone',
      data: { id: dashboardId },
      msg: 'Template exported successfully',
    });
    if (res) {
      yield put({
        type: actions.TEMPLATE_DASHBOARD_LOADING,
        data: false,
      });
      const url = `/app/myReports/${res.data.id}`;
      history.push(url);
    } else {
      yield put({ type: actions.TEMPLATE_DASHBOARD_LOADING, data: false });
    }
    yield put({ type: actions.TOGGLE_VIEW_TEMPLATE_MODAL, data: false });
  });
}

export function* importDashboardTemplateSaga() {
  yield takeEvery(actions.IMPORT_TEMPLATE_DASHBOARD, function* ({ data }) {
    const res = yield call(api.post, {
      url: 'templates/dashboards/import',
      disableNotification: false,
      data,
      msg: 'Template imported successfully',
    });
    if (res) {
      yield put({
        type: actions.FETCH_TEMPLATE_DASHBOARDS,
      });
    } else {
      yield put({ type: actions.UPLOAD_TEMPLATE_FAILURE });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchTemplateDashboardsSaga),
    fork(fetchTemplateDashboardDataSaga),
    fork(fetchTemplateDashboardChartsSaga),
    fork(fetchTemplateDashboardChartsDataSaga),
    fork(updateTemplateDashboardDataSaga),
    fork(deleteTemplateDashboardDataSaga),
    fork(duplicateTemplateDashboardDataSaga),
    fork(uploadTemplateSaga),
    fork(exportDashboardTemplateSaga),
    fork(importDashboardTemplateSaga),
  ]);
}
