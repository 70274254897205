import _ from 'lodash';
import moment from 'moment';
import { calculateEndDate } from 'utils';
// import { jobModel } from 'models/job';

const jobTypes = {
  BOTH: 'Re-apply categories',
  ALL: 'Re-apply categories',
  DATA_STREAM_ONLY: 'Sync Warehouse',
  INDEX: 'Re-index',
};

export const transformJob = (job) => {
  const dateEndStr = moment(job.dateEnd).utc().format('M/D/YYYY');
  const dateEndStrArr = dateEndStr.split('/');
  const start = moment(job.dateBegin).utc().format('M/D/YYYY').split('/');
  const dateBeginStr = start[2] === dateEndStrArr[2] ? start.slice(0, -1).join('/') : start.join('/');
  // const dateRange = moment.duration(moment(end).diff(moment(start))).humanize();
  return {
    ...job,
    count: job.categories ? job.categories.length : '-',
    // dateRange,
    dateBeginStr,
    dateEndStr,
    type: job.jobType && job.jobType in jobTypes ? jobTypes[job.jobType] : 'Reapply categories',
    status: _.capitalize(job.jobStatus),
  };
};
export const transformJobs = (res) => {
  const jobs = _.get(res, 'categories');
  return jobs.filter((x) => !x.jobStatus || x.jobStatus.toLowerCase() !== 'deleted').map((x) => transformJob(x));
};


const fieldsToRemove = ['type', 'jobType'];

export const prepJob = (job) => {
  let jobString = job.jobType
  if (jobString.includes("BOTH")) {
    jobString = jobString.replace("BOTH", "CATEGORIZE, INDEX, DATA_STREAM")
  }
  const actions = jobString.split(", ")
  if ('categories' in job) {
    job.categories = job.categories.map((x) => typeof x === 'string' ? x : x.categoryName);
    if (job.jobType !== 'BOTH') delete job.categories;
  }

  const jobPayload = {
    ...job,
    dateBegin: moment(job.dateBegin).utc().startOf('day').toISOString(),
    dateEnd: calculateEndDate(job.dateEnd),
    actions
  }
  if (job.jobType.includes('CLASSIFY')) {
    jobPayload.classifiers = ['e7d74751-9612-4521-8d61-9c2e9e002f69']
  }

  return _.omit(jobPayload, fieldsToRemove);
};
