import _ from 'lodash';
import { userModel } from 'models/user';

const fieldsToRemove = ['actAccountByAccountid', 'lastLoggedIn', 'files', 'fullName', 'systemRole', 'createdDt', 'updatedDt', '_flags', 'rolesArr'];

export const transformUser = (user) => {
  const roles = _.get(user, 'roles', []);
  const modelRoles = userModel.find((x) => x.field === 'roles');
  const options = _.get(modelRoles, 'options', []);
  const optionsByValue = _(options).keyBy('value').value();
  const rolesArr = roles ? roles.filter((x) => x !== 'Everyone').map((x) => ({
    label: _.get(optionsByValue, `${x}.label`, _.startCase(x)),
    value: x,
  })) : [];
  return {
    ...user,
    fullName: `${user.fullName ? user.fullName : `${user.firstName} ${user.lastName}`}`,
    externalId: user.externalId || '',
    rolesArr,
  };
};
export const transformUsers = (users) => users.map((u) => transformUser(u));

export const prepUser = (user) => _.omit({
  ...user,
  roles: [
    ...user.rolesArr.map((r) => r.value),
  ],
  externalId: user.externalId || null,
}, fieldsToRemove);

export const prepUserFromAccount = (user) => _.omit({
  accountid: user.accountId,
  firstName: user.adminFirstName,
  lastName: user.adminLastName,
  email: user.adminEmail,
  phone: user.adminPhone,
  roles: [
    'Everyone',
    'AccountAdmin',
  ],
  externalId: null,
  status: 'ACTIVE',
  preferences: '',
  oktaId: '',
  siteId: user.siteId,
}, fieldsToRemove);
