/* istanbul ignore file */
const lightTheme = {
  themeName: 'light',
  layout: '#f1f3f6',
  main: '#ffffff',
  mainBg: '#f5f9fb',
  mainGreyText: '#677791',
  textDark: '',
  textLight: '',
  title: '',
  titleHover: '',
  inputLabel: '',
  danger: '',
  success: '',
  modalHeader: '',
  icon: '',
  placeholder: '',
  sidebar: {
    icon: {
      primary: '',
      secondary: '',
      selected: '',
      hover: {
        primary: '',
        secondary: '',
      },
    },
    text: '',
  },
  button: {
    primary: '#0079c0',
    secondary: '',
    cancel: '',
    text: '',
    borderPrimary: '',
    borderSecondary: '',
    borderCancel: '',
    hover: {
      primary: '#262d38',
      secondary: '',
      cancel: '',
      text: '',
      borderPrimary: '',
      borderSecondary: '',
      borderCancel: '',
    },
  },
  table: {
    primary: '',
    secondary: '',
    text: '',
    title: '',
    icons: {
      edit: {
        primary: '',
        icon: '',
        hover: {
          primary: '',
          icon: '',
        },
      },
      delete: {
        primary: '',
        icon: '',
        hover: {
          primary: '',
          icon: '',
        },
      },

    },

  },
};

lightTheme.fonts = {
  primary: 'Montserrat, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export default lightTheme;
