/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';

import api from 'services';
import {
  transformChecklistItems,
  transformChecklistItem,
  prepChecklistItem,
} from 'transform/checklistItem';
import actions from './actions';
import checklistActions from '../checklists/actions';

const entityType = 'checklistItems';

export function* fetchChecklistItemsSaga() {
  yield takeEvery(actions.FETCH_CHECKLISTITEMS, function* fetchChecklistItems() {
    const data = yield call(api.fetchAll, { entityType, transform: transformChecklistItems });
    if (data) {
      yield put({ type: actions.FETCH_CHECKLISTITEMS_SUCCESS, data });
    } else {
      yield put({ type: actions.FETCH_CHECKLISTITEMS_FAILURE });
    }
  });
}

export function* fetchChecklistItemSaga() {
  yield takeEvery(actions.FETCH_CHECKLISTITEM, function* fetchChecklistItem({ id }) {
    const data = yield call(api.fetchItem, { entityType, transform: transformChecklistItem, id });
    if (data) {
      yield put({ type: actions.FETCH_CHECKLISTITEM_SUCCESS, data });
    } else {
      yield put({ type: actions.FETCH_CHECKLISTITEM_FAILURE });
    }
  });
}

export function* addChecklistItemSaga() {
  yield takeEvery(actions.ADD_CHECKLISTITEM, function* addChecklistItem({ data, onFinish }) {
    if (data.id === 'ciTempID') {
      delete data.id;
    }
    const checklistItem = yield call(api.createItem, { entityType, data: prepChecklistItem(data) });
    if (checklistItem) {
      yield put({ type: actions.ADD_CHECKLISTITEM_SUCCESS, selectedItem: checklistItem });
      yield put({ type: checklistActions.FETCH_CHECKLIST, id: data.checklistId });
    } else {
      yield put({ type: actions.ADD_CHECKLISTITEM_FAILURE });
    }
    onFinish();
  });
}

export function* editChecklistItemSaga() {
  yield takeEvery(actions.EDIT_CHECKLISTITEM, function* editChecklistItem({
    data, id, onFinish = () => {},
  }) {
    if (data.popups?.length) {
      data.popups = data.popups.filter((p) => p.id !== 'tempID');
    }
    if (data.alerts?.length) {
      data.alerts = data.alerts.filter((alert) => alert.id !== 'aTempID');
    }

    const checklistItem = yield call(api.editItem, { entityType, data: prepChecklistItem(data), id });
    if (checklistItem) {
      yield put({ type: actions.EDIT_CHECKLISTITEM_SUCCESS, selectedItem: checklistItem });
    } else {
      yield put({ type: actions.EDIT_CHECKLISTITEM_FAILURE });
    }
    yield put({ type: checklistActions.FETCH_CHECKLIST, id: checklistItem?.checklistId });
    onFinish();
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchChecklistItemsSaga),
    fork(fetchChecklistItemSaga),
    fork(addChecklistItemSaga),
    fork(editChecklistItemSaga),
  ]);
}
