import styled from 'styled-components';

export const FormTitleStyled = styled.div`
  display: flex;
  // align-items: flex-end;

  .date {
    margin-left: 10px;
    opacity: 0.5;
    font-size: 75%;
    transition: opacity .4s;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const FormTitleChildren = styled.div`
  font-size: 75%;
`;
