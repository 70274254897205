/** @prettier */

import _ from 'lodash';
import { handleCsvBadValue, wrapCsvValue } from 'utils/csv';

export const transformCommunications = (res) => {
  const media = _.get(res, 'media', []);
  const newMedia = media.map((m) => ({
    ...m,
    externalId: m.metadata.externalId,
  }));
  // eslint-disable-next-line no-underscore-dangle
  return { ...res, data: newMedia, links: res._links };
};

const fieldsToRemove = ['updatedDate', 'createdDate', 'fileMethod'];
export const prepCommunication = (data) => {
  const customFieldsToRemove = data.fileMethod === 'upload' ? ['mediaUrl'] : ['media'];
  return _.omit(
    {
      ...data,
    },
    [...fieldsToRemove, ...customFieldsToRemove]
  );
};

export const prepCommunicationsForCsv = (t) =>
  // const metadata = t.metadata ? JSON.stringify(t.metadata): '';
  {
    return {
      ..._.omit(
        {
          ...t,
          externalId: wrapCsvValue(handleCsvBadValue(t.externalId)),
          // metadata,
        },
        ['metadata']
      ),
    };
  };
export const convertTextToChat = (data) => {
  const messages = _.get(data, 'messages', []);
  const words = [];
  let mod = 0;
  let startTime = 0;
  messages.forEach((m, i) => {
    const current = i + mod;
    const text = m.text || '';
    const currentTime = new Date(m.createTime);
    const currentMs = currentTime.getTime();
    if (i === 0) startTime = currentMs;
    const s = currentMs - startTime;
    words.push({
      p: current,
      c: 1,
      s,
      e: s,
      m: 'turn',
      w: m.speakerRole,
    });
    text.split(' ').forEach((word, wordIndex) => {
      const cur = current + wordIndex;
      mod = cur + 1;
      words.push({
        p: cur,
        c: 1,
        s,
        e: s,
        w: word,
      });
    });
  });
  return {
    ...data,
    transcript: {
      words,
    },
  };
};
