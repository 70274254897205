import {
  all, takeEvery, put, fork, take,
} from 'redux-saga/effects';
import settings from 'settings';
import { clearAuth, clearState } from 'utils/localStorage';
import history from 'utils/history';
import { hasIntersection } from 'utils';
import actions from './actions';
import profileActions from '../profile/actions';
import usersActions from '../users/actions';
import accountActions from '../accounts/actions';
import appActions from '../app/actions';

export function* loginOktaSuccess() {
  yield takeEvery(actions.LOGIN_OKTA_SUCCESS, function* (action) {
    try {
      const { userinfo } = action;
      const jwtTokenGroups = userinfo.groups || [];
      const hasAdminPrivileges = hasIntersection(jwtTokenGroups, settings.adminRoles);
      const { token } = action;
      const profile = {
        hasAdminPrivileges,
        firstName: userinfo.given_name,
        lastName: userinfo.family_name,
        fullName: userinfo.name,
        ...userinfo,
      };
      yield put({ type: profileActions.PROFILE_SET, profile });
      yield put({ type: actions.TOKEN_SET, token: token });
      if (hasAdminPrivileges) {
        yield put({ type: accountActions.FETCH_ACCOUNT_OPTIONS });
        yield take(accountActions.FETCH_ACCOUNT_OPTIONS_SUCCESS);
        yield put({ type: appActions.FETCH_BACKEND_VERSION });
        yield put(appActions.fetchPlatformVersion());
      }
      yield put({ type: usersActions.FETCH_MY_USER, hasAdminPrivileges });
    } catch (err) {
      // TODO: Need to make a saga specify for unauthorized;
      console.error('couldn\'t get account', err);
      yield put({ type: actions.LOGOUT });
    }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearState('activeAccountId');
    clearAuth();
    yield put({ type: profileActions.PROFILE_CLEAR });
    yield put({ type: appActions.CLEAR_DATA });
    yield take(actions.CLEAR_DATA_SUCCESS); // Wait until account is ready
    yield put({ type: appActions.SET_ADMIN_INIT, adminInit: false });
    yield put({ type: appActions.SET_API_CONNECTED, apiConnected: false });
    history.push('/login');
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginOktaSuccess),
    fork(logout),
  ]);
}
