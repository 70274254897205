export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const ADD_FILTER = 'ADD_FILTER';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const GET_AGENTS_STATUS = 'GET_AGENTS_STATUS';
export const GET_AGENTS_STATUS_SUCCESS = 'GET_AGENTS_STATUS_SUCCESS';
export const GET_AGENTS_STATUS_FAILURE = 'GET_AGENTS_STATUS_FAILURE';
export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE';
export const CLEAR_AGENT_STATUS = 'CLEAR_AGENT_STATUS';
