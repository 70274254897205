import getMenu from 'components/Sidebar/options';
import _ from 'lodash';

export function getDefaultPath(dashboards) {
  const getParent = (lastRoute) => {
    const parent = [];
    const options = getMenu(dashboards);
    if (!lastRoute) return parent;
    parent.push(lastRoute);
    options.forEach((option) => {
      if (option.children) {
        option.children.forEach((child) => {
          if (child.key === lastRoute) {
            parent.push(option.key);
          }
        });
      }
    });
    return parent;
  };
  if (window && window.location.pathname) {
    const routes = window.location.pathname.split('/');
    const len = routes.length;
    if (len > 2 && routes[len - 2] === 'dashboards') return [routes[len - 1], 'dashboards'];
    if (len > 1) {
      const lastRoute = routes[len - 1];
      return getParent(lastRoute);
    }
  }
  return [];
}

export function getPathArr(dashboards) {
  const path = window.location.pathname.replace('/app/', '');
  const split = path.split('/');
  return _.uniq([...split, path]);
}
