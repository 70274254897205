import React, { Component } from 'react';
import { default as packageInfo }  from '../../../package.json';
import MetaView from './MetaView';

const DATE = process.env.DATE;
const ENV = process.env.NODE_ENV;

export default class MetaContainer extends Component {
  constructor(props) {
    super(props);
    const date = DATE || packageInfo.releaseDate || '?';
    this.state = { version: packageInfo.version, date, env: ENV };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    // Display fallback UI
    this.setState({ hasError: error.message });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          {' '}
          --- error in metaView --
          {this.state.error}
          {' '}

        </div>
      );
    }
    return (
      <MetaView {...this.state} />
    );
  }
}
