/** @prettier */
import { VBODashboardConstants } from '../../screens/VBODashboards/constants';

const vboDashboardActions = {
  FETCH_VBO_DASHBOARDS: 'FETCH_VBO_DASHBOARDS',
  FETCH_VBO_DASHBOARDS_SUCCESS: 'FETCH_VBO_DASHBOARDS_SUCCESS',
  FETCH_VBO_DASHBOARDS_FAILURE: 'FETCH_VBO_DASHBOARDS_FAILURE',
  FETCH_VBO_DASHBOARD_DATA: 'FETCH_VBO_DASHBOARD_DATA',
  FETCH_VBO_DASHBOARD_DATA_SUCCESS: 'FETCH_VBO_DASHBOARD_DATA_SUCCESS',
  FETCH_VBO_DASHBOARD_DATA_FAILURE: 'FETCH_VBO_DASHBOARD_DATA_FAILURE',
  FETCH_VBO_DASHBOARD_CHARTS: 'FETCH_VBO_DASHBOARD_CHARTS',
  FETCH_VBO_DASHBOARD_CHARTS_SUCCESS: 'FETCH_VBO_DASHBOARD_CHARTS_SUCCESS',
  FETCH_VBO_DASHBOARD_CHARTS_FAILURE: 'FETCH_VBO_DASHBOARD_CHARTS_FAILURE',
  FETCH_VBO_DASHBOARD_CHARTS_DATA: 'FETCH_VBO_DASHBOARD_CHARTS_DATA',
  FETCH_VBO_DASHBOARD_CHARTS_DATA_SUCCESS: 'FETCH_VBO_DASHBOARD_CHARTS_DATA_SUCCESS',
  FETCH_VBO_DASHBOARD_CHARTS_DATA_FAILURE: 'FETCH_VBO_DASHBOARD_CHARTS_DATA_FAILURE',
  VBO_DASHBOARD_CHARTS_DATA_LOADING: 'VBO_DASHBOARD_CHARTS_DATA_LOADING',
  UPDATE_VBO_DASHBOARD: 'UPDATE_VBO_DASHBOARD',
  ADD_NEW_DASHBOARD_CHART: 'ADD_NEW_DASHBOARD_CHART',
  TOGGLE_DASHBOARD_EDITOR_SIDEBAR: 'TOGGLE_DASHBOARD_EDITOR_SIDEBAR',
  TOGGLE_RENAME_FORM: 'TOGGLE_RENAME_FORM',
  DELETE_DASHBOARD_REPORT: 'DELETE_DASHBOARD_REPORT',
  DELETE_DASHBOARD_REPORT_SUCCESS: 'DELETE_DASHBOARD_REPORT_SUCCESS',
  DELETE_DASHBOARD_REPORT_FALIURE: 'DELETE_DASHBOARD_REPORT_FALIURE',
  CLONE_DASHBOARD_REPORT: 'CLONE_DASHBOARD_REPORT',
  CLONE_DASHBOARD_REPORT_SUCCESS: 'CLONE_DASHBOARD_REPORT_SUCCESS',
  CLONE_DASHBOARD_REPORT_FALIURE: 'CLONE_DASHBOARD_REPORT_FALIURE',
  DELETE_DASHBOARD_CHART: 'DELETE_DASHBOARD_CHART',
  DELETE_DASHBOARD_CHART_SUCCESS: 'DELETE_DASHBOARD_CHART_SUCCESS',
  DELETE_DASHBOARD_CHART_FALIURE: 'DELETE_DASHBOARD_CHART_FALIURE',
  TOGGLE_CLONE_TO_ACCOUNT_POPUP: 'TOGGLE_CLONE_TO_ACCOUNT_POPUP',
  IMPORT_DASHBOARD: 'IMPORT_DASHBOARD',
  IMPORT_DASHBOARD_SUCCESS: 'IMPORT_DASHBOARD_SUCCESS',
  IMPORT_DASHBOARD_FALIURE: 'IMPORT_DASHBOARD_FALIURE',
  EXPORT_DASHBOARD: 'EXPORT_DASHBOARD',
  EXPORT_DASHBOARD_SUCCESS: 'EXPORT_DASHBOARD_SUCCESS',
  EXPORT_DASHBOARD_FALIURE: 'EXPORT_DASHBOARD_FALIURE',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
  CLEAR_DASHBOARD_STATE: 'CLEAR_DASHBOARD_STATE',
  SELECT_DASHBOARD_CHART: 'SELECT_DASHBOARD_CHART',
  FETCH_CHARTS_DATA_SUCCESS: 'FETCH_CHARTS_DATA_SUCCESS',
  FETCH_CHARTS_DATA_FAILURE: 'FETCH_CHARTS_DATA_FAILURE',
  FETCH_CHARTS_DATA: 'FETCH_CHARTS_DATA',
  EDIT_CHART_DATA_SUCCESS: 'FETCH_CHARTS_DATA_SUCCESS',
  EDIT_CHART_DATA_FAILURE: 'EDIT_CHART_DATA_FAILURE',
  EDIT_CHART_DATA: 'EDIT_CHART_DATA',
  CLEAR_CHART_DATA: 'CLEAR_CHART_DATA',
  ADD_NEW_CHART: 'ADD_NEW_CHART',
  ADD_NEW_CHART_SUCCESS: 'ADD_NEW_CHART_SUCCESS',
  LOADING_EDIT_SIDEBAR: 'LOADING_EDIT_SIDEBAR',
  ADD_VBO_DASHBOARD: 'ADD_VBO_DASHBOARD',
  FETCH_CATEGORY_COLLECTIONS: 'FETCH_CATEGORY_COLLECTIONS',
  FETCH_CATEGORY_COLLECTIONS_SUCCESS: 'FETCH_CATEGORY_COLLECTIONS_SUCCESS',
  FETCH_CATEGORY_COLLECTIONS_FAILURE: 'FETCH_CATEGORY_COLLECTIONS_FAILURE',
  FETCH_DASHBOARD_FILTERS: 'FETCH_DASHBOARD_FILTERS',
  FETCH_DASHBOARD_FILTERS_SUCCESS: 'FETCH_DASHBOARD_FILTERS_SUCCESS',
  CLEAR_FILTER_STATE: 'CLEAR_FILTER_STATE',
  CHANGE_SELECTED_FILTER: 'CHANGE_SELECTED_FILTER',
  CHANGE_CALLS_FILTER_VALUE: 'CHANGE_CALLS_FILTER_VALUE',
  ADD_TEMP_CHART: 'ADD_TEMP_CHART',
  CHANGE_TEMP_TEXT_VALUE: 'CHANGE_TEMP_TEXT_VALUE',
  REMOVE_TEMP_CHART: 'REMOVE_TEMP_CHART',
  CLONE_TO_ACCOUNT: 'CLONE_TO_ACCOUNT',
  CLONE_TO_ACCOUNT_SUCCESS: 'CLONE_TO_ACCOUNT_SUCCESS',
  CLONE_TO_ACCOUNT_FAILURE: 'CLONE_TO_ACCOUNT_FAILURE',
  GET_CALL_COUNT: 'GET_CALL_COUNT',
  GET_CALL_COUNT_SUCCESS: 'GET_CALL_COUNT_SUCCESS',
  GET_CALLS_LIST: 'GET_CALLS_LIST',
  GET_CALLS_LIST_SUCCESS: 'GET_CALLS_LIST_SUCCESS',
  SET_CALLS_COUNT_LOADING: 'SET_CALLS_COUNT_LOADING',
  FETCH_MEASURE_COLLECTION: 'FETCH_MEASURE_COLLECTION',
  FETCH_MEASURE_COLLECTION_SUCCESS: 'FETCH_MEASURE_COLLECTION_SUCCESS',
  FETCH_MEASURE_COLLECTION_FAILURE: 'FETCH_MEASURE_COLLECTION_FAILURE',
  SET_MY_REPORTS_SELECTED_VIEW_FILTER: 'SET_MY_REPORTS_SELECTED_VIEW_FILTER',
  CLEAR_MY_REPORTS_SELECTED_VIEW_FILTER: 'CLEAR_MY_REPORTS_SELECTED_VIEW_FILTER',
  FETCH_MEASURES: 'FETCH_MEASURES',
  FETCH_MEASURES_SUCCESS: 'FETCH_MEASURES_SUCCESS',
  FETCH_DIMENSIONS: 'FETCH_DIMENSIONS',
  FETCH_DIMENSIONS_SUCCESS: 'FETCH_DIMENSIONS_SUCCESS',
  CHANGE_EDIT_CHART_TYPE: 'CHANGE_EDIT_CHART_TYPE',
  FETCH_DASHBOARD_DIMENSION_VALUES: 'FETCH_DASHBOARD_DIMENSION_VALUES',
  FETCH_DASHBOARD_DIMENSION_VALUES_SUCCESS: 'FETCH_DASHBOARD_DIMENSION_VALUES_SUCCESS',
  FETCH_DASHBOARD_DIMENSION_VALUES_FAILURE: 'FETCH_DASHBOARD_DIMENSION_VALUES_FAILURE',
  UPLOAD_TEMPLATE_IMAGE: 'UPLOAD_TEMPLATE_IMAGE',
  UPLOAD_TEMPLATE_IMAGE_SUCCESS: 'UPLOAD_TEMPLATE_IMAGE_SUCCESS',
  UPLOAD_TEMPLATE_IMAGE_FAILURE: 'UPLOAD_TEMPLATE_IMAGE_FAILURE',
  SET_UPDATING_VBO_DASHBOARD: 'SET_UPDATING_VBO_DASHBOARD',

  fetchDashboardDimensionValues: (dashboardId, data) => ({
    type: vboDashboardActions.FETCH_DASHBOARD_DIMENSION_VALUES,
    dashboardId,
    data,
  }),
  fetchVboDashboards: () => ({
    type: vboDashboardActions.FETCH_VBO_DASHBOARDS,
  }),
  fetchVboDashboardData: (dashboardId) => ({
    type: vboDashboardActions.FETCH_VBO_DASHBOARD_DATA,
    dashboardId,
  }),
  fetchVboDashboardCharts: (dashboardId) => ({
    type: vboDashboardActions.FETCH_VBO_DASHBOARD_CHARTS,
    dashboardId,
  }),
  fetchVboDashboardChartsData: (chartId, queryParams) => ({
    type: vboDashboardActions.FETCH_VBO_DASHBOARD_CHARTS_DATA,
    chartId,
    queryParams,
  }),
  updateVboDashboard: (dashboardId, data) => ({
    type: vboDashboardActions.UPDATE_VBO_DASHBOARD,
    dashboardId,
    data,
  }),
  addNewDashboardChart: (data, dashboardId, options = {}) => ({
    type: vboDashboardActions.ADD_NEW_DASHBOARD_CHART,
    dashboardId,
    data,
    isDuplicate: options.isDuplicate,
  }),
  toggleDashboardEditorSideBar: (data, sidebarType = VBODashboardConstants.DASHBOARD_SIDEBAR_TYPE.CHART) => ({
    type: vboDashboardActions.TOGGLE_DASHBOARD_EDITOR_SIDEBAR,
    data,
    sidebarType,
  }),
  toggleRenameForm: () => ({
    type: vboDashboardActions.TOGGLE_RENAME_FORM,
  }),
  deleteDashboardReport: (dashboardId, history) => ({
    type: vboDashboardActions.DELETE_DASHBOARD_REPORT,
    dashboardId,
    history,
  }),
  cloneDashboardReport: (data) => ({
    type: vboDashboardActions.CLONE_DASHBOARD_REPORT,
    data,
  }),
  deleteDashboardChart: (chartId, dashboardId, isText, onFinish) => ({
    type: vboDashboardActions.DELETE_DASHBOARD_CHART,
    chartId,
    dashboardId,
    isText,
    onFinish,
  }),
  toggleCloneAccountPopup: () => ({
    type: vboDashboardActions.TOGGLE_CLONE_TO_ACCOUNT_POPUP,
  }),
  exportDashboard: (dashboardId, onlyDownload) => ({
    type: vboDashboardActions.EXPORT_DASHBOARD,
    dashboardId,
    onlyDownload,
  }),
  importDashboard: (data) => ({
    type: vboDashboardActions.IMPORT_DASHBOARD,
    data,
  }),
  downloadReport: (data) => ({
    type: vboDashboardActions.DOWNLOAD_REPORT,
    data,
  }),
  clearDashboardState: () => ({
    type: vboDashboardActions.CLEAR_DASHBOARD_STATE,
  }),
  selectDashboardChart: (data) => ({
    type: vboDashboardActions.SELECT_DASHBOARD_CHART,
    data,
  }),
  fetchChartsData: (chartId, resData) => ({
    type: vboDashboardActions.FETCH_CHARTS_DATA,
    chartId,
    resData,
  }),
  editChartData: (chartId, data, filterUrl) => ({
    type: vboDashboardActions.EDIT_CHART_DATA,
    chartId,
    data,
    filterUrl,
  }),
  clearChartData: (chartId) => ({
    type: vboDashboardActions.CLEAR_CHART_DATA,
    chartId,
  }),
  addChartFullData: (data) => ({
    type: vboDashboardActions.FETCH_CHARTS_DATA_SUCCESS,
    data,
  }),
  addNewChart: (dashboardId, data) => ({
    type: vboDashboardActions.ADD_NEW_CHART,
    dashboardId,
    data,
  }),
  loadingEditSideBar: (data) => ({
    type: vboDashboardActions.LOADING_EDIT_SIDEBAR,
    data,
  }),
  newlyAddedChartAction: (data) => ({
    type: vboDashboardActions.ADD_NEW_CHART_SUCCESS,
    data,
  }),
  addVboDashboard: (data, history) => ({
    type: vboDashboardActions.ADD_VBO_DASHBOARD,
    data,
    history,
  }),
  fetchCategoryCollection: () => ({
    type: vboDashboardActions.FETCH_CATEGORY_COLLECTIONS,
  }),
  fetchDashboardFilters: (dashboardId, data) => ({
    type: vboDashboardActions.FETCH_DASHBOARD_FILTERS,
    dashboardId,
    data,
  }),
  clearFiltersState: (key) => ({
    type: vboDashboardActions.CLEAR_FILTER_STATE,
    key,
  }),
  changeSelectedFilter: (filterName, value) => ({
    type: vboDashboardActions.CHANGE_SELECTED_FILTER,
    filterName,
    value,
  }),
  changeCallsFilterValue: (queryString, aggregation, chartId) => ({
    type: vboDashboardActions.CHANGE_CALLS_FILTER_VALUE,
    queryString,
    aggregation,
    chartId,
  }),
  addTempChart: (data) => ({
    type: vboDashboardActions.ADD_TEMP_CHART,
    data,
  }),
  changeTempTextValue: (data) => ({
    type: vboDashboardActions.CHANGE_TEMP_TEXT_VALUE,
    data,
  }),
  removeTempChart: (data) => ({
    type: vboDashboardActions.REMOVE_TEMP_CHART,
    data,
  }),
  cloneToAccountDashboard: (data) => ({
    type: vboDashboardActions.CLONE_TO_ACCOUNT,
    data,
  }),
  getCallsCount: (data, queryParams) => ({
    type: vboDashboardActions.GET_CALL_COUNT,
    data,
    queryParams,
  }),
  getCallsList: (dashboardId, queryParams) => ({
    type: vboDashboardActions.GET_CALLS_LIST,
    dashboardId,
    queryParams,
  }),
  fetchMeasureCollection: (category) => ({
    type: vboDashboardActions.FETCH_MEASURE_COLLECTION,
    category,
  }),

  setMyReportsSelectedViewFilter: (selectedViewFilter) => ({
    type: vboDashboardActions.SET_MY_REPORTS_SELECTED_VIEW_FILTER,
    selectedViewFilter,
  }),

  fetchMeasures: (data) => ({
    type: vboDashboardActions.FETCH_MEASURES,
    data,
  }),

  fetchDimensions: (data) => ({
    type: vboDashboardActions.FETCH_DIMENSIONS,
    data,
  }),
  changeEditChartType: (data) => ({
    type: vboDashboardActions.CHANGE_EDIT_CHART_TYPE,
    data,
  }),
  uploadTemplateImage: ({ imageFile, previewImageFile }, cb) => ({
    type: vboDashboardActions.UPLOAD_TEMPLATE_IMAGE,
    imageFile,
    previewImageFile,
    cb,
  }),
};
export default vboDashboardActions;
