import React from 'react';
import FormDates from 'components/UI/FormDates';
import { FormTitleStyled, FormTitleChildren } from './styles';

const FormTitle = ({
  editMode, type, created, updated, children, customTitle,
}) => (
  <FormTitleStyled>
    {customTitle || (
    <>
      <span>
        {`${editMode ? 'Edit' : 'Create'} ${type}`}
      </span>
      {(editMode && updated && <FormDates created={created} updated={updated} position="bottom" />)}
    </>
    )}
    <FormTitleChildren>{children}</FormTitleChildren>
  </FormTitleStyled>
);

export default FormTitle;
