/** @prettier */
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

import { FaInfoCircle } from 'react-icons/fa';

const Styled = styled.label`
  display: block;
  margin-bottom: 15px;

  .label {
    color: #858892;
    font-weight: 400;
    margin-bottom: 10px;
    display: block;
    font-size: 12px;
  }

  &.required {
    .label:after {
      content: ' *';
      color: rgba(255, 0, 0, 0.65);
    }
  }
`;

const tooltip = (title) => (
  <Tooltip title={title}>
    <FaInfoCircle style={{ opacity: '0.5', marginLeft: '5px' }} />
  </Tooltip>
);

export const FormGroup = ({ label, required, children, style = {}, tooltipTitle = '', className = '' }) => (
  <Styled style={style} className={required ? 'required' : ''}>
    {label && (
      <span className={className}>
        <span className="label">{label}</span>
        {tooltipTitle && tooltip(tooltipTitle)}
      </span>
    )}
    {children}
  </Styled>
);
