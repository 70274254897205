/** @prettier */
import React from 'react';
import { Button as AntButton } from 'antd';
import { withTheme } from 'styled-components';
import { ButtonStyles } from './style';

export const Button = withTheme(
  ({ icon = null, type = 'default', className, onClick, disabled = false, children, theme, ...rest }) => {
    return (
      <>
        <ButtonStyles disabled={disabled} />
        <AntButton type={type} className={className} onClick={onClick} disabled={disabled} {...rest}>
          <span className="button-icon">{icon}</span>
          <span className="button-text">{children}</span>
        </AntButton>
      </>
    );
  }
);
