/** @prettier */
import api from './api';
import { OktaConfig } from './auth';
import tours from './tours';
import moment from 'moment';

const NODE_ENV = process.env.REACT_APP_CUSTOM_NODE_ENV || process.env.NODE_ENV;
const environment =
  NODE_ENV === 'development' || NODE_ENV === 'dev'
    ? 'dev'
    : NODE_ENV === 'production' || NODE_ENV === 'prod'
    ? 'prod'
    : NODE_ENV;
const version = process.env.REACT_APP_VERSION || 'local';
const adminRoles = ['SuperAdmin']; // DO NOT CHANGE WITHOUT CHECKING WITH OUT DEVELOPERS

const siteConfig = {
  siteName: 'VoiceBase',
  siteIcon: 'ion-flash',
  footerText: `LivePerson ©${moment().format('YYYY')}`,
};

const themeConfig = {
  mode: 'themedefault',
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  customerMode: 'production',
  environment,
  theme: 'light',
};
const language = 'english';
const microsoftIdpId = process.env.REACT_APP_MICROSOFT_IDP_ID;

export default {
  api,
  adminRoles,
};
export { api, environment, version, siteConfig, themeConfig, language, OktaConfig, microsoftIdpId, tours };
