const collectionActions = {
  FETCH_COLLECTIONS: 'FETCH_COLLECTIONS',
  FETCH_COLLECTIONS_SUCCESS: 'FETCH_COLLECTIONS_SUCCESS',
  FETCH_COLLECTIONS_FAILURE: 'FETCH_COLLECTIONS_FAILURE',
  SELECT_COLLECTION: 'SELECT_COLLECTION',
  ADD_COLLECTION: 'ADD_COLLECTION',
  ADD_COLLECTION_SUCCESS: 'ADD_COLLECTION_SUCCESS',
  ADD_COLLECTION_FAILURE: 'ADD_COLLECTION_FAILURE',
  EDIT_COLLECTION: 'EDIT_COLLECTION',
  EDIT_COLLECTION_SUCCESS: 'EDIT_COLLECTION_SUCCESS',
  EDIT_COLLECTION_FAILURE: 'EDIT_COLLECTION_FAILURE',
  GET_COLLECTION: 'GET_COLLECTION',
  DELETE__COLLECTION: 'DELETE__COLLECTION',
  CHANGE_COLLECTION: 'CHANGE_COLLECTION',
  CHANGE_COLLECTION_VIEW: 'CHANGE_COLLECTION_VIEW',
  CLEAR_COLLECTIONS: 'CLEAR_COLLECTIONS',
  SORT_COLLECTIONS: 'SORT_COLLECTIONS',
  sortCollections: (e) => ({
    type: collectionActions.SORT_COLLECTIONS,
    sort: e.sort,
  }),
  clearCollections: (options) => ({
    type: collectionActions.CLEAR_COLLECTIONS,
  }),
  fetchCollections: () => ({
    type: collectionActions.FETCH_COLLECTIONS,
  }),
  selectCollection: (selectedItem) => ({
    type: collectionActions.SELECT_COLLECTION,
    selectedItem,
  }),
  addCollection: (data) => ({
    type: collectionActions.ADD_COLLECTION,
    data,
  }),
  editCollection: (data) => ({
    type: collectionActions.EDIT_COLLECTION,
    data,
  }),
  getCollection: (data) => ({
    type: collectionActions.GET_COLLECTION,
    data,
  }),
  deleteCollection: (id) => ({
    type: collectionActions.DELETE__COLLECTION,
    id,
  }),
  changeCollectionView: (selectedItem) => ({
    type: collectionActions.CHANGE_COLLECTION_VIEW,
    selectedItem,
  }),
};
export default collectionActions;
