const hostedTablesActions = {
  SET_HOSTED_TABLES_STEP_1: 'SET_HOSTED_TABLES_STEP_1',
  GET_HOSTED_TABLES_CONFIG: 'GET_HOSTED_TABLES_CONFIG',
  GET_HOSTED_TABLES_CONFIG_SUCCESS: 'GET_HOSTED_TABLES_CONFIG_SUCCESS',
  UPLOAD_HOSTED_TABLE: 'UPLOAD_HOSTED_TABLE',
  SET_LOADING: 'SET_LOADING',
  SET_INIT: 'SET_INIT',
  DELETE_HOSTED_TABLE: 'DELETE_HOSTED_TABLE',
  SET_HOSTED_TABLE_NAMES: 'SET_HOSTED_TABLE_NAMES',
  SET_UPLOAD_POPUP_VISIBLE: 'SET_UPLOAD_POPUP_VISIBLE',
  RESET_POPUP: 'RESET_POPUP',
  SET_EDIT_FORM: 'SET_EDIT_FORM',
  EDIT_HOSTED_TABLE: 'EDIT_HOSTED_TABLE',
  SET_STEP: 'SET_STEP',
  SET_PREVIEW: 'SET_PREVIEW',
  setHostedTablsData: (data) => ({
    type: hostedTablesActions.SET_HOSTED_TABLES_STEP_1,
    step: data.step,
    tableName: data.tableName,
    file: data.file,
    containsHeader: data.containsHeader,
    numberOfColumn: data.numberOfColumn,
    uploadData: data.uploadData,
    delimiter: data.delimiter,
  }),
  getHostedTablesConfig: () => ({
    type: hostedTablesActions.GET_HOSTED_TABLES_CONFIG,
  }),
  uploadHostedTable: (columns) => ({
    type: hostedTablesActions.UPLOAD_HOSTED_TABLE,
    columns,
  }),
  editHostedTable: (tableName, containsHeader, file, truncateTableInExasol, delimiter) => ({
    type: hostedTablesActions.EDIT_HOSTED_TABLE,
    tableName,
    containsHeader,
    file,
    truncateTableInExasol,
    delimiter,
  }),
  deleteHostedTable: (tableName) => ({
    type: hostedTablesActions.DELETE_HOSTED_TABLE,
    tableName,
  }),
  setUploadPopupVisible: (uploadPopupVisible) => ({
    type: hostedTablesActions.SET_UPLOAD_POPUP_VISIBLE,
    uploadPopupVisible,
  }),
  setEditForm: (tableName, isEditPopup) => ({
    type: hostedTablesActions.SET_EDIT_FORM,
    tableName,
    isEditPopup,
  }),
  setStep: (step) => ({
    type: hostedTablesActions.SET_STEP,
    step,
  }),
  showPreview: (file, delimiter, containsHeader, tableName) => ({
    type: hostedTablesActions.SET_PREVIEW,
    file,
    delimiter,
    containsHeader,
    tableName,
  }),
};

export default hostedTablesActions;
