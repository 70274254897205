/* eslint-disable import/prefer-default-export */
import {
  ADD_AGENT_TO_FILTER,
  APPLY_TEAM_FILTER,
  CLEAR_ALL_FILTERS,
  FETCH_AGENTS_DATA,
  FETCH_AGENTS_DATA_FAILURE,
  FETCH_AGENTS_DATA_SUCCESS,
  FETCH_CALLS,
  FETCH_CALLS_FAILURE,
  FETCH_CALLS_SUCCESS,
  FETCH_CALL_DRIVERS_DATA,
  FETCH_CALL_DRIVERS_DATA_SUCCESS,
  FETCH_MONTH_AGENT_CALL_AVOIDANCE,
  FETCH_MONTH_AGENT_CALL_AVOIDANCE_FAILURE,
  FETCH_MONTH_AGENT_CALL_AVOIDANCE_SUCCESS,
  FETCH_TEAMS,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAMS_SUCCESS,
  FETCH_TRACKING_SILENCE,
  FETCH_TRACKING_SILENCE_SUCCESS,
  SET_SELECTED_DATE,
  UPDATE_PAST_VALUE,
} from './constants';

export const applyTeamFilter = (filter) => ({
  type: APPLY_TEAM_FILTER,
  filter,
});

export const fetchMonthAgentCallAvoidance = (agentSupervisorId, startDate, endDate, past = false) => ({
  type: FETCH_MONTH_AGENT_CALL_AVOIDANCE,
  startDate,
  endDate,
  past,
  agentSupervisorId,
});

export const fetchMonthAgentCallAvoidanceSuccess = (data) => ({
  type: FETCH_MONTH_AGENT_CALL_AVOIDANCE_SUCCESS,
  data,
});

export const fetchMonthAgentCallAvoidanceFailure = (err) => ({
  type: FETCH_MONTH_AGENT_CALL_AVOIDANCE_FAILURE,
  err,
});

export const setSelectedDate = (selectedDate) => ({
  type: SET_SELECTED_DATE,
  selectedDate,
});

export const fetchTrackingSilence = (agentSupervisorId, startDate, endDate) => ({
  type: FETCH_TRACKING_SILENCE,
  startDate,
  endDate,
  agentSupervisorId,
});

export const fetchTrackingSilenceSuccess = (data) => ({
  type: FETCH_TRACKING_SILENCE_SUCCESS,
  data,
});

export const fetchTrackingSilenceFailure = () => ({
  type: FETCH_TRACKING_SILENCE_SUCCESS,
});

export const fetchAgentsData = (agentSupervisorId, startDate, endDate) => ({
  type: FETCH_AGENTS_DATA,
  startDate,
  endDate,
  agentSupervisorId,
});

export const fetchAgentsDataSuccess = (data) => ({
  type: FETCH_AGENTS_DATA_SUCCESS,
  data,
});

export const fetchAgentsDataFailure = () => ({
  type: FETCH_AGENTS_DATA_FAILURE,
});

export const fetchCallDriversData = (agentSupervisorId, startDate, endDate) => ({
  type: FETCH_CALL_DRIVERS_DATA,
  startDate,
  endDate,
  agentSupervisorId,
});

export const fetchCallDriversDataSuccess = (data) => ({
  type: FETCH_CALL_DRIVERS_DATA_SUCCESS,
  data,
});

export const updatePastValue = (past, val) => ({
  type: UPDATE_PAST_VALUE,
  past,
  val,
});

export const fetchTeams = (data) => ({
  type: FETCH_TEAMS,
  data,
});

export const fetchTeamsSuccess = (data) => ({
  type: FETCH_TEAMS_SUCCESS,
  data,
});

export const fetchTeamsFailure = (data) => ({
  type: FETCH_TEAMS_FAILURE,
  data,
});

export const fetchCalls = (agentSupervisorId, startDate, endDate, from) => ({
  type: FETCH_CALLS,
  startDate,
  endDate,
  agentSupervisorId,
  from,
});

export const fetchCallsSuccess = (data) => ({
  type: FETCH_CALLS_SUCCESS,
  data,
});

export const fetchCallsFailure = (err) => ({
  type: FETCH_CALLS_FAILURE,
  err,
});

export const addAgentToFilter = (agent) => ({
  type: ADD_AGENT_TO_FILTER,
  agent,
});

export const clearAllFilters = () => ({
  type: CLEAR_ALL_FILTERS,
});
