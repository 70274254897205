import {
  FETCH_USERS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  ADD_FILTER,
  UPDATE_AGENT,
  GET_AGENTS_STATUS,
  GET_AGENTS_STATUS_SUCCESS,
  GET_AGENTS_STATUS_FAILURE,
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
  CLEAR_AGENT_STATUS,
} from './constants';

export const fetchUsers = () => ({
  type: FETCH_USERS,
});

export const fecthUsersSuccess = (data) => ({
  type: FETCH_USERS_SUCCESS,
  data,
});

export const fetchUsersFailure = () => ({
  type: FETCH_USERS_FAILURE,
});

export const addFilter = (filter) => ({
  type: ADD_FILTER,
  filter,
});

export const updateAgent = (agent) => ({
  type: UPDATE_AGENT,
  agent,
});

export const fetchAgentsStatus = () => ({
  type: GET_AGENTS_STATUS,
});

export const fetchAgentsStatusSuccess = (data) => ({
  type: GET_AGENTS_STATUS_SUCCESS,
  data,
});

export const fetchAgentsStatusFailure = () => ({
  type: GET_AGENTS_STATUS_FAILURE,
});

export const fetchCampaigns = () => ({
  type: FETCH_CAMPAIGNS,
});

export const fetchCampaignsSuccess = (data) => ({
  type: FETCH_CAMPAIGNS_SUCCESS,
  data,
});

export const fetchCampaignsFailure = () => ({
  type: FETCH_CAMPAIGNS_FAILURE,
});

export const clearAgentStatus = () => ({
  type: CLEAR_AGENT_STATUS,
});
