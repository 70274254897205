/** @prettier */
import React from 'react';
import { MdClose } from 'react-icons/md';
import { ModalStyled } from './styles';

const Dialog = ({
  children,
  closeIcon = true,
  onClose,
  title,
  width,
  maskClosable = true,
  noMotion,
  footer = null,
  ...rest
}) => (
  <ModalStyled
    closable={closeIcon}
    closeIcon={<MdClose />}
    title={title}
    width={width}
    footer={footer}
    visible
    onCancel={() => onClose()}
    maskClosable={maskClosable}
    {...(noMotion && { transitionName: '' })}
    {...(noMotion && { maskTransitionName: '' })}
    {...rest}
  >
    {children}
  </ModalStyled>
);

export default Dialog;
