import styled from 'styled-components';

export const FormDateStyled = styled.span`
  cursor: help;

  time {
    pointer-events: none;
  }
`;

export const TooltipStyled = styled.div`
  div {
    display: flex;
    justify-content: space-between;

    &:first-child { margin-bottom: 3px; }

    span:first-child {
      margin-right: 10px;
      opacity: 0.6;
    }
  }
`;
