import React from 'react';
import styled from 'styled-components';

const Styled = styled.h5`
  font-size: 15px;
  color: #262D38;
  letter-spacing: 0.1em;
  font-weight: normal;
  margin: 0 0 15px;
`;

const Subheading = ({ children }) => <Styled>{children}</Styled>;

export const SubHeading2 = (props) => (
  <Styled
    style={{ color: 'gray', textTransform: 'uppercase', fontSize: 12 }}
    {...props}
  />
);

export default Subheading;
