import _ from 'lodash';

const fieldsToRemove = ['updatedDate', 'createdDate', 'checkDelay', 'lastErrorMsg', 'editMode'];

export const transformConnection = (data) => ({
  ...data,
  // configuration: data.configuration ? JSON.parse(data.configuration): {},
  format: data.interactionType,
  id: data._id,
});
export const transformConnections = (data) => data.map((x) => transformConnection(x));

export const prepConnection = (data) => _.omit({
  ...data,
  // metadataFilterRules: data?.metadataFilterRules?.length === 0 ? '' : JSON.stringify(data.metadataFilterRules), // EA-2936 backend cannot handle empty array
  interactionType: data.format,
  _id: data.id,
  delayValue: typeof data.checkDelay !== 'undefined' ? (data.checkDelay ? data.delayValue : null) : undefined,
}, fieldsToRemove);

export const editConnectionPrep = (data) => prepConnection(data);
