/* istanbul ignore file */
import SchemaData from 'schema/media-configuration/schema.json';
import { forEach } from 'lodash';

export const UNSUPPORTED_MCS_LANGUAGES = ['ar', 'da-DK', 'fi-FI', 'hi-IN', 'no-NO', 'pt-BR', 'pt-PT', 'sv-SE','ca-ES'];

export const StandardTabs = {
  TRANSCRIPT: 'TRANSCRIPT',
  KEYWORD: 'KEYWORD',
  REDACTION: 'REDACTION',
  ANALYTICS: 'ANALYTICS',
  METADATA: 'METADATA',
  CALLBACKS: 'CALLBACKS',
};

export const FormFieldTypes = {
  Text: 'Text',
  Radio: 'Radio',
  Select: 'Select',
  MultipleSelect: 'MultipleSelect',
  Switch: 'Switch',
  Checkbox: 'Checkbox',
  VocabularyList: 'VocabularyList',
  MetaData: 'Metadata',
  InputURL: 'InputURL',
  StereoSpeakerList: 'StereoSpeakerList',
  ConvertFileFormat: 'ConvertFileFormat',
};

export const STANDARD_TAB_FIELD = {
  NUMBERS_TO_SYMBOLS: 'NUMBERS_TO_SYMBOLS',
  CHANNEL: 'CHANNEL',
  PRIORITY: 'PRIORITY',
  CUSTOM_VOCABULARIES: 'CUSTOM_VOCABULARIES',
  CONVERT_FILE_FORMAT: 'CONVERT_FILE_FORMAT',
  CONVERT_FILE_OGG_CODEC: 'CONVERT_FILE_OGG_CODEC',
  CONVERT_FILE_ONLY_IF_REDACTED: 'CONVERT_FILE_ONLY_IF_REDACTED',
  STEREO_SPEAKER_LIST: 'STEREO_SPEAKER_LIST',
  STEREO_LEFT_SPEAKER_LIST: 'STEREO_LEFT_SPEAKER_LIST',
  STEREO_RIGHT_SPEAKER_LIST: 'STEREO_RIGHT_SPEAKER_LIST',
  AD_HOC_VOCABULARY_LIST: 'AD_HOC_VOCABULARY_LIST',
  TRANSCRIPT_OTHER_SETTINGS: 'TRANSCRIPT_OTHER_SETTINGS',
  KEYWORDS_OTHER_SETTINGS: 'KEYWORDS_OTHER_SETTINGS',
  REDACTION_OTHER_OPTIONS: 'REDACTION_OTHER_OPTIONS',
  ANALYTICS_OTHER_OPTIONS: 'ANALYTICS_OTHER_OPTIONS',
  CALL_BACKS_OTHER_OPTIONS: 'CALL_BACKS_OTHER_OPTIONS',
  OTHER_SETTINGS: 'OTHER_SETTINGS',
  OTHER_OPTIONS: 'OTHER_OPTIONS',
  CONVERSATION_METRICS: 'CONVERSATION_METRICS',
  KEYWORD_SPOTTING_TEST: 'KEYWORD_SPOTTING_TEST',
  KNOWLEDGE_DISCOVERY: 'KNOWLEDGE_DISCOVERY',
  CONVERSATION: 'CONVERSATION',
  NUMBER_BLASTER: 'NUMBER_BLASTER',
  PREDICTIONS: 'PREDICTIONS',
  PCI_DETECTION: 'PCI_DETECTION',
  SSN_DETECTION: 'SSN_DETECTION',
  PCI_REDACTION: 'PCI_REDACTION',
  SSN_REDACTION: 'SSN_REDACTION',
  CONTENT_FILTERING: 'CONTENT_FILTERING',
  PREDICTIVE_MODEL_NAME: 'PREDICTIVE_MODEL_NAME',
  APPLY_ALL_CATEGORIES: 'APPLY_ALL_CATEGORIES',
  APPLY_CATEGORIES: 'APPLY_CATEGORIES',
  CATEGORIZATION: 'CATEGORIZATION',
  VOICE_FEATURES: 'VOICE_FEATURES',
  SPEAKER_SENTIMENTS: 'SPEAKER_SENTIMENTS',
  METADATA: 'METADATA',
  CALLBACKS_URL: 'CALLBACKS_URL',
  CALLBACKS_URL_ANALYTICS: 'CALLBACKS_URL_ANALYTICS',
  CALLBACKS_METHOD_ANALYTICS: 'CALLBACKS_METHOD_ANALYTICS',
  CALLBACKS_URL_REDACTION: 'CALLBACKS_URL_REDACTION',
  CALLBACKS_METHOD_REDACTION: 'CALLBACKS_METHOD_REDACTION',
  METRICS: 'METRICS',
  SKIP_REDACTED: 'SKIP_REDACTED',
  CONNECTIONS: 'CONNECTIONS',
};

export const SPEECH_MODEL_DATA = {
  Europa: 'Queen',
  Titan: 'King',
  Ganymede: 'Rook',
  Phobos: 'Pawn'
};

export const MEDIA_TABS = {
  PROVIDE_URL: 'PROVIDE_URL',
  UPLOAD: 'UPLOAD',
  UPLOAD_NAME: 'UPLOAD_NAME',
  UPLOAD_DESCRIPTION: 'UPLOAD_DESCRIPTION',
};

export const METADATA_TABS = {
  ENTER_METADATA: 'ENTER_METADATA',
  UPLOAD: 'UPLOAD',
};

export const CHOOSE_UPLOADER_TABS = {
  NEW_CONFIG: 'NEW_CONFIG',
  EXISTING_CONFIG: 'EXISTING_CONFIG',
};

const getLanguageLabel = (languageDetail) => {
  if (languageDetail.Queen) {
    return languageDetail.Queen.description;
  } if (languageDetail.King) {
    return languageDetail.King.description;
  }
  if (languageDetail.Pawn) {
    return languageDetail.Pawn.description;
  }
  return null;
};

export const languages = [];
if (languages.length === 0) {
  forEach(SchemaData, (languageDetail, key) => {
    languages.push({
      name: key,
      label: getLanguageLabel(languageDetail),
      details: languageDetail,
    });
  });
  languages.sort((a, b) => (a.label > b.label) - (a.label < b.label))
}

export const SPEECH_MODEL_MAP = {
  King: 'Titan',
  Queen: 'Europa',
  Bishop: 'Proteus',
  Pawn: 'Phobos'
  // Rook: 'Ganymede',
};

export const SPEAKER_LIST_NAME = {
  AGENT: 'AGENT',
  CALLER: 'CALLER',
};

export const ADVANCED_UPLOADER_TABS = {
  CONFIGURATION: 'CONFIGURATION',
  METADATA: 'UPLOAD',
};

export const UPLOADER_FIELD = {
  STANDARD_UPLOADER: 'STANDARD_UPLOADER',
  ADVANCED_UPLOADER: 'ADVANCED_UPLOADER',
};

export const UPLOADER_COMPONENTS = {
  UPLOAD_MEDIA: 'UPLOAD_MEDIA',
  MAP_CSV: 'MAP_CSV',
  CHOOSE_UPLOADER: 'CHOOSE_UPLOADER',
  CHOOSE_LANGUAGE_MODEL: 'CHOOSE_LANGUAGE_MODEL',
  STANDARD_UPLOADER: 'STANDARD_UPLOADER',
  ADVANCED_UPLOADER: 'ADVANCED_UPLOADER',
  PREVIEW_CONFIG: 'PREVIEW_CONFIG',
  CONNECTIONS: 'CONNECTIONS'
};

export const UPLOAD_MEDIA_FILE_TYPES = [
  'mp3', 'mp4', 'flv', 'wmv', 'avi', 'mpeg', 'aac', 'aiff', 'au', 'ogg', '3gp', 'flac', 'ra', 'm4a', 'wma', 'm4v', 'caf', 'cf', 'mov', 'mpg', 'webm', 'wav', 'asf', 'amr', 'gsm'
];
export const UPLOAD_MEDIA_CSV_FILE_TYPES = [
  'csv', 'xls', 'xlsx',
];

export const CSV_FILE_HEADERS = {
  mediaUrl: 'mediaUrl',
  metadata: [
    'externalId',
    'title',
    'description',
    'externalVersion',
    'callDetails',
    'extended',
  ],
};

export const UPLOADED_FILE_INTERACTION_TYPE = {
  VOICE: 'VOICE',
  TEXT: 'TEXT'
}

export const CONNECTION_FORMAT_TYPE = {
  SPEECH: 'SPEECH',
  CHAT: 'CHAT'
}