const actions = {
  PROFILE_SET: 'PROFILE_SET',
  PROFILE_SET_USER: 'PROFILE_SET_USER',
  PROFILE_CLEAR: 'PROFILE_CLEAR',
  PROFILE_CHANGE: 'PROFILE_CHANGE',
  UPDATE_PREFERENCES: 'UPDATE_PREFERENCES',
  profileSet: (profile) => ({
    type: actions.PROFILE_SET,
    profile,
  }),
  profileSetUser: (user) => ({
    type: actions.PROFILE_SET_USER,
    user,
  }),
  profileClear: () => ({
    type: actions.PROFILE_CLEAR,
  }),
  updatePreferences: (preferences) => ({
    type: actions.UPDATE_PREFERENCES,
    preferences,
  }),
};
export default actions;
