/** @prettier */
import * as Yup from 'yup';
import { isValidArnRoleString, isValidAzureBlobServiceURL } from 'utils';
import { AccountTabs } from 'screens/Account/form/AccountForm';
import { CONTRACT_CODE_VALUES } from '../screens/Account/form/AccountFormInputs';
import validations from './validations';

const accountModel = [
  {
    field: 'id',
    primaryKey: true,
    source: 'server',
  },
  {
    field: 'billingAcknowledgement',
    defaultValue: false,
  },
  {
    field: 'technicalContactFirstName',
    required: true,
  },
  {
    field: 'technicalContactLastName',
    required: true,
  },
  {
    field: 'technicalContactEmail',
    required: true,
  },
  {
    field: 'technicalContactPhone',
    required: true,
  },
  {
    field: 'technicalContactTitle',
  },
  {
    field: 'billingContactFirstName',
    required: true,
  },
  {
    field: 'billingContactLastName',
    required: true,
  },
  {
    field: 'billingContactEmail',
    required: true,
  },
  {
    field: 'billingContactPhone',
    required: true,
  },
  {
    field: 'billingContactTitle',
  },
  {
    field: 'name',
  },
  {
    field: 'bearerToken',
  },
  {
    field: 'allowSuperAdminAccess',
    type: 'boolean',
    defaultValue: true,
  },
  {
    field: 'updatedDt',
    server: true, // @todo: this field is actually a client-side calculated field
    defaultSort: true,
    sortDirection: 'desc',
  },
  {
    field: 'adminFirstName',
  },
  {
    field: 'adminLastName',
  },
  {
    field: 'adminEmail',
  },
  {
    field: 'adminPhone',
  },
  {
    field: 'accountRepresentativeContactFirstName',
    required: false,
  },
  {
    field: 'accountRepresentativeContactLastName',
    required: false,
  },
  {
    field: 'accountRepresentativeContactEmail',
    required: false,
  },
  {
    field: 'accountId',
  },
  {
    field: 'contractCode',
    defaultValue: null,
    required: true,
  },
  {
    field: 'AccountAgent',
    defaultValue: '',
  },
  {
    field: 'TableauAccess',
    defaultValue: '',
  },
  {
    field: 'rcrMode',
    defaultValue: 'CONTENT',
  },
  {
    field: 'tableauSiteEnabled',
    defaultValue: false,
  },
  {
    field: 'embeddedDashboardUrl'
  },
];

/**
 * Should only validate on the customer side.
 */
const accountSchema = ({ shouldValidateEtlConfig = false, isMyAccount = false, focusTab }) => {
  const validationSchema = {
    name: Yup.string()
      .required('Required')
      .test(...validations.stringWithSpacesAndColon)
      .test(...validations.trimSpaces)
      .test(...validations.advancedString2)
      .min(3, 'Minimum 3 characters')
      .max(100, 'Limit 100 characters'),
    siteId: Yup.string()
      .when('contractCode', {
        is: (value) => [CONTRACT_CODE_VALUES.STANDARD_PRICING, CONTRACT_CODE_VALUES.RESELLER_PRICING].includes(value),
        then: Yup.string().required('Required'),
      })
      .optional()
      .test(...validations.trimSpaces)
      .test(...validations.alphaNumeric)
      .max(40, 'Limit 40 characters')
      .nullable(),
    adminEnabled: Yup.boolean(),
    adminFirstName: Yup.string()
      .when('adminEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      })
      .max(50, 'Limit 50 characters')
      .test(...validations.stringWithSpaces)
      .test(...validations.trimSpaces),
    adminLastName: Yup.string()
      .when('adminEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      })
      .max(50, 'Limit 50 characters')
      .test(...validations.stringWithSpaces)
      .test(...validations.trimSpaces),
    adminEmail: Yup.string()
      .when('adminEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      })
      .max(128, 'Limit 128 characters')
      .email('Must be valid email'),
    adminPhone: Yup.string()
      .when('adminEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      })
      .max(20, 'Limit 20 characters')
      .test(...validations.trimSpaces),
    accountRepresentativeContactEmail: Yup.string()
      .nullable()
      .max(128, 'Limit 128 characters')
      .email('Must be valid email'),
    techSameAs: Yup.boolean(),
    technicalContactFirstName: Yup.string()
      .when('techSameAs', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .max(63, 'Limit 63 characters')
      .test(...validations.stringWithSpaces)
      .test(...validations.trimSpaces),
    technicalContactLastName: Yup.string()
      .when('techSameAs', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .max(63, 'Limit 63 characters')
      .test(...validations.stringWithSpaces)
      .test(...validations.trimSpaces),
    technicalContactEmail: Yup.string()
      .when('techSameAs', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .max(128, 'Limit 128 characters')
      .email('Must be valid email'),
    technicalContactPhone: Yup.string()
      .when('techSameAs', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .max(20, 'Limit 20 characters')
      .test(...validations.trimSpaces),
    billingSameAs: Yup.boolean(),
    billingContactFirstName: Yup.string()
      .when('billingSameAs', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .max(63, 'Limit 63 characters')
      .test(...validations.stringWithSpaces)
      .test(...validations.trimSpaces),
    billingContactLastName: Yup.string()
      .when('billingSameAs', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .max(63, 'Limit 63 characters')
      .test(...validations.stringWithSpaces)
      .test(...validations.trimSpaces),
    billingContactEmail: Yup.string()
      .when('billingSameAs', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .max(128, 'Limit 128 characters')
      .email('Must be valid email'),
    billingContactPhone: Yup.string()
      .when('billingSameAs', {
        is: false,
        then: Yup.string()
          .required('Required')
          .test(...validations.trimSpaces),
      })
      .max(20, 'Limit 20 characters'),
    contractCode: Yup.string().required('Required').nullable(),
    AccountAgent: Yup.number().test('valid', 'Cannot be less than 0', (v) => !v || v > 0),
    TableauAccess: Yup.number().test('valid', 'Cannot be less than 0', (v) => !v || v > 0),
    tableauSiteEnabled: Yup.boolean(),
    embeddedDashboardUrl: Yup.string().test(...validations.trimSpaces).url('Must be a valid URL'),
  };

  if (!isMyAccount && focusTab !== AccountTabs.OUTPUT) {
    validationSchema.billingAcknowledgement = Yup.boolean().oneOf([true], 'Must acknowledge before proceeding');
  }

  if (shouldValidateEtlConfig) {
    validationSchema.dataStorage = Yup.string();

    validationSchema.etlConfig = Yup.object().when('dataStorage', {
      is: 'S3',
      then: Yup.object().shape({
        s3Bucket: Yup.string()
          .test(...validations.beginOrEndWithSpace)
          .test('valid-s3-url', 'Invalid S3 bucket URL', (value) => {
            const patterns = [
              /^https?:\/\/([^.]+)\.s3\.([^\.]+)\.amazonaws\.com(.*?)$/,
              /^https?:\/\/s3-([^.]+)\.amazonaws\.com\/([^\/]+)(.*?)$/,
              /^https?:\/\/s3\.([^.]+)\.amazonaws\.com\/([^\/]+)(.*?)$/,
            ];
            return patterns.some((pattern) => pattern.test(value));
          })
          .required('Required'),
        s3Role: Yup.string()
          .trim()
          .max(255, 'Limit 255 characters')
          .required('Required')
          .test(
            'valid',
            'Format should be arn:aws:iam::<account_id>:role/<role_name>',
            (s3Role) => s3Role && isValidArnRoleString(s3Role)
          ),
        s3Folder: Yup.string().trim().max(255, 'Limit 255 characters'),
      }),
    });

    validationSchema.etlConfigAzure = Yup.object().when('dataStorage', {
      is: 'Azure',
      then: Yup.object().shape({
        azureBlobServiceURL: Yup.string()
          .max(255, 'Limit 255 characters')
          .required('Required')
          .test(...validations.beginOrEndWithSpace)
          .test(
            'valid',
            `Format should be "https://<<storage-account-name>>.blob.core.windows.net"`,
            (azureBlobServiceURL) => azureBlobServiceURL && isValidAzureBlobServiceURL(azureBlobServiceURL)
          ),
        azureContainerName: Yup.string()
          .trim()
          .matches(/^[a-z0-9-[a-z0-9-]*[a-z0-9-]$/, 'Only lowercase, numbers and hyphens are allowed')
          .min(3, 'Mininum 3 characters')
          .max(63, 'Limit 63 characters')
          .required('Required'),
        azureSASToken: Yup.string().trim().max(255, 'Limit 255 characters').required('Required'),
      }),
    });
  }

  return Yup.object().shape(validationSchema);
};
export { accountModel, accountSchema };
