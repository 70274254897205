import React from 'react';

export const withLocalStorage = (WrappedComponent) => (props) => {
  const ls = localStorage;

  const getItem = (key) => {
    let data;
    try {
      data = JSON.parse(ls.getItem(key));
    } catch (err) {}
    return data;
  };

  const setItem = (key, data) => {
    ls.setItem(key, JSON.stringify(data));
  };

  const removeItem = (key) => {
    ls.removeItem(key);
  };

  return (
    <WrappedComponent
      {...props}
      getItem={getItem}
      setItem={setItem}
      removeItem={removeItem}
    />
  );
};
