import styled from 'styled-components';

export const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CheckboxWrap = styled.label`
  margin-left: auto;
  margin-right: 20px;
  input { margin-right: 5px; }
`;

export const Required = styled.span`
  :after{
    content: " *";
    color: rgba(255, 0, 0, 0.65);
    font-size: 12px;
  }
`;

export const AccountImportInput = styled.div`
  display: flex;
`;

export const CountInputsWrap = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 460px;
`;