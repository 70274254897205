const categoryActions = {
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',
  FETCH_CATEGORY: 'FETCH_CATEGORY',
  FETCH_CATEGORY_SUCCESS: 'FETCH_CATEGORY_SUCCESS',
  FILTER_CATEGORIES: 'FILTER_CATEGORIES',
  FILTER_CATEGORIES_SUCCESS: 'FILTER_CATEGORIES_SUCCESS',
  SELECT_CATEGORY: 'SELECT_CATEGORY',
  CLEAR_CATEGORY_SELECTED: 'CLEAR_CATEGORY_SELECTED',
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  DELETE__CATEGORY: 'DELETE__CATEGORY',
  BULK_PUBLISH_CATEGORY: 'BULK_PUBLISH_CATEGORY',
  BULK_PUBLISH_CATEGORY_SUCESSS: 'BULK_PUBLISH_CATEGORY_SUCCESS',
  BULK_DELETE_CATEGORY: 'BULK_DELETE_CATEGORY',
  BULK_DELETE_CATEGORY_SUCESSS: 'BULK_DELETE_CATEGORY_SUCCESS',
  CHANGE_CATEGORY: 'CHANGE_CATEGORY',
  CHANGE_CATEGORY_VIEW: 'CHANGE_CATEGORY_VIEW',
  CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',
  SORT_CATEGORIES: 'SORT_CATEGORIES',
  CHANGE_CATEGORY_IMPORT_VIEW: 'CHANGE_CATEGORY_IMPORT_VIEW',
  IMPORT_CATEGORIES: 'IMPORT_CATEGORIES',
  IMPORT_CATEGORIES_SUCCESS: 'IMPORT_CATEGORIES_SUCCESS',
  SAVE_QUERY_AS_CATEGORY: 'SAVE_QUERY_AS_CATEGORY',
  CLEAR_QUERY_AS_CATEGORY: 'CLEAR_QUERY_AS_CATEGORY',
  OPEN_CATEGORY_IN_WORKBENCH: 'OPEN_CATEGORY_IN_WORKBENCH',
  CLEAR_CATEGORY_IN_WORKBENCH: 'CLEAR_CATEGORY_IN_WORKBENCH',
  UPDATE_JOB_REAPPLY_DATE: 'UPDATE_JOB_REAPPLY_DATE',
  UPDATE_JOB_REAPPLY_MEDIA_FILTER_EXPRESSION: 'UPDATE_JOB_REAPPLY_MEDIA_FILTER_EXPRESSION',
  FETCH_CATEGORY_CHART: 'FETCH_CATEGORY_CHART',
  FETCH_CATEGORY_CHART_SUCCESS: 'FETCH_CATEGORY_CHART_SUCCESS',
  FETCH_CATEGORY_CHART_FAILURE: 'FETCH_CATEGORY_CHART_FAILURE',
  CLEAR_CATEGORY_CHART: 'CLEAR_CATEGORY_CHART',
  CATEGORY_CHART_ISLOADING: 'CATEGORY_CHART_ISLOADING',
  GET_CALL_COUNT: 'GET_CALL_COUNT',
  GET_CALL_COUNT_SUCCESS: 'GET_CALL_COUNT_SUCCESS',

  sortCategories: (e) => ({
    type: categoryActions.SORT_CATEGORIES,
    sort: e.sort,
  }),
  clearCategories: () => ({
    type: categoryActions.CLEAR_CATEGORIES,
  }),
  clearCategorySelected: () => ({
    type: categoryActions.CLEAR_CATEGORY_SELECTED,
  }),
  fetchCategories: (options) => ({
    type: categoryActions.FETCH_CATEGORIES,
    options,
    retainFilters: options?.clearExistingFilters,
    filters: options?.filters,
  }),
  filterCategories: (filters) => ({
    type: categoryActions.FILTER_CATEGORIES,
    filters,
  }),
  selectCategory: (selectedItem) => ({
    type: categoryActions.SELECT_CATEGORY,
    selectedItem,
  }),
  addCategory: (data, onFinish) => ({
    type: categoryActions.ADD_CATEGORY,
    data,
    onFinish,
  }),
  editCategory: (data, id, onFinish) => ({
    type: categoryActions.EDIT_CATEGORY,
    data,
    id,
    onFinish,
  }),
  fetchCategory: (id) => ({
    type: categoryActions.FETCH_CATEGORY,
    id,
  }),
  bulkDeleteCategory: (data) => ({
    type: categoryActions.BULK_DELETE_CATEGORY,
    data,
  }),
  bulkPublishCategory: (data, publish) => ({
    type: categoryActions.BULK_PUBLISH_CATEGORY,
    data,
    publish,
  }),
  deleteCategory: (id) => ({
    type: categoryActions.DELETE__CATEGORY,
    id,
  }),
  changeCategoryView: (selectedItem) => ({
    type: categoryActions.CHANGE_CATEGORY_VIEW,
    selectedItem,
  }),
  changeCategoryImportView: (selectedItem) => ({
    type: categoryActions.CHANGE_CATEGORY_IMPORT_VIEW,
    selectedItem,
  }),
  importCategories: (data, mappings, onFinish,fetchCategory) => ({
    type: categoryActions.IMPORT_CATEGORIES,
    data,
    mappings,
    onFinish,fetchCategory
  }),
  saveQueryAsCategory: (tab) => ({
    type: categoryActions.SAVE_QUERY_AS_CATEGORY,
    tab,
  }),
  clearQueryAsCategory: () => ({
    type: categoryActions.CLEAR_QUERY_AS_CATEGORY,
  }),
  openCategoryInWorkbench: (category) => ({
    type: categoryActions.OPEN_CATEGORY_IN_WORKBENCH,
    category,
  }),
  clearCategoryInWorkbench: () => ({
    type: categoryActions.CLEAR_CATEGORY_IN_WORKBENCH,
  }),
  updateJobReapplyMediaFilterExpression: (mediaFilterExpression) => ({
    type: categoryActions.UPDATE_JOB_REAPPLY_MEDIA_FILTER_EXPRESSION,
    mediaFilterExpression,
  }),
  updateJobReapplyDate: (dateType, date) => ({
    type: categoryActions.UPDATE_JOB_REAPPLY_DATE,
    dateType,
    date,
  }),

  fetchCategoryChart: (data, title) => ({
    type: categoryActions.FETCH_CATEGORY_CHART,
    data,
    title,
  }),

  clearVisualizationChart: () => ({
    type: categoryActions.CLEAR_CATEGORY_CHART,
  }),

  // N
  getCallsCount: (data, queryParams) => ({
    type: categoryActions.GET_CALL_COUNT,
    data,
    queryParams,
  }),
};
export default categoryActions;
