/** @prettier */
import { all, takeEvery, fork, call, put } from 'redux-saga/effects';
import api from 'services';
import platformApi from 'services/platform';
import { transformSpottingGroups, prepSpottingGroup } from 'transform/spottingGroup';
import actions from './actions';

const entityType = 'spottingGroups';

export function* fetchSpottingGroupsSaga() {
  yield takeEvery(actions.FETCH_SPOTTINGGROUPS, function* () {
    const data = yield call(api.fetchAll, { entityType, transform: transformSpottingGroups });
    if (data) {
      const existingNames = data.map((x) => x.name.toLowerCase());
      yield put({ type: actions.FETCH_SPOTTINGGROUPS_SUCCESS, data, existingNames });
    } else {
      yield put({ type: actions.FETCH_SPOTTINGGROUPS_FAILURE });
    }
  });
}

export function* addSpottingGroupSaga() {
  yield takeEvery(actions.ADD_SPOTTINGGROUP, function* ({ data, id, onFinish }) {
    const spottingGroup = yield call(platformApi.createItem, {
      entityType,
      data: prepSpottingGroup(data),
      id: encodeURIComponent(id || data.name),
    });
    if (spottingGroup) {
      yield put({ type: actions.ADD_SPOTTINGGROUP_SUCCESS, selectedItem: spottingGroup });
      yield put({ type: actions.FETCH_SPOTTINGGROUPS });
    } else {
      yield put({ type: actions.ADD_SPOTTINGGROUP_FAILURE });
    }
    onFinish();
  });
}

export function* editSpottingGroupSaga() {
  yield takeEvery(actions.EDIT_SPOTTINGGROUP, function* ({ data, id, onFinish }) {
    const spottingGroup = yield call(api.editItem, {
      entityType,
      data: prepSpottingGroup(data),
      id: encodeURIComponent(id),
    });
    if (spottingGroup) {
      yield put({ type: actions.FETCH_SPOTTINGGROUPS });
      yield put({ type: actions.EDIT_SPOTTINGGROUP_SUCCESS, selectedItem: spottingGroup });
    } else {
      yield put({ type: actions.EDIT_SPOTTINGGROUP_FAILURE });
    }
    onFinish();
  });
}

export function* deleteSpottingGroupSaga() {
  yield takeEvery(actions.DELETE__SPOTTINGGROUP, function* () {});
}

export default function* rootSaga() {
  yield all([
    fork(fetchSpottingGroupsSaga),
    fork(addSpottingGroupSaga),
    fork(editSpottingGroupSaga),
    fork(deleteSpottingGroupSaga),
  ]);
}
