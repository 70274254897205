const preferenceActions = {
  FETCH_PREFERENCES: 'FETCH_PREFERENCES',
  FETCH_PREFERENCES_SUCCESS: 'FETCH_PREFERENCES_SUCCESS',
  FETCH_PREFERENCES_FAILURE: 'FETCH_PREFERENCES_FAILURE',
  SELECT_PREFERENCE: 'SELECT_PREFERENCE',
  ADD_PREFERENCE: 'ADD_PREFERENCE',
  ADD_PREFERENCE_SUCCESS: 'ADD_PREFERENCE_SUCCESS',
  ADD_PREFERENCE_FAILURE: 'ADD_PREFERENCE_FAILURE',
  EDIT_PREFERENCE: 'EDIT_PREFERENCE',
  EDIT_PREFERENCE_SUCCESS: 'EDIT_PREFERENCE_SUCCESS',
  EDIT_PREFERENCE_FAILURE: 'EDIT_PREFERENCE_FAILURE',
  PATCH_PREFERENCE: 'PATCH_WAREHOUSE',
  GET_PREFERENCE: 'GET_PREFERENCE',
  DELETE_PREFERENCE: 'DELETE__PREFERENCE',
  CHANGE_PREFERENCE: 'CHANGE_PREFERENCE',
  CHANGE_PREFERENCE_VIEW: 'CHANGE_PREFERENCE_VIEW',
  CLEAR_PREFERENCES: 'CLEAR_PREFERENCES',
  SORT_PREFERENCES: 'SORT_PREFERENCES',
  sortPreferences: (e) => ({
    type: preferenceActions.SORT_PREFERENCES,
    sort: e.sort,
  }),
  clearPreferences: (options) => ({
    type: preferenceActions.CLEAR_PREFERENCES,
  }),
  fetchPreferences: () => ({
    type: preferenceActions.FETCH_PREFERENCES,
  }),
  selectPreference: (selectedItem) => ({
    type: preferenceActions.SELECT_PREFERENCE,
    selectedItem,
  }),
  addPreference: (data) => ({
    type: preferenceActions.ADD_PREFERENCE,
    data,
  }),
  editPreference: (data, context, onFinish) => ({
    type: preferenceActions.EDIT_PREFERENCE,
    data,
    context,
    onFinish,
  }),
  patchPreference: (data) => ({
    type: preferenceActions.PATCH_PREFERENCE,
    data,
  }),
  getPreference: (data) => ({
    type: preferenceActions.GET_PREFERENCE,
    data,
  }),
  deletePreference: (id) => ({
    type: preferenceActions.DELETE_PREFERENCE,
    id,
  }),
  changePreferenceView: (selectedItem) => ({
    type: preferenceActions.CHANGE_PREFERENCE_VIEW,
    selectedItem,
  }),
};
export default preferenceActions;
