const fields = {
  accountid: {
    field: 'accountid',
    dynamicOptions: true,
  },
  id: {
    field: 'id',
    primaryKey: true,
    source: 'server',
  },
  name: {
    field: 'name',
  },
  description: {
    field: 'description',
  },
  enabled: {
    field: 'enabled',
    type: 'boolean',
    defaultValue: true,
  },
  active: {
    field: 'active',
    type: 'boolean',
    defaultValue: true,
  },
  status: {
    field: 'status',
    type: 'enum',
    options: [
      { value: 'ACTIVE', label: 'Active' },
      { value: 'INACTIVE', label: 'Inactive' },
    ],
    defaultValue: 'ACTIVE',
    required: true,
  },
  label: {
    field: 'label',
    required: true,
  },
  ontid: {
    field: 'ontid',
    primaryKey: true,
    source: 'server',
  },
  extid: {
    field: 'extid',
    defaultValue: 'autoForeignKey',
    required: true,
  },
};

export default fields;
