/** @prettier */
import { all, takeEvery, fork, call, put, select } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import api from 'services';
import { showToast } from 'utils/toast';
import get from 'lodash/get';
import { transformVboChartsData, transformVboMeasures, transformVboDimensions } from 'transform/vboDashboards';
import { VBODashboardConstants } from 'screens/VBODashboards/constants';
import actions from './actions';
import templateDashboardActions from '../templateDashboards/actions';

export function* fetchVboDashboardsSaga() {
  yield takeEvery(actions.FETCH_VBO_DASHBOARDS, function* () {
    const res = yield call(api.get, {
      url: 'dashboards',
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_VBO_DASHBOARDS_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_VBO_DASHBOARDS_FAILURE });
    }
  });
}

export function* fetchVboDashboardDataSaga() {
  yield takeEvery(actions.FETCH_VBO_DASHBOARD_DATA, function* ({ dashboardId }) {
    yield put({ type: actions.SET_UPDATING_VBO_DASHBOARD, data: true });
    const res = yield call(api.get, {
      url: `dashboards/${dashboardId}`,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_VBO_DASHBOARD_DATA_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_VBO_DASHBOARD_DATA_FAILURE });
    }
    yield put({ type: actions.SET_UPDATING_VBO_DASHBOARD, data: false });
  });
}

export function* fetchVboDashboardChartsSaga() {
  yield takeEvery(actions.FETCH_VBO_DASHBOARD_DATA, function* ({ dashboardId }) {
    const res = yield call(api.get, {
      url: `dashboards/${dashboardId}/charts`,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_VBO_DASHBOARD_CHARTS_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_VBO_DASHBOARD_CHARTS_FAILURE });
    }
  });
}

export function* fetchVboDashboardChartsDataSaga() {
  yield takeEvery(actions.FETCH_VBO_DASHBOARD_CHARTS_DATA, function* ({ chartId, queryParams = '' }) {
    // Only show ...isLoading on initial page load.
    const state = yield select();
    const chartsData = get(state, 'vboDashboards.chartsData');
    const isLoading = !chartsData[chartId];
    put({
      type: actions.VBO_DASHBOARD_CHARTS_DATA_LOADING,
      data: { id: chartId, isLoading },
    });
    const res = yield call(api.get, {
      url: `charts/${chartId}/metrics?${queryParams}`,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_VBO_DASHBOARD_CHARTS_DATA_SUCCESS,
        data: transformVboChartsData(res.data, get(state, 'vboDashboards.filterData')),
      });
      put({
        type: actions.VBO_DASHBOARD_CHARTS_DATA_LOADING,
        data: { id: chartId, isLoading: false },
      });
    } else {
      // yield put({
      //   type: actions.VBO_DASHBOARD_CHARTS_DATA_LOADING,
      //   data: { id: chartId, isLoading: false },
      // });
      yield put({ type: actions.FETCH_VBO_DASHBOARD_CHARTS_DATA_FAILURE });
    }
  });
}

export function* updateVboDashboardDataSaga() {
  yield takeEvery(actions.UPDATE_VBO_DASHBOARD, function* ({ dashboardId, data }) {
    yield put({ type: actions.SET_UPDATING_VBO_DASHBOARD, data: true });
    const res = yield call(api.put, {
      url: `dashboards/${dashboardId}`,
      data,
      msg: 'Report updated successfully',
    });
    if (res) {
      yield put({
        type: actions.FETCH_VBO_DASHBOARD_DATA_SUCCESS,
        data: res.data,
      });
      yield put({
        type: actions.FETCH_VBO_DASHBOARD_DATA,
        dashboardId: res.data.id,
      });
      yield put({
        type: actions.TOGGLE_DASHBOARD_EDITOR_SIDEBAR,
        dashboardId: false,
      });
    } else {
      yield put({ type: actions.FETCH_VBO_DASHBOARD_DATA_FAILURE });
    }
  });
}

export function* deleteVboDashboardDataSaga() {
  yield takeEvery(actions.DELETE_DASHBOARD_REPORT, function* ({ dashboardId, history }) {
    const res = yield call(api.delete, {
      url: `dashboards/${dashboardId}`,
      msg: 'Report deleted successfully',
    });
    if (res) {
      yield put({ type: actions.FETCH_VBO_DASHBOARDS });
      history.push('/app/myReports');
    }
  });
}

export function* cloneVboDashboardDataSaga() {
  yield takeEvery(actions.CLONE_DASHBOARD_REPORT, function* ({ data }) {
    const res = yield call(api.post, {
      url: 'dashboards/clone',
      data,
      msg: 'Report successfully cloned',
    });
    if (res) {
      yield put({ type: actions.FETCH_VBO_DASHBOARDS });
    }
  });
}

export function* addNewVboDashboardChartSaga() {
  yield takeEvery(actions.ADD_NEW_DASHBOARD_CHART, function* ({ data, dashboardId, isDuplicate = false }) {
    yield put({ type: actions.SET_UPDATING_VBO_DASHBOARD, data: true });
    const res = yield call(api.post, {
      url: `dashboards/${dashboardId}/charts`,
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.ADD_NEW_CHART_SUCCESS,
        data: res.data,
      });
      if (isDuplicate) {
        showToast('success', { task: 'Chart duplicated successfully' });
      }
    }
  });
}

export function* deleteVboDashboardChartSaga() {
  yield takeEvery(actions.DELETE_DASHBOARD_CHART, function* ({ chartId, isText, onFinish }) {
    yield put({ type: actions.SET_UPDATING_VBO_DASHBOARD, data: true });
    const res = yield call(api.delete, {
      url: `charts/${chartId}`,
      disableNotification: true,
    });
    if (res) {
      onFinish(res);
      showToast('success', { task: `${isText ? 'Text' : 'Chart'} deleted successfully.` });
    }
  });
}

export function* exportDashboardSaga() {
  yield takeEvery(actions.EXPORT_DASHBOARD, function* ({ dashboardId, onlyDownload = false }) {
    const res = yield call(api.get, {
      url: `dashboards/${dashboardId}/export`,
      msg: 'Report exported successfully',
    });
    if (res && !onlyDownload) {
      yield put({ type: actions.IMPORT_DASHBOARD, data: res.data });
    }
    if (res && onlyDownload) {
      yield put({ type: actions.DOWNLOAD_REPORT, data: res.data });
    }
  });
}

export function* importDashboardSaga() {
  yield takeEvery(actions.IMPORT_DASHBOARD, function* ({ data, history }) {
    const res = yield call(api.post, {
      url: 'dashboards/import',
      data,
      msg: 'Report imported successfully',
    });
    if (res) {
      if (history) {
        yield put({
          type: templateDashboardActions.TEMPLATE_DASHBOARD_LOADING,
          data: false,
        });
        yield put({
          type: templateDashboardActions.TOGGLE_VIEW_TEMPLATE_MODAL,
          data: false,
          template: {},
        });
        history.push(`/app/myReports/${res.data.dashboard.id}`);
      } else {
        yield put({
          type: actions.FETCH_VBO_DASHBOARDS,
        });
      }
    }
  });
}

export function* fetchChartsDataSaga() {
  yield takeEvery(actions.FETCH_CHARTS_DATA, function* ({ chartId, resData }) {
    const res = yield call(api.get, {
      url: `charts/${chartId}`,
      disableNotification: true,
    });
    if (res) {
      yield put({ type: actions.FETCH_CHARTS_DATA_SUCCESS, data: res.data });
      if (resData) {
        resData(res.data);
      }
    }
  });
}

export function* editChartsDataSaga() {
  yield takeEvery(actions.EDIT_CHART_DATA, function* ({ chartId, data, filterUrl }) {
    yield put({
      type: actions.LOADING_EDIT_SIDEBAR,
      data: true,
      chartData: data,
    });
    const res = yield call(api.put, {
      url: `charts/${chartId}`,
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.CLEAR_CHART_DATA,
        chartId,
      });
      yield put({
        type: actions.CHANGE_EDIT_CHART_TYPE,
        data,
      });
      yield put({
        type: actions.FETCH_VBO_DASHBOARD_DATA,
        dashboardId: data.dashboardId,
      });
      yield put({
        type: actions.FETCH_VBO_DASHBOARD_CHARTS_DATA,
        chartId,
        queryParams: filterUrl,
      });
      yield put({
        type: actions.TOGGLE_DASHBOARD_EDITOR_SIDEBAR,
        dashboardId: false,
      });

      yield put({
        type: actions.LOADING_EDIT_SIDEBAR,
        data: false,
        chartData: data,
      });
      showToast('success', {
        task: `${data?.type === VBODashboardConstants.MAP_TYPES.SIMPLE_TEXT ? 'Text' : 'Chart'} edited successfully.`,
      });
    } else {
      yield put({
        type: actions.LOADING_EDIT_SIDEBAR,
        data: false,
        chartData: data,
      });
    }
  });
  //
}

export function* addNewChartsSaga() {
  yield takeEvery(actions.ADD_NEW_CHART, function* ({ dashboardId, data }) {
    yield put({ type: actions.SET_UPDATING_VBO_DASHBOARD, data: true });
    yield put({
      type: actions.LOADING_EDIT_SIDEBAR,
      data: true,
      chartData: data,
    });
    const res = yield call(api.post, {
      url: `dashboards/${dashboardId}/charts`,
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.LOADING_EDIT_SIDEBAR,
        data: false,
        chartData: data,
      });
      yield put({
        type: actions.ADD_NEW_CHART_SUCCESS,
        data: res.data,
      });
      localStorage.removeItem('blurredSelects');
      showToast('success', {
        task: `${data?.type === VBODashboardConstants.MAP_TYPES.SIMPLE_TEXT ? 'Text' : 'Chart'} added successfully.`,
      });
    } else {
      yield put({
        type: actions.LOADING_EDIT_SIDEBAR,
        data: true,
        chartData: data,
      });
    }
  });
}

export function* addVboDashboardSaga() {
  yield takeEvery(actions.ADD_VBO_DASHBOARD, function* ({ data, history }) {
    try {
      const res = yield call(api.post, {
        url: 'dashboards',
        data,
        msg: 'Report added successfully',
      });
      if (res && res.data) {
        yield put({
          type: actions.TOGGLE_RENAME_FORM,
        });
        history.push(`/app/myReports/${res.data.id}?isEdit=true`);
      }
    } catch (err) {
      console.error(err);
    }
  });
}

export function* fetchCategoryCollectionsSaga() {
  yield takeEvery(actions.FETCH_CATEGORY_COLLECTIONS, function* () {
    const res = yield call(api.get, {
      url: 'collections',
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_CATEGORY_COLLECTIONS_SUCCESS,
        data: res.data,
      });
    } else {
      yield put({ type: actions.FETCH_CATEGORY_COLLECTIONS_FAILURE });
    }
  });
}

export function* fetchDashboardFiltersSaga() {
  yield takeEvery(
    actions.FETCH_DASHBOARD_FILTERS,
    function* ({ dashboardId, data: { filterName, filterLabel, filterType, selectedValue } }) {
      const res = yield call(api.get, {
        url: `dashboards/${dashboardId}/filters/${filterName}`,
        disableNotification: true,
      });
      if (res) {
        yield put({
          type: actions.FETCH_DASHBOARD_FILTERS_SUCCESS,
          data: {
            filterName,
            data: res.data,
            filterLabel,
            filterType,
            selectedValue,
          },
        });
      } else {
        yield put({ type: actions.FETCH_CATEGORY_COLLECTIONS_FAILURE });
      }
    }
  );
}

export function* fetchDashboardDimensionValuesSaga() {
  yield takeEvery(
    actions.FETCH_DASHBOARD_DIMENSION_VALUES,
    function* ({ dashboardId, data: { filterName, filterLabel, filterType, selectedValue } }) {
      const res = yield call(api.get, {
        url: `dashboards/${dashboardId}/filters/${filterName}`,
        disableNotification: true,
      });
      if (res) {
        yield put({
          type: actions.FETCH_DASHBOARD_DIMENSION_VALUES_SUCCESS,
          data: {
            filterName,
            data: res.data,
            filterLabel,
            filterType,
            selectedValue,
          },
        });
      } else {
        yield put({ type: actions.FETCH_DASHBOARD_DIMENSION_VALUES_FAILURE });
      }
    }
  );
}

export function* cloneToAccountDashboardSaga() {
  yield takeEvery(actions.CLONE_TO_ACCOUNT, function* ({ data }) {
    const res = yield call(api.post, {
      url: 'dashboards/multi-account-clone',
      data,
      msg: 'Dashboard successfully cloned',
    });
    if (res) {
      yield put({
        type: actions.TOGGLE_CLONE_TO_ACCOUNT_POPUP,
      });
    }
  });
}

export function* getCallsListSaga() {
  yield takeEvery(actions.GET_CALLS_LIST, function* ({ dashboardId, queryParams = '' }) {
    const res = yield call(api.get, {
      url: `dashboards/${dashboardId}/calls?${queryParams}`,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.GET_CALLS_LIST_SUCCESS,
        data: res.data,
      });
    }
  });
}

export function* getCallsCountSaga() {
  yield takeEvery(actions.GET_CALL_COUNT, function* ({ data, queryParams = '' }) {
    yield put({
      type: actions.SET_CALLS_COUNT_LOADING,
      data: true,
    });
    const res = yield call(api.post, {
      url: `metrics?${queryParams}`,
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.GET_CALL_COUNT_SUCCESS,
        data: res.data,
      });
      yield put({
        type: actions.SET_CALLS_COUNT_LOADING,
        data: false,
      });
    }
  });
}

export function* fetchMeasureCollectionSaga() {
  yield takeEvery(actions.FETCH_MEASURE_COLLECTION, function* ({ category }) {
    const res = yield call(api.get, {
      url: `collections/${category}/categories`,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_MEASURE_COLLECTION_SUCCESS,
        category,
        data: res.data,
      });
    } else {
      // yield put({ type: actions.FETCH_MEASURE_COLLECTION_FAILURE });
    }
  });
}

export function* fetchMeasuresSaga() {
  yield takeEvery(actions.FETCH_MEASURES, function* (data) {
    const res = yield call(api.get, {
      url: 'measures',
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_MEASURES_SUCCESS,
        data: transformVboMeasures(res.data),
      });
    }
  });
}

export function* fetchDimensionsSaga() {
  yield takeEvery(actions.FETCH_DIMENSIONS, function* (data) {
    const res = yield call(api.get, {
      url: 'dimensions',
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_DIMENSIONS_SUCCESS,
        data: transformVboDimensions(res.data),
      });
    }
  });
}

export function* uploadTemplateImage() {
  yield takeEvery(actions.UPLOAD_TEMPLATE_IMAGE, function* ({ imageFile, previewImageFile, cb }) {
    if (imageFile || previewImageFile) {
      let resThumb;
      let resPreview;
      if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);
        resThumb = yield call(api.postFile, {
          url: 'images',
          data: formData,
          disableNotification: true,
        });
      }

      if (previewImageFile) {
        const formData = new FormData();
        formData.append('image', previewImageFile);
        resPreview = yield call(api.postFile, {
          url: 'images',
          data: formData,
          disableNotification: true,
        });
      }

      if (resThumb || resPreview) {
        yield put({ type: actions.UPLOAD_TEMPLATE_IMAGE_SUCCESS });
        cb?.(resThumb?.data?.imageUrl, resPreview?.data?.imageUrl);
      }
    } else {
      console.error('something went wrong in template image upload');
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchVboDashboardsSaga),
    fork(fetchVboDashboardDataSaga),
    fork(fetchVboDashboardChartsSaga),
    fork(fetchVboDashboardChartsDataSaga),
    fork(updateVboDashboardDataSaga),
    fork(deleteVboDashboardDataSaga),
    fork(cloneVboDashboardDataSaga),
    fork(addNewVboDashboardChartSaga),
    fork(deleteVboDashboardChartSaga),
    fork(exportDashboardSaga),
    fork(importDashboardSaga),
    fork(fetchChartsDataSaga),
    fork(editChartsDataSaga),
    fork(addNewChartsSaga),
    fork(addVboDashboardSaga),
    fork(fetchCategoryCollectionsSaga),
    fork(fetchDashboardFiltersSaga),
    fork(cloneToAccountDashboardSaga),
    fork(getCallsCountSaga),
    fork(getCallsListSaga),
    fork(fetchMeasureCollectionSaga),
    fork(fetchMeasuresSaga),
    fork(fetchDimensionsSaga),
    fork(fetchDashboardDimensionValuesSaga),
    fork(uploadTemplateImage),
  ]);
}
