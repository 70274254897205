import { getBaseReducer } from 'utils';
import { bearerTokenModel as model } from 'models/bearerToken';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  newBearerToken: null,
  entityType: 'bearerTokens',
  loadingState:false,
  bearerTokenFormStep: 0
};

export default function bearerTokenReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_BEARERTOKENS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_BEARERTOKENS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_BEARERTOKENS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
        filters: action.filters,
      };
    case actions.SELECT_BEARERTOKEN:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_BEARERTOKEN_SUCCESS:
      return {
        ...state,
        newBearerToken: action.newBearerToken,
        data: [
          action.newBearerToken,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.CLEAR_NEW_BEARERTOKEN_SUCCESS:
      return {
        ...state,
        newBearerToken: null,
      };
    case actions.EDIT_BEARERTOKEN_SUCCESS:
      return {
        ...state,
        newBearerToken: null,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__BEARERTOKEN:
      return {
        ...state,
        newBearerToken: null,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_BEARERTOKEN_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
      case actions.BEARER_TOKEN_LOADING_STATE:
      return {
        ...state,
        loadingState: action.state ,
      };
      case actions.ADD_BEARER_TOKEN_STEP:
      return {
        ...state,
        selectedItem: action.step === 0 ? {} : state.selectedItem,
        bearerTokenFormStep: action.step
      };
      case actions.SET_FORM_VALUE: 
      console.log(action.value)
      return {
        ...state,
        selectedItem: action.value ? {...state.selectedItem, ...action.value} : {}
      }
    default:
      return state;
  }
}
