import reduxUtils from '../reduxUtils';

const mappingActions = {
  ...(reduxUtils.getActions('mappings')),
  CLEAR_MAPPING_SELECTED: 'CLEAR_MAPPING_SELECTED',
  CHANGE_MAPPING_IMPORT_VIEW: 'CHANGE_MAPPING_IMPORT_VIEW',
  IMPORT_MAPPINGS: 'IMPORT_MAPPINGS',
  IMPORT_MAPPINGS_SUCCESS: 'IMPORT_MAPPINGS_SUCCESS',
  IMPORT_MAPPINGS_FAILURE: 'IMPORT_MAPPINGS_FAILURE',
  FETCH_METADATA: 'FETCH_METADATA',
  FETCH_METADATA_SUCCESS: 'FETCH_METADATA_SUCCESS',
  FETCH_METADATA_FAILURE: 'FETCH_METADATA_FAILURE',
  FETCH_CALL_DETAILS_METADATA_MAPPINGS: 'FETCH_CALL_DETAILS_METADATA_MAPPINGS',
  FETCH_CALL_DETAILS_METADATA_MAPPINGS_SUCCESS: 'FETCH_CALL_DETAILS_METADATA_MAPPINGS_SUCCESS',
  FETCH_CALL_DETAILS_METADATA_MAPPINGS_FAILURE: 'FETCH_CALL_DETAILS_METADATA_MAPPINGS_FAILURE',
  FETCH_CALL_DETAILS_METADATA: 'FETCH_CALL_DETAILS_METADATA',
  FETCH_CALL_DETAILS_METADATA_SUCCESS: 'FETCH_CALL_DETAILS_METADATA_SUCCESS',
  FETCH_CALL_DETAILS_METADATA_FAILURE: 'FETCH_CALL_DETAILS_METADATA_FAILURE',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  ADD_CALL_DETAIL_MAPPING: 'ADD_CALL_DETAIL_MAPPING',
  ADD_CALL_DETAIL_MAPPING_SUCCESS: 'ADD_CALL_DETAIL_MAPPING_SUCCESS',
  ADD_CALL_DETAIL_MAPPING_FAILURE: 'ADD_CALL_DETAIL_MAPPING_FAILURE',
  EDIT_CALL_DETAIL_MAPPING: 'EDIT_CALL_DETAIL_MAPPING',
  EDIT_CALL_DETAIL_MAPPING_SUCCESS: 'EDIT_CALL_DETAIL_MAPPING_SUCCESS',
  EDIT_CALL_DETAIL_MAPPING_FAILURE: 'EDIT_CALL_DETAIL_MAPPING_FAILURE',
  DELETE_CALL_DETAIL_MAPPING: 'DELETE_CALL_DETAIL_MAPPING',
  DELETE_CALL_DETAIL_MAPPING_SUCCESS: 'DELETE_CALL_DETAIL_MAPPING_SUCCESS',
  DELETE_CALL_DETAIL_MAPPING_FAILURE: 'DELETE_CALL_DETAIL_MAPPING_FAILURE',
  CHANGE_CALL_DETAILS_MAPPING_IMPORT_VIEW: 'CHANGE_CALL_DETAILS_MAPPING_IMPORT_VIEW',
  IMPORT_CALL_DETAILS_MAPPINGS: 'IMPORT_CALL_DETAILS_MAPPINGS',
  IMPORT_CALL_DETAILS_MAPPINGS_SUCCESS: 'IMPORT_CALL_DETAILS_MAPPINGS_SUCCESS',
  IMPORT_CALL_DETAILS_MAPPINGS_FAILURE: 'IMPORT_CALL_DETAILS_MAPPINGS_FAILURE',
  FETCH_MAPPINGS_FAILURE: 'FETCH_MAPPINGS_FAILURE',
  FETCH_MAPPING_FAILURE: 'FETCH_MAPPING_FAILURE',
  ADD_MAPPING_FAILURE: 'ADD_MAPPING_FAILURE',
  clearMappingSelected: (options) => ({
    type: mappingActions.CLEAR_MAPPING_SELECTED,
  }),
  changeMappingImportView: (data) => ({
    type: mappingActions.CHANGE_MAPPING_IMPORT_VIEW,
    data,
  }),
  changeCallDetailsMappingImportView: (data) => ({
    type: mappingActions.CHANGE_CALL_DETAILS_MAPPING_IMPORT_VIEW,
    data,
  }),
  importMappings: (data, mappings) => ({
    type: mappingActions.IMPORT_MAPPINGS,
    data,
    mappings,
  }),
  fetchMetadata: () => ({
    type: mappingActions.FETCH_METADATA,
  }),
  fetchCallDetailsMetadataMappings: () => ({
    type: mappingActions.FETCH_CALL_DETAILS_METADATA_MAPPINGS,
  }),
  fetchCallDetailsMetadataMappingsSuccess: (data) => ({
    type: mappingActions.FETCH_CALL_DETAILS_METADATA_MAPPINGS_SUCCESS,
    data
  }),
  fetchCallDetailsMetadataMappingsFailure: () => ({
    type: mappingActions.FETCH_CALL_DETAILS_METADATA_MAPPINGS_FAILURE,
  }),
  fetchCallDetailsMetadata: () => ({
    type: mappingActions.FETCH_CALL_DETAILS_METADATA,
  }),
  fetchCallDetailsMetadataSuccess: (data) => ({
    type: mappingActions.FETCH_CALL_DETAILS_METADATA_SUCCESS,
    data
  }),
  fetchCallDetailsMetadataFailure: (data) => ({
    type: mappingActions.FETCH_CALL_DETAILS_METADATA_FAILURE,
    data
  }),
  seTabActive: (tab) => ({
    type: mappingActions.SET_ACTIVE_TAB,
    tab
  }),
  addCallDetailMapping: (data) => ({
    type: mappingActions.ADD_CALL_DETAIL_MAPPING,
    data
  }),
  addCallDetailMappingSuccess: (data) => ({
    type: mappingActions.ADD_CALL_DETAIL_MAPPING_SUCCESS,
    data
  }),
  addCallDetailMappingFaiure: () => ({
    type: mappingActions.ADD_CALL_DETAIL_MAPPING_FAILURE,
  }),
  editCallDetailMapping: (data) => ({
    type: mappingActions.EDIT_CALL_DETAIL_MAPPING,
    data
  }),
  editCallDetailMappingSuccess: (data) => ({
    type: mappingActions.EDIT_CALL_DETAIL_MAPPING_SUCCESS,
    data
  }),
  editCallDetailMappingFaiure: () => ({
    type: mappingActions.EDIT_CALL_DETAIL_MAPPING_FAILURE,
  }),
  deleteCallMapping: (mappingId) => ({
    type: mappingActions.DELETE_CALL_DETAIL_MAPPING,
    mappingId
  }),
  deleteCallMappingSuccess: () => ({
    type: mappingActions.DELETE_CALL_DETAIL_MAPPING_SUCCESS,
  }),
  deleteCallMappingFailure: () => ({
    type: mappingActions.DELETE_CALL_DETAIL_MAPPING_FAILURE,
  }),
  importCallDetailMappings: (data, mappings) => ({
    type: mappingActions.IMPORT_CALL_DETAILS_MAPPINGS,
    data,
    mappings,
  }),
  importCallDetailMappingsSuccess: (data, mappings) => ({
    type: mappingActions.IMPORT_CALL_DETAILS_MAPPINGS_SUCCESS,
    data,
    mappings,
  }),
  importCallDetailMappingsFailure: () => ({
    type: mappingActions.IMPORT_CALL_DETAILS_MAPPINGS_FAILURE,
  }),
};

export default mappingActions;
