import reduxUtils from '../reduxUtils';

const checklistItemActions = {
  ...(reduxUtils.getActions('checklistItems')),
  CLEAR_CHECKLISTITEM_SELECTED: 'CLEAR_CHECKLISTITEM_SELECTED',
  CREATE_TEMP_POPUP: 'CREATE_TEMP_POPUP',
  EDIT_CHECKLISTITEM_FAILURE: 'EDIT_CHECKLISTITEM_FAILURE',
  createTempPopup: (selectedChecklistItem) => ({
    type: checklistItemActions.CREATE_TEMP_POPUP,
    selectedChecklistItem,
  }),
};

export default checklistItemActions;
