import { cloneDeep, sortBy } from 'lodash';
import {
  APPLY_TEAM_FILTER,
  FETCH_MONTH_AGENT_CALL_AVOIDANCE,
  FETCH_MONTH_AGENT_CALL_AVOIDANCE_SUCCESS,
  FETCH_MONTH_AGENT_CALL_AVOIDANCE_FAILURE,
  SET_SELECTED_DATE,
  FETCH_TRACKING_SILENCE,
  FETCH_TRACKING_SILENCE_SUCCESS,
  FETCH_TRACKING_SILENCE_FAILURE,
  FETCH_AGENTS_DATA,
  FETCH_AGENTS_DATA_SUCCESS,
  FETCH_AGENTS_DATA_FAILURE,
  FETCH_CALL_DRIVERS_DATA_SUCCESS,
  UPDATE_PAST_VALUE,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_CALLS_SUCCESS,
  ADD_AGENT_TO_FILTER,
  CLEAR_ALL_FILTERS,
} from './constants';

const initState = {
  loading: false,
  months: [],
  past: false,
  pastValue: null,
  selectedDate: {
    startDate: null,
    endDate: null,
  },
  slienceIssueData: [],
  trackingSilenceData: [],
  agentsData: [],
  callDriversData: [],
  teams: [],
  filters: { team: null, agents: [] },
  calls: [],
};

const getAgentsFilter = (agents, agent) => {
  if (agents.includes(agent)) {
    return agents.filter((el) => el !== agent);
  }
  return [...agents, agent];
};

export default function usageReducer(state = initState, action) {
  switch (action.type) {
    case APPLY_TEAM_FILTER:
      return {
        ...state,
        filters: { ...state.filters, team: action.filter },
      };
    case FETCH_MONTH_AGENT_CALL_AVOIDANCE:
      return {
        ...state,
        data: [],
        selectedYear: action.year,
        selectedMonth: action.month,
      };
    case FETCH_MONTH_AGENT_CALL_AVOIDANCE_SUCCESS:
      return {
        ...state,
        slienceIssueData: action.data, // replace with actual data from server
      };
    case FETCH_MONTH_AGENT_CALL_AVOIDANCE_FAILURE:
      return {
        ...state,
      };
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: cloneDeep(action.selectedDate),
      };
    case FETCH_TRACKING_SILENCE:
      return {
        ...state,
        // manage laoding here
      };
    case FETCH_TRACKING_SILENCE_SUCCESS:
      return {
        ...state,
        trackingSilenceData: action.data,
      };
    case FETCH_TRACKING_SILENCE_FAILURE:
      return {
        ...state,
        trackingSilenceData: [],
      };
    case FETCH_AGENTS_DATA:
      return {
        ...state,
        // manage loading here
      };
    case FETCH_AGENTS_DATA_SUCCESS:
      return {
        ...state,
        agentsData: action.data,
      };
    case FETCH_AGENTS_DATA_FAILURE:
      return {
        ...state,
        agentsData: [],
      };
    case FETCH_CALL_DRIVERS_DATA_SUCCESS:
      return {
        ...state,
        callDriversData: action.data,
      };
    case UPDATE_PAST_VALUE:
      return {
        ...state,
        past: action.past,
        pastValue: action.val,
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teams: sortBy(action.data, 'fullName'),
      };
    case FETCH_TEAMS_FAILURE:
      return {
        ...state,
        teams: [],
      };
    case FETCH_CALLS_SUCCESS:
      return {
        ...state,
        calls: action.data.scores,
      };
    case ADD_AGENT_TO_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          agents: getAgentsFilter(state.filters.agents, action.agent),
        },
      };
    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filters: { team: null, agents: [] },
      };
    default:
      return state;
  }
}
