import { getPathArr } from 'utils/urlSync';
import actions from './actions';

const preKeys = getPathArr();

const initState = {
  openDrawer: window.innerWidth > 1220,
  openKeys: preKeys,
  current: preKeys,
  activeAccountId: null,
  activeAccount: {},
  init: false,
  messages: {},
  apiConnected: false,
  apiConnectionSlow: false,
  adminInit: false,
  backendVersion: null,
  defaults: {},
  userRestricted: false,
  whiteListedAccounts: [],
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_BACKEND_VERSION_SUCCESS:
      return {
        ...state,
        backendVersion: action.version,
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
      };
    case actions.CHANGE_ACTIVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        activeAccountId: action.activeAccountId,
        activeAccount: { ...action.activeAccount },
      };
    case actions.SET_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: action.activeAccount,
      };
    case actions.FETCH_ACCOUNT_ETL_CONFIG_SUCCESS:
      return {
        ...state,
        activeAccount: { ...state.activeAccount, ...action.etlConfig }
      }
    case actions.SET_API_CONNECTED:
      return {
        ...state,
        apiConnected: action.apiConnected,
        apiConnectionSlow: false,
      };
    case actions.SET_API_CONNECTION_SLOW:
      return {
        ...state,
        apiConnectionSlow: true,
      };
    case actions.SET_ADMIN_INIT:
      return {
        ...state,
        adminInit: action.adminInit,
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: [],
      };
    case actions.SET_HISTORY:
      return {
        ...state,
        history: action.history,
      };
    case actions.FETCH_PLATFORM_VERSION_SUCCESS:
      return {
        ...state,
        platformVersion: action.version,
      };
    case actions.UPDATE_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: action.account,
      };
    case actions.UPDATE_ACTIVE_ACCOUNT_LANGUAGE:
      return {
        ...state,
        activeAccount: { ...state.activeAccount, language: action.language },
      };
    case actions.CHANGE_ACTIVE_ACCOUNT_PCI:
      return {
        ...state,
        activeAccount: {
          ...state.activeAccount,
          pciRedaction: action.value,
        },
      };
    case actions.SET_USER_RESTRICTED:
      return {
        ...state,
        userRestricted: action.userRestricted,
        hasAdminPrivileges: action.hasAdminPrivileges,
        whiteListedAccounts: action.whiteListedAccounts,
      };
    case actions.SET_USER_RESTRICTED_ONLY:
      return {
        ...state,
        userRestricted: action.userRestricted,
      };
    default:
      return state;
  }
}
