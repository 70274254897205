import _ from 'lodash';

export const transformChecklistItem = (data) => ({
  ...data,
});

export const transformChecklistItems = (data) => data.map((x) => transformChecklistItem(x));

const fieldsToRemove = ['count', 'children', 'popups'];
export const prepChecklistItem = (data) => _.omit({
  ...data,
  parentId: data.parentId && data.parentId !== '' ? data.parentId : null,
}, fieldsToRemove);
