/** @prettier */
import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  &.account-dialog .ant-modal-content {
    height: 75vh;
    max-height: 625px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      height: calc(100% - 55px);

      .k-form {
        max-height: unset;
        margin-bottom: unset;
      }
      .ant-tabs-content-holder {
        overflow: auto;
      }
    }
  }

  .ant-modal-header {
    background: #262d38;
  }

  .ant-modal-close-x {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .ant-modal-title {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }

  .k-form .k-form-field {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
