const userActions = {
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
  FETCH_MY_USER: 'FETCH_MY_USER',
  SELECT_USER: 'SELECT_USER',
  ADD_USER: 'ADD_USER',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERRORS: 'ADD_USER_ERRORS',
  EDIT_USER: 'EDIT_USER',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  GET_USER: 'GET_USER',
  DELETE__USER: 'DELETE__USER',
  CHANGE_USER: 'CHANGE_USER',
  CHANGE_USER_VIEW: 'CHANGE_USER_VIEW',
  CLEAR_USERS: 'CLEAR_USERS',
  SORT_USERS: 'SORT_USERS',
  FILTER_USERS: 'FILTER_USERS',
  ADD_GETTING_STARTED_TASK: 'ADD_GETTING_STARTED_TASK',
  RESEND_INVITE: 'RESEND_INVITE',
  RESEND_INVITE_FAILURE: 'RESEND_INVITE_FAILURE',
  RESEND_INVITE_SUCCESS: 'RESEND_INVITE_SUCCESS',
  TOGGLE_ROLE_FILTERS: 'TOGGLE_ROLE_FILTERS',
  sortUsers: (e) => ({
    type: userActions.SORT_USERS,
    sort: e.sort,
  }),
  clearUsers: () => ({
    type: userActions.CLEAR_USERS,
  }),
  fetchUsers: () => ({
    type: userActions.FETCH_USERS,
  }),
  fetchMyUser: (hasAdminPrivileges) => ({
    type: userActions.FETCH_MY_USER,
    hasAdminPrivileges,
  }),
  selectUser: (selectedItem) => ({
    type: userActions.SELECT_USER,
    selectedItem,
  }),
  addUser: (data, onFinish) => ({
    type: userActions.ADD_USER,
    data,
    onFinish,
  }),
  editUser: (data, id, onFinish, editPreferences) => ({
    type: userActions.EDIT_USER,
    data,
    id,
    onFinish,
    editPreferences,
  }),
  getUser: (data) => ({
    type: userActions.GET_USER,
    data,
  }),
  deleteUser: (id) => ({
    type: userActions.DELETE__USER,
    id,
  }),
  changeUserView: (selectedItem) => ({
    type: userActions.CHANGE_USER_VIEW,
    selectedItem,
  }),
  filterUsers: (searchTerm) => ({
    type: userActions.FILTER_USERS,
    searchTerm,
  }),
  addGettingStartedTask: (data, id, task, accountID = null) => ({
    type: userActions.ADD_GETTING_STARTED_TASK,
    data,
    id,
    addTask: true,
    task,
    accountID,
  }),
  resendInvite: (user) => ({
    type: userActions.RESEND_INVITE,
    user,
  }),
  resendInviteSuccess: () => ({
    type: userActions.RESEND_INVITE_SUCCESS,
  }),
  resendInviteFailure: () => ({
    type: userActions.RESEND_INVITE_FAILURE,
  }),
  toggleRoleFilters: (role) => ({
    type: userActions.TOGGLE_ROLE_FILTERS,
    role,
  }),
};
export default userActions;
