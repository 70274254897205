import actions from './actions';

const initState = {
  username: '',
  role: '',
  firstName: '',
  lastName: '',
  sysAdmin: '',
  userId: '',
  accountId: '',
  accountName: '',
  locale: '',
  client: '',
  referrer: '',
  allowSuperAdminAccess: '',
  account: {},
  user: {},
};

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case actions.PROFILE_SET:
      return {
        ...state,
        ...action.profile,
      };
    case actions.PROFILE_SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actions.PROFILE_CLEAR:
      return initState;
    case actions.PROFILE_CHANGE:
      return {
        ...state,
        ...action.profile,
      };
    case actions.UPDATE_PREFERENCES:
      return {
        ...state,
        user: { ...state.user, preferences: action.preferences },
      };
    default:
      return state;
  }
}
