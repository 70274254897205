import { getBaseReducer } from 'utils';
import actions from './actions';
// import { mappingModel as model } from 'models/mapping';

const initState = {
  ...(getBaseReducer()), // model
  entityType: 'mappings',
  cache: [],
  viewImport: false,
  viewCallDetailsImport: false,
  imported: [],
  callDetailsImported: [],
  callDetailsMetaData: null,
  callDetailsMappings: [],
  initCallDetails: false,
  activeTab: 'ea',
};

export default function mappingReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_MAPPINGS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        selectedCallDetailsItem: {},
        init: false,
        initCallDetails: false,
      };
    case actions.CLEAR_MAPPING_SELECTED:
      return {
        ...state,
        selectedItem: {},
        selectedCallDetailsItem: {}
      };
    case actions.SORT_MAPPINGS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_MAPPINGS:
      return {
        ...state,
        init: false,
      }
    case actions.FETCH_MAPPINGS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
      };
    case actions.FETCH_MAPPING_SUCCESS:
      return {
        ...state,
        selectedItem: action.data,
      };
    case actions.SELECT_MAPPING:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_MAPPING_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_MAPPING_SUCCESS:
      const index = state.data.findIndex((x) => x.id === action.selectedItem.id);
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          action.selectedItem,
          ...state.data.slice(index + 1),
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_MAPPING_CACHE:
      const { id, content, query } = action.data || {};
      return {
        ...state,
        cache: [...(state.cache?.filter((x) => x.id !== id) || []), { id, content, query }],
      };
    case actions.DELETE_MAPPING_CACHE:
      return {
        ...state,
        cache: state.cache ? state.cache.filter((x) => x.id !== action.id) : [],
      };
    case actions.DELETE_MAPPING:
      return {
        ...state,
        data: state.data ? state.data.filter((x) => x.id !== action.id) : [],
        cache: state.cache ? state.cache.filter((x) => x.id !== action.id) : [],
        selectedId: null,
      };
    case actions.CHANGE_MAPPING_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        selectedCallDetailsItem: action.selectedItem,
        view: !state.view,
      };
    case actions.CHANGE_MAPPING_IMPORT_VIEW:
      return {
        ...state,
        imported: action.data,
        viewImport: !state.viewImport,
      };
    case actions.CHANGE_CALL_DETAILS_MAPPING_IMPORT_VIEW:
      return {
        ...state,
        imported: action.data,
        viewCallDetailsImport: !state.viewCallDetailsImport,
      };
    case actions.IMPORT_MAPPINGS_SUCCESS:
      return {
        ...state,
        imported: [],
        viewImport: false,
      };
    case actions.IMPORT_CALL_DETAILS_MAPPINGS_SUCCESS:
      return {
        ...state,
        callDetailsImported: [],
        viewCallDetailsImport: false,
      };
    case actions.IMPORT_CALL_DETAILS_MAPPINGS_FAILURE:
      return {
        ...state,
        viewCallDetailsImport: false,
      };
    case actions.FETCH_METADATA_SUCCESS:
      return {
        ...state,
        metaDataList: action.metaDataList,
      };
    case actions.FETCH_METADATA_FAILURE:
      return {
        ...state,
        metaDataList: null,
      };
    case actions.FETCH_CALL_DETAILS_METADATA:
      return {
        ...state,
      };
    case actions.FETCH_CALL_DETAILS_METADATA_SUCCESS:
      return {
        ...state,
        callDetailsMetaData: action.data
      };
    case actions.FETCH_CALL_DETAILS_METADATA_FAILURE:
      return {
        ...state,
        callDetailsMetaData: null
      };
    case actions.FETCH_CALL_DETAILS_METADATA_MAPPINGS:
      return {
        ...state,
        initCallDetails: false,
      };
    case actions.FETCH_CALL_DETAILS_METADATA_MAPPINGS_SUCCESS:
      return {
        ...state,
        callDetailsMappings: action.data,
        initCallDetails: true,
      };
    case actions.FETCH_CALL_DETAILS_METADATA_MAPPINGS_FAILURE:
      return {
        ...state,
        callDetailsMappings: action.data
      }
    case actions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab
      }
    case actions.EDIT_CALL_DETAIL_MAPPING:
      return {
        ...state,
      };
    case actions.EDIT_CALL_DETAIL_MAPPING_SUCCESS:
      return {
        ...state,
        view: false
      };
    case actions.EDIT_CALL_DETAIL_MAPPING_FAILURE:
      return {
        ...state,
      };
    case actions.ADD_CALL_DETAIL_MAPPING:
      return {
        ...state,
      };
    case actions.ADD_CALL_DETAIL_MAPPING_SUCCESS:
      return {
        ...state,
        selectedCallDetailsItem: action.data,
        view: false
      };
    case actions.ADD_CALL_DETAIL_MAPPING_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
