/* eslint-disable linebreak-style */
const pciFlagActions = {
  FETCH_FLAGGED_MEDIA: 'FETCH_FLAGGED_MEDIA',
  FETCH_FLAGGED_MEDIA_SUCCESS: 'FETCH_FLAGGED_MEDIA_SUCCESS',
  FETCH_FLAGGED_MEDIA_FAILURE: 'FETCH_FLAGGED_MEDIA_FAILURE',
  SET_TEMP_FORM_VALUES: 'SET_TEMP_FORM_VALUES',
  TEST_CONFIGURATION_SUCCESS: 'TEST_CONFIGURATION_SUCCESS',

  fetchFlaggedMedia: () => ({
    type: pciFlagActions.FETCH_FLAGGED_MEDIA,
  }),
  setTempFormValues: (data) => ({
    type: pciFlagActions.SET_TEMP_FORM_VALUES,
    data
  })
};
export default pciFlagActions;
