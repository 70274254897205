/** @prettier */
/* eslint-disable import/no-cycle */
import { all, takeEvery, fork, call, put, take, select } from 'redux-saga/effects';
import api from 'services';
import {
  transformAccounts,
  transformAccount,
  transformPlatformAccount,
  transformEditedAccount,
  prepEditMyAccount,
  prepAccount,
  filterAccounts,
  prepValidateConnection,
} from 'transform/account';
import _ from 'lodash';
import { loadState } from 'utils/localStorage';
import { prepUserFromAccount } from 'transform/user';
import platformApi from 'services/platform';
import moment from 'moment';
import { showToast } from 'utils/toast';
import appActions from '../app/actions';
import actions from './actions';

const entityType = 'accounts';

export function* filterAccountsSaga() {
  yield takeEvery(actions.FILTER_ACCOUNTS, function* ({ filters }) {
    const state = yield select();
    const source = _.get(state, 'Accounts.source', []);
    const filtersExisting = _.get(state, 'Accounts.filters', {});
    const cleaned = _.omitBy({ ...filtersExisting, ...filters }, _.isNil);
    yield put({ type: actions.FILTER_ACCOUNTS_SUCCESS, data: filterAccounts(source, cleaned), filters: cleaned });
  });
}

export function* selectAccountHookSaga() {
  yield takeEvery(actions.SELECT_ACCOUNT, function* ({ selectedItem }) {
    const selectedAccount = yield call(api.fetchItem, {
      entityType: 'accounts',
      transform: transformAccount,
      id: selectedItem?.id,
    });
    yield put({ type: actions.CLEAR_DEFAULT_LANGS_MODEL });
    yield put({
      type: actions.FETCH_ACCOUNT_ETL_CONFIG,
      selectedItem: selectedAccount,
    });
    yield put({
      type: actions.SELECT_ACCOUNT_SUCCESS,
      selectedItem: selectedAccount,
    });
  });
}

export function* fetchEtlConfigSaga() {
  yield takeEvery(actions.FETCH_ACCOUNT_ETL_CONFIG, function* ({ selectedItem, isMyAccount = false }) {
    if (selectedItem?.allowSuperAdminAccess || isMyAccount) {
      const etlConfig = yield call(api.fetch, {
        entityType: 'etl-destination/config',
        activeAccountIdOverride: selectedItem.id,
      });
      if (etlConfig[0]?.type === 'AZURE') {
        _.set(selectedItem, 'etlConfigAzure', etlConfig[0]);
      } else if (etlConfig[0]?.type === 'S3') {
        _.set(selectedItem, 'etlConfig', etlConfig[0]);
      } else {
        _.set(selectedItem, 'etlConfig', {});
        _.set(selectedItem, 'etlConfigAzure', {});
      }

      if (isMyAccount) {
        const activeAccountEtlConfig = {
          etlConfigAzure: {},
          etlConfig: {},
        };
        if (etlConfig[0]?.type === 'AZURE') {
          activeAccountEtlConfig.etlConfigAzure = etlConfig[0];
        } else if (etlConfig[0]?.type === 'S3') {
          activeAccountEtlConfig.etlConfig = etlConfig[0];
        }
        yield put({ type: appActions.FETCH_ACCOUNT_ETL_CONFIG_SUCCESS, etlConfig: activeAccountEtlConfig });
      } else {
        yield put({ type: actions.FETCH_ACCOUNT_ETL_CONFIG_SUCCESS, data: selectedItem });
      }
    }
  });
}

export function* fetchTTLSaga() {
  yield takeEvery(actions.FETCH_TTL, function* ({ accountID }) {
    const data = yield call(api.fetchItem, { entityType: 'ttl', id: accountID, activeAccountIdOverride: accountID });
    if (data) {
      yield put(actions.fetchTTLSuccess(data));
    } else {
      yield put(actions.fetchTTLFailure('Something went wrong'));
    }
  });
}

export function* updateDefaultSpeechModelSaga() {
  yield takeEvery(actions.UPDATE_DEFAULT_SPEECH_MODEL, function* ({ data = {}, accountID: id = '' }) {
    const res = yield call(api.editItem, {
      entityType: 'speechModel',
      data,
      id,
      activeAccountIdOverride: id,
    });
    if (res) {
      yield put(actions.updateDefaultSpeechModelSuccess(res));
    } else {
      yield put(actions.updateDefaultSpeechModelFailure('Something went wrong'));
    }
  });
}

export function* fetchAccountsSaga() {
  yield takeEvery(actions.FETCH_ACCOUNTS, function* ({ filters, clearExistingFilters = true }) {
    const state = yield select();
    const filtersExisting = _.get(state, 'Accounts.filters', {});
    const filtersCleaned = clearExistingFilters ? filters : _.omitBy({ ...filtersExisting, ...filters }, _.isNil);
    const data = yield call(api.fetchAll, { entityType, transform: transformAccounts });
    yield put({ type: actions.FETCH_ACCOUNTS_SUCCESS, data });
    yield put({
      type: actions.FILTER_ACCOUNTS_SUCCESS,
      data: filterAccounts(data, filtersCleaned),
      filters: filtersCleaned,
    });
  });
}

export function* fetchCategorizationJobs() {
  yield takeEvery(actions.FETCH_CATEGORIZATION_JOBS, function* ({}) {
    const data = yield call(api.fetchAll, { entityType: 'managementJobs' });
    if (data) {
      yield put({ type: actions.FETCH_CATEGORIZATION_JOBS_SUCCESS, data });
      showToast('success', { task: 'Successfully fetched categorization jobs' });
    }
  });
}

function* setCurrentAccount(accounts = [], currentAccount) {
  const state = yield select();

  const appActiveAccountId = _.get(state, 'App.activeAccountId');
  const localActiveAccountId = loadState('activeAccountId');
  const activeAccountId = localActiveAccountId || currentAccount?.id;

  let activeAccount = currentAccount;
  if (_.isEmpty(activeAccount)) {
    const accountOption = accounts.find((x) => x.id === activeAccountId);
    activeAccount = accountOption || accounts[0];
  }

  if (!appActiveAccountId && activeAccount) {
    yield put({
      type: appActions.CHANGE_ACTIVE_ACCOUNT,
      activeAccountId: activeAccount.id,
      activeAccount: transformAccount(activeAccount),
    });
  }
}

function* refreshSelectedAccountSaga() {
  yield takeEvery(actions.REFRESH_SELECTED_ACCOUNT, function* () {
    const state = yield select();
    const id = _.get(state, 'Accounts.selectedItem.id');

    if (id) {
      const account = yield call(api.fetchItem, { entityType: 'accounts', id });
      const data = [...state.Accounts.data.filter((i) => i.id !== account.id)];
      const selectedItem = transformAccount(account);
      data.push(selectedItem);
      yield put({ type: actions.REFRESH_ACCOUNT_SUCCESS, selectedItem, data });
      selectedItem.etlConfig = {};
      yield put({ type: actions.FETCH_ACCOUNT_ETL_CONFIG, selectedItem });

      // Handle case when user is activating the active account
      if (selectedItem.id === state.App.activeAccount.id) {
        const action = yield take(actions.FETCH_ACCOUNT_ETL_CONFIG_SUCCESS);
        yield put({ type: appActions.CHANGE_ACTIVE_ACCOUNT, activeAccount: action.data, clear: false });
      }
    }
  });
}

/**
 * Fetch Accounts for SuperAdmin dropdown
 */
export function* fetchAccountsOptionsSaga() {
  yield takeEvery(actions.FETCH_ACCOUNT_OPTIONS, function* () {
    const state = yield select();
    const accountsStore = _.get(state, 'Accounts');
    const accounts = !accountsStore.init ? yield call(api.fetchAll, { entityType: 'accounts' }) : accountsStore.data;
    try {
      yield put({
        type: actions.FETCH_ACCOUNT_OPTIONS_SUCCESS,
        data: accounts,
      });
      yield put({ type: appActions.SET_ADMIN_INIT, adminInit: true });
      const localActiveAccountId = loadState('activeAccountId');
      let currentAccount;
      if (localActiveAccountId) {
        currentAccount = accounts?.find((i) => i.id === localActiveAccountId);
      }
      if (currentAccount == null) {
        currentAccount = accounts?.find((i) => i.name === 'VoiceBase');
      }
      yield setCurrentAccount(accounts, currentAccount);
    } catch (e) {
      // console.log(e);
    }
  });
}

export function* updateVbAccessSaga() {
  yield takeEvery(actions.UPDATE_VB_ACCESS, function* ({ access, id }) {
    const state = yield select();
    const data = { allowSuperAdminAccess: access };
    const { activeAccount } = state.App;
    const activeAccountIdOverride = id || activeAccount.id;
    yield call(api.patchItem, { entityType: 'account', data, activeAccountIdOverride });
    yield put({
      type: appActions.CHANGE_ACTIVE_ACCOUNT,
      activeAccount: { ...activeAccount, ...data },
    });
  });
}

/**
 * Set accounts on start up
 */
export function* setAccessAccount() {
  yield takeEvery(actions.SET_ACCESS_ACCOUNTS, function* ({ accounts = [] }) {
    const localActiveAccountId = loadState?.('activeAccountId');
    const account = accounts.find((i) => i.id === localActiveAccountId) || accounts[0];
    if (account) {
      // EA-1281 only AccountAdmins can configure their etl destinations
      // const hasEAProduct = account.products.find((i) => i.name === 'EA');
      // const hasRoleAccess = ['AccountAdmin'].some((i) => account.roles.includes(i));
      // if (hasEAProduct && hasRoleAccess) {
      //   const etlConfig = yield call(api.fetch, { entityType: 'etl-destination/config', activeAccountIdOverride: account.id });
      //   if (etlConfig[0]?.type === 'AZURE') {
      //     account.etlConfigAzure = etlConfig[0];
      //   } else if (etlConfig[0]?.type === 'S3') {
      //     account.etlConfig = etlConfig[0];
      //   } else {
      //     account.etlConfig = {};
      //     account.etlConfigAzure = {};
      //   }
      // }
      yield put({ type: actions.SET_ACCESS_ACCOUNTS_SUCCESS, accounts });
      yield put({ type: appActions.SET_API_CONNECTED, apiConnected: true });
      yield setCurrentAccount(accounts, account);
    }
  });
}

export function* addAccountSaga() {
  yield takeEvery(actions.ADD_ACCOUNT, function* ({ data, onFinish }) {
    yield put({ type: actions.TOGGLE_ACCOUNT_IS_LOADING, data: true });
    const userData = prepUserFromAccount({
      ...data,
    });
    const accountData = prepAccount(data);
    const account = yield call(api.createItem, {
      entityType,
      data: {
        account: accountData,
        adminUser: userData,
      },
    });
    if (account) {
      yield call(api.toggleAccountProduct, {
        productType: 'EA_HOSTED',
        accountId: account.id,
        isActivating: true,
      });
      yield call(api.toggleAccountProduct, {
        productType: 'VBO',
        accountId: account.id,
        isActivating: true,
      });
      yield put({ type: actions.ADD_ACCOUNT_SUCCESS, selectedItem: transformAccount(account) });
      yield put({ type: actions.CHANGE_ACCOUNT_FORM_VIEW, data: false });
      yield put({ type: actions.TOGGLE_ACCOUNT_IS_LOADING, data: false });
      yield put({ type: actions.FETCH_ACCOUNTS });
    }
    onFinish(!!account);
  });
}

export function* updateTTLSaga() {
  yield takeEvery(actions.UPDATE_TTL, function* ({ data: { ttl = {}, accountID: id = '' } }) {
    const rPayload = Object.fromEntries(
      Object.entries(ttl)
        .map(([k, v]) => [k, +v.slice(0, v.indexOf(' '))])
        .map(([k, v]) => [k, +v < 0 ? +v : +v * 1440])
    );
    const res = yield call(api.editItem, {
      entityType: 'ttl',
      data: rPayload,
      id,
      activeAccountIdOverride: id,
    });
    if (res) {
      yield put(actions.updateTTLSuccess(res));
    } else {
      yield put(actions.updateTTLFailure('Something went wrong'));
    }
  });
}

export function* editAccountSaga() {
  yield takeEvery(actions.EDIT_ACCOUNT, function* ({ data, id, onFinish, isMyAccount = false }) {
    const account = yield call(api.editItem, {
      entityType,
      data: prepEditMyAccount(data),
      id,
      activeAccountIdOverride: id,
      needResponse: true,
    });
    yield put({ type: actions.FETCH_DEFAULT_LANGUAGE_MODEL, status: false });
    if (account.status === 200) {
      const updatedAccount = transformEditedAccount(account.data);
      yield put({ type: actions.FETCH_ACCOUNTS });
      yield put({ type: actions.EDIT_ACCOUNT_SUCCESS, selectedItem: updatedAccount });
      if (isMyAccount) {
        yield put({ type: appActions.UPDATE_ACTIVE_ACCOUNT_LANGUAGE, language: updatedAccount.language });
        yield put({ type: appActions.CHANGE_ACTIVE_ACCOUNT_PCI, value: data.pciRedaction });
      }
      yield put({ type: actions.FETCH_DEFAULT_LANGUAGE_MODEL, status: false });
      if (account.data.account && account.data.account.language === null) {
        yield put({ type: actions.CLEAR_DEFAULT_LANGS_MODEL });
        yield put({ type: actions.FETCH_DEFAULT_LANGUAGE_MODEL, status: true });
      }
    } else {
      yield put({ type: actions.EDIT_ACCOUNT_FAILURE });
    }
    onFinish(!!account);
  });
}

export function* editMyAccountSaga() {
  yield takeEvery(actions.EDIT_MY_ACCOUNT, function* ({ data, onFinish = () => {} }) {
    const state = yield select();
    const accountPayload = prepEditMyAccount(data);
    const resData = yield call(api.editItem, { entityType: 'account', data: accountPayload });
    yield put({ type: actions.FETCH_DEFAULT_LANGUAGE_MODEL, status: false });
    if (resData?.account) {
      let transformedAccount = transformAccount(resData);
      transformedAccount = transformEditedAccount(transformedAccount);
      const newActiveAccount = { ...state.App.activeAccount, etlConfig: transformedAccount?.etlConfig };

      if (transformedAccount) {
        // somehow the account being sent from the server have roles array
        // empty so temporarily using the roles from prev obj
        transformedAccount.roles = state.App.activeAccount.roles;

        yield put({ type: actions.EDIT_MY_ACCOUNT_SUCCESS });
        yield put({ type: actions.EDIT_ACCOUNT_SUCCESS, selectedItem: transformedAccount });
        yield put({
          type: appActions.CHANGE_ACTIVE_ACCOUNT_SUCCESS,
          activeAccount: {
            ...newActiveAccount,
            ...transformedAccount,
            billingSameAs: transformedAccount.billingSameAs,
          },
          activeAccountId: transformedAccount.id,
        });
      } else {
        yield put({ type: actions.EDIT_MY_ACCOUNT_FAILURE });
      }
    } else {
      yield put({ type: actions.EDIT_MY_ACCOUNT_FAILURE });
    }

    onFinish(!!resData?.account);
  });
}

export function* fetchAccountSaga() {
  yield takeEvery(actions.FETCH_ACCOUNT, function* ({ id }) {
    if (id) {
      const account = yield call(api.fetchItem, { entityType, id });
      if (account) {
        const selectedItem = transformAccount(account);
        yield put({ type: actions.FETCH_ACCOUNT_SUCCESS, selectedItem });
      } else {
        yield put({ type: actions.FETCH_ACCOUNT_FAILURE });
      }
    }
  });
}

export function* fetchPlatformAccountSaga() {
  yield takeEvery(actions.FETCH_PLATFORM_ACCOUNT, function* ({ id }) {
    if (id) {
      const account = yield call(api.fetchPlatformItem, { entityType: 'account', id });
      if (account) {
        const selectedItemFromPlatform = transformPlatformAccount(account);
        yield put({ type: actions.FETCH_PLATFORM_ACCOUNT_SUCCESS, selectedItemFromPlatform });
      } else {
        yield put({ type: actions.FETCH_PLATFORM_ACCOUNT_FAILURE });
      }
    }
  });
}

export function* updateDefaultSpeechLanguage() {
  yield takeEvery(actions.UPDATE_SPEECH_DEFAUT_LANGUAGE, function* ({ data, selectedItem, totalLength, currentIndex }) {
    const langUpdate = yield call(platformApi.platformPutReq, {
      urlPath: `account/${selectedItem.id}/settings/default-speech-model/${data.language}`,
      data,
      entityType: 'languageSettings',
    });
    if (langUpdate) {
      if (totalLength - 1 === currentIndex) {
        yield put({ type: actions.EDIT_ACCOUNT_SUCCESS, selectedItem });
        yield put({ type: actions.EDIT_MY_ACCOUNT_SUCCESS });
        yield put({ type: actions.FETCH_DEFAULT_LANGUAGE_MODEL, status: true });
      }
      yield put({ type: actions.UPDATE_SPEECH_DEFAUT_LANGUAGE_SUCCESS });
    } else {
      yield put({ type: actions.UPDATE_SPEECH_DEFAUT_LANGUAGE_FAILURE });
    }
  });
}

export function* getDefaultSpeechLanguageSaga() {
  yield takeEvery(actions.GET_SPEECH_DEFAULT_LANGUAGE, function* ({ lng, accountID }) {
    const langUpdate = yield call(platformApi.platformGetReq, {
      urlPath: `account/${accountID}/settings/default-speech-model/${lng}`,
      disableNotification: true,
      activeAccountIdOverride: accountID,
    });
    if (langUpdate) {
      if (langUpdate.model === 'Proteus') {
        langUpdate.model = 'Europa';
      }
      yield put({
        type: actions.GET_SPEECH_DEFAULT_LANGUAGE_SUCCESS,
        data: { language: lng, model: langUpdate.model },
      });
    } else {
      yield put({ type: actions.GET_SPEECH_DEFAULT_LANGUAGE_FAILURE });
    }
  });
}

export function* getPredictiveModelSaga() {
  yield takeEvery(actions.GET_PREDICTIVE_MODEL, function* ({ accountID }) {
    const predictiveModels = yield call(platformApi.platformGetReq, {
      urlPath: 'definition/prediction/classifiers?ignoreDisabled=true',
      activeAccountIdOverride: accountID,
      disableNotification: true,
    });
    if (predictiveModels) {
      yield put({ type: actions.GET_PREDICTIVE_MODEL_SUCCESS, data: predictiveModels.classifiers });
    } else {
      yield put({ type: actions.GET_PREDICTIVE_MODEL_FAILURE });
    }
  });
}
export function* fetchMissingRecordsSaga() {
  yield takeEvery(actions.FETCH_MISSING_RECORDS, function* ({ dateRange, accountId }) {
    const { fromDate, toDate } = dateRange;
    const missingRecords = yield call(api.get, {
      url: `missingRecords?from=${fromDate}&to=${toDate}`,
      activeAccountIdOverride: accountId,
      disableNotification: true,
    });
    if (missingRecords && missingRecords?.status === 200) {
      yield put({ type: actions.FETCH_MISSING_RECORDS_SUCCESS, data: missingRecords.data });
    } else {
      yield put({ type: actions.FETCH_MISSING_RECORDS_FAILURE });
    }
  });
}
export function* fetchSkippedRecordsSaga() {
  yield takeEvery(actions.FETCH_SKIPPED_RECORDS, function* ({ dateRange, accountId }) {
    const { fromDate, toDate } = dateRange;
    const skippedRecords = yield call(api.get, {
      url: `skipRecords?from=${fromDate}&to=${toDate}`,
      activeAccountIdOverride: accountId,
      disableNotification: true,
    });
    if (skippedRecords && skippedRecords?.status === 200) {
      yield put({ type: actions.FETCH_SKIPPED_RECORDS_SUCCESS, data: skippedRecords.data });
    } else {
      yield put({ type: actions.FETCH_SKIPPED_RECORDS_FAILURE });
    }
  });
}

export function* validateConnection() {
  yield takeEvery(actions.VALIDATE_CONNECTION, function* ({ data, onFinish }) {
    const dataStorageType = data.dataStorage;
    try {
      const response = yield call(api.post, {
        url: `validate/connection/${dataStorageType.toLowerCase()}`,
        data: prepValidateConnection(data),
        msg: 'Connection Validated successfully',
      });
      if (response.status === 200) {
        yield put({ type: actions.VALIDATE_CONNECTION_SUCCESS, data: { message: '' } });
      }
      onFinish();
    } catch (err) {
      if (err) {
        yield put({ type: actions.VALIDATE_CONNECTION_FAILURE, data: err });
      }
      onFinish();
    }
  });
}

export function* getEmbededDashboardUrlSaga() {
  yield takeEvery(actions.FETCH_EMBEDED_DASHBOARD_URL, function* ({accountId}) {
    try {
      const response = yield call(api.get, {
        url: `tableau-sites`,
        activeAccountIdOverride: accountId,
        disableNotification: true
      });
      if (response.status === 200) {
        yield put({ type: actions.FETCH_EMBEDED_DASHBOARD_URL_SUCCESS, data: response.data });
      }
    } catch (err) {
      if (err) {
        // yield put({ type: actions.VALIDATE_CONNECTION_FAILURE, data: err });
      }
    }
  });
}

export function* updateEmbededDashboardUrlSaga() {
  yield takeEvery(actions.UPDATE_EMBEDED_DASHBOARD_URL, function* ({accountId, data, recordId}) {
    try {
      const response = yield call(api.put, {
        url: `tableau-sites/${recordId}`,
        activeAccountIdOverride: accountId,
        data,
        msg: 'Tableau embedded URL updated successfully',
      });
      if (response.status === 200) {
        yield put({ type: actions.FETCH_EMBEDED_DASHBOARD_URL, accountId });
      }
    } catch (err) {
      if (err) {
        // yield put({ type: actions.VALIDATE_CONNECTION_FAILURE, data: err });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchAccountsSaga),
    fork(selectAccountHookSaga),
    fork(fetchEtlConfigSaga),
    fork(filterAccountsSaga),
    fork(fetchAccountsOptionsSaga),
    fork(updateVbAccessSaga),
    fork(addAccountSaga),
    fork(editAccountSaga),
    fork(editMyAccountSaga),
    fork(refreshSelectedAccountSaga),
    fork(fetchTTLSaga),
    fork(updateTTLSaga),
    fork(fetchPlatformAccountSaga),
    fork(setAccessAccount),
    fork(updateDefaultSpeechLanguage),
    fork(getDefaultSpeechLanguageSaga),
    fork(getPredictiveModelSaga),
    fork(validateConnection),
    fork(fetchMissingRecordsSaga),
    fork(fetchSkippedRecordsSaga),
    fork(fetchCategorizationJobs),
    fork(getEmbededDashboardUrlSaga),
    fork(updateEmbededDashboardUrlSaga)
  ]);
}
