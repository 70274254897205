import React from 'react';
import Moment from 'react-moment';
import { Tooltip } from 'antd';
import { FormDateStyled, TooltipStyled } from './styles';
import { formatDateForCell } from 'utils';

const DateTooltip = ({ created, updated }) => {
  const createdDate = formatDateForCell(new Date(created));
  const updatedDate = formatDateForCell(new Date(updated));
  return (
    <TooltipStyled>
      <div>
        <span>Created:</span>
        <span>{createdDate}</span>
      </div>
      <div>
        <span>Updated:</span>
        <span>{updatedDate}</span>
      </div>
    </TooltipStyled>
  );
};

const FormDates = ({ created, updated, position = 'top' }) => (
  <Tooltip
    position={position}
    className="tooltip"
    title={<DateTooltip created={created} updated={updated} />}
  >
    <FormDateStyled className="date" title={updated}>
      {'Last updated '}
      <Moment fromNow>{updated}</Moment>
    </FormDateStyled>
  </Tooltip>
);

export default FormDates;
