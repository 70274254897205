import { getBaseReducer } from 'utils';
import model from 'models/interaction';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  entityType: 'interactions',
  data: null,
  meta: { total: 0 },
  instances: {},
  lastPlayed: [],
  lastSelectedPlayer: [],
};

export default function interactionReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_INTERACTIONS:
      return {
        ...state,
        data: [],
        instances: {},
        meta: { total: 0 },
        selectedItem: {},
        init: false,
      };
    case actions.SORT_INTERACTIONS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_INTERACTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case actions.RESET_INTERACTIONS_INSTANCES: {
      return {
        ...state,
        instances: {},
      };
    }
    case actions.FETCH_INTERACTIONS_SUCCESS: {
      const updateSuccess = {
        data: action.data,
        meta: {
          total: action.total,
        },
        filters: action.filters,
        init: action.init ?? true,
        isLoading: false,
        isFetching: false,
      };
      if (!action.instance) return { ...state, ...updateSuccess };
      return {
        ...state,
        instances: {
          ...state.instances,
          [action.instance]: updateSuccess,
        },
        isFetching: false,
        lastPlayed: [],
      };
    }
    case actions.FETCH_INTERACTIONS_FAILURE: {
      const updateFailure = {
        data: [],
        init: true,
        isLoading: false,
        error: true,
      };

      if (!action.instance) return { ...state, ...updateFailure };
      return {
        ...state,
        instances: {
          ...state.instances,
          [action.instance]: updateFailure,
        },
        isFetching: false,
      };
    }
    case actions.SELECT_INTERACTION:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_INTERACTION_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_INTERACTION_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__INTERACTION:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_INTERACTION_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    case actions.SET_LAST_SELECTED_PLAYER:
      return {
        ...state,
        lastSelectedPlayer: action.mediaId,
      };
    case actions.UPDATE_LAST_PLAYED:
      return {
        ...state,
        lastPlayed: [...state.lastPlayed, action.mediaId],
      };
    case actions.SET_ACTIVE_MEDIA:
      return {
        ...state,
        activeMedia: action.mediaId,
      };
    default:
      return state;
  }
}
