/** @prettier */
import styled, { css } from 'styled-components';

export const DashboardHeadList = styled.p`
  text-align: center;
  font-style: normal;
  font-weight: 600;
`;

export const Picker = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`;

export const CenteredData = styled.div`
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FiltersFormat = css`
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  cursor: auto;

  p.forceNoPadding {
    padding-left: 0px;
  }
`;

export const StyledDateRangeText = styled.p`
  color: #7e8288;
  &.forceHover:hover {
    color: #7e8288;
  }
`;

export const StyledDateList = styled.p`
  color: #ffffff;
  cursor: pointer;
  padding: 2px 0px;

  :hover {
    background-color: black;
  }
  &.forceHover:hover {
    color: white;
  }
`;

export const StyledDateRanges = styled.div`
  ${FiltersFormat};
`;

export const StyledFilterSelect = styled.div`
  ${FiltersFormat};
  max-height: 528px;
  overflow-y: scroll;
`;

export const FaInfoCircleStyled = styled.p`
  margin-left: 4px;
  margin-right: 4px;
  opacity: 0.5;
`;

export const ChartTitleWrap = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TemplateMenuStyle = styled.div`
  display: flex;

  div {
    color: white;
    font-size: 13px;
    line-height: 22px;
    font-weight: 600;
    margin-left: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  svg path {
    stroke: white;
  }
`;

export const ChartWrapperStyle = styled.div`
  height: 500px;
  width: 100%;
  padding: 1.25rem 5% 2.5rem;
  background: white;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #000000d9;
  }

  .edit-mode-vbo-ch-td:hover {
    background-color: #676c74;
    opacity: 1;
  }

  .recharts-responsive-container {
    .recharts-label {
      font-weight: 600;
      fill: #4a4f58;
    }
    .recharts-tooltip-label {
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
      color: #677791;
    }
    .recharts-tooltip-item-name {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #262d38;
      margin-right: 0.5rem;
    }
    .recharts-tooltip-item-value {
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      margin-left: 0.5rem;
    }
    .recharts-tooltip-item-unit {
      display: none;
    }
  }

  .vbo-dash-gauge-top {
    font-weight: 600;
    font-size: ${(props) => props.fontSizeTop || '2.6vw'};
    line-height: 22px;
    text-align: center;
    color: #262d38;
    mix-blend-mode: normal;
    transform: translateY(-30px);
  }

  .vbo-dash-gauge-bottom {
    font-weight: normal;
    font-size: ${(props) => props.fontSizeBottom || '0.6vw'};
    line-height: 22px;
    text-align: center;
    color: #677791;
    transform: translateY(-15px);
  }
`;

export const ChartLegendStyle = styled.div`
  display: flex;
  margin-left: 10px;

  span {
    background-color: ${(props) => props.color || 'black'};
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    margin-top: 4px;
  }
`;

export const TextBold = styled.p`
  text-align: center;
  margin: 15px 32px 25px 32px;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
`;

export const RowCard = styled.div`
  margin: 0px;
  padding: 1rem 5%;
  background: white;

  .ant-card-body {
    padding: 0%;
  }
`;

export const SmallText = styled.div`
  font-size: 11px;
`;
