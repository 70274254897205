import checklistActions from 'redux/checklists/actions';
import { getBaseReducer } from 'utils';
import actions from './actions';

const initState = {
  ...(getBaseReducer()), // model
  entityType: 'checklistItems',
  cache: [],
  selectedPopup: {}, // This is actually the global popup
  expandedKeys: [],
};

export default function checklistItemReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CHECKLISTITEMS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
      };
    case actions.FETCH_CHECKLISTITEM_SUCCESS:
      return {
        ...state,
        selectedItem: action.data,
      };
    case actions.SELECT_CHECKLISTITEM:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_CHECKLISTITEM_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data,
          action.selectedItem,
        ],
        selectedItem: {},
        view: false,
      };
    case actions.EDIT_CHECKLISTITEM_SUCCESS:
      const index = state.data.findIndex((x) => x.id === action.selectedItem.id);
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          action.selectedItem,
          ...state.data.slice(index + 1),
        ],
        selectedItem: action.selectedItem,
        view: false,
      };

    case actions.CHANGE_CHECKLISTITEM_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !!action.selectedItem,
      };
    case actions.CREATE_TEMP_POPUP:
      const selectedItem = {
        id: 'tempID',
        name: '',
        requireAll: true,
        type: 'SOME HARDCODED TYPES',
        categories: [],
        checklistItemId: action.selectedChecklistItem?.id,
      };
      return {
        ...state,
        selectedItem,
      };
    case checklistActions.ADD_POPUP_SUCCESS:
      return {
        ...state,
        ...(action.isGlobal ? { selectedPopup: action.selectedPopup } : { selectedItem: action.selectedPopup })
      };
    case checklistActions.DELETE_CHECKLIST_ITEM_SUCCESS:
      return {
        ...state,
        selectedItem: {},
      };
    case checklistActions.UPDATE_POPUP_SUCCESS:
      return {
        ...state,
        ...(action.isGlobal ? { selectedPopup: action.selectedPopup } : { selectedItem: action.selectedPopup })
      };
    case checklistActions.CREATE_TEMP_ITEM:
      return {
        ...state,
        selectedItem: { id: 'ciTempID', name: 'New Item' },
      };
    case checklistActions.DELETE_POPUP_SUCCESS:
      return {
        ...state,
        selectedItem: {},
      };
    case checklistActions.CLEAR_CHECKLIST_SELECTED:
      return {
        ...state,
        selectedItem: {},
      };

    default:
      return state;
  }
}
