/** @prettier */
import { all, takeEvery, fork, call, put, takeLatest } from 'redux-saga/effects';
import api from 'services';
import _ from 'lodash';
import actions from './actions';
import platformApi from 'services/platform';
import { showToast } from 'utils/toast';

const entityType = 'bearerTokens';

export function* fetchBearerTokensSaga() {
  yield takeEvery(actions.FETCH_BEARERTOKENS, function* () {
    const res = yield call(api.fetchAll, { entityType });
    const data = _.get(res, 'keys', []);
    if (res && data && !('err' in data)) {
      yield put({ type: actions.FETCH_BEARERTOKENS_SUCCESS, data });
    } else {
      yield put({ type: actions.FETCH_BEARERTOKENS_FAILURE });
    }
  });
}

export function* addBearerToken() {
  yield takeEvery(actions.ADD_BEARERTOKEN, function* (action) {
    const { tokenType, data, cb } = action;
    yield put({ type: actions.BEARER_TOKEN_LOADING_STATE, state: true });

    const bearerToken = yield call(api.createItem, {
      entityType,
      data: tokenType === 'read-only' ? { groups: ['VbPlayer'], ...data } : data,
    });

    if (bearerToken) {
      yield put({ type: actions.ADD_BEARERTOKEN_SUCCESS, newBearerToken: bearerToken });
      yield put({ type: actions.ADD_BEARER_TOKEN_STEP, step: 2 });
      yield put({ type: actions.BEARER_TOKEN_LOADING_STATE, state: false });
      cb(bearerToken)
    } else {
      yield put({ type: actions.ADD_BEARERTOKEN_FAILURE });
      yield put({ type: actions.BEARER_TOKEN_LOADING_STATE, state: false });
    }
  });
}

export function* clearNewBearerToken() {
  yield takeLatest(actions.CLEAR_NEW_BEARERTOKEN, function* () {
    yield put({ type: actions.CLEAR_NEW_BEARERTOKEN_SUCCESS });
  });
}

export function* deleteBearerToken() {
  yield takeEvery(actions.DELETE__BEARERTOKEN, function* () {});
}

export function* addBearerTokenDescriptionSaga() {
  yield takeEvery(actions.ADD_BEARER_TOKEN_DESCRIPTION, function* ({ id, description,cb }) {
    try {
      yield put({ type: actions.BEARER_TOKEN_LOADING_STATE, state: true });
      yield put({ type: actions.SELECT_BEARERTOKEN, selectedItem: { description: description, id: id } });
      const bearerToken = yield call(platformApi.platformPutReq, {
        entityType,
        data: {description},
        disableNotification: false,
        urlPath: `profile/keys/${id}/description`,
      });
      yield put({ type: actions.BEARER_TOKEN_LOADING_STATE, state: false });
      if (bearerToken) {
        yield put({ type: actions.FETCH_BEARERTOKENS });
        yield put({ type: actions.ADD_BEARER_TOKEN_STEP, step: 0 });
        cb(bearerToken)
      }
    } catch (err) {
      yield put({ type: actions.BEARER_TOKEN_LOADING_STATE, state: false });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchBearerTokensSaga),
    fork(addBearerToken),
    fork(deleteBearerToken),
    fork(clearNewBearerToken),
    fork(addBearerTokenDescriptionSaga),
  ]);
}
