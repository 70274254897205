/** @prettier */
const communicationActions = {
  FETCH_COMMUNICATIONS: 'FETCH_COMMUNICATIONS',
  FETCH_COMMUNICATIONS_SUCCESS: 'FETCH_COMMUNICATIONS_SUCCESS',
  FETCH_COMMUNICATIONS_FAILURE: 'FETCH_COMMUNICATIONS_FAILURE',
  SELECT_COMMUNICATION: 'SELECT_COMMUNICATION',
  ADD_COMMUNICATION: 'ADD_COMMUNICATION',
  ADD_COMMUNICATION_SUCCESS: 'ADD_COMMUNICATION_SUCCESS',
  ADD_COMMUNICATION_FAILURE: 'ADD_COMMUNICATION_FAILURE',
  EDIT_COMMUNICATION: 'EDIT_COMMUNICATION',
  EDIT_COMMUNICATION_SUCCESS: 'EDIT_COMMUNICATION_SUCCESS',
  PATCH_COMMUNICATION: 'PATCH_WAREHOUSE',
  GET_COMMUNICATION: 'GET_COMMUNICATION',
  GET_COMMUNICATION_SUCCESS: 'GET_COMMUNICATION_SUCCESS',
  DELETE__COMMUNICATION: 'DELETE__COMMUNICATION',
  CHANGE_COMMUNICATION: 'CHANGE_COMMUNICATION',
  CHANGE_COMMUNICATION_VIEW: 'CHANGE_COMMUNICATION_VIEW',
  CLEAR_COMMUNICATIONS: 'CLEAR_COMMUNICATIONS',
  SORT_COMMUNICATIONS: 'SORT_COMMUNICATIONS',
  SYNC_WAREHOUSE: 'SYNC_WAREHOUSE',
  SET_COMMUNICATIONS: 'SET_COMMUNICATIONS',
  SET_COMMUNICATIONS_FILTERS: 'SET_COMMUNICATIONS_FILTERS',
  SET_COMMUNICATIONS_PAGE: 'SET_COMMUNICATIONS_PAGE',
  SET_COMMUNICATIONS_SUCCESS: 'SET_COMMUNICATIONS_SUCCESS',
  SET_COMMUNICATIONS_FAILURE: 'SET_COMMUNICATIONS_FAILURE',
  BULK_EXPORT_TRANSCRIPTS: 'BULK_EXPORT_TRANSCRIPTS',
  BULK_EXPORT_TRANSCRIPTS_SUCCESS: 'BULK_EXPORT_TRANSCRIPTS_SUCCESS',
  BULK_EXPORT_TRANSCRIPTS_FAILURE: 'BULK_EXPORT_TRANSCRIPTS_FAILURE',
  MEDIA_FILE_DOWNLOAD: 'MEDIA_FILE_DOWNLOAD',
  MEDIA_FILE_DOWNLOAD_SUCCESS: 'MEDIA_FILE_DOWNLOAD_SUCCESS',
  MEDIA_FILE_DOWNLOAD_FAILURE: 'MEDIA_FILE_DOWNLOAD_FAILURE',
  MEDIA_FILE_DOWNLOAD_START: 'MEDIA_FILE_DOWNLOAD_START',
  MEDIA_FILE_DOWNLOAD_STOP:'MEDIA_FILE_DOWNLOAD_STOP',
  
  setCommunications: (data) => ({
    type: communicationActions.SET_COMMUNICATIONS,
    data,
  }),
  setCommunicationsFilters: (data) => ({
    type: communicationActions.SET_COMMUNICATIONS_FILTERS,
    data,
  }),
  setCommunicationsPage: (data) => ({
    type: communicationActions.SET_COMMUNICATIONS_PAGE,
    data,
  }),
  sortCommunications: (e) => ({
    type: communicationActions.SORT_COMMUNICATIONS,
    sort: e.sort,
  }),
  clearCommunications: () => ({
    type: communicationActions.CLEAR_COMMUNICATIONS,
  }),
  fetchCommunications: (filters = {}) => ({
    type: communicationActions.FETCH_COMMUNICATIONS,
    filters,
  }),
  selectCommunication: (selectedItem) => ({
    type: communicationActions.SELECT_COMMUNICATION,
    selectedItem,
  }),
  addCommunication: (data, onFinish) => ({
    type: communicationActions.ADD_COMMUNICATION,
    data,
    onFinish,
  }),
  editCommunication: (data, id, onFinish) => ({
    type: communicationActions.EDIT_COMMUNICATION,
    data,
    id,
    onFinish,
  }),
  patchCommunication: (data) => ({
    type: communicationActions.PATCH_COMMUNICATION,
    data,
  }),
  getCommunication: (data) => ({
    type: communicationActions.GET_COMMUNICATION,
    data,
  }),
  deleteCommunication: (id) => ({
    type: communicationActions.DELETE__COMMUNICATION,
    id,
  }),
  changeCommunicationView: (selectedItem) => ({
    type: communicationActions.CHANGE_COMMUNICATION_VIEW,
    selectedItem,
  }),
  syncMediaToWarehouse: () => ({
    type: communicationActions.SYNC_WAREHOUSE,
  }),
  bulkExportTranscripts: (data) => ({
    type: communicationActions.BULK_EXPORT_TRANSCRIPTS,
    data,
  }),
  mediaDownload: (data) => ({
    type: communicationActions.MEDIA_FILE_DOWNLOAD,
    data
  })
};
export default communicationActions;
