import {
  all, takeEvery, fork, call, put, select,
} from 'redux-saga/effects';
import _ from 'lodash';

import api from 'services';
import { showToast } from 'utils/toast';
import actions from './actions';

const entityType = 'preferences';

export function* fetchPreferencesSaga() {
  yield takeEvery(actions.FETCH_PREFERENCES, function* () {
    const data = yield call(api.fetchAll, { entityType });
    yield put({ type: actions.FETCH_PREFERENCES_SUCCESS, data });
    if (!data) {
      yield put({ type: actions.FETCH_PREFERENCES_FAILURE });
    }
  });
}

export function* addPreferenceSaga() {
  yield takeEvery(actions.ADD_PREFERENCE, function* ({ data }) {
    const preference = yield call(api.createItem, { entityType, data });
    if (preference) {
      yield put({ type: actions.ADD_PREFERENCE_SUCCESS, selectedItem: preference });
      yield put({ type: actions.FETCH_PREFERENCES });
    } else {
      yield put({ type: actions.ADD_PREFERENCE_FAILURE });
    }
  });
}

export function* patchPreferenceSaga() {
  yield takeEvery(actions.PATCH_PREFERENCE, function* ({ data, id }) {
    const preference = yield call(api.patchItem, { entityType, data, id });
    yield put({ type: actions.EDIT_PREFERENCE_SUCCESS, selectedItem: preference });
    yield put({ type: actions.FETCH_PREFERENCES });
    if (!preference) {
      yield put({ type: actions.EDIT_PREFERENCE_FAILURE });
    }
  });
}

export function* editPreferenceSaga() {
  yield takeEvery(actions.EDIT_PREFERENCE, function* ({ data, context, onFinish }) {
    const state = yield select();
    const existingPreferences = _.get(state, 'preferences.data', {});
    const existingContext = _.get(existingPreferences, context, {});
    const user = _.get(state, 'Profile.user', {});
    const preferences = {
      ...existingPreferences,
      [context]: {
        ...existingContext,
        ...data,
      },
    };
    const updatedUser = yield call(api.editItem, { entityType: 'user', data: { ...user, preferences: JSON.stringify(preferences) }, disableNotification: true });
    if (updatedUser) showToast('success', { task: 'Preferences updated' });
    yield put({ type: actions.EDIT_PREFERENCE_SUCCESS, data: preferences, context });
    onFinish();
  });
}

export function* deletePreferenceSaga() {
  yield takeEvery(actions.DELETE_PREFERENCE, function* ({ id }) {
    // yield call(api.deleteItem, { entityType, id });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchPreferencesSaga),
    fork(addPreferenceSaga),
    fork(editPreferenceSaga),
    fork(patchPreferenceSaga),
    fork(deletePreferenceSaga),
  ]);
}
