import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import actions from './actions';
import api from '../../services';
import { transformCollections } from '../../transform/collection';

const entityType = 'collections';

export function* fetchCollectionsSaga() {
  yield takeEvery(actions.FETCH_COLLECTIONS, function* () {
    const data = yield call(api.fetchAll, { entityType, transform: transformCollections });
    if (!data) {
      yield put({ type: actions.FETCH_COLLECTIONS_FAILURE });
    }
    yield put({ type: actions.FETCH_COLLECTIONS_SUCCESS, data });
  });
}

export function* addCollection() {
  yield takeEvery(actions.ADD_COLLECTION, function* ({ data }) {
    const collection = yield call(api.createItem, { entityType, data });
    if (!collection) {
      yield put({ type: actions.ADD_COLLECTION_FAILURE });
    }
    yield put({ type: actions.ADD_COLLECTION_SUCCESS, selectedItem: collection });
    yield put({ type: actions.FETCH_COLLECTIONS });
  });
}

export function* editCollection() {
  yield takeEvery(actions.EDIT_COLLECTION, function* ({ data, id }) {
    const collection = yield call(api.editItem, { entityType, data, id });
    if (!collection) {
      yield put({ type: actions.EDIT_COLLECTION_FAILURE });
    }
    yield put({ type: actions.EDIT_COLLECTION_SUCCESS, selectedItem: collection });
    yield put({ type: actions.FETCH_COLLECTIONS });
  });
}

export function* deleteCollection() {
  yield takeEvery(actions.DELETE__COLLECTION, function* () {
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchCollectionsSaga),
    fork(addCollection),
    fork(editCollection),
    fork(deleteCollection),
  ]);
}
