import * as Yup from 'yup';
import uuid from 'react-uuid';
import validations from './validations';

const spottingGroupModel = [
  {
    field: 'name',
    primaryKey: true,
  },
  {
    field: 'keywords',
    type: 'array',
    defaultValue: [
      {
        name: '',
        inEdit: true,
        id: uuid(),
      },
    ],
  },
  {
    field: 'updatedDate',
    type: 'date',
    server: true,
    defaultSort: true,
    sortDirection: 'desc',
  },
];

const spottingGroupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .max(255, 'Limit 255 characters')
    .test(
      'valid',
      'Can only contain letters, numbers, underscore or a dash',
      // (v) => !v || v.match(validations.lettersNumbersCharacters),
      (v) => !v || v.match(/^[a-z0-9\-\_]+$/i),
    )
    .test(...validations.trimSpaces)
    .test(
      'is-unique',
      'Must be unique',
      function test(v) {
        const { existingNames = [] } = this.parent;
        return v && !existingNames.includes(v.toLowerCase());
      },
    ),
  keywords: Yup.array()
    .required('Keywords are Required')
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Keyword name is required')
          .max(100, 'Keyword name is limited to 100 characters')
          .test(
            'valid',
            'Keyword name can only contain letters, numbers, -, or spaces',
            (v) => !v || v.match(/^[a-zA-Z0-9- ]*$/g),
          )
          .test(...validations.trimSpaces),
      }),
    )
    .uniqueInArray('Keyword name must be unique', 'name'),
});

export { spottingGroupModel, spottingGroupSchema };
