/* eslint-disable no-confusing-arrow */
/* eslint-disable arrow-parens */
/* eslint-disable no-case-declarations */
/* eslint-disable import/named */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { cloneDeep } from 'lodash';
import { getBaseReducer, rePosition } from 'utils';
import actions from './actions';

const initState = {
  ...getBaseReducer(), // model
  entityType: 'checklists',
  cache: [],
  defaultExpandedKeys: [],
  selectedAlert: {},
  selectedPopup: {},
  selectedItemPopup: {},
  activeTab: 'checklist-items',
  addItemPopup: false,
  addGlobalPopup: false,
  tempItem: null,
  treeScrollTop: {
    checklistItemScrollTop: 0,
    alertsTreeScrollTop: 0,
    popupsTreeScrollTop: 0,
  },
};

const filterChecklistItem = (selectedChecklist, id) => {
  const clone = cloneDeep(selectedChecklist);
  const newItems = [];
  clone.items.forEach(el => {
    if (el.children.length) {
      el.children = el.children.filter(c => c.id !== id);
    }
    if (el.id !== id) newItems.push(el);
  });
  clone.items = rePosition(newItems);
  return clone;
};

const filterByProp = (selectedChecklist, id, prop) => {
  const clone = cloneDeep(selectedChecklist);
  if (clone[prop]) {
    const newItems = clone[prop].filter(el => el.id !== id);
    clone[prop] = rePosition(newItems);
  }
  return clone;
};

// const filterAlertItem = (selectedChecklist, id) => {
//   const clone = cloneDeep(selectedChecklist);
//   const newItems = clone.alerts.filter(el => el.id !== id);
//   clone.alerts = rePosition(newItems);
//   return clone;
// };

// const filterPopup = (selectedChecklist, id) => {
//   const clone = cloneDeep(selectedChecklist);
//   const newItems = clone.globalPopUps.filter(el => el.id !== id);
//   clone.alerts = rePosition(newItems);
//   return clone;
// };

export default function checklistReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_CHECKLISTS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.CLEAR_CHECKLIST_SELECTED:
      return {
        ...state,
        selectedItem: {},
        selectedPopup: {},
        selectedAlert: {},
        selectedItemPopup: {},
      };
    case actions.SORT_CHECKLISTS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_CHECKLISTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        init: true,
      };
    case actions.FETCH_CHECKLIST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_CHECKLIST_SUCCESS:
      const { data: checklist } = action;
      checklist.items = rePosition(checklist.items);
      checklist.alerts = rePosition(checklist.alerts);
      checklist.items = checklist.items.map(item => {
        item.children = item.popups;
        if (item.id === state?.expandItemID) {
          item.expanded = true;
        }
        return item;
      });
      return {
        ...state,
        selectedItem: checklist,
        data: state.data.map(c => (c.id === checklist.id ? checklist : c)),
        isLoading: false,
      };
    case actions.FETCH_CHECKLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SELECT_CHECKLIST: {
      return {
        ...state,
        refreshingChecklist: true,
      };
    }
    case actions.ADD_CHECKLIST_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.selectedItem],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_CHECKLIST_SUCCESS:
      const index = state.data.findIndex(x => x.id === action.selectedItem.id);
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          action.selectedItem,
          ...state.data.slice(index + 1),
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_CHECKLIST_CACHE:
      const { id, content, query } = action.data || {};
      return {
        ...state,
        cache: [
          ...state.cache.filter(x => x.id !== id),
          { id, content, query },
        ],
      };
    case actions.DELETE_CHECKLIST_CACHE:
      return {
        ...state,
        cache: state.cache ? state.cache.filter(x => x.id !== action.id) : [],
      };
    case actions.DELETE_CHECKLIST:
      return {
        ...state,
        data: state.data ? state.data.filter(x => x.id !== action.id) : [],
        cache: state.cache ? state.cache.filter(x => x.id !== action.id) : [],
        selectedId: null,
      };
    case actions.CHANGE_CHECKLIST_VIEW: {
      return {
        ...state,
        view: !state.view,
      };
    }
    case actions.UPDATE_CHECKLIST_ITEMS: {
      return {
        ...state,
      };
    }
    case actions.UPDATE_CHECKLIST_ITEMS_SUCCESS:
      return {
        ...state,
        data: state.data.map(cl => cl.id === action.data.id ? action.data : cl),
      };
    case actions.UPDATE_CHECKLIST_ITEMS_FAILURE:
      return {
        ...state,
        err: action.err,
      };
    case actions.DELETE_CHECKLIST_ITEM: {
      const updatedChecklist = filterChecklistItem(
        state.selectedItem,
        action.id,
      );
      return {
        ...state,
        tempData: state.data,
        data: state.data.map(cl => cl.id === updatedChecklist.id ? updatedChecklist : cl),
        selectedItem: updatedChecklist,
      };
    }
    case actions.DELETE_CHECKLIST_ITEM_SUCCESS:
      return {
        ...state,
        tempData: null,
      };
    case actions.DELETE_CHECKLIST_ITEM_FAILURE:
      return {
        ...state,
        ...(state.tempData && { data: state.tempData }),
      };
    case actions.ON_CHECKLIST_ITEM_TREE_CHANGE:
      return {
        ...state,
        selectedItem: { ...state.selectedItem, items: action.treeData },
      };
    case actions.ON_ALERT_TREE_CHANGE:
      return {
        ...state,
        selectedItem: { ...state.selectedItem, alerts: action.treeData },
      };
    case actions.ADD_ALERT:
      return {
        ...state,
      };
    case actions.ADD_ALERT_SUCCESS:
      return {
        ...state,
        // selectedAlert: action.selectedAlert,
        activeTab: 'checklist-alerts',
      };
    case actions.SELECT_CHECKLIST_ALERT:
      return {
        ...state,
        selectedAlert: action.selectedAlert,
      };
    case actions.DELETE_ALERT: {
      const updatedChecklist = filterByProp(
        state.selectedItem,
        action.id,
        'alerts',
      );
      return {
        ...state,
        tempData: state.data,
        data: state.data.map(cl => cl.id === updatedChecklist.id ? updatedChecklist : cl),
        selectedItem: updatedChecklist,
      };
    }
    case actions.DELETE_ALERT_SUCCESS:
      return {
        ...state,
        tempData: null,
        selectedAlert: {},
      };
    case actions.DELETE_ALERT_FAILURE:
      return {
        ...state,
        ...(state.tempData && { data: state.tempData }),
      };
    case actions.DELETE_POPUP: {
      let updatedChecklistt = state.selectedItem;
      if (action.isGlobal === false) {
        updatedChecklistt.items = updatedChecklistt.items.map(item => {
          if (item?.popups) {
            item.children = item.popups = item.popups.filter(
              popup => popup.id !== action.id,
            );
          }
          return item;
        });
      } else {
        updatedChecklistt = filterByProp(
          state.selectedItem,
          action.id,
          'globalPopups',
        );
      }
      return {
        ...state,
        tempData: state.data,
        data: state.data.map(cl => cl.id === updatedChecklistt.id ? updatedChecklistt : cl),
        selectedItem: updatedChecklistt,
      };
    }
    case actions.DELETE_POPUP_SUCCESS:
      return {
        ...state,
        tempData: null,
        selectedPopup: {},
      };
    case actions.DELETE_POPUP_FAILURE:
      return {
        ...state,
        ...(state.tempData && { data: state.tempData }),
      };
    case actions.CHANGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case actions.FIX_SERVER_POSITIONING_SUCCESS:
      const { checklist: checklst } = action;
      // const checklist = fakeChecklist;
      checklst.items = checklst.items.map(item => {
        item.children = item.popups.map(popup => ({
          ...popup,
        }));
        return item;
      });
      checklst.items = rePosition(checklst.items);
      checklst.alerts = rePosition(checklst.alerts);
      return {
        ...state,
        selectedItem: checklst,
        refreshingChecklist: false,
      };
    case actions.FIX_SERVER_POSITIONING_FAILURE:
      return {
        ...state,
        refreshingChecklist: false,
      };
    case actions.UPDATE_ALERT_SUCCESS:
      return {
        ...state,
        selectedAlert: action.selectedAlert,
      };
    case actions.SELECT_CHECKLIST_POPUP:
      return {
        ...state,
        selectedPopup: action.selectedPopup,
      };
    case actions.ADD_POPUP:
      return {
        ...state,
      };
    case actions.ADD_POPUP_SUCCESS:
      return {
        ...state,
        selectedPopup: {},
        expandItemID: action.expandItemID,
      };
    case actions.UPDATE_POPUP_SUCCESS:
      return {
        ...state,
        selectedPopup: action.selectedPopup,
        expandItemID: action.expandItemID,
      };
    case actions.SET_ADD_ITEM_POPUP:
      return {
        ...state,
        addItemPopup: action.bool,
      };
    case actions.UPDATE_SELECTED_CHECKLIST:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.UPDATE_ADD_GLOBAL_POPUP_FLAG:
      return {
        ...state,
        addGlobalPopup: action.bool,
      };
    case actions.CREATE_TEMP_ITEM:
      const tempItem = { id: 'ciTempID', name: 'New Item' };
      return {
        ...state,
        // tempItem,
        selectedItem: {
          ...state.selectedItem,
          items: [
            ...state.selectedItem.items,
            ...(!state.selectedItem.items.find(it => it.id === 'ciTempID')
              ? [tempItem]
              : []),
          ],
        },
      };
    case actions.CREATE_TEMP_ALERT:
      const tempAlert = { id: 'aTempID', name: 'New Alert' };
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          alerts: [
            ...state.selectedItem.alerts,
            ...(!state.selectedItem.alerts.find(it => it.id === tempAlert.id)
              ? [tempAlert]
              : []),
          ],
        },
        selectedAlert: tempAlert,
      };
    case actions.CREATE_TEMP_GLOBAL_POPUP:
      const tempGlobalPopup = {
        id: 'tempID',
        name: 'New Global Popup',
        requireAll: true,
        // type: 'SOME HARDCODED TYPES',
        categories: [{ name: '', text: '' }],
        checklistItemId: action.checklistId,
      };
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          globalPopups: [
            ...state.selectedItem.globalPopups,
            ...(!state.selectedItem.alerts.find(it => it.id === tempGlobalPopup.id)
              ? [tempGlobalPopup]
              : []),
          ],
        },
        selectedPopup: tempGlobalPopup,
      };
    case actions.UPDATE_TREE_SCROLL_TOP:
      return {
        ...state,
        treeScrollTop: { ...state.treeScrollTop, [action.name]: action.value },
      };
    case actions.CLEAR_TREE_SCROLL:
      return {
        ...state,
        treeScrollTop: initState.treeScrollTop,
      };
    case actions.SET_CHECKLIST_SETTINGS:
      return {
        ...state,
        selectedItem: { ...state.selectedItem, settings: action.settings },
      };
    case action.SAVE_SETTINGS:
      return {
        ...state,
      };
    case actions.SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        selectedItem: action.data,
      };
    case actions.SAVE_SETTINGS_FAILURE:
      return {
        ...state,
      };
    case actions.DUPLICATE_CHECKLIST:
      return {
        ...state,
        checklistId: action.checklistId,
      };
    default:
      return state;
  }
}
