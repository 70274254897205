/** @prettier */
import { createGlobalStyle } from 'styled-components';

export const ButtonStyles = createGlobalStyle`
  button {
    cursor: pointer;
  }

  .sg-button {
    padding: 4px 14px;
  }

  .sg-button.sg-primary {
    background-color: ${(props) => (props.disabled ? '' : props.theme?.button?.primary)};
    border-color:${(props) => (props.disabled ? '' : '#0079c0')};
    transition: all 0.3s;
    background-image: none;
    box-shadow: none !important;

    &:hover {
      background-color: ${(props) => (props.disabled ? '' : props.theme?.button?.hover?.primary)};
      border-color: ${(props) => (props.disabled ? '' : props.theme?.button?.hover?.primary)};
    }

    &:active {
      background-color: #003980;
      border-color: #003980;
    }

    &.sg-bare {
      color: #0079c0;

      &:hover {
        color: #0059a0;
      }
    }
  }

  .sg-button.sg-success {
    background-color: #6eb733;
    color: white !important;
    border-color: #6eb733;
    transition: all 0.3s;
    background-image: none;
    box-shadow: none !important;

    &:hover {
      background-color: #4e9713;
      border-color: #4e9713;
    }

    &:active {
      background-color: #2e7700;
      border-color: #2e7700;
    }

    &.sg-bare {
      color: #2e7700;

      &:hover {
        color: #0e5700;
      }
    }
  }

  .sg-button.sg-danger {
    background-color: #d75353;
    color: white !important;
    border-color: #d75353;
    transition: all 0.3s;
    background-image: none;
    box-shadow: none !important;

    &:hover {
      background-color: #b73333;
      border-color: #b73333;
    }

    &:active {
      background-color: #971313;
      border-color: #971313;
    }

    &.sg-bare {
      color: #d75353 !important;

      &:hover {
        color: #971313 !important;
      }
    }
  }

  .sg-button.sg-cancel {
    background: #ffffff;
    border-color: #0079c0;
    color: #0079c0;

    &:hover {
      background-color: darken(#ffffff, 10%);
      border-color: darken(#0079c0, 10%);
    }
  }`;
