/** @prettier */

import moment from 'moment';

import {
  clone, forEach, find, findKey,
} from 'lodash';
import { VBODashboardConstants } from 'screens/VBODashboards/constants';

import {
  FiPhone,
  BsVolumeMute,
  FiSmile,
  AiOutlineMessage,
  AiOutlineFolder,
} from 'react-icons/all';
import { isNested } from '../screens/VBODashboards/components/common';

const measuresMetaData = [
  { group: 'Call Metrics', value: 'cmt', icon: FiPhone },
  { group: 'Silence', value: 'sil', icon: BsVolumeMute },
  { group: 'Sentiment', value: 'sent', icon: FiSmile },
  { group: 'Talk Style', value: 'tkst', icon: AiOutlineMessage },
];

const dimensionsMetaData = [
  { name: 'callStartTime', type: 'date', interval: 'day' },
  { name: 'dateCreated', type: 'date', interval: 'day' },
];

const createRows = (startDay, numberDays, xAxisName, yAxisName) => {
  const rows = [];

  for (let i = 0; i < numberDays; i += 1) {
    rows.push({
      name: xAxisName,
      label: startDay.format(),
      docCount: 0,
      columns: [
        {
          name: yAxisName,
          value: 0,
        },
      ],
    });
    startDay.add(1, 'days');
  }

  return rows;
};

const fillInRows = (firstDate, originalRows, numDays, xAxisName, yAxisName) => {
  const oldRows = clone(originalRows);
  const rowDate = firstDate.clone();
  let newRows = [];
  let rowIndex = 0;

  for (let i = 0; i <= numDays; i += 1) {
    if (rowDate.isSame(moment(oldRows[rowIndex].label).utc())) {
      newRows[i] = oldRows[rowIndex];
      rowIndex += 1;
    } else {
      newRows = newRows.concat(createRows(rowDate.clone(), 1, xAxisName, yAxisName));
    }
    rowDate.add(1, 'days');
  }

  return newRows;
};

const addMissingDays = (days, startDate, endDate) => {
  let newDays = clone(days);
  if (newDays.length) {
    let diffDays;
    const firstRowLabel = newDays[0].label;
    const firstRowDate = moment(firstRowLabel).utc();

    const lastRowLabel = newDays[newDays.length - 1].label;
    const lastRowDate = moment(lastRowLabel).utc();

    const xAxisName = newDays[0]?.name;
    const yAxisName = newDays[0]?.columns[0]?.name;

    // fill missing in-between values
    diffDays = lastRowDate.diff(firstRowDate, 'days');
    if (diffDays > newDays.length) {
      // missing data so fill-in.
      newDays = fillInRows(firstRowDate, newDays, diffDays, xAxisName, yAxisName);
    }

    // fill missing before values
    if (startDate.isBefore(firstRowDate)) {
      diffDays = moment(firstRowDate).diff(startDate, 'days');
      const rowsToPrepend = createRows(startDate.clone(), diffDays, xAxisName, yAxisName);
      newDays = rowsToPrepend.concat(newDays);
    }

    // fill missing after values
    // TODO - make sure don't add values for dates after today.
    if (lastRowDate.isBefore(endDate)) {
      diffDays = moment(endDate).diff(lastRowDate, 'days') - 1;
      const rowsToAppend = createRows(lastRowDate.clone().add(1, 'days'), diffDays, xAxisName, yAxisName);
      newDays = newDays.concat(rowsToAppend);
    }
  }

  return newDays;
};

export const transformVboChartsData = (data, filterData) => {
  const newChart = data;

  const dateKey = findKey(filterData, (filter) => filter.type === 'timeRange');
  const dateRange = filterData[dateKey].selectedValue;
  if (dateRange) {
    const date = dateRange.split('~');
    const startDate = moment(date[0]).utc();
    const endDate = moment(date[1]).utc();

    const interval = isNested(newChart.rows)
      ? newChart?.aggregations[0]?.aggregations[0]?.interval
      : newChart?.aggregations[0]?.interval;

    if (
      interval == 'day'
      && (newChart?.type === 'line-graph' || newChart?.type === 'bar-graph' || newChart?.type === 'spark-line' || newChart?.type === 'heatmap')
    ) {
      if (isNested(newChart.rows)) {
        forEach(newChart.rows, (row) => {
          row.rows = addMissingDays(row.rows, startDate, endDate);
        });
      } else {
        newChart.rows = addMissingDays(newChart.rows, startDate, endDate);
      }
    }
  }

  return newChart;
};

export const transformVboMeasures = (measures) => {
  // Resolution % and Alerts/Categories groups should be ignored.
  const newMeasures = [];
  if (measures) {
    measures.forEach((measure) => {
      const newMeasure = find(newMeasures, { label: measure.group });
      if (newMeasure && measure.group !== VBODashboardConstants.EDIT_SIDEBAR_RESOLUTION_NAME && measure.group !== VBODashboardConstants.EDIT_SIDEBAR_ALERTS_NAME) {
        newMeasure.options.push({
          label: measure.label,
          value: measure.name,
          description: measure.description,
          units: measure.units,
          scale: measure.scale,
        });
      } else if (!newMeasure) {
        const measureMetaData = find(measuresMetaData, { group: measure.group });
        if (measure.group !== VBODashboardConstants.EDIT_SIDEBAR_RESOLUTION_NAME && measure.group !== VBODashboardConstants.EDIT_SIDEBAR_ALERTS_NAME) {
          const measureObject = {
            label: measure.group,
            value: measureMetaData ? measureMetaData.value : null,
            icon: measureMetaData ? measureMetaData.icon : AiOutlineFolder,
            options: [
              {
                label: measure.label,
                value: measure.name,
                description: measure.description,
                units: measure.units,
                scale: measure.scale,
              },
            ],
          };
          newMeasures.push(measureObject);
        }
      }
    });
  }

  return newMeasures;
};

export const transformVboDimensions = (dimensions) => {
  let newDimensions = [];

  if (dimensions) {
    newDimensions = dimensions.filter((dimension) => dimension.group === 'Display');
    newDimensions = newDimensions.map((dimension) => {
      const dimensionMetaData = find(dimensionsMetaData, { name: dimension.name });
      return {
        label: dimension.label,
        value: dimension.name,
        type: dimensionMetaData ? dimensionMetaData.type : 'terms',
        interval: dimensionMetaData ? dimensionMetaData.interval : null,
      };
    });
  }

  // Needed for collections appended in separate call
  newDimensions.push({ label: 'Collections', value: 'col', options: [] });

  return newDimensions;
};
