const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL || 'http://';
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
const API_PORT = process.env.REACT_APP_API_PORT;
const API_URL = process.env.REACT_APP_API_URL;

const ADMIN_API_PROTOCOL = process.env.REACT_APP_ADMIN_API_PROTOCOL || API_PROTOCOL;
const ADMIN_API_DOMAIN = process.env.REACT_APP_ADMIN_API_DOMAIN;
const ADMIN_API_PORT = process.env.REACT_APP_ADMIN_API_PORT || API_PORT;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const PLATFORM_API_PROTOCOL = process.env.REACT_APP_PLATFORM_API_PROTOCOL || API_PROTOCOL;
const PLATFORM_API_DOMAIN = process.env.REACT_APP_PLATFORM_API_DOMAIN;
const PLATFORM_API_PORT = process.env.REACT_APP_PLATFORM_API_PORT;
const PLATFORM_API_URL = process.env.REACT_APP_PLATFORM_API_URL;
const PLATFORM_ROOT_PATH = process.env.REACT_APP_PLATFORM_ROOT_PATH || '/v3/';
const platformApiUrl = PLATFORM_API_URL || `${PLATFORM_API_PROTOCOL}${PLATFORM_API_DOMAIN}${PLATFORM_API_PORT ? `:${PLATFORM_API_PORT}` : ''}`;

const SUPERVISOR_API_URL = process.env.REACT_APP_SUPERVISOR_URL || '';
const ROOT_PATH = process.env.REACT_APP_ROOT_PATH ;

const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

const TABLEAU_URL = process.env.REACT_APP_TABLEAU_URL;
const TABLEAU_PROJECT = process.env.REACT_APP_TABLEAU_PROJECT || 'ThriveAirPortal-autosize';
const TABLEAU_SITE = process.env.REACT_APP_TABLEAU_SITE || 'Sales_Demos';

const apiUrl = API_URL || `${API_PROTOCOL}${API_DOMAIN}${API_PORT ? `:${API_PORT}` : ''}`; // https://demo.labs.voicebase.com:81'; // 'http://voicebase.com/api/'
const adminApiUrl = ADMIN_API_URL || `${ADMIN_API_PROTOCOL}${ADMIN_API_DOMAIN}${ADMIN_API_PORT ? `:${ADMIN_API_PORT}` : ''}`; // https://demo.labs.voicebase.com:81'; // 'http://voicebase.com/api/'
const apiRoot = `${apiUrl}${ROOT_PATH}`;
const parsedUrl = new URL(window.location.href);
const googleApiKey = process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY || ''
const pendoApiKey = process.env.REACT_APP_PENDO_KEY || ''

const api = {
  app: parsedUrl.origin,
  url: apiUrl,
  adminUrl: adminApiUrl,
  supervisorApiUrl: SUPERVISOR_API_URL,
  rootPath: ROOT_PATH,
  root: apiRoot,
  auth: `${apiRoot}services/authenticate`,
  pattern: 'normal',

  fetchAll: `${apiRoot}system/get`,
  search: `${apiRoot}system/search`,
  fetchItem: `${apiRoot}system/get`,
  createItem: `${apiRoot}system/upsert`,
  editItem: `${apiRoot}system/upsert`,
  patchItem: `${apiRoot}system/upsert`,
  deleteItem: `${apiRoot}system/delete`,
  okta: {
    domain: AUTH_DOMAIN,
    clientId: AUTH_CLIENT_ID,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  entityContext: {
    // dashboards: 'mock',
    kpis: 'mock',
    tables: 'mock',
    charts: 'mock',
    // checklists: 'mock',
    // EA server adds properties to categories:
    // categories: 'platform',
    vp: 'platform',
    jobs: 'platform',
    jobsBatch: 'platform',
    communications: 'platform',
    vocabularyLists: 'platform',
    spottingGroups: 'platform',
    bearerTokens: 'platform',
    ttl: 'platform',
    managementJobs: 'platform'
  },
  serverContextMap: {
    platform: `${platformApiUrl}${PLATFORM_ROOT_PATH}`,
  },
  entityMap: {
    mappings: 'etl-mapping',
    checklistItems: 'checklist-items',
    checklistAlerts: 'checklist-alerts',
    jobs: 'jobs/categorizations',
    jobsBatch: 'jobs/batch',
    managementJobs: 'management/jobs/categorizations',
    ttl: 'account',
    scores: 'scores/time-intervals',
    streams: 'telecom-devices',
    groups: 'orgs',
    questions: 'scorecard-questions',
    collections: 'groups', // 'ConfigGroup',
    interactions: 'categories/vbql',
    bearerTokens: 'profile/keys',
    vocabularyLists: 'definition/vocabularies',
    spottingGroups: 'definition/spotting/groups',
    communications: 'media',
    connections: 'integration-configurations',
    syncMediaToWarehouse: 'media/sync',
    syncOntologies: 'ontologies/sync',
    processes: 'procdwexportprocessing',
    channels: 'sourcechannels', // 'actsourcesystem', // no screen
    dataSources: 'sources', // 'sourcesystems', // 'actdwsubscription',
    vocabularies: 'ontologies', // 'ConfigOntology',
    kpis: 'metrics', // 'ConfigAggMetricDef',
    classifiers: 'definition/prediction/classifiers?ignoreDisabled=true',
    namedConfiguration: 'definition/configurations/'
  },
  filterNameMappings: {
    scorecardId: 'scorecard',
  },
  apiFilters: {
    predictors: {
      ontologyType: 'PREDICTOR',
    },
    collections: {
      collection: true,
    },
  },
  googleAnalytics: {
    apiKey: googleApiKey
  },
  tableau: {
    root: TABLEAU_URL,
    site: TABLEAU_SITE,
    project: TABLEAU_PROJECT,
    url: `/t/${TABLEAU_SITE}/views/${TABLEAU_PROJECT}/`,
    eaUrl: process.env.REACT_APP_TABLEAU_EA_URL,
    ticket: `${apiRoot}services/tableau/trustedticket/site/${TABLEAU_SITE}/userid/`,
  },
  pendo: {
    apiKey: pendoApiKey
  },
};
export default api;
