import _ from 'lodash';
import uuid from 'react-uuid';

export const transformSpottingGroup = (data) => {
  const keywords = data.keywords || [];
  return {
    ...data,
    name: data.groupName,
    count: keywords.length,
    keywordsStr: keywords.join(', '),
    keywords: keywords.map((t, i) => ({
      name: t,
      id: `${i}_${t}`,
      inEdit: true,
    })),
  };
};
export const transformSpottingGroups = (res) => {
  const data = _.get(res, 'groups', []);
  return data.map((u) => transformSpottingGroup(u));
};

const fieldsToRemove = ['updatedDate', 'createdDate', 'name', 'keywordsStr', 'dateModified', 'dateCreated', 'count', 'existingNames'];
export const prepSpottingGroup = (data) => _.omit({
  ...data,
  groupName: data.name,
  keywords: data.keywords.map((x) => x.name).filter((x) => x),
}, fieldsToRemove);

export const prepKeywordsForCsv = (t) => ({ name: t.name });

export const importKeywordsFromCsv = (t) => ({
  name: t.name,
  id: uuid(),
  inEdit: true,
}); // the import process converts column name to all smalls
