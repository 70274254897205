const interactionActions = {
  FETCH_INTERACTIONS: 'FETCH_INTERACTIONS',
  FETCH_INTERACTIONS_SUCCESS: 'FETCH_INTERACTIONS_SUCCESS',
  FETCH_INTERACTIONS_FAILURE: 'FETCH_INTERACTIONS_FAILURE',
  SELECT_INTERACTION: 'SELECT_INTERACTION',
  ADD_INTERACTION: 'ADD_INTERACTION',
  ADD_INTERACTION_SUCCESS: 'ADD_INTERACTION_SUCCESS',
  ADD_INTERACTION_FAILURE: 'ADD_INTERACTION_FAILURE',
  EDIT_INTERACTION: 'EDIT_INTERACTION',
  EDIT_INTERACTION_SUCCESS: 'EDIT_INTERACTION_SUCCESS',
  EDIT_INTERACTION_FAILURE: 'EDIT_INTERACTION_FAILURE',
  PATCH_INTERACTION: 'PATCH_INTERACTION',
  GET_INTERACTION: 'GET_INTERACTION',
  DELETE__INTERACTION: 'DELETE__INTERACTION',
  CHANGE_INTERACTION: 'CHANGE_INTERACTION',
  CHANGE_INTERACTION_VIEW: 'CHANGE_INTERACTION_VIEW',
  CLEAR_INTERACTIONS: 'CLEAR_INTERACTIONS',
  SORT_INTERACTIONS: 'SORT_INTERACTIONS',
  UPDATE_LAST_PLAYED: 'UPDATE_LAST_PLAYED',
  SET_ACTIVE_MEDIA: 'SET_ACTIVE_MEDIA',
  SET_LAST_SELECTED_PLAYER: 'SET_LAST_SELECTED_PLAYER',
  RESET_INTERACTIONS_INSTANCES: 'RESET_INTERACTIONS_INSTANCES',
  sortInteractions: (e) => ({
    type: interactionActions.SORT_INTERACTIONS,
    sort: e.sort,
  }),
  clearInteractions: (options) => ({
    type: interactionActions.CLEAR_INTERACTIONS,
  }),
  fetchVBQL: (filters, instance) => ({
    type: interactionActions.FETCH_INTERACTIONS,
    filters,
    instance,
  }),
  selectInteraction: (selectedItem) => ({
    type: interactionActions.SELECT_INTERACTION,
    selectedItem,
  }),
  addInteraction: (data) => ({
    type: interactionActions.ADD_INTERACTION,
    data,
  }),
  editInteraction: (data, id) => ({
    type: interactionActions.EDIT_INTERACTION,
    data,
    id,
  }),
  patchInteraction: (data) => ({
    type: interactionActions.PATCH_INTERACTION,
    data,
  }),
  getInteraction: (data) => ({
    type: interactionActions.GET_INTERACTION,
    data,
  }),
  deleteInteraction: (id) => ({
    type: interactionActions.DELETE__INTERACTION,
    id,
  }),
  changeInteractionView: (selectedItem) => ({
    type: interactionActions.CHANGE_INTERACTION_VIEW,
    selectedItem,
  }),
  setLastSelectedPlayer: (mediaId) => ({
    type: interactionActions.SET_LAST_SELECTED_PLAYER,
    mediaId
  }),
  updateLastPlayed: (mediaId) => ({
    type: interactionActions.UPDATE_LAST_PLAYED,
    mediaId,
  }),
  fetchInteractionsSuccess: (data) => ({
    type: interactionActions.FETCH_INTERACTIONS_SUCCESS,
    ...data,
  }),
  setActiveMedia: (mediaId) => ({
    type: interactionActions.SET_ACTIVE_MEDIA,
    mediaId,
  }),
  resetInteractionInstances: () => ({
    type: interactionActions.RESET_INTERACTIONS_INSTANCES,
  })
};
export default interactionActions;
