/* istanbul ignore file */

import styled from 'styled-components';
import { palette, font } from 'styled-theme';

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};
  height: 100%;

  > div {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .ant-row:not(.ant-form-item) {
    ${'' /* margin-left: -8px;
    margin-right: -8px; */};
    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /*
    LAYOUT
  */

  .ReactCollapse--collapse {
    transition: height 0.3s;
  }

  .Layout {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .Content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .ContentTop {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background-color: white;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

      h3 {
        margin: 0;
        font-weight: 300;
        letter-spacing: -0.02em;
        font-size: 18px;
      }
    }

    .ContentMain {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .Row {
    display: flex;
    margin: 0 -10px;

    .Col {
      padding: 0 10px;
      flex: 1;
    }
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
  }

  .padding-0 {
    padding: 0 !important;
  }

  /*
    TYPOGRAPHY
  */

  body {
    font-family: 'Montserrat';
    font-size: 14px;
  }

  body,
  .k-content,
  .k-tabstrip > .k-content {
    color: #081433;
  }

  h1 {
    font-size: 24px;
    letter-spacing: -0.03em;
    font-weight: 300;
    // margin: 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
  }

  a,
  button.link {
    color: #0079c0;
    text-decoration: none;
    cursor: pointer;
  }

  button.link {
    border: 0;
    padding: 0;
    box-shadow: none;
    background: transparent;
    font-weight: inherit;
    font-size: inherit;
  }

  .text-muted {
    color: #757779 !important;
  }

  .text-right {
    text-align: right !important;
  }

  .small-caps-bold,
  .small-caps-light  {
    font-size: 11px;
    text-transform: uppercase;
    color: #262D38;
    line-height: 24px;
  }

  .small-caps-light {
    opacity: 0.5;
    font-weight: 500;
  }

  .small-caps-bold {
    font-weight: 800;
  }

  /*
    TABLES
  */

  .k-grid {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #081433;
    font-weight: 300;

    .k-header {
      background-color: #f5f7f9;
      color: black;

      a {
        color: black;
      }
    }

    th {
      font-size: 11px;
      opacity: 0.5;
      text-transform: uppercase;
      line-height: 20px;
      font-weight: normal;
    }

    th,
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 0;
      color: #081433 !important;

      &.right {
        text-align: right !important;
      }

      &.center {
        text-align: center !important;
      }

      &.red,
      &.danger,
      &.bad {
        color: #d75353 !important;
      }

      &.green,
      &.success,
      &.good {
        color: #48a000 !important;
      }

      &.muted {
        color: #757779 !important;
      }

      &.bold {
        font-weight: 500 !important;
      }

      &.compressed {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    th {
      padding-top: 0;
      padding-bottom: 0;
    }

    tbody td:first-child,
    thead tr:first-child th:first-child {
      padding-left: 20px;
    }

    tbody td:last-child,
    thead th:last-child {
      padding-right: 20px;
    }
  }

  .k-grid-dark {
    background-color: transparent;
    color: white;

    .k-grid-header,
    .k-header {
      background-color: transparent;
      color: white;

      a {
        color: white;
      }
    }

    th,
    td {
      color: white !important;
    }

    tbody tr:hover,
    tbody tr.k-state-hover {
      background-color: #0079c0;
    }
  }

  .k-grid-small {
    th,
    td {
      padding-top: 5px;
      padding-bottom: 5px;

      &.bad {
        color: #d75353 !important;
      }

      &.good {
        color: #8ed753 !important;
      }
    }
  }

  /*
    FORMS
  */

  .k-form {
    padding: 0;
    margin-bottom: 20px;
    color: #081433;
  }

  .k-textbox {
    height: 36px;
  }

  .k-textbox,
  .k-textarea,
  .k-input {
    padding: 7px 12px;
    color: #081433;
    font-weight: 300;
  }

  .k-multiselect {
    .k-input {
      height: 34px;
    }

    .k-button {
      padding: 4px 8px;
    }

    .k-clear-value {
      top: 7px;
      right: 8px;
    }
  }

  .k-animation-container {
    z-index: 10003; // set the desired value based on the application requirements
  }

  .k-dropdown {
    width: 100%;
  }

  .k-dropdown-wrap .k-input {
    padding: 7px 12px;
    height: 34px;
  }

  .k-radio,
  .k-checkbox {
    // display: none !important;
  }

  .k-form .k-radio-label,
  .k-form .k-checkbox-label {
    padding-left: 24px;
  }

  .k-checkbox:checked + .k-checkbox-label::before,
  .k-radio:checked + .k-radio-label::before {
    border-color: #0079c0;
    background-color: #0079c0;
  }

  /*
    MODALS
  */

  .k-window-titlebar {
    background: #f5f7f9;
    color: #081433;
    // border-bottom: 1px solid #D5D7D9;
    padding: 0.75rem 1rem;
  }

  tr:hover .row-controls {
    display: block;
  }

  /*
    POPUP
  */

  .k-popup {
    border: 0;

    .k-button {
      justify-content: flex-start;
    }
  }
`;

export default DashAppHolder;
