/** @prettier */
import * as Yup from 'yup';
import _ from 'lodash';
import { regexConstants } from '../constants';
import fields from './fields';
import validations from './validations';

const mandatoryFields = ['collection', 'label', 'query', 'industry'];

const collections = [
  'Activities',
  'AgentQuality',
  'Alerts',
  'CallDriver',
  'Emotions',
  'Finance',
  'Marketing',
  'Offers',
  'Other',
  'Outcomes',
  'Products',
  'Sales',
  'Scorecards',
  'Services',
  'Situation',
  'Strategies',
  'Competitors',
  'Objections',
  'Rebuttals',
  'Features',
  'Values',
  'Issues',
  'Promotions',
  'Questions',
  'Pitch',
  'Intro',
  'Closing',
  'Disclosures',
  'Bot',
  'BotSummary',
  'BotConversations',
  'ContactDriver'
];

const categoryModel = [
  fields.id,
  // fields.name,
  {
    field: 'categoryName',
  },
  {
    field: 'label',
    required: true,
  },
  {
    field: 'name',
    server: true, // @todo: this field is actually a client-side calculated field
    defaultSort: true,
  },
  {
    field: 'categoryDescription',
  },
  fields.active,
  {
    field: 'tags',
    type: 'array',
  },
  {
    field: 'query',
    // required: true,
  },
  {
    field: 'version',
    defaultValue: '1.0.0',
  },
  {
    field: 'notes',
  },
  {
    field: 'dateCreated',
    type: 'date',
    server: true,
  },
  {
    field: 'dateModified',
    type: 'date',
    server: true,
  },
  {
    field: 'collection',
    type: 'enum',
    options: collections.sort().map((x) => ({
      label: _.startCase(x),
      value: x,
    })),
    defaultValue: 'Activities',
    required: true,
  },
  {
    field: 'groups',
  },
];
const queryKeywords = ['select', 'where', 'match', 'from'];

const categorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .when('categoryNameSimple', {
      is: true,
      then: Yup.string().required('Required'),
    })
    .test(...validations.basicString)
    .test(...validations.trimSpaces),
  collection: Yup.string()
    .max(125, 'Collection Limited to 125 characters')
    .test(
      'valid',
      'Collection limited to letters, numbers or -',
      (v) => !v || v.match(regexConstants.ALPHA_NUM_AND_HYPHEN)
    )
    .test('valid', 'Collection must start with a letter', (v) => !v || v.match(/^[a-zA-Z]/)),
  groups: Yup.string()
    .max(125, 'Group limited to 125 characters')
    .test('valid', 'Groups limited to letters, numbers, -', (v) => !v || v.match(regexConstants.ALPHA_NUM_AND_HYPHEN)),
  label: Yup.string()
    .when('categoryName', {
      is: (categoryName) => !categoryName,
      then: Yup.string().required('Label required'),
    })
    .max(125, 'Label is limited to 125 characters')
    .test('valid', 'Label limited to letters, numbers or -', (v) => !v || v.match(regexConstants.ALPHA_NUM_AND_HYPHEN)),
  categoryDescription: Yup.string()
    .nullable()
    .max(1000, 'Description is limited to 1000 characters')
    .test(...validations.beginsWithValidCsvCharacter)
    .test(...validations.advancedString3WithQuotes)
    .test(...validations.trimSpacesWithLineReturn),
  tags: Yup.array()
    .of(
      Yup.string()
        .max(63, 'Each Tag is limited to 63 characters')
        .test(...validations.advancedString3WithQuotes)
        .test(...validations.trimSpaces)
        .test(...validations.beginsWithValidCsvCharacter)
    )
    .max(32, 'Max limit of 32 tags'),
  notes: Yup.string()
    .nullable()
    .max(5000, 'Note is limited to 5000 characters')
    .test(...validations.advancedString3WithQuotes)
    .test(...validations.trimSpacesWithLineReturn)
    .test(...validations.beginsWithValidCsvCharacter),
  version: Yup.string()
    .max(50, 'Version is limited to 50 characters')
    .test(...validations.stringWithSpaces)
    .test(...validations.trimSpaces)
    .test(...validations.beginsWithValidCsvCharacter),
  query: Yup.string()
    .required('Query required')
    .max(7000, 'Query is limited to 7000 characters')
    .test('valid', 'Not a valid VBQL query', (v) => {
      if (!v) return true;
      const value = v.toLowerCase();
      return _.some(queryKeywords, (k) => value.indexOf(k) > -1);
    }),
});

const categoryBulkSchema = Yup.array().of(categorySchema);

export { categoryModel, categorySchema, categoryBulkSchema, collections, mandatoryFields };
