import { getBaseReducer } from 'utils';
import { dataSourceModel as model } from 'models/dataSource';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  entityType: 'sources',
};

export default function dataSourceReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_DATASOURCES:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_DATASOURCES:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_DATASOURCES_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
      };
    case actions.SELECT_DATASOURCE:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_DATASOURCE_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_DATASOURCE_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__DATASOURCE:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_DATASOURCE_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    default:
      return state;
  }
}
