import {
  all, takeEvery, fork, call, put, select, takeLatest,
} from 'redux-saga/effects';
import api from 'services';
import _ from 'lodash';
import actions from './actions';

const entityType = 'streams';
const stripData = (data) => _.omit(data, ['actAccountByAccountid', 'createdDt', 'updatedDt', '_flags']);

export function* fetchStreamsSaga() {
  yield takeEvery(actions.FETCH_STREAMS, function* ({ filters, clearExistingFilters = false }) {
    const state = yield select();
    const filtersExisting = _.get(state, 'Streams.filters', {});
    const filtersCleaned = clearExistingFilters ? filters : _.omitBy({ ...filtersExisting, ...filters }, _.isNil);
    const data = yield call(api.fetchAll, { entityType, filters: filtersCleaned });
    if (data && !('err' in data)) {
      yield put({ type: actions.FETCH_STREAMS_SUCCESS, data, filters: filtersCleaned });
    }
  });
}

export function* addStream() {
  yield takeEvery(actions.ADD_STREAM, function* ({ data, onFinish }) {
    const hasSource = !!_.get(data, 'source.id');
    const source = hasSource ? data.source
      : yield call(api.createItem, { entityType: 'dataSources', data: { name: 'defaultTelecom', sourceType: 'telecom' } });
    const stream = yield call(api.createItem, { entityType, data: { ...data, source } });
    if (stream.key) {
      yield put({ type: actions.ADD_STREAM_SUCCESS, newStream: stream });
      yield put({ type: actions.FETCH_STREAMS, filters: { source: source.id } });
    }
    onFinish();
  });
}

export function* patchStream() {
  yield takeEvery(actions.PATCH_STREAM, function* ({ data, id }) {
    const stream = yield call(api.patchItem, { entityType, data, id });
    if (stream) {
      yield put({ type: actions.EDIT_STREAM_SUCCESS, selectedItem: stream });
      yield put({ type: actions.FETCH_STREAMS });
    }
  });
}

export function* editStream() {
  yield takeEvery(actions.EDIT_STREAM, function* ({ data, id, onFinish }) {
    const stream = yield call(api.editItem, { entityType, data: stripData(data), id });
    if (stream) {
      yield put({ type: actions.EDIT_STREAM_SUCCESS, selectedItem: stream });
      yield put({ type: actions.FETCH_STREAMS });
    }
    onFinish();
  });
}

export function* clearNewStream() {
  yield takeLatest(actions.CLEAR_NEW_STREAM, function* () {
    yield put({ type: actions.CLEAR_NEW_STREAM_SUCCESS });
  });
}

export function* deleteStream() {
  yield takeEvery(actions.DELETE__STREAM, function* () {
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchStreamsSaga),
    fork(addStream),
    fork(editStream),
    fork(patchStream),
    fork(deleteStream),
    fork(clearNewStream),
  ]);
}
