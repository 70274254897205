import actions from './actions';

const initState = {};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      const { token, expiresAt } = action.payload;
      return {
        token,
        expiresAt,
      };
    case actions.TOKEN_SET:
      return {
        ...state,
        token: action.token,
      };
    case actions.LOGOUT:
      return {
        token: null,
      };
    default:
      return state;
  }
}
