/* istanbul ignore file */
import clone from 'clone';
import defaultTheme from './themedefault';

const theme = clone(defaultTheme);
export default {
  ...theme,
  palette: {
    ...theme.palette,
    dashboard: [
      '#1a2e47', // 0: widget background
      '#1a2e47', // 1: widget border
      '#fff', // 2: text
      '#8ed753', // 3: green text
      '#d75353', // 4: red text
      '#ffffffeb', // 5: muted text
      '#fff', // 6: table text
      '#000000', // 7: dashboard background
    ],
  },
};
