const streamActions = {
  FETCH_STREAMS: 'FETCH_STREAMS',
  FETCH_STREAMS_SUCCESS: 'FETCH_STREAMS_SUCCESS',
  FETCH_STREAMS_FAILURE: 'FETCH_STREAMS_FAILURE',
  SELECT_STREAM: 'SELECT_STREAM',
  ADD_STREAM: 'ADD_STREAM',
  ADD_STREAM_SUCCESS: 'ADD_STREAM_SUCCESS',
  EDIT_STREAM: 'EDIT_STREAM',
  EDIT_STREAM_SUCCESS: 'EDIT_STREAM_SUCCESS',
  PATCH_STREAM: 'PATCH_STREAM',
  GET_STREAM: 'GET_STREAM',
  DELETE__STREAM: 'DELETE__STREAM',
  CHANGE_STREAM: 'CHANGE_STREAM',
  CHANGE_STREAM_VIEW: 'CHANGE_STREAM_VIEW',
  CLEAR_NEW_STREAM: 'CLEAR_NEW_STREAM',
  CLEAR_NEW_STREAM_SUCCESS: 'CLEAR_NEW_STREAM_SUCCESS',
  CLEAR_STREAMS: 'CLEAR_STREAMS',
  SORT_STREAMS: 'SORT_STREAMS',
  sortStreams: (e) => ({
    type: streamActions.SORT_STREAMS,
    sort: e.sort,
  }),
  clearNewStream: () => ({
    type: streamActions.CLEAR_NEW_STREAM,
  }),
  clearStreams: (options) => ({
    type: streamActions.CLEAR_STREAMS,
  }),
  fetchStreams: (filters) => ({
    type: streamActions.FETCH_STREAMS,
    filters,
  }),
  selectStream: (selectedItem) => ({
    type: streamActions.SELECT_STREAM,
    selectedItem,
  }),
  addStream: (data, onFinish) => ({
    type: streamActions.ADD_STREAM,
    data,
    onFinish,
  }),
  editStream: (data, id, onFinish) => ({
    type: streamActions.EDIT_STREAM,
    data,
    id,
    onFinish,
  }),
  patchStream: (data) => ({
    type: streamActions.PATCH_STREAM,
    data,
  }),
  getStream: (data) => ({
    type: streamActions.GET_STREAM,
    data,
  }),
  deleteStream: (id) => ({
    type: streamActions.DELETE__STREAM,
    id,
  }),
  changeStreamView: (selectedItem) => ({
    type: streamActions.CHANGE_STREAM_VIEW,
    selectedItem,
  }),
};
export default streamActions;
