/** @prettier */
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';

export const VBAccessTitleStyle = styled.span`
  margin-left: 10px;
  top: 1px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FaInfoCircleStyle = styled(FaInfoCircle)`
  margin-left: 4px;
  opacity: 0.5;
`;

export const RegularUl = styled.ul`
  padding-left: 25px;
  list-style-type: disc;
  padding-top: 5px;
`;

export const RadioGroup = styled.div`
  margin-top: 10px;
  input {
    margin-right: 5px;
    top: 2px;
    position: relative;
  }
  label {
    margin-right: 15px;
  }
`;

export const ValueLabel = styled.div`
  margin-top: 13px;
  margin-bottom: -1px;
  color: #5e5e5e;
  font-weight: 500;
`;
// TODO: refactor in shared component file
export const NameDisplay = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ttlViewStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  right: '20px',
  top: '17px',
};
export const clickable = { cursor: 'pointer' };

export const Description = styled.div`
  font-size: 13px;
  color: #677791;
  font-weight: 400;
`;

export const TextClick = styled.div`
  color: #1890ff;
  font-size: 12px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledInput = styled.input`
  border: 1px solid #860f0f00;
  box-shadow: none;
  background-color: #860f0f00;
  color: black;
  background-color: #f0f1f4;
  height: 30px;
  border-radius: 4px;
  &:focus-visible {
    border: 1px solid grey;
    outline: 0 !important;
  }
`;

export const Button = styled.button`
  border: none;
  height: 30px;
  border-radius: 4px;
  background-color: #0079c0;
  color: #fff;
  margin-left: -10px;
  &:disabled {
    border: none;
    background-color: #cccccc;
    color: #fff;
  }
`;

export const LockedInfo = styled.div`
  margin-top: 20px;
  color: #b3afaf;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CategorizationJobActionsTooltip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .anticon-info-circle {
    margin-right: 20px;
  }
`;
