import _ from 'lodash';

const customSingulars = {
  categories: 'category',
};

const getSingular = (entityType) => (entityType in customSingulars ? customSingulars[entityType] : entityType.slice(0, -1));

const getNameVariations = (entityType) => ({
  singular: getSingular(entityType),
  upper: entityType.toUpperCase(),
  upperSingular: getSingular(entityType).toUpperCase(),
  start: _.upperFirst(entityType),
  startSingular: _.upperFirst(getSingular(entityType)),
});

const getActionTypes = (entityType) => {
  const { upper, upperSingular } = getNameVariations(entityType);
  return {
    [`FETCH_${upper}`]: `FETCH_${upper}`,
    [`FETCH_${upper}_SUCCESS`]: `FETCH_${upper}_SUCCESS`,
    [`FETCH_${upper}_FAILURE`]: `FETCH_${upper}_FAILURE`,
    [`FILTER_${upper}`]: `FILTER_${upper}`,
    [`FILTER_${upper}_SUCCESS`]: `FILTER_${upper}_SUCCESS`,
    [`CLEAR_${upper}`]: `CLEAR_${upper}`,
    [`SORT_${upper}`]: `SORT_${upper}`,

    [`SELECT_${upperSingular}`]: `SELECT_${upperSingular}`,
    [`FETCH_${upperSingular}`]: `FETCH_${upperSingular}`,
    [`FETCH_${upperSingular}_SUCCESS`]: `FETCH_${upperSingular}_SUCCESS`,
    [`FETCH_${upperSingular}_FAILURE`]: `FETCH_${upperSingular}_FAILURE`,
    [`ADD_${upperSingular}`]: `ADD_${upperSingular}`,
    [`ADD_${upperSingular}_SUCCESS`]: `ADD_${upperSingular}_SUCCESS`,
    [`EDIT_${upperSingular}`]: `EDIT_${upperSingular}`,
    [`EDIT_${upperSingular}_SUCCESS`]: `EDIT_${upperSingular}_SUCCESS`,
    [`PATCH_${upperSingular}`]: `PATCH_${upperSingular}`,
    [`GET_${upperSingular}`]: `GET_${upperSingular}`,
    [`DELETE_${upperSingular}`]: `DELETE_${upperSingular}`,
    [`CHANGE_${upperSingular}`]: `CHANGE_${upperSingular}`,
    [`CHANGE_${upperSingular}_VIEW`]: `CHANGE_${upperSingular}_VIEW`,
  };
};

const getActions = (entityType) => {
  const {
    upper, upperSingular, start, startSingular,
  } = getNameVariations(entityType);
  const actionTypes = getActionTypes(entityType);

  return {
    ...actionTypes,
    [`fetch${start}`]: (options) => ({
      type: actionTypes[`FETCH_${upper}`],
      options,
    }),
    [`filter${start}`]: (filters) => ({
      type: actionTypes[`FILTER_${upper}`],
      filters,
    }),
    [`sort${start}`]: (e) => ({
      type: actionTypes[`SORT_${upper}`],
      sort: e.sort,
    }),
    [`clear${start}`]: () => ({
      type: actionTypes[`CLEAR_${upper}`],
    }),

    [`fetch${startSingular}`]: (id) => ({
      type: actionTypes[`FETCH_${upperSingular}`],
      id,
    }),
    [`clear${startSingular}Selected`]: () => ({
      type: actionTypes[`CLEAR_${upperSingular}_SELECTED`],
    }),
    [`select${startSingular}`]: (selectedItem) => ({
      type: actionTypes[`SELECT_${upperSingular}`],
      selectedItem,
    }),
    [`add${startSingular}`]: (data, history, onFinish) => ({
      type: actionTypes[`ADD_${upperSingular}`],
      data,
      history,
      onFinish,
    }),
    [`edit${startSingular}`]: (data, id, history, onFinish) => ({
      type: actionTypes[`EDIT_${upperSingular}`],
      data,
      id,
      history,
      onFinish,
    }),
    [`delete${startSingular}`]: (id) => ({
      type: actionTypes[`DELETE_${upperSingular}`],
      id,
    }),
    [`change${startSingular}View`]: (selectedItem) => ({
      type: actionTypes[`CHANGE_${upperSingular}_VIEW`],
      selectedItem,
    }),
  };
};

export default {
  getActionTypes,
  getActions,
};
