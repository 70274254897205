import React from 'react';
import {
  useHistory, Route, Redirect, Switch, BrowserRouter as Router,
} from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import LoginPage from 'components/Page/LoginPage';
import { OktaConfig } from 'settings';
import { OktaAuth } from '@okta/okta-auth-js';
import asyncComponent from './utils/AsyncFunc';
import Main from './components/Main/Main';

/* istanbul ignore next */
const notFound = () => import('./components/Page/404');
/* istanbul ignore next */
const internalServerError = () => import('./components/Page/500');
/* istanbul ignore next */
const AccountHelp = () => import('./components/Page/AccountHelp');
/* istanbul ignore next */
const LogoutPage = () => import('./components/Page/LogoutPage');
/* istanbul ignore next */
const ActivatePage = () => import('./components/Page/ActivatePage');

const SecureRoutes = () => {
  const history = useHistory();
  const authClient = new OktaAuth(OktaConfig);
  const onAuthRequired = () => { history.push('/login'); };
  return (
    <Security
      restoreOriginalUri={(_oktaAuth, originalUri) => {
        /* istanbul ignore next */
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
      }}
      oktaAuth={authClient}
      onAuthRequired={onAuthRequired}
    >
      <Switch>
        <Route exact path="/404" component={asyncComponent(notFound)} />
        <Route exact path="/500" component={asyncComponent(internalServerError)} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/help" component={asyncComponent(AccountHelp)} />
        <Route path="/implicit/callback" component={LoginCallback} />
        <Route path="/logout" component={asyncComponent(LogoutPage)} />
        <Route path="/activate" component={asyncComponent(ActivatePage)} />
        <Route exact path="/">
          <Redirect to="/app" />
        </Route>
        <SecureRoute path="/app" component={Main} />
      </Switch>
    </Security>
  );
};

const PublicRoutes = () => (
  <Router>
    <SecureRoutes />
  </Router>
);

export default PublicRoutes;
