import fields from './fields';

const interactionModel = [
  fields.accountid,
  {
    field: 'mediaId',
    primaryKey: true,
    source: 'server',
  },
  {
    field: 'firstName',
    required: true,
  },
  {
    field: 'lastName',
    required: true,
    defaultSort: true,
  },
  {
    field: 'status',
    type: 'enum',
    options: [
      'FINISHED',
      'PROCESSING',
    ],
    default: 'ACTIVE',
    required: true,
  },
];

export default interactionModel;
