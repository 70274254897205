export default {
  name: 'untitled search',
  condition: 'and',
  level: 0,
  id: 'unsaved',
  createdDate: '2021-11-17T10:57:47.064+0000',
  updatedDate: '2021-11-17T10:57:47.065+0000',
  filters: [
    {
      name: 'Call Start Date',
      id: 'callStartTime',
      type: 'dateRange',
      ranges: ['2021-10-10T00:00:00.000Z', '2021-11-01T00:00:00.000Z'],
    },
    {
      name: 'Created Date',
      id: 'dateCreated',
      type: 'dateRange',
      ranges: ['2021-10-01T00:00:00.000Z', '2021-11-01T00:00:00.000Z'],
    },
    {
      name: 'Call Length',
      type: 'range',
      disabled: true,
      id: 'callLength',
      ranges: ['0', '30'],
    },
    {
      name: 'Interaction Type',
      id: 'interactionType',
      type: 'select',
    },
    {
      name: 'language',
      id: 'language',
      type: 'select',
      value: 'U.S. English',
    },
    {
      name: 'silence',
      id: 'silence',
      type: 'select',
      value: '20',
      comparison: 'Greater than',
    },
    {
      name: 'Agent Sentiment',
      id: 'agentSentiment',
      type: 'select',
      value: 'Neutral',
    },
    {
      name: 'Caller Sentiment',
      id: 'callerSentiment',
      type: 'select',
      value: 'Neutral',
    },
    {
      name: 'Limit',
      id: 'limit',
      type: 'number',
      value: 10,
    },
  ],
  rules: [
    // {
    //   id: uuid(),
    //   operator: 'says',
    //   speaker: 'anyone',
    //   language: 'english',
    //   with: [
    //     {
    //       id: uuid(),
    //       words: [
    //         {
    //           value: 'card',
    //           substitutions: null,
    //           permutations: null,
    //         },
    //         {
    //           value: 'e-mail',
    //           substitutions: null,
    //           permutations: null,
    //         },
    //         {
    //           value: 'credit',
    //           substitutions: null,
    //           permutations: null,
    //         },
    //       ],
    //     },
    //     {
    //       id: uuid(),
    //       substitutions: null,
    //       permutations: null,
    //       words: [
    //         {
    //           value: 'hotel',
    //           substitutions: null,
    //           permutations: null,
    //         },
    //         {
    //           value: 'restaurant',
    //           substitutions: null,
    //           permutations: null,
    //         },
    //         {
    //           value: 'talk',
    //           substitutions: null,
    //           permutations: null,
    //         },
    //       ],
    //     },
    //   ],
    //   timeWithIn: {
    //     type: 'last',
    //     value: 30,
    //     unit: 'percent',
    //   },
    // },
    // {
    //   condition: 'and',
    //   level: 1,
    //   id: uuid(),
    //   rules: [
    //     {
    //       id: uuid(),
    //       operator: 'said',
    //       value: 'agent',
    //       with: [
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['email', 'e-mail', 'call'],
    //         },
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['phone', 'survey'],
    //         },
    //       ],
    //       timeWithIn: {
    //         type: 'last',
    //         value: 30,
    //         unit: 'percent',
    //       },
    //     },
    //     {
    //       id: uuid(),
    //       operator: 'said',
    //       value: 'customer',
    //       with: [
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['email', 'e-mail', 'call'],
    //         },
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['phone', 'survey'],
    //         },
    //       ],
    //       timeWithIn: null,
    //     },
    //     {
    //       condition: 'and',
    //       level: 2,
    //       id: uuid(),
    //       rules: [
    //         {
    //           id: uuid(),
    //           operator: 'said',
    //           value: 'agent deep',
    //           with: [
    //             {
    //               id: uuid(),
    //               substitutions: null,
    //               permutations: null,
    //               words: ['email', 'e-mail', 'call'],
    //             },
    //             {
    //               id: uuid(),
    //               substitutions: null,
    //               permutations: null,
    //               words: ['phone', 'survey'],
    //             },
    //           ],
    //           timeWithIn: {
    //             type: 'last',
    //             value: 30,
    //             unit: 'percent',
    //           },
    //         },
    //         {
    //           condition: 'and',
    //           level: 3,
    //           id: 'last',
    //           rules: [
    //             {
    //               operator: 'said',
    //               value: 'agent L 4',
    //               with: [
    //                 {
    //                   id: uuid(),
    //                   substitutions: null,
    //                   permutations: null,
    //                   words: ['email', 'e-mail', 'call'],
    //                 },
    //                 {
    //                   id: uuid(),
    //                   substitutions: null,
    //                   permutations: null,
    //                   words: ['phone', 'survey'],
    //                 },
    //               ],
    //               time: null,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   condition: 'and',
    //   level: 1,
    //   id: uuid(),
    //   rules: [
    //     {
    //       id: uuid(),
    //       operator: 'said in same speaker turn',
    //       value: 'customer',
    //       with: [
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['email', 'e-mail', 'call'],
    //         },
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['phone', 'survey'],
    //         },
    //       ],
    //       timeWithIn: {
    //         type: 'first',
    //         value: 10,
    //         unit: 'percent',
    //       },
    //     },
    //   ],
    // },
    // {
    //   condition: 'and',
    //   level: 1,
    //   id: uuid(),
    //   rules: [
    //     {
    //       id: uuid(),
    //       operator: 'said',
    //       value: 'agent',
    //       with: [
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['email', 'e-mail', 'call'],
    //         },
    //         {
    //           id: uuid(),
    //           substitutions: null,
    //           permutations: null,
    //           words: ['phone', 'survey'],
    //         },
    //       ],
    //       timeWithIn: null,
    //     },
    //   ],
    // },
  ],
};
