import { getBaseReducer } from 'utils';
import actions from './actions';

const initState = {
  ...(getBaseReducer()), // model
  entityType: 'preferences',
  context: '',
  data: {
    categories: {},
  },
};

export default function preferenceReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_PREFERENCES:
      return {
        ...state,
        data: {},
        context: '',
      };
    case actions.FETCH_PREFERENCES_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case actions.SELECT_PREFERENCE:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_PREFERENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.context]: action.data,
        },
        context: action.context,
        view: false,
      };
    case actions.EDIT_PREFERENCE_SUCCESS:
      return {
        ...state,
        context: action.context,
        data: {
          ...state.data,
          [action.context]: action.data[action.context],
        },
        view: false,
      };
    case actions.DELETE_PREFERENCE:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_PREFERENCE_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    default:
      return state;
  }
}
