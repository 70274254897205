const schemaActions = {
  FETCH_SCHEMA: 'FETCH_SCHEMA',
  FETCH_SCHEMA_SUCCESS: 'FETCH_SCHEMA_SUCCESS',
  FETCH_SCHEMA_FAILURE: 'FETCH_SCHEMA_FAILURE',
  SELECT_SCHEMA: 'SELECT_SCHEMA',
  SELECT_SCHEMA_SUCCESS: 'SELECT_SCHEMA_SUCCESS',
  SELECT_SCHEMA_FAILURE: 'SELECT_SCHEMA_FAILURE',
  CLEAR_SELECTED_SCHEMA: 'CLEAR_SELECTED_SCHEMA',
  ADD_SCHEMA: 'ADD_SCHEMA',
  ADD_SCHEMA_SUCCESS: 'ADD_SCHEMA_SUCCESS',
  EDIT_SCHEMA: 'EDIT_SCHEMA',
  EDIT_SCHEMA_SUCCESS: 'EDIT_SCHEMA_SUCCESS',
  GET_SCHEMA: 'GET_SCHEMA',
  DELETE__SCHEMA: 'DELETE__SCHEMA',
  CHANGE_SCHEMA: 'CHANGE_SCHEMA',
  CHANGE_SCHEMA_VIEW: 'CHANGE_SCHEMA_VIEW',
  CLEAR_SCHEMA: 'CLEAR_SCHEMA',
  SORT_SCHEMA: 'SORT_SCHEMA',
  sortSchema: (e) => ({
    type: schemaActions.SORT_SCHEMA,
    sort: e.sort,
  }),
  clearSchema: (options) => ({
    type: schemaActions.CLEAR_SCHEMA,
  }),
  fetchSchema: () => ({
    type: schemaActions.FETCH_SCHEMA,
  }),
  selectSchema: (selectedItem) => ({
    type: schemaActions.SELECT_SCHEMA,
    selectedItem,
  }),
  clearSelectedSchema: () => ({
    type: schemaActions.CLEAR_SELECTED_SCHEMA,
  }),
  addSchema: (data) => ({
    type: schemaActions.ADD_SCHEMA,
    data,
  }),
  editSchema: (data) => ({
    type: schemaActions.EDIT_SCHEMA,
    data,
  }),
  getSchema: (data) => ({
    type: schemaActions.GET_SCHEMA,
    data,
  }),
  deleteSchema: (id) => ({
    type: schemaActions.DELETE__SCHEMA,
    id,
  }),
  changeSchemaView: (selectedItem) => ({
    type: schemaActions.CHANGE_SCHEMA_VIEW,
    selectedItem,
  }),
};
export default schemaActions;
