import _ from 'lodash';
import { nestChildren } from 'utils';

const exclude = [
  'Aiport',
  '$69',
  'Standard Metrics',
  'Request',
];

const expandHierarchy = (result, x, counts) => {
  const hierarchy = x.split('_');
  let currentId = '';
  let currentName = '';
  let parentId = '';
  hierarchy.forEach((h, i) => {
    parentId = currentId;
    currentId = i === 0 ? h.toLowerCase() : `${currentId}_${h.toLowerCase()}`;
    currentName = i === 0 ? h : `${currentName} / ${h}`;
    result.push({
      id: currentId,
      parent: parentId,
      hasContent: i === hierarchy.length - 1,
      level: i,
      name: currentName,
      text: _.startCase(h),
      count: _.get(counts, currentId, 0),
      expanded: true,
    });
  });
  return result;
};

export const transformCollections = (collections, counts = {}) => {
  const filtered = collections.filter((x) => exclude.indexOf(x.label) < 0);
  const expanded = filtered.reduce((acc, curr) => expandHierarchy(acc, curr, counts), []);
  const cleaned = _(expanded).uniqBy('id').orderBy('id').value();
  return nestChildren(cleaned, '', 'items');
};
