/* eslint-disable import/prefer-default-export */
export const UPDATE_QUERY_JSON = 'UPDATE_QUERY_JSON';
export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
export const GET_SEARCH_LIST_SUCCESS = 'GET_SEARCH_LIST_SUCCESS';
export const GET_SEARCH_LIST_FAILURE = 'GET_SEARCH_LIST_FAILURE';
export const ADD_SEARCH = 'ADD_SEARCH';
export const ADD_SEARCH_SUCCESS = 'ADD_SEARCH_SUCCESS';
export const ADD_SEARCH_FAILURE = 'ADD_SEARCH_FAILURE';
export const EDIT_SEARCH = 'EDIT_SEARCH';
export const EDIT_SEARCH_SUCCESS = 'EDIT_SEARCH_SUCCESS';
export const EDIT_SEARCH_FAILURE = 'EDIT_SEARCH_FAILURE';
export const DELETE_SEARCH = 'DELETE_SEARCH';
export const DELETE_SEARCH_SUCCESS = 'DELETE_SEARCH_SUCCESS';
export const DELETE_SEARCH_FAILURE = 'DELETE_SEARCH_FAILURE';
export const UPDATE_CALL_LENGTH = 'UPDATE_CALL_LENGTH';
export const TOGGLE_SAVE_SEARCH = 'TOGGLE_SAVE_SEARCH';
export const SEARCH_CALLS = 'SEARCH_CALLS';
export const SEARCH_CALLS_SUCCESS = 'SEARCH_CALLS_SUCCESS';
export const SEARCH_CALLS_FAILURE = 'SEARCH_CALLS_FAILURE';
export const SET_COLLAPSED_QUERIES = 'SET_COLLAPSED_QUERIES';
export const TOGGLE_LIST_TYPE = 'TOGGLE_LIST_TYPE';
export const TOGGLE_PREVIEW = 'TOGGLE_PREVIEW';
export const SET_MULTI_TEXT_FIELDS = 'SET_MULTI_TEXT_FIELDS';
