/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import Auth from './auth/reducer';
import App from './app/reducer';
import drawer from './drawer/reducer';
import Collections from './collections/reducer';
import modal from './modal/reducer';
import Profile from './profile/reducer';
import Options from './options/reducer';
import Connections from './connections/reducer';
import Mappings from './mappings/reducer';
import Users from './users/reducer';
import Streams from './streams/reducer';
import BearerTokens from './bearerTokens/reducer';
import DataSources from './dataSources/reducer';
import Interactions from './interactions/reducer';
import Accounts from './accounts/reducer';
import VocabularyLists from './vocabularyLists/reducer';
import Checklists from './checklists/reducer';
import ChecklistItems from './checklistItems/reducer';
import Communications from './communications/reducer';
import SpottingGroups from './spottingGroups/reducer';
import Categories from './categories/reducer';
import Jobs from './jobs/reducer';
import Tabs from './tabs/reducer';
import TabGroups from './tabGroups/reducer';
import Schema from './schema/reducer';
import player from './player/reducer';
import preferences from './preferences/reducer';
import usageReducer from './usage/reducer';
import statusReducer from './status/reducer';
import agentCallAvoidanceReducer from './agentCallAvoidance/reducer';
import pciFlag from './pciflag/reducer';
import vboDashboards from './vboDashboards/reducer';
import templateDashboards from './templateDashboards/reducer';
import categoryLibrary from './categoryLibrary/reducer';
import callSearch from './callSearch/reducer';
import uploadMediaReducer from './uploadMedia/reducer';
import hostedTablesReducer from './hostedTables/reducer';

export default {
  Auth,
  App,
  modal,
  Collections,
  Interactions,
  drawer,
  Profile,
  Options,
  Connections,
  Communications,
  Mappings,
  Users,
  Streams,
  BearerTokens,
  DataSources,
  Accounts,
  VocabularyLists,
  Checklists,
  ChecklistItems,
  SpottingGroups,
  Categories,
  Jobs,
  Tabs,
  TabGroups,
  Schema,
  player,
  preferences,
  usage: usageReducer,
  status: statusReducer,
  agentCallAvoidance: agentCallAvoidanceReducer,
  pciFlag,
  vboDashboards,
  templateDashboards,
  categoryLibrary,
  callSearch,
  uploadMedia: uploadMediaReducer,
  hostedTables: hostedTablesReducer,
};
