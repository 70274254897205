/* eslint-disable import/named */
import _ from 'lodash';
import uuid from 'react-uuid';
import { arrayFromString } from 'utils';

export const transformVocabularyList = (data) => {
  const terms = data.terms || [];
  return {
    ...data,
    name: data.vocabularyName,
    type: data.vocabularyType,
    count: terms.length,
    termsStr: terms.map((x) => x.term).join(', '),
    terms: terms.map((t, i) => ({
      ...t,
      soundsLikeStr: 'soundsLike' in t ? t.soundsLike.join(', ') : '',
      id: `${i}_${t.term}`,
      inEdit: true,
    })),
  };
};
export const transformVocabularyLists = (res) => {
  const data = _.get(res, 'vocabularies', []);
  return data.map((u) => transformVocabularyList(u));
};

const fieldsToRemove = ['updatedDate', 'createdDate', 'name', 'type', 'termsStr', 'dateModified', 'dateCreated', 'count', 'existingNames'];
export const prepVocabularyList = (data) => _.omit({
  ...data,
  vocabularyName: data.name,
  vocabularyType: data.type,
  terms: data.terms.map((t) => {
    const omitFields = ['soundsLikeStr', 'inEdit'];
    const soundsLike = arrayFromString(t.soundsLikeStr);
    const update = {
      ...t,
      weight: t.weight || 0,
      soundsLike,
    };
    if (!soundsLike || !soundsLike.length) omitFields.push('soundsLike');
    return _.omit(update, ['soundsLikeStr', 'inEdit', 'id']);
  }),
}, fieldsToRemove);

export const prepTermsForCsv = (t) => ({
  ...(_.omit({
    ...t,
    weight: t.weight || 0,
  }, ['soundsLikeStr', 'inEdit', 'id'])),
});

export const importTermsFromCsv = (t) => (_.omit({
  ...t,
  soundsLikeStr: t.soundslike,
  soundsLike: arrayFromString(t.soundslike),
  id: uuid(),
  inEdit: true,
}, 'soundslike')); // the import process converts column name to all smalls
