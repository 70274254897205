/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FaInfoCircleStyle } from 'screens/Account/styles';
import { Tooltip } from 'antd';

export const withLabel = (Component) => function WithLabelComponent({
  label = true,
  title = '',
  desc = '',
  required = false,
  tooltip = '',
  ...props
}) {
  return label ? (
    <label className={`k-form-field ${required ? ' required-field' : ''}`}>
      <span className="input-label">{title.toUpperCase()}</span>
      {tooltip && (
        <Tooltip placement="top" title={tooltip}>
          <FaInfoCircleStyle />
        </Tooltip>
      )}
      <span className="input-desc"> {desc}</span>
      <Component {...props} />
    </label>
  ) : (
    <Component {...props} />
  );
};
