import { getBaseReducer } from 'utils';
import { categoryModel as model } from 'models/category';
import jobActions from 'redux/jobs/actions';
import { merge } from 'lodash';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  entityType: 'categories',
  source: [],
  create: {},
  workbench: null,
  sort: [{ field: 'dateModified', dir: 'desc' }],
  jobReapply: {
    mediaFilterExpression: '',
    dateBegin: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    dateEnd: new Date(),
  },
};

export default function categoryReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_CATEGORIES:
      return {
        ...state,
        data: [],
        source: [],
        selectedItem: {},
        create: {},
        init: false,
        workbench: null,
      };
    case actions.CLEAR_CATEGORY_SELECTED:
      return {
        ...state,
        selectedItem: {},
      };
    case actions.SORT_CATEGORIES:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_CATEGORIES:
      return {
        ...state,
        init: false,
        isLoading: true,
      };
    case actions.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        source: action.data,
        data: action.data,
        isLoading: false,
        init: true,
      };
    case actions.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.FILTER_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.data,
        filters: action.filters,
        view: false,
      };
    case actions.SELECT_CATEGORY:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__CATEGORY:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_CATEGORY_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    case actions.SAVE_QUERY_AS_CATEGORY:
      return {
        ...state,
        create: action.tab || {},
      };
    case actions.CLEAR_QUERY_AS_CATEGORY:
      return {
        ...state,
        create: {},
      };
    case actions.OPEN_CATEGORY_IN_WORKBENCH:
      return {
        ...state,
        workbench: action.category || null,
      };
    case actions.CLEAR_CATEGORY_IN_WORKBENCH:
      return {
        ...state,
        workbench: null,
      };
    case actions.UPDATE_JOB_REAPPLY_DATE:
      return {
        ...state,
        jobReapply: {
          ...state.jobReapply,
          [action.dateType]: action.date,
        },
      };
    case actions.UPDATE_JOB_REAPPLY_MEDIA_FILTER_EXPRESSION:
      return {
        ...state,
        jobReapply: {
          ...state.jobReapply,
          mediaFilterExpression: action.mediaFilterExpression,
        },
      };
    case jobActions.ADD_JOB_SUCCESS:
      return {
        ...state,
        jobReapply: {
          dateBegin: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
          dateEnd: new Date(),
        },
      };

    case actions.FETCH_CATEGORY_CHART_SUCCESS:
      return {
        ...state,
        chartData: merge({ 0: action.data }, { 0: { description: action.title, label: action.title } }),
      };

    case actions.CLEAR_CATEGORY_CHART:
      return {
        ...state,
        chartData: null,
      };

    case actions.CATEGORY_CHART_ISLOADING:
      return {
        ...state,
        chartData: { ...state.chartData, isLoading: action.data },
      };

    case actions.GET_CALL_COUNT_SUCCESS:
      return {
        ...state,
        callsCount: action.data,
      };

    default:
      return state;
  }
}
