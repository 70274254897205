import playerActions from './actions';

const initialState = {
  playerVisibility: false,
  mediaId: null,
  data: {},
  mediaUrl: null,
  pciFlag: null,
  pciFlagAction: null,
  timeStampOfLastPlayed: null,
  isPlayerMinimized: false,
};

export default function playerReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case playerActions.SHOW_PLAYER:
      return {
        ...state,
        playerVisibility: true,
        mediaId: action.mediaId,
        data: {},
        mediaUrl: null,
        pciFlag: null,
        pciFlagAction: null,
        timeStampOfLastPlayed: new Date(),
        isPlayerMinimized: false,
      };
    case playerActions.HIDE_PLAYER:
      return {
        ...state,
        playerVisibility: false,
        mediaId: null,
        data: {},
        mediaUrl: null,
        pciFlag: null,
        pciFlagAction: null,
        timeStampOfLastPlayed: null,
        isPlayerMinimized: false,
      };
    case playerActions.FETCH_MEDIA_JSON_SUCCESS:
      return {
        ...state,
        mediaId: action.mediaId,
        data: action.data,
        mediaUrl: action.mediaUrl,
      };
    case playerActions.FETCH_PII_FLAG_SUCCESS:
      return {
        ...state,
        pciFlag: action.data,
      };
    case playerActions.FETCH_PII_FLAG_ACTION_SUCCESS:
      return {
        ...state,
        pciFlagAction: action.data,
      };
    case playerActions.FETCH_MEDIA_JSON_ERROR:
      return {
        ...state,
        mediaId: null,
        data: {},
        mediaUrl: null,
        error: 'Could not retrieve media data',
      };
    case playerActions.FETCH_PII_FLAG_ERROR:
      return {
        ...state,
        error: 'Could not retrieve pii flag',
      };
    case playerActions.FETCH_PII_FLAG_ACTION_ERROR:
      return {
        ...state,
        error: 'Could not retrieve pii flag action',
      };
    case playerActions.MINIMIZE_PLAYER:
      return {
        ...state,
        isPlayerMinimized: action.isPlayerMinimized,
      };
    default:
      return state;
  }
}
