const bearerTokenActions = {
  FETCH_BEARERTOKENS: 'FETCH_BEARERTOKENS',
  FETCH_BEARERTOKENS_SUCCESS: 'FETCH_BEARERTOKENS_SUCCESS',
  FETCH_BEARERTOKENS_FAILURE: 'FETCH_BEARERTOKENS_FAILURE',
  SELECT_BEARERTOKEN: 'SELECT_BEARERTOKEN',
  ADD_BEARERTOKEN: 'ADD_BEARERTOKEN',
  ADD_BEARERTOKEN_SUCCESS: 'ADD_BEARERTOKEN_SUCCESS',
  ADD_BEARERTOKEN_FAILURE: 'ADD_BEARERTOKEN_FAILURE',
  GET_BEARERTOKEN: 'GET_BEARERTOKEN',
  DELETE__BEARERTOKEN: 'DELETE__BEARERTOKEN',
  CHANGE_BEARERTOKEN: 'CHANGE_BEARERTOKEN',
  CHANGE_BEARERTOKEN_VIEW: 'CHANGE_BEARERTOKEN_VIEW',
  CLEAR_NEW_BEARERTOKEN: 'CLEAR_NEW_BEARERTOKEN',
  CLEAR_NEW_BEARERTOKEN_SUCCESS: 'CLEAR_NEW_BEARERTOKEN_SUCCESS',
  CLEAR_BEARERTOKENS: 'CLEAR_BEARERTOKENS',
  SORT_BEARERTOKENS: 'SORT_BEARERTOKENS',
  ADD_BEARER_TOKEN_DESCRIPTION: 'ADD_BEARER_TOKEN_DESCRIPTION',
  BEARER_TOKEN_LOADING_STATE: 'BEARER_TOKEN_LOADING_STATE',
  ADD_BEARER_TOKEN_STEP: 'ADD_BEARER_TOKEN_STEP',
  SET_FORM_VALUE: 'SET_FORM_VALUE',

  sortBearerTokens: (e) => ({
    type: bearerTokenActions.SORT_BEARERTOKENS,
    sort: e.sort,
  }),
  clearNewBearerToken: () => ({
    type: bearerTokenActions.CLEAR_NEW_BEARERTOKEN,
  }),
  clearBearerTokens: (options) => ({
    type: bearerTokenActions.CLEAR_BEARERTOKENS,
  }),
  fetchBearerTokens: (filters) => ({
    type: bearerTokenActions.FETCH_BEARERTOKENS,
    filters,
  }),
  selectBearerToken: (selectedItem) => ({
    type: bearerTokenActions.SELECT_BEARERTOKEN,
    selectedItem,
  }),
  addBearerToken: (tokenType,data,cb) => ({
    type: bearerTokenActions.ADD_BEARERTOKEN,
    tokenType,
    data,
    cb
  }),
  getBearerToken: (data) => ({
    type: bearerTokenActions.GET_BEARERTOKEN,
    data,
  }),
  deleteBearerToken: (id) => ({
    type: bearerTokenActions.DELETE__BEARERTOKEN,
    id,
  }),
  changeBearerTokenView: (selectedItem) => ({
    type: bearerTokenActions.CHANGE_BEARERTOKEN_VIEW,
    selectedItem,
  }),
  addBearerTokenDescription: (id, description, cb) => ({
    type: bearerTokenActions.ADD_BEARER_TOKEN_DESCRIPTION,
    id, description, cb
  }),
  changeBearerTokenStep: (step) => ({
    type: bearerTokenActions.ADD_BEARER_TOKEN_STEP,
    step
  }),
  setFormData: (value) => (
    {
    type: bearerTokenActions.SET_FORM_VALUE,
    value
  })
};
export default bearerTokenActions;
