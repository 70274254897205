/** @prettier */
import { all, takeEvery, fork, call, put, select } from 'redux-saga/effects';
import _, { cloneDeep } from 'lodash';
import { isTableauOnlyRole } from 'utils/roleHelpers';
import {
  transformCategory,
  transformCategories,
  prepCategory,
  transformBulkCategory,
  prepCategories,
  filterCategories,
} from 'transform/category';
import { transformCollections } from 'transform/collection';
import { transformJobs } from 'transform/job';
import { getUniqFromData } from 'utils';
import api from 'services';
import platformApi from 'services/platform';
import { getAuthenticatedUser } from 'redux/users/saga';
import userActions from 'redux/users/actions';
import { routesConstants } from '../../constants';
import collectionsActions from '../collections/actions';
import optionsActions from '../options/actions';
import actions from './actions';

const entityType = 'categories';

export function* filterCategoriesSaga() {
  yield takeEvery(actions.FILTER_CATEGORIES, function* ({ filters }) {
    const state = yield select();
    const source = _.get(state, 'Categories.source', []);
    const filtersExisting = _.get(state, 'Categories.filters', {});
    const cleaned = _.omitBy({ ...filtersExisting, ...filters }, _.isNil);
    yield put({ type: actions.FILTER_CATEGORIES_SUCCESS, data: filterCategories(source, cleaned), filters: cleaned });
  });
}

export function* fetchCategoriesSaga() {
  yield takeEvery(
    actions.FETCH_CATEGORIES,
    function* fetchCategories({ filters, clearExistingFilters = false, retainFilters = false }) {
      const state = yield select();
      const filtersExisting = _.get(state, 'Categories.filters', {});
      let filtersCleaned = clearExistingFilters ? filters : _.omitBy({ ...filtersExisting, ...filters }, _.isNil);
      if (retainFilters) {
        filtersCleaned = filters;
      }
      let jobs = null;
      if (!isTableauOnlyRole(state)) {
        // Tableau only role does not have access to category jobs
        // alpha system category conversion:
        // const data = yield call(api.fetchAll, {entityType, transform: transformCategories, filters});
        jobs = yield call(api.fetchAll, { entityType: 'jobsBatch', transform: transformJobs }); // , filters: { jobStatus: 'PENDING' } - removing because of other required states - i.e. 'RUNNING'
      }
      const pending = !jobs
        ? null
        : jobs
            .filter((x) => x.jobStatus === 'PENDING' || x.jobStatus === 'RUNNING')
            .reduce((a, c) => {
              const categories = c.categories || [];
              return [...a, ...categories];
            }, [])
            .reduce((a, c) => ({ ...a, [c]: 'PENDING' }), {});
      const response = yield call(api.fetchAll, { entityType });
      const data = transformCategories(response, pending);
      yield put({ type: actions.FETCH_CATEGORIES_SUCCESS, data });

      const tags = getUniqFromData(data, 'tags');
      const groups = getUniqFromData(data, 'collectionAndGroups');
      const groupCounts = groups.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.toLowerCase()]: data.filter(
            (d) => d.collectionAndGroups.map((x) => x.toLowerCase()).indexOf(curr.toLowerCase()) > -1
          ).length,
        }),
        {}
      );
      yield put({
        type: optionsActions.UPDATE_OPTIONS,
        options: {
          tags,
          groups,
        },
      });
      yield put({
        type: collectionsActions.FETCH_COLLECTIONS_SUCCESS,
        data: transformCollections(groups, groupCounts),
      });
      yield put({
        type: actions.FILTER_CATEGORIES_SUCCESS,
        data: filterCategories(data, filtersCleaned),
        filters: filtersCleaned,
      });
      /*
        yield put({
          type: actions.FETCH_JOBS_SUCCESS,
          data: filterCategories(data, filtersCleaned),
          filters: filtersCleaned,
        });
    */
    }
  );
}

export function* fetchCategorySaga() {
  yield takeEvery(actions.FETCH_CATEGORY, function* ({ id }) {
    const category = yield call(api.fetchItem, { entityType, id });
    if (category) {
      yield put({ type: actions.FETCH_CATEGORY_SUCCESS, selectedItem: transformCategory(category) });
    }
  });
}

const getHistory = (state) => state.App.history;

export function* addCategorySaga() {
  yield takeEvery(actions.ADD_CATEGORY, function* ({ data, onFinish }) {
    const history = yield select(getHistory);
    const category = yield call(api.createItem, { entityType, data: prepCategory(data) });
    if (category?.id) {
      yield put({ type: actions.ADD_CATEGORY_SUCCESS, selectedItem: transformCategory(category) });
      const authenticatedUser = yield select(getAuthenticatedUser);
      const activeAccount = yield select((state) => state.App.activeAccount);
      const userClone = cloneDeep(authenticatedUser);
      userClone.roles = activeAccount.roles.filter((role) => !role.includes('Super')); // filter is applied for removing super roles,present in old user accounts due to server issue
      const parsedPreferences = JSON.parse(userClone.preferences || '{}');
      if (!(parsedPreferences.getStarted || []).includes('tagCalls')) {
        yield put(userActions.addGettingStartedTask(userClone, userClone.id, 'tagCalls'));
      }
      yield put({ type: actions.CHANGE_CATEGORY_VIEW, selectedItem: {} });
      yield put({ type: actions.CLEAR_QUERY_AS_CATEGORY });
      // @TODO: Fix history so it doesn't need to be passed in via action - Done for ADD_CATEGORY action
      if (history.location.pathname === `${routesConstants.CATEGORIES}/add`)
        yield call([history, history.push], routesConstants.CATEGORIES);
    }
    onFinish();
  });
}

export function* editCategorySaga() {
  yield takeEvery(actions.EDIT_CATEGORY, function* ({ data, id, onFinish }) {
    const history = yield select(getHistory);
    const category = yield call(api.editItem, { entityType, data: prepCategory(data), id });
    if (category?.id) {
      yield put({ type: actions.EDIT_CATEGORY_SUCCESS, selectedItem: transformCategory(category) });
      yield put({ type: actions.CLEAR_QUERY_AS_CATEGORY });
      // @TODO: Fix history so it doesn't need to be passed in via action - Done for EDIT_CATEGORY action
      if (history.location.pathname === `${routesConstants.CATEGORIES}/${id}`)
        yield call([history, history.push], routesConstants.CATEGORIES);
    }
    onFinish();
  });
}

export function* deleteBulkCategorySaga() {
  yield takeEvery(actions.BULK_DELETE_CATEGORY, function* ({ data }) {
    const output = yield call(api.post, {
      url: 'categories/bulk-delete',
      data: transformBulkCategory(data, true),
      msg: 'Successfully deleted categories',
    });
    if (output?.status === 200) {
      yield put({ type: actions.FETCH_CATEGORIES, clearExistingFilters: true });
      yield put({ type: actions.CLEAR_QUERY_AS_CATEGORY });
    }
  });
}

export function* publishBulkCategorySaga() {
  yield takeEvery(actions.BULK_PUBLISH_CATEGORY, function* ({ data, publish }) {
    const state = yield select();
    const categoryData = _.get(state, 'Categories.data', []);
    const output = yield call(platformApi.post, {
      entityType,
      urlPath: 'definition/categories/bulk-ops',
      data: transformBulkCategory(data, false, publish),
    });
    if (output?.results) {
      yield put({ type: actions.FETCH_CATEGORIES, clearExistingFilters: true });
      yield put({ type: actions.CLEAR_QUERY_AS_CATEGORY });
    }

    if (output?.status === 200) {
      const resultsData = output?.data?.results || [];
      const categoryUpdatedData = categoryData.filter((val) => {
        const resultItem = resultsData.find((result) => result?.response?.category?.categoryName === val.categoryName);
        if (resultItem && resultItem?.status === '200') {
          val.active = resultItem.response.category.active;
          val.dateCreated = resultItem.response.category.dateCreated;
          val.dateModified = resultItem.response.category.dateModified;
        }
        return val;
      });

      yield put({ type: actions.FETCH_CATEGORIES_SUCCESS, data: categoryUpdatedData });
    }
  });
}

export function* importCategoriesSaga() {
  yield takeEvery(actions.IMPORT_CATEGORIES, function* ({ data, mappings, onFinish = () => {}, fetchCategory = true }) {
    const categories = yield call(api.createArray, { entityType, data: prepCategories(data) });
    if (categories) {
      const authenticatedUser = yield select(getAuthenticatedUser);
      const activeAccount = yield select((state) => state.App.activeAccount);
      const userClone = cloneDeep(authenticatedUser);
      userClone.roles = activeAccount.roles.filter((role) => !role.includes('Super'));
      const parsedPreferences = JSON.parse(userClone.preferences || '{}');
      if (!(parsedPreferences.getStarted || []).includes('tagCalls')) {
        yield put(userActions.addGettingStartedTask(userClone, userClone.id, 'tagCalls'));
      }
      yield put({ type: actions.IMPORT_CATEGORIES_SUCCESS, selectedItem: null });
      if (fetchCategory) {
        yield put({ type: actions.FETCH_CATEGORIES, clearExistingFilters: false });
      }
    }
    onFinish();
  });
}

export function* fetchCategoryChartSaga() {
  yield takeEvery(actions.FETCH_CATEGORY_CHART, function* ({ data, title }) {
    yield put({
      type: actions.CATEGORY_CHART_ISLOADING,
      data: true,
    });
    const res = yield call(api.post, {
      url: `metrics`,
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_CATEGORY_CHART_SUCCESS,
        data: res.data,
        title,
      });
      yield put({
        type: actions.CATEGORY_CHART_ISLOADING,
        data: false,
      });
    } else {
      yield put({ type: actions.FETCH_CATEGORY_CHART_FAILURE });
    }
  });
}

export function* getCallsCountSaga() {
  yield takeEvery(actions.GET_CALL_COUNT, function* ({ data, queryParams = '' }) {
    const res = yield call(api.post, {
      url: `metrics?${queryParams}`,
      data,
      disableNotification: true,
    });
    if (res) {
      yield put({
        type: actions.GET_CALL_COUNT_SUCCESS,
        data: res.data,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchCategoriesSaga),
    fork(fetchCategorySaga),
    fork(filterCategoriesSaga),
    fork(addCategorySaga),
    fork(editCategorySaga),
    fork(importCategoriesSaga),
    fork(deleteBulkCategorySaga),
    fork(publishBulkCategorySaga),
    fork(fetchCategoryChartSaga),
  ]);
}
