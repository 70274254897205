import * as Yup from 'yup';
import moment from 'moment';

const jobModel = [
  {
    field: 'id',
    primaryKey: true,
    source: 'server',
  },
  {
    field: 'dateBegin',
    type: 'date',
    required: true,
  },
  {
    field: 'dateEnd',
    type: 'date',
    required: true,
  },
  {
    field: 'dateCreated',
    source: 'server',
    type: 'date',
    defaultSort: true,
    sortDirection: 'desc',
  },
  {
    field: 'categories',
    type: 'array',
  },
  {
    field: 'jobType',
    type: 'enum',
    options: [
      {
        label: 'Sync warehouse',
        value: 'DATA_STREAM',
      },
      // {
      //   label: 'Sync configurations',
      //   value: 'SYNC_ONTOLOGIES',
      // },
      {
        label: 'Re-index',
        value: 'INDEX',
      },
      {
        label: 'Run Resolution Model',
        value: 'INDEX, CLASSIFY, DATA_STREAM',
      },
      // {
      //   label: 'Re-index and Run Resolution Model',
      //   value: 'INDEX, CLASSIFY',
      // },
    ],
    defaultValue: 'DATA_STREAM',
    required: true,
  },

];

const jobSchema = Yup.object().shape({
  dateBegin: Yup.date()
    .required('Required')
    .typeError('Must be a valid date'),
  dateEnd: Yup.date()
    .required('Required')
    .typeError('Must be a valid date')
    .when('dateBegin',
      (dateBegin, yup) => dateBegin && yup.min(moment(dateBegin).format('YYYY-MM-DD 00:00:00'), 'End date cannot be before start date')),
});

export { jobModel, jobSchema };
