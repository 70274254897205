/* eslint-disable linebreak-style */
const templateDashboardActions = {
  FETCH_TEMPLATE_DASHBOARDS: 'FETCH_TEMPLATE_DASHBOARDS',
  FETCH_TEMPLATE_DASHBOARDS_SUCCESS: 'FETCH_TEMPLATE_DASHBOARDS_SUCCESS',
  FETCH_TEMPLATE_DASHBOARDS_FAILURE: 'FETCH_TEMPLATE_DASHBOARDS_FAILURE',
  FETCH_TEMPLATE_DASHBOARD_DATA: 'FETCH_TEMPLATE_DASHBOARD_DATA',
  FETCH_TEMPLATE_DASHBOARD_DATA_SUCCESS:
    'FETCH_TEMPLATE_DASHBOARD_DATA_SUCCESS',
  FETCH_TEMPLATE_DASHBOARD_DATA_FAILURE:
    'FETCH_TEMPLATE_DASHBOARD_DATA_FAILURE',
  FETCH_TEMPLATE_DASHBOARD_CHARTS: 'FETCH_TEMPLATE_DASHBOARD_CHARTS',
  FETCH_TEMPLATE_DASHBOARD_CHARTS_SUCCESS:
    'FETCH_TEMPLATE_DASHBOARD_CHARTS_SUCCESS',
  FETCH_TEMPLATE_DASHBOARD_CHARTS_FAILURE:
    'FETCH_TEMPLATE_DASHBOARD_CHARTS_FAILURE',
  FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA: 'FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA',
  FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA_SUCCESS:
    'FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA_SUCCESS',
  FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA_FAILURE:
    'FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA_FAILURE',
  UPDATE_TEMPLATE_DASHBOARD: 'UPDATE_TEMPLATE_DASHBOARD',
  DELETE_TEMPLATE_DASHBOARD: 'DELETE_TEMPLATE_DASHBOARD',
  DUPLICATE_TEMPLATE_DASHBOARD: 'DUPLICATE_TEMPLATE_DASHBOARD',
  UPLOAD_TEMPLATE: 'UPLOAD_TEMPLATE',
  IMPORT_TEMPLATE_DASHBOARD: 'IMPORT_TEMPLATE_DASHBOARD',
  IMPORT_TEMPLATE_DASHBOARD_SUCCESS: 'IMPORT_TEMPLATE_DASHBOARD_SUCCESS',
  IMPORT_TEMPLATE_DASHBOARD_FALIURE: 'IMPORT_TEMPLATE_DASHBOARD_FALIURE',
  EXPORT_DASHBOARD_TEMPLATE: 'EXPORT_DASHBOARD_TEMPLATE',
  EXPORT_DASHBOARD_TEMPLATE_SUCCESS: 'EXPORT_DASHBOARD_TEMPLATE_SUCCESS',
  EXPORT_DASHBOARD_TEMPLATE_FALIURE: 'EXPORT_DASHBOARD_TEMPLATE_FALIURE',
  TOGGLE_VIEW_TEMPLATE_MODAL: 'TOGGLE_VIEW_TEMPLATE_MODAL',
  TEMPLATE_DASHBOARD_LOADING: 'TEMPLATE_DASHBOARD_LOADING',
  UPLOAD_TEMPLATE_FAILURE: 'UPLOAD_TEMPLATE_FAILURE',

  fetchTemplateDashboards: () => ({
    type: templateDashboardActions.FETCH_TEMPLATE_DASHBOARDS,
  }),

  fetchTemplateDashboardData: dashboardId => ({
    type: templateDashboardActions.FETCH_TEMPLATE_DASHBOARD_DATA,
    dashboardId,
  }),
  fetchTemplateDashboardCharts: dashboardId => ({
    type: templateDashboardActions.FETCH_TEMPLATE_DASHBOARD_CHARTS,
    dashboardId,
  }),
  fetchTemplateDashboardChartsData: chartId => ({
    type: templateDashboardActions.FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA,
    chartId,
  }),
  updateTemplateDashboard: (dashboardId, data) => ({
    type: templateDashboardActions.UPDATE_TEMPLATE_DASHBOARD,
    dashboardId,
    data,
  }),
  deleteTemplateDashboard: dashboardId => ({
    type: templateDashboardActions.DELETE_TEMPLATE_DASHBOARD,
    dashboardId,
  }),
  duplicateTemplateDashboard: dashboardId => ({
    type: templateDashboardActions.DUPLICATE_TEMPLATE_DASHBOARD,
    dashboardId,
  }),
  uploadTemplateDetails: data => ({
    type: templateDashboardActions.UPLOAD_TEMPLATE,
    data,
  }),
  exportTemplateDashboard: (dashboardId, history) => ({
    type: templateDashboardActions.EXPORT_DASHBOARD_TEMPLATE,
    dashboardId,
    history,
  }),
  importTemplateDashboard: data => ({
    type: templateDashboardActions.IMPORT_TEMPLATE_DASHBOARD,
    data,
  }),
  toggleViewTemplateModal: (data, template) => ({
    type: templateDashboardActions.TOGGLE_VIEW_TEMPLATE_MODAL,
    data,
    template,
  }),
  templateDashboardLoading: data => ({
    type: templateDashboardActions.TEMPLATE_DASHBOARD_LOADING,
    data,
  }),
};
export default templateDashboardActions;
