const vocabularyListActions = {
  FETCH_VOCABULARYLISTS: 'FETCH_VOCABULARYLISTS',
  FETCH_VOCABULARYLISTS_SUCCESS: 'FETCH_VOCABULARYLISTS_SUCCESS',
  FETCH_VOCABULARYLISTS_FAILURE: 'FETCH_VOCABULARYLISTS_FAILURE',
  SELECT_VOCABULARYLIST: 'SELECT_VOCABULARYLIST',
  ADD_VOCABULARYLIST: 'ADD_VOCABULARYLIST',
  ADD_VOCABULARYLIST_SUCCESS: 'ADD_VOCABULARYLIST_SUCCESS',
  EDIT_VOCABULARYLIST: 'EDIT_VOCABULARYLIST',
  EDIT_VOCABULARYLIST_SUCCESS: 'EDIT_VOCABULARYLIST_SUCCESS',
  PATCH_VOCABULARYLIST: 'PATCH_WAREHOUSE',
  GET_VOCABULARYLIST: 'GET_VOCABULARYLIST',
  DELETE__VOCABULARYLIST: 'DELETE__VOCABULARYLIST',
  CHANGE_VOCABULARYLIST: 'CHANGE_VOCABULARYLIST',
  CHANGE_VOCABULARYLIST_VIEW: 'CHANGE_VOCABULARYLIST_VIEW',
  CLEAR_VOCABULARYLISTS: 'CLEAR_VOCABULARYLISTS',
  SORT_VOCABULARYLISTS: 'SORT_VOCABULARYLISTS',
  sortVocabularyLists: (e) => ({
    type: vocabularyListActions.SORT_VOCABULARYLISTS,
    sort: e.sort,
  }),
  clearVocabularyLists: () => ({
    type: vocabularyListActions.CLEAR_VOCABULARYLISTS,
  }),
  fetchVocabularyLists: () => ({
    type: vocabularyListActions.FETCH_VOCABULARYLISTS,
  }),
  selectVocabularyList: (selectedItem) => ({
    type: vocabularyListActions.SELECT_VOCABULARYLIST,
    selectedItem,
  }),
  addVocabularyList: (data, onFinish) => ({
    type: vocabularyListActions.ADD_VOCABULARYLIST,
    data,
    onFinish,
  }),
  editVocabularyList: (data, id, onFinish) => ({
    type: vocabularyListActions.EDIT_VOCABULARYLIST,
    data,
    id,
    onFinish,
  }),
  patchVocabularyList: (data) => ({
    type: vocabularyListActions.PATCH_VOCABULARYLIST,
    data,
  }),
  getVocabularyList: (data) => ({
    type: vocabularyListActions.GET_VOCABULARYLIST,
    data,
  }),
  deleteVocabularyList: (id) => ({
    type: vocabularyListActions.DELETE__VOCABULARYLIST,
    id,
  }),
  changeVocabularyListView: (selectedItem) => ({
    type: vocabularyListActions.CHANGE_VOCABULARYLIST_VIEW,
    selectedItem,
  }),
};
export default vocabularyListActions;
