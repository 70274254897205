/* istanbul ignore file */
import React from 'react';
import { Header, HeaderTitle } from './StyledComponents';

const tours = () => ({
  homeFirst: [
    {
      title: (
        <Header>
            <HeaderTitle>Welcome to LivePerson Analytics Studio</HeaderTitle>
        </Header>
      ),
      content: (<div>
        <p>We’re excited to help you unlock the value of voice data, and to start seeing the actionable insights you’ve been waiting for!</p>
        <br />
        <p>Take this tour to learn how to use this portal to manage your account and your data.</p>
      </div>),
      placement: 'center',
      target: 'body',
      locale: { next: 'Take the tour!' },
      styles: {
        options: { width: 600 },
      },
    },
  ],
  home: [
    /*
    {
      target: '.button-home-connect-data',
      title: "Connect your data",
      content: 'Create a bearer token to begin sending calls. Your developer will need this to set up the API integration.',
      placement: 'bottom',
    },
*/
    {
      target: '.menu-sidebar-accounts',
      title: 'Manage Account',
      content: 'Review account settings, billing and technical contacts, and choose to have Analytics Studio help set-up and manage your account by enabling access here.',
      placement: 'right',
    },
    {
      target: '.analyst-tools',
      title: 'Manage Categories',
      content: 'Review all the categories saved in your account, this helps you manage which categories are drafts or published.',
      placement: 'right',
    },
    {
      target: '.analyst-tools',
      title: 'Analyst Workbench',
      content: 'Work with your data live! Here you can do ad-hoc search and build new categories for expanded use cases and new questions your team will ask!',
      placement: 'right',
    },
    {
      target: '.menu-sidebar-documentation',
      title: 'Documentation',
      content: 'For any and all questions, navigate here to find documentation, best practices and how-to guides for the API, VBQL and using our data in your favorite BI tool.',
      placement: 'top',
      locale: { last: 'Finished!' },
    },
  ],
  category: [
    {
      target: '.tour-category-add',
      content: 'Click here to add a category',
    },
    {
      target: '.tour-category-export',
      content: 'You can export your categories as CSV',
    },
  ],
  workbench: [
    {
      target: '.tourWorkbenchVbql',
      content: 'Enter your VBQL query here',
    },
    {
      target: '.tourWorkbenchRun',
      content: 'Click here to see what matches your VBQL query!',
    },
  ],
});

export default tours;
