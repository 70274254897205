import React, { useState } from 'react';
import { Button, Modal, Select, Tooltip } from 'antd';
import { upperCase } from 'lodash';
import { saveState } from 'utils/localStorage';

const UserRestrictedPopup = ({ history, whiteListedAccounts, setUserRestricted, hasAdminPrivileges, fetchMyUser }) => {

  const accountsList = whiteListedAccounts.map((val) => {
    let disabled = false;
    if (val.whiteListingEnabled && !val.whiteListed) {
      disabled = true;
    }
    const label = `${val.name}${val.siteId ? ` - ${val.siteId}`: ''}${val.contractCode ? ` - ${upperCase(val.contractCode)}` : ''}`;
    return {
      label,
      value: val.id,
      disabled,
      title: disabled ? 'Account Restricted for current network, Please contact administrator.' : label,
    };
  }).sort((x, y) => (x.disabled === y.disabled) ? 0 : x.disabled ? 1 : -1);

  const [activeAccountId, setActiveAccountId] = useState(null);
  const [showFormError, setShowFormError] = useState(false);
  const isAvailableAccount = accountsList.some((val) => val.disabled === false);

  return (
    <Modal
      title="Account Restricted"
      closable={false}
      visible={true}
      footer={[
        <Button
          key="submit"
          type={!isAvailableAccount ? "primary" : ""}
          onClick={() => {
            history?.push('/logout');
          }}
        >
          Logout
        </Button>,
        isAvailableAccount ?
          <Button
            key="switch"
            type="primary"
            onClick={() => {
              if (activeAccountId) {
                saveState('activeAccountId', activeAccountId);
                setUserRestricted(false);
                fetchMyUser(hasAdminPrivileges);
              } else {
                setShowFormError(true);
              }
            }}
          >
            Switch Account
          </Button>
          :
          <></>,
      ]}
    >
      <p>Your account has been restricted for current network.</p>
      {
        !isAvailableAccount ? <p>Please contact administrator.</p> : <>
          <p>Please Switch to another account.</p>
          <Select
            placeholder="Select Account"
            value={activeAccountId}
            style={{ width: 300 }}
            onChange={(value) => {
              setShowFormError(false);
              setActiveAccountId(value);
            }}
          >
            {
              accountsList.map((val, index) => {
                return (
                  <Select.Option key={index} value={val.value} disabled={val.disabled} style={val.disabled ? { background: '#EBEBE4' } : {}}>
                    <Tooltip title={val.title} placement="right">
                      <span style={{ width: '100%' }}>{val.label}</span>
                    </Tooltip>
                  </Select.Option>
                );
              })
            }
          </Select>
          {showFormError && <p className='ant-form-item-explain-error'>Please select the account to switch!</p>}
        </>
      }
    </Modal >
  );
};

export default UserRestrictedPopup;
