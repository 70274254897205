/* eslint-disable linebreak-style */
import { getBaseReducer } from 'utils';
import { accountModel as model } from 'models/account';
import actions from './actions';

const initState = {
  ...getBaseReducer(model),
  entityType: 'templateDashboards',
  source: [],
  templateData: [],
  selectedDashboard: {},
  dashboardCharts: [],
  chartsData: {},
  uploadedTemplateData: null,
  viewTemplateModal: false,
  selectedTemplate: {},
  loadingData: false,
  isLoading: false
};

export default function templateDashboardReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TEMPLATE_DASHBOARDS:
      return {
        ...state,
        init: false,
        isLoading: true
      };
    case actions.FETCH_TEMPLATE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        init: true,
        isLoading: false,
        templateData: action.data,
      };
    case actions.FETCH_TEMPLATE_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        selectedDashboard: action.data,
      };
    case actions.FETCH_TEMPLATE_TEMPLATE_DASHBOARDS:
      return {
        ...state,
        init: false,
      };
    case actions.FETCH_TEMPLATE_TEMPLATE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        init: true,
        templateData: action.data,
      };
    case actions.FETCH_TEMPLATE_TEMPLATE_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        selectedDashboard: action.data,
      };
    case actions.FETCH_TEMPLATE_DASHBOARD_CHARTS_SUCCESS:
      return {
        ...state,
        init: true,
        dashboardCharts: action.data,
      };
    case actions.FETCH_TEMPLATE_DASHBOARD_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        chartsData: { ...state.chartsData, [action.data.id]: action.data },
      };
    case actions.TOGGLE_VIEW_TEMPLATE_MODAL:
      return {
        ...state,
        viewTemplateModal: action.data,
        selectedTemplate: action.template,
      };
    case actions.TEMPLATE_DASHBOARD_LOADING:
      return {
        ...state,
        loadingData: action.data,
      };
    
    case actions.UPLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
      }
    default:
      return state;
  }
}
