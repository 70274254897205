/* eslint-disable import/no-cycle */
import {
  all, takeEvery, fork, call, put, select,
} from 'redux-saga/effects';
import api from 'services';
import platformApi from 'services/platform';
import _, { cloneDeep, get } from 'lodash';
import { showToast } from 'utils/toast';
import { getAuthenticatedUser } from 'redux/users/saga';
import userActions from 'redux/users/actions';

import actions from './actions';

const entityType = 'interactions';
const stripData = (data) => _.omit(data, ['actAccountByAccountid', 'createdDt', 'updatedDt', '_flags']);

export function* fetchVBQLSaga() {
  yield takeEvery(actions.FETCH_INTERACTIONS, function* ({ filters, instance }) {
    if (filters) {
      try {
        const results = yield call(platformApi.fetchVBQL, { filters, path: 'data' });
        if (results) {
          let data = _.get(results, 'rows', []);
          !data ? (data = [], showToast('success', { task: 'No results found' })) : data
          data.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
          const total = _.get(results, 'totalResults', 0);
          yield put({
            type: actions.FETCH_INTERACTIONS_SUCCESS, data: data || [], total, instance,
          });
          const authenticatedUser = yield select(getAuthenticatedUser);
          const activeAccount = yield select((state) => state.App.activeAccount);
          const userClone = cloneDeep(authenticatedUser);
          userClone.roles = activeAccount.roles.filter((role) => !role.includes('Super')); // filter is applied for removing super roles,present in old user accounts due to server issue
          const parsedPreferences = JSON.parse(userClone.preferences || '{}');
          if (!(parsedPreferences.getStarted || []).includes('searchCalls')) {
            yield put(
              userActions.addGettingStartedTask(
                userClone,
                userClone.id,
                'searchCalls',
              ),
            );
          }
        }
      } catch (err) {
        showToast('error', { task: "Couldn't load results" });
        yield put({ type: actions.FETCH_INTERACTIONS_FAILURE, instance });
      }
    }
  });
}

export function* addInteraction() {
  yield takeEvery(actions.ADD_INTERACTION, function* ({ data }) {
    const interaction = yield call(api.createItem, { entityType, data });
    if (interaction) {
      yield put({ type: actions.ADD_INTERACTION_SUCCESS, selectedItem: interaction });
      yield put({ type: actions.FETCH_INTERACTIONS });
    } else {
      yield put({ type: actions.ADD_INTERACTION_FAILURE });
    }
  });
}

export function* patchInteraction() {
  yield takeEvery(actions.PATCH_INTERACTION, function* ({ data, id }) {
    const interaction = yield call(api.patchItem, { entityType, data, id });
    if (interaction) {
      yield put({ type: actions.EDIT_INTERACTION_SUCCESS, selectedItem: interaction });
      yield put({ type: actions.FETCH_INTERACTIONS });
    } else {
      yield put({ type: actions.EDIT_INTERACTION_FAILURE });
    }
  });
}

export function* editInteraction() {
  yield takeEvery(actions.EDIT_INTERACTION, function* ({ data, id }) {
    const interaction = yield call(api.editItem, { entityType, data: stripData(data), id });
    if (interaction) {
      yield put({ type: actions.EDIT_INTERACTION_SUCCESS, selectedItem: interaction });
      yield put({ type: actions.FETCH_INTERACTIONS });
    } else {
      yield put({ type: actions.EDIT_INTERACTION_FAILURE });
    }
  });
}

export function* deleteInteraction() {
  yield takeEvery(actions.DELETE__INTERACTION, function* () {
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchVBQLSaga),
    fork(addInteraction),
    fork(editInteraction),
    fork(patchInteraction),
    fork(deleteInteraction),
  ]);
}
