/* eslint-disable linebreak-style */
import { getBaseReducer } from 'utils';
import { accountModel as model } from 'models/account';
import actions from './actions';

const initState = {
  ...getBaseReducer(model),
  entityType: 'pciFlag',
  source: [],
  tempFormValues: {},
  s3ConfigErrors: { message: "" },
};

export default function accountReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_FLAGGED_MEDIA:
      return {
        ...state,
      };
    case actions.FETCH_FLAGGED_MEDIA_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case actions.SET_TEMP_FORM_VALUES:
      return {
        ...state,
        tempFormValues: action.data
      };
    case actions.TEST_CONFIGURATION_SUCCESS:
      return {
        ...state,
        s3ConfigErrors: {
          message: action.data.message
        },
      };
    default:
      return state;
  }
}
