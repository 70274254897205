/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import {
  all,
  takeEvery,
  fork,
  take,
  call,
  put,
  delay,
  select,
} from 'redux-saga/effects';
import preferencesActions from 'redux/preferences/actions';
import { transformUsers, transformUser, prepUser } from 'transform/user';
import api from 'services';
import { showToast } from 'utils/toast';
import moment from 'moment';
import actions from './actions';
import profileActions from '../profile/actions';
import appActions from '../app/actions';
import accountActions from '../accounts/actions';
import authActions from '../auth/actions';
import { loadState } from 'utils/localStorage';

const entityType = 'users';

const getTaskName = (id) => ({
  addUser: 'Add user',
  searchCalls: 'Search your calls',
  tagCalls: 'Tag you calls',
  connectData: 'Connect your data',
  configuration: 'Set your configuration',
}[id]);

export const getAuthenticatedUser = (state) => state?.Profile?.user || {};
export const getActiveAccount = (state) => state?.App?.activeAccount || {};

export function* fetchUsersSaga() {
  yield takeEvery(actions.FETCH_USERS, function* () {
    const data = yield call(api.fetchAll, {
      entityType,
      transform: transformUsers,
    });
    yield put({ type: actions.FETCH_USERS_SUCCESS, data });
  });
}

function* checkWhiteListedAccount(accounts) {
  let response = true;
  const localActiveAccountId = loadState?.('activeAccountId');
  const account = accounts.find((i) => i.id === localActiveAccountId) || accounts[0];
  if (account && account.whiteListingEnabled) {
    response = false;

    if (account.whiteListed) {
      response = true;
    }
  }

  return response;
}

/**
 * For bootstrapping the user/accounts when the app startsup
 */
export function* fetchMyUserSaga() {
  yield takeEvery(actions.FETCH_MY_USER, function* ({ hasAdminPrivileges }) {
    let tries = 0;
    let success = false;
    let takingTooLong = false;
    setTimeout(() => {
      takingTooLong = true;
    }, 10000);
    while (tries < 3 && !success && !takingTooLong) {
      try {
        const { user, accounts } = yield call(api.fetch, {
          entityType: 'user',
        });
        tries += 1;
        if (user == null) continue;
        success = true;
        delete user.roles; // We do not need roles on the individual user

        const isWhiteListed = yield checkWhiteListedAccount(accounts);

        if (isWhiteListed) {
          yield put({
            type: profileActions.PROFILE_SET_USER,
            user: { ...user },
          });
          yield put({ type: accountActions.SET_ACCESS_ACCOUNTS, accounts });
          yield take(appActions.CHANGE_ACTIVE_ACCOUNT); // Wait until account is ready

          if (!hasAdminPrivileges) {
            yield put({ type: appActions.SET_API_CONNECTED, apiConnected: true });
          }
          const preferences = user.preferences ? user.preferences : '{}';
          yield put({
            type: preferencesActions.FETCH_PREFERENCES_SUCCESS,
            data: JSON.parse(preferences),
          });
        } else {
          yield put({ type: appActions.SET_USER_RESTRICTED, userRestricted: true, hasAdminPrivileges, whiteListedAccounts: accounts });
        }
      } catch (e) {
        tries += 1;
        if (tries < 3) yield delay(2000);
      }
    }
    if (!success) {
      showToast('error', { task: "Couldn't load user" });
      yield put({ type: authActions.LOGOUT });
    }
  });
}

export function* addUserSaga() {
  yield takeEvery(actions.ADD_USER, function* ({ data, onFinish }) {
    const user = yield call(api.createItem, {
      entityType,
      data: prepUser(data),
    });
    if (user.createdDate) {
      const authenticatedUser = yield select(getAuthenticatedUser);
      const parsedPreferences = JSON.parse(
        authenticatedUser.preferences || '{}',
      );
      if (!(parsedPreferences.getStarted || []).includes('addUser')) {
        yield put(
          actions.addGettingStartedTask(
            authenticatedUser,
            authenticatedUser.id,
            'addUser',
          ),
        );
      }
      yield put({
        type: actions.ADD_USER_SUCCESS,
        selectedItem: transformUser(user),
      });
      yield put({ type: actions.FETCH_USERS });
    }
    onFinish?.();
  });
}

export const getUpdatedPreferences = (preferences, task) => {
  const parsedPreferences = JSON.parse(preferences || '{}');
  if (!(parsedPreferences.getStarted || []).includes(task)) {
    parsedPreferences.getStarted = [
      ...(parsedPreferences.getStarted || []),
      task,
    ];
  }
  return JSON.stringify(parsedPreferences);
};

export function* editUserSaga() {
  yield takeEvery(
    [actions.EDIT_USER, actions.ADD_GETTING_STARTED_TASK],
    function* editUser({
      data,
      id,
      onFinish = () => { },
      addTask = false,
      task,
      accountID,
      editPreferences = false,
    }) {
      let userData = {};
      const activeAccount = yield select(getActiveAccount) || {};
      const isAccountAdmin = (activeAccount?.roles || []).includes('AccountAdmin');
      if (addTask) {
        if (isAccountAdmin) {
          const { roles, ...rest } = data;
          userData = rest;
          const { preferences } = yield select(getAuthenticatedUser);
          userData.preferences = getUpdatedPreferences(preferences, task);
          const res = yield call(api.put, {
            url: 'user',
            data: userData,
            disableNotification: true,
            // ...(accountID && { activeAccountIdOverride: accountID }),
          });
          if (res?.data) {
            showToast('success', {
              task: `You have completed ${getTaskName(task)} task`,
            });
            yield put(
              profileActions.updatePreferences(res?.data?.preferences || ''),
            );
          }
        }
      } else if (editPreferences) {
        if (isAccountAdmin) {
          const { roles, ...rest } = data;
          userData = rest;
          yield call(api.put, {
            url: 'user',
            data: userData,
            disableNotification: true,
          });
        }
      } else {
        const user = yield call(api.editItem, {
          entityType,
          data: prepUser(data),
          id,
          ...(accountID && { activeAccountIdOverride: accountID }),
        });
        if (user.createdDate) {
          yield put({ type: actions.EDIT_USER_SUCCESS, selectedItem: user });
          yield put({ type: actions.FETCH_USERS });
        }
      }
      onFinish();
    },
  );
}

// export function* deleteUserSaga() {
//   yield takeEvery(actions.DELETE__USER, function* () {

//   });
// }

export function* resendInviteSaga() {
  yield takeEvery(actions.RESEND_INVITE, function* gen({ user }) {
    try {
      const res = yield call(api.get, {
        url: `users/${user.id}/resend-invite`,
        msg: 'Invite sent successfully!',
      });
      yield put(
        res.status === 200
          ? actions.resendInviteSuccess()
          : actions.resendInviteFailure(),
      );
      if (res.status === 200) {
        const preferences = JSON.stringify({
          ...JSON.parse(user.preferences || '{}'),
          invitation: moment.utc(true).format(),
        });
        yield put(actions.editUser({ ...user, preferences }, user.id));
      }
    } catch (err) {
      console.log(err)
    }

  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchUsersSaga),
    fork(fetchMyUserSaga),
    fork(addUserSaga),
    fork(editUserSaga),
    // fork(deleteUserSaga),
    fork(resendInviteSaga),
  ]);
}
