/** @prettier */
import * as Yup from 'yup';
import fields from './fields';
import validations from './validations';

const userModel = [
  fields.accountid,
  fields.status,
  {
    field: 'firstName',
    required: true,
  },
  {
    field: 'lastName',
    required: true,
  },
  {
    field: 'fullName',
    server: true, // @todo: this field is actually a client-side calculated field
    defaultSort: true,
  },
  {
    field: 'email',
  },
  {
    field: 'externalId',
  },
  {
    field: 'oktaId',
  },
  {
    field: 'roles',
    type: 'multi',
    options: [
      { value: 'SuperAdmin', label: 'Analytics Studio Admin', editableBy: ['SuperAdmin'] },
      { value: 'AccountAdmin', label: 'Admin', editableBy: ['SuperAdmin', 'AccountAdmin'] },
      { value: 'AccountAnalyst', label: 'Analyst', editableBy: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'] },
      {
        value: 'TableauAccess', label: 'Tableau', editableBy: ['SuperAdmin', 'AccountAdmin'], products: ['EA_HOSTED'],
      },
    ],
    optionPermissions: true,
  },
  {
    field: 'phone',
  },
  {
    field: 'preferences',
  },
];

const userSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Limit 50 characters')
    .test(...validations.stringWithSpaces)
    .test(...validations.trimSpaces),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Limit 50 characters')
    .test(...validations.stringWithSpaces)
    .test(...validations.trimSpaces),
  email: Yup.string()
    .required('Required')
    .email(),
  phone: Yup.string()
    .max(63, 'Limit 63 characters')
    .test(...validations.lettersNumbersCharacters),
  externalId: Yup.string()
    .max(255, 'Limit 255 characters')
    .test(...validations.advancedString)
    .test(...validations.trimSpaces),
  preferences: Yup.string()
    .max(4000, 'Limit 4000 characters')
    .nullable(),
});

export { userModel, userSchema };
