const actions = {
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CHANGE_ACTIVE_ACCOUNT: 'CHANGE_ACTIVE_ACCOUNT',
  CHANGE_ACTIVE_ACCOUNT_SUCCESS: 'CHANGE_ACTIVE_ACCOUNT_SUCCESS',
  SET_ACTIVE_ACCOUNT: 'SET_ACTIVE_ACCOUNT',
  FETCH_BACKEND_VERSION: 'FETCH_BACKEND_VERSION',
  FETCH_BACKEND_VERSION_SUCCESS: 'FETCH_BACKEND_VERSION_SUCCESS',
  SET_API_CONNECTED: 'SET_API_CONNECTED',
  SET_API_CONNECTION_SLOW: 'SET_API_CONNECTION_SLOW',
  CHANGE_ACTIVE_ACCOUNT_BY_ID: 'CHANGE_ACTIVE_ACCOUNT_BY_ID',
  SET_ADMIN_INIT: 'SET_ADMIN_INIT',
  CLEAR_MENU: 'CLEAR_MENU',
  CLEAR_DATA: 'CLEAR_DATA',
  CLEAR_DATA_SUCCESS: 'CLEAR_DATA_SUCCESS',
  SET_HISTORY: 'SET_HISTORY',
  FETCH_PLATFORM_VERSION: 'FETCH_PLATFORM_VERSION',
  FETCH_PLATFORM_VERSION_SUCCESS: 'FETCH_PLATFORM_VERSION_SUCCESS',
  FETCH_PLATFORM_VERSION_FAILURE: 'FETCH_PLATFORM_VERSION_FAILURE',
  UPDATE_ACTIVE_ACCOUNT: 'UPDATE_ACTIVE_ACCOUNT',
  UPDATE_ACTIVE_ACCOUNT_LANGUAGE: 'UPDATE_ACTIVE_ACCOUNT_LANGUAGE',
  CHANGE_ACTIVE_ACCOUNT_PCI: 'CHANGE_ACTIVE_ACCOUNT_PCI',
  FETCH_ACCOUNT_ETL_CONFIG_SUCCESS: 'FETCH_ACCOUNT_ETL_CONFIG_SUCCESS',
  SET_USER_RESTRICTED: 'SET_USER_RESTRICTED',
  SET_USER_RESTRICTED_ONLY: 'SET_USER_RESTRICTED_ONLY',
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  fetchBackendVersion: () => ({
    type: actions.FETCH_BACKEND_VERSION,
  }),
  changeCurrent: (current) => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  changeActiveAccount: (activeAccountId, activeAccount, clear = true) => ({
    type: actions.CHANGE_ACTIVE_ACCOUNT,
    activeAccountId,
    activeAccount,
    clear,
  }),
  changeActiveAccountById: (activeAccountId) => ({
    type: actions.CHANGE_ACTIVE_ACCOUNT_BY_ID,
    activeAccountId,
  }),
  setActiveAccount: (activeAccount) => ({
    type: actions.SET_ACTIVE_ACCOUNT,
    activeAccount,
  }),
  setApiConnected: (apiConnected) => ({
    type: actions.SET_API_CONNECTED,
    apiConnected,
  }),
  setApiConnectionSlow: () => ({
    type: actions.SET_API_CONNECTION_SLOW,
  }),
  setAdminInit: (adminInit) => ({
    type: actions.SET_ADMIN_INIT,
    adminInit,
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),
  clearData: () => ({
    type: actions.CLEAR_DATA,
  }),
  setHistory: (history) => ({ type: actions.SET_HISTORY, history }),
  fetchPlatformVersion: () => ({
    type: actions.FETCH_PLATFORM_VERSION,
  }),
  fetchPlatformVersionSuccess: (version) => ({
    type: actions.FETCH_BACKEND_VERSION_SUCCESS,
    version,
  }),
  fetchPlatformVersionFailure: () => ({
    type: actions.FETCH_PLATFORM_VERSION_FAILURE,
  }),
  updateActiveAcc: (account) => ({
    type: actions.UPDATE_ACTIVE_ACCOUNT,
    account,
  }),
  updateActiveAccountLanguage: (language) => ({
    type: actions.UPDATE_ACTIVE_ACCOUNT_LANGUAGE,
    language,
  }),
  changeActiveAccountPCI: (value) => ({
    type: actions.CHANGE_ACTIVE_ACCOUNT_PCI,
    value,
  }),
  setUserRestricted: (userRestricted) => ({
    type: actions.SET_USER_RESTRICTED_ONLY,
    userRestricted,
  })
};
export default actions;
