/** @prettier */
import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import IntlMessages from 'components/utility/intlMessages';
import settings from 'settings';
import services from './index';
import { showToast } from 'utils/toast';

const platformApiUrl = _.get(settings, 'api.serverContextMap.platform');

function fetchVBQL({ filters, path = 'data.rows', disableNotification = false }) {
  const entityType = 'interactions';
  const url = services.entityUrl({ entityType });
  const headers = services.authHeaders({ entityType });
  const { query } = filters;
  headers['Content-Type'] = 'text/plain';
  return axios
    .post(url, query, { headers })
    .then((res) => _.get(res, path))
    .catch((err) => (disableNotification ? console.error(err) : services.handleServerError(err)));
}

function post({ entityType, urlPath, data, disableNotification = false }) {
  const { authHeaders, handleServerError, success } = services;
  const headers = authHeaders();
  const url = platformApiUrl + urlPath;
  return axios
    .post(url, data, {
      headers,
    })
    .then((res) => {
      if (!disableNotification) {
        success(res, 'Successfully updated', <IntlMessages id={`entityType.singular.${entityType}`} />);
      }
      return res;
    })
    .catch((error) => {
      handleServerError(error, 'Updating', <IntlMessages id={`entityType.singular.${entityType}`} />);
      return error.response?.data;
    });
}

function createItem({ entityType, data, id, filters, disableNotification = false }) {
  // Note: platform requires 2 differences: 1 - use PUT method. 2 - id required on create
  const { entityUrl, authHeaders, handleServerError, success } = services;
  const url = entityUrl({ entityType, id, filters });
  const headers = authHeaders({ entityType });
  return axios
    .put(url, data, {
      headers,
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(res, 'Successfully created', <IntlMessages id={`entityType.singular.${entityType}`} />);
    })
    .then((res) => res)
    .catch((err) => handleServerError(err, 'Creating', <IntlMessages id={`entityType.singular.${entityType}`} />));
}

function createItemWithFormData({ entityType, data, id, filters, disableNotification = false }) {
  // Note: platform requires 2 differences: 1 - use PUT method. 2 - id required on create
  const { entityUrl, authHeaders, handleServerError, success } = services;
  const url = entityUrl({ entityType, id, filters });
  const headers = authHeaders({ entityType });
  const bodyFormData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    bodyFormData.append(key, value);
  }
  return axios
    .post(url, bodyFormData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(res, 'Sucessfully Created', <IntlMessages id={`entityType.singular.${entityType}`} />);
    })
    .then((res) => res)
    .catch((err) => handleServerError(err, 'Creating', <IntlMessages id={`entityType.singular.${entityType}`} />));
}

function platformPutReq({ entityType, data, id, filters, disableNotification = false, urlPath }) {
  const { authHeaders, handleServerError, success } = services;
  const url = platformApiUrl + urlPath;
  const headers = authHeaders();
  return axios
    .put(url, data, {
      headers,
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(res, 'Successfully edited', <IntlMessages id={`entityType.singular.${entityType}`} />);
    })
    .then((res) => res)
    .catch((err) => handleServerError(err, 'Creating', <IntlMessages id={`entityType.singular.${entityType}`} />));
}

function platformPostReq({ entityType, data, disableNotification = false, urlPath }) {
  const { authHeaders, handleServerError, success } = services;
  const url = platformApiUrl + urlPath;
  const headers = authHeaders();
  return axios
    .post(url, data, {
      headers,
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(res, 'Successfully created', <IntlMessages id={`entityType.singular.${entityType}`} />);
    })
    .then((res) => res)
    .catch((err) => handleServerError(err, 'Creating', <IntlMessages id={`entityType.singular.${entityType}`} />));
}

function platformGetReq({ entityType, id, filters, disableNotification = false, urlPath, activeAccountIdOverride }) {
  const { entityUrl, authHeaders, handleServerError, success } = services;
  const url = platformApiUrl + urlPath;
  const headers = authHeaders({ activeAccountIdOverride });
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(res, 'Successfully created', <IntlMessages id={`entityType.singular.${entityType}`} />);
    })
    .then((res) => res)
    .catch((err) => handleServerError(err, 'Editing', <IntlMessages id={`entityType.singular.${entityType}`} />));
}

function platformDelReq({ entityType, disableNotification = false, urlPath }) {
  const { authHeaders, handleServerError, success } = services;
  const url = platformApiUrl + urlPath;
  const headers = authHeaders();
  return axios
    .delete(url, {
      headers,
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(res, 'Successfully deleted', <IntlMessages id={`entityType.singular.${entityType}`} />);
    })
    .then((res) => res)
    .catch((err) => handleServerError(err, 'Deleting', <IntlMessages id={`entityType.singular.${entityType}`} />));
}

function postReqFile({ url, disableNotification = false, msg = '', data, responseTypeBlob = false }) {
  const { authHeaders, handleServerError } = services;
  const fullURL = platformApiUrl + url;
  const headers = authHeaders();
  
  const config = {
    headers,
  };
  
  if (responseTypeBlob) {
    config.responseType = 'blob'; // for handling binary data to download file
  }
  
  return axios
    .post(fullURL, data, config)
    .then((res) => {
      return res;
    })
    .catch(async (err) => {
      //error handling for binary data for blob type
      if (responseTypeBlob && err.response && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        const errorJSON = JSON.parse(errorText);
        console.log(errorJSON);
        handleBlobServerError(errorJSON);
        return Promise.reject(err);
      }
      if (disableNotification) {
        console.error(err);
        return Promise.reject(err);
      } else {
        handleServerError(err, 'Failed', '');
        console.log(err.response.data)
        return Promise.reject(_.get(err, 'response.data'));
      }
    });
}

function handleBlobServerError (error){
    const responseData = error; 
    const platformErrors = responseData.errors.map((i) => i.error);
      // Limit the number of errors to display to 5, we do not want to potentially display 100s of errors.
      showToast('error', {
        task: (
          <div>
            {platformErrors.length > 5 && (
              <span>5 of {platformErrors.length} errors</span>
            )}
            {platformErrors.slice(0, 5).map((i) => (
              <div key={i}>{i}</div>
            ))}
          </div>
        ),
        type: null,
      });
      return;
}

export default {
  fetchVBQL,
  createItem,
  createItemWithFormData,
  post,
  platformPutReq,
  platformGetReq,
  platformPostReq,
  platformDelReq,
  postReqFile
};
