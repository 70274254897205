import React from 'react';
import { Empty } from 'antd';
import { NotFoundIcon } from 'assets/images/icons';

const styles = {
  notFoundWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  outer: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '65vh',
  },
  text: {
    color: 'grey',
  },
  iconDiv: {
    padding: 8,
    background: '#ebf5fd',
    borderRadius: '50%',
    width: '46px',
    height: '46px',
  },
};

const NotFound = () => (
  <div style={styles.outer}>
    <Empty
      image={(
        <div style={styles.notFoundWrapper}>
          <div style={styles.iconDiv}>
            <NotFoundIcon className="notfound__icon" />
            {/* <Icon name="not_found" /> */}
          </div>
        </div>
        )}
      imageStyle={{
        height: 40,
        top: 20,
      }}
      description={(
        <div>
          <p style={styles.text}>No records available.</p>
        </div>
        )}
    />
  </div>
);

export default NotFound;
