import React from 'react';
import { message } from 'antd';
import { IntlProvider } from 'react-intl';
import config, { getCurrentLanguage } from 'components/LanguageSwitcher/config';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import AppLocale from '../language';
import { capitalize } from 'lodash';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

// eslint-disable-next-line import/prefer-default-export
export const showToast = (
  type = 'success',
  { type: cType = '', task = '' },
) => {
  let msg = "";
  if (cType?.props) {
    let cAppMsg = currentAppLocale.messages[cType?.props?.id];
    msg = capitalize(`${cAppMsg} ${task}`);
  } else {
    msg = capitalize(task)
  }

  message[type === 'success' ? 'info' : 'error']({
    content: (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <span >
          {type === 'success' ? msg : (
            <>{cType} {task} </>
          )}
        </span>
      </IntlProvider>
    ),
    className:
      type === 'success' ? 'toast-info-container' : 'toast-error-container',
    duration: 3,
    icon: type === 'success' ? <CheckOutlined /> : <StopOutlined />,
    style: {
      marginTop: '80vh',
    },
  });
};
