/* eslint-disable import/no-cycle */
import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import _ from 'lodash';
import platformApi from '../../services/platform';
import actions from './actions';
import { transformSchema, getSchemaOptions } from '../../transform/schema';
import optionsActions from '../options/actions';

export function* fetchSchemaSaga() {
  yield takeEvery(actions.FETCH_SCHEMA, function* () {
    // const data = yield call(api.fetchAll, {entityType, transform: transformSchema});

    const [data, totals] = yield all([
      call(platformApi.fetchVBQL, { filters: { query: 'DESCRIBE media' } }),
      call(platformApi.fetchVBQL, { filters: { query: 'SELECT * FROM media LIMIT 1' }, path: 'data' }),
    ]);
    yield put({ type: actions.FETCH_SCHEMA_SUCCESS, data: transformSchema(data) });
    yield put({
      type: optionsActions.UPDATE_OPTIONS,
      options: {
        schema: getSchemaOptions(data),
        interactionsTotal: _.get(totals, 'totalResults', 0),
      },
    });
  });
}

export function* selectSchemaSaga() {
  yield takeEvery(actions.SELECT_SCHEMA, function* ({ selectedItem }) {
    let sanitizedSelectedItemId = selectedItem?.id;
    if (sanitizedSelectedItemId.includes(':')) {
      // EA-2728 we need to add quotes around sub fields for vbql
      const splitField = sanitizedSelectedItemId.split(':');
      const subField = splitField[1];
      if (subField.includes('-') || subField.indexOf(' ') >= 0) {
        sanitizedSelectedItemId = `${splitField[0]}:"${subField}"`;
      }
    }
    const data = yield call(platformApi.fetchVBQL, {
      filters: { query: `SELECT topN(1000, ${sanitizedSelectedItemId}) AS sample FROM media` },
      disableNotification: true,
    });
    const sample = _.get(data, '[0].sample', {});
    if (data) {
      yield put({
        type: actions.SELECT_SCHEMA_SUCCESS,
        data: {
          sample,
          ...selectedItem,
        },
      });
    } else {
      yield put({ type: actions.SELECT_SCHEMA_FAILURE, data: selectedItem });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSchemaSaga),
    fork(selectSchemaSaga),
  ]);
}
