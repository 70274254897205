/** @prettier */
import uuid from 'react-uuid';

export const newWord = {
  value: '',
  substitutions: null,
  permutations: null,
  condition: 'or',
};

export const initialSearch = {
  condition: 'and',
  level: 0,
  id: 'unsaved',
  includeExcludeOperation: {},
  filters: [
    {
      name: 'Start Date',
      id: 'callStartTime',
      type: 'dateRange',
      // ranges: ['2021-10-10T00:00:00.000Z', '2021-11-01T00:00:00.000Z'],
    },
    {
      name: 'Created Date',
      id: 'dateCreated',
      type: 'dateRange',
      // ranges: ['2021-10-01T00:00:00.000Z', '2021-11-01T00:00:00.000Z'],
    },
    {
      name: 'Conversation Length',
      type: 'range',
      disabled: true,
      id: 'callLength',
      ranges: ['0', '30'],
    },
    {
      name: 'Interaction Type',
      id: 'interactionType',
      type: 'select',
    },
    {
      name: 'language',
      id: 'language',
      type: 'select',
      // value: 'U.S. English',
    },
    {
      name: 'silence',
      id: 'silence',
      type: 'select',
      value: '20',
      comparison: 'Any',
    },
    {
      name: 'Agent Sentiment',
      id: 'agentSentiment',
      type: 'select',
      value: 'Any',
    },
    {
      name: 'Consumer Sentiment',
      id: 'callerSentiment',
      type: 'select',
      value: 'Any',
    },
    {
      name: 'Latest Skill Name',
      id: 'latestSkillName',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Latest Agent Group',
      id: 'latestAgentGroup',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Latest Agent Name',
      id: 'latestAgentName',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Campaign Name',
      id: 'campaignName',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Engagement Campaign Name',
      id: 'engagementCampaignName',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Channel',
      id: 'channel',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Close Reason',
      id: 'closeReason',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Device Type',
      id: 'device',
      type: 'text',
      value: [],
      multiSelect: true,
      includeExclude: true,
    },
    {
      name: 'Limit',
      id: 'limit',
      type: 'number',
      value: 10,
    },
  ],
  rules: [
    {
      id: uuid(),
      speaker: 'anyone',
      operator: 'says',
      language: 'english',
      base: true,
      with: [
        {
          id: uuid(),
          words: [newWord],
          condition: 'and',
        },
      ],
      timeWithIn: null,
    },
  ],
};

export const initialGroup = {
  condition: 'and',
  level: 1,
  id: uuid(),
  rules: [
    {
      id: uuid(),
      operator: 'says',
      speaker: 'anyone',
      with: [
        {
          id: uuid(),
          words: [newWord],
          condition: 'and',
        },
      ],
      timeWithIn: null,
    },
  ],
};
