/** @prettier */
/* istanbul ignore file */
import Papa from 'papaparse';

export const fetchCsv = (csvFile, callback) => {
  const config = {
    header: true,
    download: true,
    skipEmptyLines: true,
  };
  return new Promise((complete, error) => {
    Papa.parse(csvFile, { ...config, complete, error });
  });
};
export const hasBadValue = (value) => {
  return /^[\=\+\-\@\n\t]/.test(value);
};

//  Append apostrophe to beginning of string if contains bad value /^[=+-@\n\t]/ - https://voicebase.jira.com/browse/EA-6647
export const handleCsvBadValue = (value) => {
  return hasBadValue(value) ? `'${value}` : value;
};

//  Replace double quotes with 2 double quotes since the csv library will auto add
//  double quotes that will ultimately cause them to hide
export const wrapCsvValue = (value) => {
  const regex = /[,\"\n]/g;
  if (regex.test(value)) {
    return `""${value.replace(/"/g, '""')}""`;
  }
  return value;
};
