// localStorage.js
export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    console.error('couldn\'t save local storage');
    // ignore write errors
  }
};

export const clearState = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error('couldn\'t clear local storage');
    // ignore write errors
  }
};

export function clearAuth() {
  clearState('token');
  clearState('profile');

  // Okta
  clearState('okta-token-storage');
  clearState('okta-cache-storage');
  clearState('okta-pkce-storage');
}
