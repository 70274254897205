import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import 'assets/okta/css/okta-sign-in.min.css';
import logo from 'assets/images/LP-Analytics-Studio_Logo_Stacked.svg';
import { OktaConfig, microsoftIdpId } from 'settings';

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();
  const history = useHistory();

  useEffect(() => {
    const {
      issuer, clientId, redirectUri, scopes,
    } = OktaConfig;
    const widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      useClassicEngine: true,
      redirectUri,
      // ...(microsoftIdpId && { idps: [{ type: 'MICROSOFT', id: microsoftIdpId }] }),
      logo,
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to Analytics Studio',
        },
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes,
      },
      features: {
        idpDiscovery: true,
        rememberMe: false,
      },
      idpDiscovery: {
        requestContext: window.location.href
      }
    });

    widget?.authClient?.session?.exists()?.then(function (sessionExists) {
      if (sessionExists) {
        widget.authClient.token.getWithoutPrompt().then(function (response) {
          widget.authClient.tokenManager.setTokens(response.tokens);
          oktaAuth.handleLoginRedirect(response.tokens);
        });
      } else {
        widget.showSignInToGetTokens({
          el: widgetRef.current,
          scopes,
        }).then((tokens) => {
          // Add tokens to storage
          oktaAuth.handleLoginRedirect(tokens);
        }).catch((err) => {
          console.error(err);
          widget.remove();
          history.push('/logout');
        });
      }
    })

    return () => widget.remove();
  }, [oktaAuth, history]);

  useEffect(() => {
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(() => {
      const forgotPasswordForm = widgetRef.current.querySelector('.forgot-password');
      if (forgotPasswordForm) {
        const formField = forgotPasswordForm.querySelector('a.email-button');
        if (formField.getAttribute('listener') !== 'true') {
          formField.addEventListener('click', function (e) {
              const elementClicked = e.target;
              elementClicked.setAttribute('listener', 'true');
              if (forgotPasswordForm.querySelector('input[type="text"]').value.trim()) {
                elementClicked.style.pointerEvents = 'none';
              }
         });
     }
      }
    });

    observer.observe(widgetRef.current, config);
    
    return () => {
      observer.disconnect();
    };
  }, [widgetRef.current]);

  // SAT-802 This is a workaround mentioned in the Okta threads below
  // https://github.com/okta/okta-oidc-js/issues/804#issuecomment-666886044
  // There is some issue in logic for checking and parsing of tokens
  // TODO: Upgraded okta 12/14/2020, hopefully this will be fixed
  // sessionStorage.setItem('okta-pkce-storage', '{}');
  return (
    <div style={{ marginTop: 30 }}>
      <div ref={widgetRef} />
    </div>
  );
};
export default Login;
