/* eslint-disable linebreak-style */
import { getBaseReducer } from 'utils';
import { accountModel as model } from 'models/account';
import actions from './actions';

const initState = {
  ...getBaseReducer(model),
  entityType: 'category-library',
  isImportingCategory: false,
  categoryLibraryData: [],
  publish: false,
  isLoading: false,
  viewCategoryAddForm: false,
  viewCategoryAddFormLoading: false,
};

export default function categoryLibraryReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CATEGORY_LIBRARY:
      return {
        ...state,
        categoryLibraryData: [],
        isLoading: true,
        isImportingCategory: false,
      };
    case actions.FETCH_CATEGORY_LIBRARY_SUCCESS:
      return {
        ...state,
        categoryLibraryData: action.data,
        isLoading: false,
      };
    case actions.FETCH_CATEGORY_LIBRARY_FAILURE:
      return {
        ...state,
        categoryLibraryData: [],
        isLoading: false,
      };
    case actions.IMPORT_DRAFT_CATEGORY:
      return {
        ...state,
        isImportingCategory: true,
        data: action.data,
        publish: action.publish,
        date: action.date,
      };
    case actions.IMPORT_DRAFT_CATEGORY_SUCCESS:
      return {
        ...state,
        isImportingCategory: false,
      };
    case actions.IMPORT_DRAFT_CATEGORY_FAILURE:
      return {
        ...state,
        isImportingCategory: false,
      };
    case actions.IMPORT_CATEGORY_OVERWRITE:
      return {
        ...state,
        isImportingCategory: true,
        data: action.data,
        flag: action.flag,
        date: action.date,
        isLoading: true,
      };
    case actions.SET_VIEW_CATEGORY_ADD_FORM:
      return {
        ...state,
        viewCategoryAddForm: action.viewCategoryAddForm,
      }
    case actions.SET_VIEW_CATEGORY_ADD_FORM_LOADING:
      return {
        ...state,
        viewCategoryAddFormLoading: action.viewCategoryAddFormLoading,
      }
    default:
      return state;
  }
}
