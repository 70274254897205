import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import styled from 'styled-components';

const Styled = styled(AntCheckbox)`
  margin-left: 0 !important;
  display: block !important;

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

export const Checkbox = (props) => {
  const { field, children } = props;
  return (
    <Styled {...field} {...props}>
      {children}
    </Styled>
  );
};
