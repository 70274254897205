import React from 'react';
import { Field as FField, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { Input as AntInput, Popconfirm } from 'antd';
import './VBForm.scss';
import { Button } from 'components-shared/buttons/Button';

export { FormGroup } from './FormGroup';
export { Checkbox } from './Checkbox';

export const LabeledInput = ({
  title, field, value, required,
}) => (
  <label className={`k-form-field ${required ? ' required-field' : ''}`}>
    <span>{title}</span>
    <FField type="text" name={field} className="k-textbox" value={value} />
    <ErrorMessage name={field} className="form-error" component={Error} />
  </label>
);

export const FormButtons = ({
  handleSubmit,
  toggleView,
  saveText = 'Save',
  isSubmitting = false,
  disabled,
  isLoading = false,
}) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <button
      type="button"
      className="k-button k-primary"
      onClick={handleSubmit}
      disabled={isLoading || isSubmitting || disabled}
    >
      {saveText}
    </button>
    <button type="button" className="k-button k-cancel" onClick={toggleView}>
      Cancel
    </button>
  </div>
);

export const NewFormButtons = ({
  handleSubmit = () => { },
  isSubmitting = false,
  disabled,
  saveText = 'Save',
  closeText = '',
  isLoading = false,
  onCancel = () => { },
  htmlType = '',
  showCancel = true,
  wrapStyle = {},
  classNames = ''
}) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end', ...wrapStyle }}>
    {showCancel && (
      <Button
        data-testid="cancel-button"
        className="n-button n-cancel"
        onClick={onCancel}
        style={{ margin: '0 8px 0' }}
      >
        {closeText || 'Cancel'}
      </Button>
    )}
    <Button
      data-testid="test-submit-button"
      type="primary"
      className={`n-button sg-button sg-primary ${classNames}`}
      onClick={handleSubmit}
      disabled={isLoading || isSubmitting || disabled}
      htmlType={htmlType}
    >
      {saveText}
    </Button>
  </div>
);

export const NewAccountFormButtons = ({
  handleSubmit = () => { },
  isSubmitting = false,
  disabled,
  saveText = 'Save',
  closeText = '',
  isLoading = false,
  onCancel = () => { },
  showCancel = true,
  showPopupConfirm = false,
  confirmMessage = '',
  dataStorageType = '',
  showValidate = false,
  isFormValidated = false,
  validateConnection = () => { },
  setIsFormValidated = () => { },
  setShowConfirmPopup = () => { },
  popUpRequired = false,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        {showValidate &&
          <Popconfirm
            placement='topRight'
            onConfirm={() => {
              validateConnection();
              setIsFormValidated(true);
            }}
            title={`Validating the connection will create a new 0-byte file in the ${dataStorageType === 'S3' ? 'Bucket' : 'Container'}, press OK to continue`}
            cancelText='Cancel'
            saveText='Ok'
          >
            <Button
              style={{ color: (isSubmitting || isFormValidated) && 'grey' }}
              disabled={isSubmitting || isFormValidated}
              data-testid="test-validate-button"
              type="primary"
              className="n-button sg-button sg-primary"
            >
              Validate Connection
            </Button>
          </Popconfirm>
        }

      </div>
      <div
        style={{ display: 'flex' }}      >
        {showCancel && (
          <Button
            data-testid="cancel-button"
            className="n-button n-cancel"
            onClick={onCancel}
            style={{ margin: '0 8px 0' }}
          >
            {closeText || 'Cancel'}
          </Button>
        )}
        {popUpRequired ? <Popconfirm
          placement='top'
          open={showPopupConfirm}
          onConfirm={() => {
            handleSubmit();
            setShowConfirmPopup(false);
          }}
          title={confirmMessage}
          cancelText='Cancel'
          saveText='Ok'
        >
          <Button
            style={{ color: (isLoading || isSubmitting || disabled) && 'grey' }}
            data-testid="test-submit-button"
            type="primary"
            className="n-button sg-button sg-primary"
            onClick={() => setShowConfirmPopup(true)}
            disabled={isLoading || isSubmitting || disabled}
          >
            {saveText}
          </Button>
        </Popconfirm> :
          <Button
            style={{ color: (isLoading || isSubmitting || disabled) && 'grey' }}
            data-testid="test-submit-button"
            type="primary"
            className="n-button sg-button sg-primary"
            onClick={handleSubmit}
            disabled={isLoading || isSubmitting || disabled}
          >
            {saveText}
          </Button>
        }
      </div>
    </div>
  )
};

export const Error = styled.div`
  color: #d75118;
  margin-top: 5px;
  font-size: 12px;
`;

const InputStyled = styled(AntInput)`
  border-radius: 2px !important;
`;

export const Input = ({ field }) => <InputStyled {...field} />;
