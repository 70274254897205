/* eslint-disable import/prefer-default-export */
import services from 'services';
import api from 'settings/api';

export const getAgentsStatus = (accountID) => {
  const headers = services.authHeaders({ activeAccountIdOverride: accountID });
  return fetch(`${api.supervisorApiUrl}/status`, { headers })
    .then((response) => response.json())
    .catch((err) => console.log('Error while fetching agents status', err));
};
