import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import CacheBuster from 'components/CacheBuster';
import { store, history } from './redux/store';
import PublicRoutes from './router';
import themes from './settings/themes';
import AppLocale from './language';
import config, {
  getCurrentLanguage,
} from './components/LanguageSwitcher/config';
import { themeConfig } from './settings';
import DashAppHolder from './AppStyle';
import GlobalStyles from './assets/styles/globalStyle';

import './App.scss';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

const App = () => (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }
      return (
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <DashAppHolder>
              <Provider store={store}>
                <PublicRoutes history={history} />
              </Provider>
              <GlobalStyles />
            </DashAppHolder>
          </ThemeProvider>
        </IntlProvider>
      );
    }}
  </CacheBuster>
);

export default App;
export { AppLocale };
