const dataSourceActions = {
  FETCH_DATASOURCES: 'FETCH_DATASOURCES',
  FETCH_DATASOURCES_SUCCESS: 'FETCH_DATASOURCES_SUCCESS',
  FETCH_DATASOURCES_FAILURE: 'FETCH_DATASOURCES_FAILURE',
  SELECT_DATASOURCE: 'SELECT_DATASOURCE',
  ADD_DATASOURCE: 'ADD_DATASOURCE',
  ADD_DATASOURCE_SUCCESS: 'ADD_DATASOURCE_SUCCESS',
  EDIT_DATASOURCE: 'EDIT_DATASOURCE',
  EDIT_DATASOURCE_SUCCESS: 'EDIT_DATASOURCE_SUCCESS',
  PATCH_DATASOURCE: 'PATCH_WAREHOUSE',
  GET_DATASOURCE: 'GET_DATASOURCE',
  DELETE__DATASOURCE: 'DELETE__DATASOURCE',
  CHANGE_DATASOURCE: 'CHANGE_DATASOURCE',
  CHANGE_DATASOURCE_VIEW: 'CHANGE_DATASOURCE_VIEW',
  CLEAR_DATASOURCES: 'CLEAR_DATASOURCES',
  SORT_DATASOURCES: 'SORT_DATASOURCES',
  sortDataSources: (e) => ({
    type: dataSourceActions.SORT_DATASOURCES,
    sort: e.sort,
  }),
  clearDataSources: () => ({
    type: dataSourceActions.CLEAR_DATASOURCES,
  }),
  fetchDataSources: () => ({
    type: dataSourceActions.FETCH_DATASOURCES,
  }),
  selectDataSource: (selectedItem) => ({
    type: dataSourceActions.SELECT_DATASOURCE,
    selectedItem,
  }),
  addDataSource: (data) => ({
    type: dataSourceActions.ADD_DATASOURCE,
    data,
  }),
  addTelecomDataSource: () => ({
    type: dataSourceActions.ADD_DATASOURCE,
    data: { name: 'defaultTelecom', sourceType: 'telecom' },
  }),
  editDataSource: (data, id) => ({
    type: dataSourceActions.EDIT_DATASOURCE,
    data,
    id,
  }),
  patchDataSource: (data) => ({
    type: dataSourceActions.PATCH_DATASOURCE,
    data,
  }),
  getDataSource: (data) => ({
    type: dataSourceActions.GET_DATASOURCE,
    data,
  }),
  deleteDataSource: (id) => ({
    type: dataSourceActions.DELETE__DATASOURCE,
    id,
  }),
  changeDataSourceView: (selectedItem) => ({
    type: dataSourceActions.CHANGE_DATASOURCE_VIEW,
    selectedItem,
  }),
};
export default dataSourceActions;
