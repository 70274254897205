/**
 * @prettier
 */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable import/named */
import moment from 'moment';
import { cloneDeep } from 'lodash';
import {
  ADD_SEARCH,
  ADD_SEARCH_FAILURE,
  ADD_SEARCH_SUCCESS,
  EDIT_SEARCH,
  EDIT_SEARCH_FAILURE,
  EDIT_SEARCH_SUCCESS,
  GET_SEARCH_LIST,
  GET_SEARCH_LIST_FAILURE,
  GET_SEARCH_LIST_SUCCESS,
  SEARCH_CALLS,
  SEARCH_CALLS_FAILURE,
  SEARCH_CALLS_SUCCESS,
  TOGGLE_SAVE_SEARCH,
  UPDATE_CALL_LENGTH,
  UPDATE_QUERY_JSON,
  SET_COLLAPSED_QUERIES,
  TOGGLE_LIST_TYPE,
  TOGGLE_PREVIEW,
  DELETE_SEARCH_SUCCESS,
  SET_MULTI_TEXT_FIELDS,
} from './constants';
import initialJSON from './initialJSON';
import { initialGroup, initialSearch } from './initalData';

const initState = {
  json: initialJSON,
  dummyGroup: initialGroup,
  searchList: [],
  loadingSearch: false,
  showSaveSearchDialog: false,
  query: '',
  collapsedQueries: false,
  listType: 'fancy',
  init: false,
  showPreview: false,
  calls: [],
  multiTextFields: {},
};

function getPermutedWords(word) {
  return word
    .split(' ')
    .map((w) => {
      if (w.length >= 4) {
        return `${w.substring(0, w.length - 1)}*`;
      }
      return w;
    })
    .join(' ');
}

function getWithQueryStr(withh) {
  const getConditionOperator = (condition) => {
    switch (condition) {
      case 'and':
        return '&&';
      case 'or':
        return '||';
      case 'andnot':
        return '&& !';
      default:
        return '';
    }
  };

  const fstr = withh.reduce((str, w, withIdx) => {
    str += `${withIdx > 0 ? `${getConditionOperator(w.condition)} ` : ''}(${w.words
      .filter((word) => word)
      .map(
        (word, i) =>
          `"${word.permutations ? getPermutedWords(word.value) : word.value}"${
            word.substitutions ? `/${word.substitutions}` : ''
          }${i !== w.words.length - 1 ? ` ${getConditionOperator(word.condition)}` : ''}`
      )
      .join(' ')}) `;
    return str;
  }, '');

  return fstr.trim();
}

function getUnitSymbol(unit) {
  return (
    {
      second: 's',
      minute: 'm',
      percent: '%',
    }[unit] || 's'
  );
}

function getTimeStr(timeWithIn) {
  let str = '';
  const { type, unit, value } = timeWithIn;
  const unitSymbol = getUnitSymbol(unit);

  if (type === 'first') {
    str = `[0${unitSymbol},${value}${unitSymbol}]`;
  } else {
    str = `[-${value}${unitSymbol},]`;
  }

  return str;
}

// Checks if all words/phrases in call search inputs are empty
const hasEmptyRules = (json) => {
  const emptyRules = json.rules?.every((rule) => {
    if (rule.rules) {
      return hasEmptyRules(rule);
    }
    return rule.with?.every((w) => {
      return w.words?.every((word) => {
        return !word.value;
      });
    });
  });

  return emptyRules;
};

function generateQuery(json) {
  const { rules } = json;
  return rules.reduce((query, rule) => {
    let r = rule;
    // Only add conditional operator for additional rules
    if (r.rules) {
      const { condition } = r;
      [r] = r.rules;
      query += `${!hasEmptyRules(json) ? ` ${condition === 'andnot' ? 'AND NOT' : condition} ` : ''}`;
    }

    query += `${r.speaker === 'anyone' ? '*' : `"${r.speaker}"`} ${r.operator} (${getWithQueryStr(r.with)})${
      r.timeWithIn ? `${getTimeStr(r.timeWithIn)}` : ''
    }`;
    return query;
  }, '');
}

const getStartEndOfDay = (dateRange) => {
  const formattedDateTimes = dateRange.map((date, i) => {
    let formattedDate = '';
    const tzOffset = moment(date).utcOffset();

    if (i === 0) {
      formattedDate = moment(date).startOf('day');
    } else {
      formattedDate = moment(date).endOf('day');
    }

    formattedDate = moment(formattedDate).add(tzOffset, 'minutes').toISOString();

    return formattedDate;
  });
  return formattedDateTimes;
};

function appendFilters(filters, json) {
  const includeExcludeOperation = json.includeExcludeOperation;
  const fltrs = filters
    .filter((f) => !f.disabled)
    .map((f) => {
      switch (true) {
        case f.id === 'callStartTime' && !!f.ranges: {
          const callStartTimeDates = getStartEndOfDay(f.ranges);
          return `callDetails.callStartTime > "${callStartTimeDates[0]}" AND callDetails.callStartTime < "${callStartTimeDates[1]}"`;
        }
        case f.id === 'dateCreated' && !!f.ranges: {
          const dateCreatedDates = getStartEndOfDay(f.ranges);
          return `dateCreated > "${dateCreatedDates[0]}" AND dateCreated < "${dateCreatedDates[1]}"`;
        }
        case f.id === 'language' && !!f.value:
          return `language="${f.value}"`;
        case f.id === 'callLength' && !!f.ranges:
          return `length > ${f.ranges[0] * 60000} AND length < ${f.ranges[1] * 60000}`;
        case f.id === 'silence' && f.comparison && f.comparison !== 'Any':
          return `totalSilenceRatio ${f.comparison.includes('Greater') ? '>' : '<'} ${+f.value / 100}`;
        case f.id === 'agentSentiment' && f.value !== 'Any': {
          if (f.value === 'Neutral') return 'metrics:"agent-sentiment" > -0.1 AND metrics:"agent-sentiment" < 0.1';
          if (f.value === 'Negative') return 'metrics:"agent-sentiment" <= -0.1';
          if (f.value === 'Positive') return 'metrics:"agent-sentiment" > 0.1';
          return '';
        }
        case f.id === 'callerSentiment' && f.value !== 'Any': {
          if (f.value === 'Neutral') return 'metrics:"caller-sentiment" > -0.1 AND metrics:"caller-sentiment" < 0.1';
          if (f.value === 'Negative') return 'metrics:"caller-sentiment" <= -0.1';
          if (f.value === 'Positive') return 'metrics:"caller-sentiment" > 0.1';
          return '';
        }
        case f.id === 'interactionType' && f.value && f.value !== 'Any': {
          return `interactionType = "${f.value.toLowerCase()}"`;
        }
        case f.id === 'latestSkillName' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }

            return `(${filteredValues.map((val) => `${exclude}callDetails.latestSkillName = "${val}"`).join(' AND ')})`;
          }
          return `${exclude}callDetails.latestSkillName = "${f.value}"`;
        }

        case f.id === 'latestAgentGroup' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }

            return `(${filteredValues
              .map((val) => `${exclude}callDetails.latestAgentGroup = "${val}"`)
              .join(' AND ')})`;
          }
          return `${exclude}callDetails.latestAgentGroup = "${f.value}"`;
        }

        case f.id === 'latestAgentName' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }

            return `(${filteredValues.map((val) => `${exclude}callDetails.latestAgentName = "${val}"`).join(' AND ')})`;
          }
          return `${exclude}callDetails.latestAgentName = "${f.value}"`;
        }

        case f.id === 'campaignName' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }

            return `(${filteredValues.map((val) => `${exclude}callDetails.campaignName = "${val}"`).join(' AND ')})`;
          }
          return `${exclude}callDetails.campaignName = "${f.value}"`;
        }

        case f.id === 'engagementCampaignName' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }

            return `(${filteredValues
              .map((val) => `${exclude}callDetails.engagementCampaignName = "${val}"`)
              .join(' AND ')})`;
          }
          return `${exclude}callDetails.engagementCampaignName = "${f.value}"`;
        }

        case f.id === 'channel' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }

            return `(${filteredValues.map((val) => `${exclude}callDetails.source = "${val}"`).join(' AND ')})`;
          }
          return `${exclude}callDetails.source = "${f.value}"`;
        }

        case f.id === 'closeReason' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }

            return `(${filteredValues.map((val) => `${exclude}callDetails.closeReason = "${val}"`).join(' AND ')})`;
          }

          return `${exclude}callDetails.closeReason = "${f.value}"`;
        }

        case f.id === 'device' &&
          ((typeof f.value === 'string' && f.value.trim() !== '') || (Array.isArray(f.value) && f.value.length)) &&
          f.value !== 'Any': {
          let exclude = '';
          if (includeExcludeOperation[f.id] === 'exclude') {
            exclude = 'NOT ';
          }
          if (Array.isArray(f.value)) {
            const filteredValues = f.value.filter((val) => val.trim() !== '');
            if (!filteredValues.length) {
              return '';
            }
            return `(${filteredValues.map((val) => `${exclude}callDetails.device = "${val}"`).join(' AND ')})`;
          }
          return `${exclude}callDetails.device = "${f.value}"`;
        }
        default:
          return '';
      }
    })
    .filter((f) => f);
  return fltrs.length ? `${!hasEmptyRules(json) ? 'AND' : 'where'} ${fltrs.join(' AND ')}` : '';
}

const generateFullQuery = (json) => {
  const { filters } = json;
  const limit = filters.find((f) => f.id === 'limit')?.value;

  return `select *, hits() from media ${!hasEmptyRules(json) ? `where ${generateQuery(json)}` : ''} ${appendFilters(
    filters,
    json
  )} limit ${limit || 10}`;
};

const refineSearchFilters = (data) => {
  const includeExcludeOperation = {};
  data.filters = data.filters.map((val) => {
    const initialValue = initialSearch.filters.find((initialItem) => initialItem.id === val.id);
    if (typeof val.value !== 'undefined' && initialValue.multiSelect) {
      const parsedValue = JSON.parse(val.value);
      if (parsedValue.exclude) {
        includeExcludeOperation[val.id] = 'exclude';
      }
      val.value = parsedValue?.data || parsedValue;
      val.multiSelect = initialValue.multiSelect;
      val.includeExclude = initialValue.includeExclude;
    }
    return val;
  });
  data.includeExcludeOperation = includeExcludeOperation;
  return data;
};

export default function jobReducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_QUERY_JSON: {
      // const callLength = state.callLength || state.json.filters.find((f) => f.id === 'callLength');
      const clone = cloneDeep(action.json);
      if (clone?.rules?.[0]) {
        clone.rules[0].base = true;
      }
      return {
        ...state,
        json: clone,
        query: `${generateFullQuery(clone)}`,
      };
    }
    case GET_SEARCH_LIST:
      return {
        ...state,
        loadingSearch: true,
      };
    case GET_SEARCH_LIST_SUCCESS: {
      const searchList = action.data.map((searchListItem) => {
        return refineSearchFilters(searchListItem);
      });

      searchList.sort((left, right) => moment.utc(right.updatedDate).diff(moment.utc(left.updatedDate)));
      return {
        ...state,
        loadingSearch: false,
        searchList,
      };
    }
    case GET_SEARCH_LIST_FAILURE:
      return {
        ...state,
        loadingSearch: false,
        searchList: [],
      };
    case ADD_SEARCH:
      return {
        ...state,
        loadingSearch: true,
      };
    case ADD_SEARCH_SUCCESS: {
      let clone = cloneDeep(action.data);
      if (clone?.rules?.[0]) {
        clone.rules[0].base = true;
      }
      clone = refineSearchFilters(clone);
      return {
        ...state,
        json: clone,
        searchList: [...state.searchList, clone],
      };
    }
    case ADD_SEARCH_FAILURE:
      return {
        ...state,
      };
    case EDIT_SEARCH:
      return {
        ...state,
      };
    case EDIT_SEARCH_SUCCESS:
      return {
        ...state,
        json: {
          ...state.json,
          name: action.data.name,
        },
        // searchList: state.searchList.map((search) => (search.id === action.data.id ? action.data : search)),
      };
    case EDIT_SEARCH_FAILURE:
      return {
        ...state,
      };
    case UPDATE_CALL_LENGTH:
      return {
        ...state,
        callLength: {
          min: action.value[0],
          max: action.value[1],
        },
      };
    case TOGGLE_SAVE_SEARCH:
      return {
        ...state,
        showSaveSearchDialog: action.bool,
      };
    case SEARCH_CALLS:
      return {
        ...state,
        searching: true,
      };
    case SEARCH_CALLS_SUCCESS:
      return {
        ...state,
        searching: false,
        calls: action.data,
        init: true,
      };
    case SEARCH_CALLS_FAILURE:
      return {
        ...state,
        searching: false,
      };
    case SET_COLLAPSED_QUERIES:
      return {
        ...state,
        collapsedQueries: action.bool,
      };
    // case UPDATE_CALL_LENGTH:
    //     return {
    //       ...state,
    //       json: {...json, filters: json.filters.map(filter => {
    //         if (filter.id === 'callLength') {
    //           filter.value = actions.value
    //         }
    //         return filter
    //       })}
    //     }
    case TOGGLE_LIST_TYPE:
      return {
        ...state,
        listType: action.listType,
      };
    case TOGGLE_PREVIEW:
      return {
        ...state,
        showPreview: action.bool,
      };
    case DELETE_SEARCH_SUCCESS:
      return {
        ...state,
        json: initialJSON,
      };
    case SET_MULTI_TEXT_FIELDS: {
      return {
        ...state,
        multiTextFields: action.multiTextFields,
      };
    }
    default:
      return state;
  }
}
