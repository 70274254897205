/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
} from 'redux-saga/effects';
import { transformUsers } from 'transform/user';
import api from 'services';
import { FETCH_CAMPAIGNS, FETCH_USERS, GET_AGENTS_STATUS } from './constants';
import {
  fecthUsersSuccess,
  fetchAgentsStatus,
  fetchAgentsStatusFailure,
  fetchAgentsStatusSuccess,
  fetchCampaignsFailure,
  fetchCampaignsSuccess,
  fetchUsersFailure,
} from './actions';
import { getAgentsStatus } from './services';

const entityType = 'users';

export function* fetchAgentsSaga() {
  yield takeEvery(FETCH_USERS, function* fetchAgents() {
    const data = yield call(api.fetchAll, {
      entityType,
      transform: transformUsers,
    });
    if (data) {
      yield put(fecthUsersSuccess(data));
    } else {
      yield put(fetchUsersFailure(data));
    }
  });
}

export function* fetchAgentsStatusSaga() {
  yield takeEvery(GET_AGENTS_STATUS, function* fetchStatus() {
    const activeAccount = yield select((state) => state?.App?.activeAccount);
    const data = yield call(getAgentsStatus, activeAccount?.id);
    if (data && !data.error) {
      // Need to parse json to js object because it comes in as a string
      const transformedData = {};
      Object.keys(data).forEach((i) => { transformedData[i] = JSON.parse(data[i]); });
      yield put(fetchAgentsStatusSuccess(transformedData));
    } else {
      yield put(fetchAgentsStatusFailure(data));
    }
  });
}

export function* fetchCampaignsSaga() {
  yield takeEvery(FETCH_CAMPAIGNS, function* fetchCampaigns() {
    const data = yield call(api.fetchAll, {
      entityType: 'campaigns',
    });
    if (data) {
      yield put(fetchCampaignsSuccess(data));
    } else {
      yield put(fetchCampaignsFailure(data));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchAgentsSaga), fork(fetchAgentsStatusSaga), fork(fetchCampaignsSaga)]);
}
