import reduxUtils from '../reduxUtils';

const checklistActions = {
  ...(reduxUtils.getActions('checklists')),
  CLEAR_CHECKLIST_SELECTED: 'CLEAR_CHECKLIST_SELECTED',
  FETCH_CHECKLIST: 'FETCH_CHECKLIST',
  FETCH_CHECKLIST_SUCCESS: 'FETCH_CHECKLIST_SUCCESS',
  FETCH_CHECKLIST_FAILURE: 'FETCH_CHECKLIST_FAILURE',
  UPDATE_CHECKLIST_ITEMS: 'UPDATE_CHECKLIST_ITEMS',
  UPDATE_CHECKLIST_ITEMS_SUCCESS: 'UPDATE_CHECKLIST_ITEMS_SUCCESS',
  UPDATE_CHECKLIST_ITEMS_FAILURE: 'UPDATE_CHECKLIST_ITEMS_POS_FAILURE',
  DELETE_CHECKLIST_ITEM: 'DELETE_CHECKLIST_ITEM',
  DELETE_CHECKLIST_ITEM_SUCCESS: 'DELETE_CHECKLIST_ITEM_SUCCESS',
  DELETE_CHECKLIST_ITEM_FAILURE: 'DELETE_CHECKLIST_ITEM_FAILURE',
  ON_CHECKLIST_ITEM_TREE_CHANGE: 'ON_CHECKLIST_ITEM_TREE_CHANGE',
  ADD_ALERT: 'ADD_ALERT',
  ADD_ALERT_SUCCESS: 'ADD_ALERT_SUCCESS',
  ADD_ALERT_FAILURE: 'ADD_ALERT_FAILURE',
  UPDATE_ALERT: 'UPDATE_ALERT',
  UPDATE_ALERT_SUCCESS: 'UPDATE_ALERT_SUCCESS',
  UPDATE_ALERT_FAILURE: 'UPDATE_ALERT_FAILURE',
  DELETE_ALERT: 'DELETE_ALERT',
  DELETE_ALERT_SUCCESS: 'DELETE_ALERT_SUCCESS',
  DELETE_ALERT_FAILURE: 'DELETE_ALERT_FAILURE',
  ON_ALERT_TREE_CHANGE: 'ON_ALERT_TREE_CHANGE',
  SELECT_CHECKLIST_ALERT: 'SELECT_CHECKLIST_ALERT',
  CHANGE_ACTIVE_TAB: 'CHANGE_ACTIVE_TAB',
  FIX_SERVER_POSITIONING_SUCCESS: 'FIX_SERVER_POSITIONING_SUCCESS',
  FIX_SERVER_POSITIONING_FAILURE: 'FIX_SERVER_POSITIONING_FAILURE',
  SELECT_CHECKLIST_POPUP: 'SELECT_CHECKLIST_POPUP',
  DELETE_POPUP: 'DELETE_POPUP',
  DELETE_POPUP_SUCCESS: 'DELETE_POPUP_SUCCESS',
  DELETE_POPUP_FAILURE: 'DELETE_POPUP_FAILURE',
  ADD_POPUP: 'ADD_POPUP',
  ADD_POPUP_SUCCESS: 'ADD_POPUP_SUCCESS',
  ADD_POPUP_FAILURE: 'ADD_POPUP_FAILURE',
  UPDATE_POPUP: 'UPDATE_POPUP',
  UPDATE_POPUP_SUCCESS: 'UPDATE_POPUP_SUCCESS',
  UPDATE_POPUP_FAILURE: 'UPDATE_POPUP_FAILURE',
  SET_ADD_ITEM_POPUP: 'SET_ADD_ITEM_POPUP',
  UPDATE_SELECTED_CHECKLIST: 'UPDATE_SELECTED_CHECKLIST',
  UPDATE_ADD_GLOBAL_POPUP_FLAG: 'UPDATE_ADD_GLOBAL_POPUP_FLAG',
  CREATE_TEMP_ITEM: 'CREATE_TEMP_ITEM',
  CREATE_TEMP_ALERT: 'CREATE_TEMP_ALERT',
  CREATE_TEMP_GLOBAL_POPUP: 'CREATE_TEMP_GLOBAL_POPUP',
  CHANGE_CHECKLISTITEM_VIEW: 'CHANGE_CHECKLISTITEM_VIEW',
  UPDATE_TREE_SCROLL_TOP: 'UPDATE_TREE_SCROLL_TOP',
  CLEAR_TREE_SCROLL: 'CLEAR_TREE_SCROLL',
  SET_CHECKLIST_SETTINGS: 'SET_CHECKLIST_SETTINGS',
  SAVE_SETTINGS: 'SAVE_SETTINGS',
  SAVE_SETTINGS_SUCCESS: 'SAVE_SETTINGS_SUCCESS',
  SAVE_SETTINGS_FAILURE: 'SAVE_SETTINGS_FAILURE',
  DUPLICATE_CHECKLIST: 'DUPLICATE_CHECKLIST',
  DUPLICATE_CHECKLIST_SUCCESS: 'DUPLICATE_CHECKLIST_SUCCESS',
  DUPLICATE_CHECKLIST_FAILURE: 'DUPLICATE_CHECKLIST_FAILURE',
  EDIT_CHECKLIST: 'EDIT_CHECKLIST',
  fetchChecklistFailure: () => ({
    type: checklistActions.FETCH_CHECKLIST_FAILURE,
  }),
  clearChecklistSelected: (options) => ({
    type: checklistActions.CLEAR_CHECKLIST_SELECTED,
  }),
  updateChecklistItems: (items, type) => ({
    type: checklistActions.UPDATE_CHECKLIST_ITEMS,
    items,
    updateType: type,
  }),
  updateChecklistItemsSuccess: (data) => ({
    type: checklistActions.UPDATE_CHECKLIST_ITEMS_SUCCESS,
    data,
  }),
  updateChecklistItemsFailure: (err) => ({
    type: checklistActions.UPDATE_CHECKLIST_ITEMS_FAILURE,
    err,
  }),
  deleteChecklistItem: (id, checklistId) => ({
    type: checklistActions.DELETE_CHECKLIST_ITEM,
    id,
    checklistId,
  }),
  deleteChecklistItemSuccess: (data) => ({
    type: checklistActions.DELETE_CHECKLIST_ITEM_SUCCESS,
    data,
  }),
  deleteChecklistItemFailure: (err) => ({
    type: checklistActions.DELETE_CHECKLIST_ITEM_FAILURE,
    err,
  }),
  onChecklistItemTreeChange: (treeData) => ({
    type: checklistActions.ON_CHECKLIST_ITEM_TREE_CHANGE,
    treeData,
  }),
  onAlertTreeChange: (treeData) => ({
    type: checklistActions.ON_ALERT_TREE_CHANGE,
    treeData,
  }),
  addAlert: (data) => ({
    type: checklistActions.ADD_ALERT,
    data,
  }),
  addAlertSuccess: (data) => ({
    type: checklistActions.ADD_ALERT_SUCCESS,
    data,
  }),
  addAlertFailure: () => ({
    type: checklistActions.ADD_ALERT_FAILURE,
  }),
  updateAlert: (data) => ({
    type: checklistActions.UPDATE_ALERT,
    data,
  }),
  updateAlertSuccess: (data) => ({
    type: checklistActions.UPDATE_ALERT,
    data,
  }),
  updateAlertFailure: () => ({
    type: checklistActions.UPDATE_ALERT_FAILURE,
  }),
  deleteAlert: (id) => ({
    type: checklistActions.DELETE_ALERT,
    id,
  }),
  deleteAlertSuccess: () => ({
    type: checklistActions.DELETE_ALERT_SUCCESS,
  }),
  deleteAlertFailure: () => ({
    type: checklistActions.DELETE_ALERT_FAILURE,
  }),
  selectChecklistAlert: (selectedAlert = {}) => ({
    type: checklistActions.SELECT_CHECKLIST_ALERT,
    selectedAlert,
  }),
  changeActiveTab: (activeTab) => ({
    type: checklistActions.CHANGE_ACTIVE_TAB,
    activeTab,
  }),
  fixServerPositioningSuccess: (checklist) => ({
    type: checklistActions.FIX_SERVER_POSITIONING_SUCCESS,
    checklist,
  }),
  fixServerPositioningFailure: () => ({
    type: checklistActions.FIX_SERVER_POSITIONING_FAILURE,
  }),
  selectChecklistPopup: (selectedPopup = {}) => ({
    type: checklistActions.SELECT_CHECKLIST_POPUP,
    selectedPopup,
  }),
  deletePopup: (id, isGlobal = true) => ({
    type: checklistActions.DELETE_POPUP,
    id,
    isGlobal,
  }),
  deletePopupSuccess: () => ({
    type: checklistActions.DELETE_POPUP_SUCCESS,
  }),
  deletePopupFailure: () => ({
    type: checklistActions.DELETE_POPUP_FAILURE,
  }),
  addPopup: (data, isGlobal) => ({
    type: checklistActions.ADD_POPUP,
    data,
    isGlobal,
  }),
  addPopupSuccess: (data) => ({
    type: checklistActions.ADD_POPUP_SUCCESS,
    data,
  }),
  addPopupFailure: () => ({
    type: checklistActions.ADD_POPUP_FAILURE,
  }),
  updatePopup: (data, isGlobal) => ({
    type: checklistActions.UPDATE_POPUP,
    data,
    isGlobal,
  }),
  updatePopupSuccess: (data) => ({
    type: checklistActions.UPDATE_POPUP,
    data,
  }),
  updatePopupFailure: () => ({
    type: checklistActions.UPDATE_POPUP_FAILURE,
  }),
  setAddItemPopup: (bool) => ({
    type: checklistActions.SET_ADD_ITEM_POPUP,
    bool,
  }),
  updateSelectedChecklist: (selectedItem) => ({
    type: checklistActions.UPDATE_SELECTED_CHECKLIST,
    selectedItem,
  }),
  updateAddGlobalPopupFlag: (bool) => ({
    type: checklistActions.UPDATE_ADD_GLOBAL_POPUP_FLAG,
    bool,
  }),
  createTempItem: () => ({
    type: checklistActions.CREATE_TEMP_ITEM,
  }),
  createTempAlert: () => ({
    type: checklistActions.CREATE_TEMP_ALERT,
  }),
  createTempGlobalPopup: (checklistId) => ({
    type: checklistActions.CREATE_TEMP_GLOBAL_POPUP,
    checklistId,
  }),
  updateTreeScrollTop: (name, value) => ({
    type: checklistActions.UPDATE_TREE_SCROLL_TOP,
    name,
    value,
  }),
  clearTreeScroll: () => ({
    type: checklistActions.CLEAR_TREE_SCROLL,
  }),
  setChechlistSettings: (settings) => ({
    type: checklistActions.SET_CHECKLIST_SETTINGS,
    settings,
  }),
  saveSettings: (checklist) => ({
    type: checklistActions.SAVE_SETTINGS,
    checklist,
  }),
  saveSettingsSuccess: (data) => ({
    type: checklistActions.SAVE_SETTINGS_SUCCESS,
    data,
  }),
  saveSettingsFailure: () => ({
    type: checklistActions.SAVE_SETTINGS_FAILURE,
  }),
  duplicateChecklist: (id) => ({
    type: checklistActions.DUPLICATE_CHECKLIST,
    checklistId: id,
  }),
  duplicateChecklistSuccess: () => ({
    type: checklistActions.DUPLICATE_CHECKLIST_SUCCESS,
  }),
  duplicateChecklistFailure: () => ({
    type: checklistActions.DUPLICATE_CHECKLIST_FAILURE,
  }),
  editChecklist: (data, id, history, onFinish, navigate) => ({
    type: checklistActions.EDIT_CHECKLIST,
    data,
    id,
    history,
    onFinish,
    navigate
  })
};

export default checklistActions;
