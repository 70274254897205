import * as Yup from 'yup';
import validations from './validations';

const vocabularyListModel = [
  {
    field: 'name',
    primaryKey: true,
  },
  {
    field: 'type',
    defaultValue: 'terms',
  },
  {
    field: 'terms',
    type: 'array',
    defaultValue: [
      {
        term: '',
        weight: 0,
        soundsLike: [],
        soundsLikeStr: '',
        inEdit: true,
      },
    ],
  },
  {
    field: 'updatedDate',
    type: 'date',
    server: true,
    defaultSort: true,
    sortDirection: 'desc',
  },
];

const vocabularyListSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .max(255, 'Limit 255 characters')
    .test(
      'valid',
      'Can only contain letters, numbers, -, _, or spaces',
      (v) => !v || v.match(/^[a-zA-Z0-9-_ ]*$/g),
    )
    .test(...validations.trimSpaces)
    .test(
      'is-unique',
      'Must be unique',
      function test(value) {
        const { existingNames = [] } = this.parent;
        return value && !existingNames.includes(value.toLowerCase());
      },
    ),
  terms: Yup.array()
    .required('Terms are Required')
    .unique('Duplicate terms aren\'t allowed', (a) => a.term)
    .of(
      Yup.object().shape({
        term: Yup.string()
          .required('Term names are required')
          .max(64, 'Term names are limited to 64 characters')
          .test(
            'valid',
            'Term names can only contain letters, numbers, -, _ or spaces',
            (v) => !v || v.match(/^[a-zA-Z0-9-_ ]*$/g),
          )
          .test(...validations.trimSpaces),
        weight: Yup.string()
          .nullable()
          .test(
            'valid',
            'Term weights must be between 0 and 5',
            (v) => !v || (v >= 0 && v <= 5),
          ),
      }),
    )
    .uniqueInArray('Term name must be unique', 'term'),
});

export { vocabularyListModel, vocabularyListSchema };
