import actions from './actions';

const initState = {
  schema: [],
  interactionsTotal: 0,
  tags: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_OPTIONS: {
      return {
        ...state,
        ...action.options,
      };
    }
    default:
      return state;
  }
}
