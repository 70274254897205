import api from './api';

const OktaConfig = {
  ...(api.okta),
  issuer: `https://${api.okta.domain}/oauth2/default`,
  redirectUri: `${api.app}/implicit/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: api.okta.disableHttpsCheck,
};
export {
  OktaConfig,
};
