/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import api from 'services';
import platformApi from 'services/platform';
import { get } from 'lodash';
import { showToast } from 'utils/toast';
import { exportToJson } from 'utils/index';
import actions from './actions';
import communicationActions from '../communications/actions';

const entityType = 'media';

export function* fetchMediaJsonSaga() {
  yield takeEvery(actions.FETCH_MEDIA_JSON, function* ({ mediaId, options = {} }) {
    const res = yield call(api.fetchItem, {
      entityType, id: mediaId, disableNotification: true, needResponse: true,
    });
    const metricsDefinition = yield call(platformApi.platformGetReq, {
      urlPath: `definition/metrics`, disableNotification: true, needResponse: true,
    });

    if (res.status === 200 && res.data) {
      const mediaUrl = get(res.data, 'streams[0].streamLocation');
      yield put({
        type: actions.FETCH_MEDIA_JSON_SUCCESS, data: { ...res.data, metricGroups: metricsDefinition.metricGroups }, mediaId, mediaUrl,
      });
      if (options.download) {
        exportToJson(res.data, `analytics-data-${res.data.mediaId}.json`);
      }
    } else {
      yield put({ type: actions.FETCH_MEDIA_JSON_ERROR });
      showToast('error', {
        task:
          'Error retrieving media. It looks like this media file has expired. Check your account storage settings for more details.',
      });
    }
  });
}

export function* fetchPciFlagSaga() {
  yield takeEvery(actions.FETCH_PII_FLAG, function* ({ mediaId }) {
    const res = yield call(platformApi.platformGetReq, {
      urlPath: `${entityType}/${mediaId}/pii-flag`, disableNotification: true, needResponse: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_PII_FLAG_SUCCESS, data: res.value,
      });
    } else yield put({ type: actions.FETCH_PII_FLAG_ERROR });
  });
}

export function* fetchPciFlagActionSaga() {
  yield takeEvery(actions.FETCH_PII_FLAG_ACTION, function* ({ accountId }) {
    const res = yield call(platformApi.platformGetReq, {
      urlPath: `account/${accountId}/settings/pii-flag-action`, disableNotification: true, needResponse: true,
    });
    if (res) {
      yield put({
        type: actions.FETCH_PII_FLAG_ACTION_SUCCESS, data: res.action,
      });
    } else yield put({ type: actions.FETCH_PII_FLAG_ACTION_ERROR });
  });
}

export function* updatePciFlagSaga() {
  yield takeEvery(actions.UPDATE_PII_FLAG, function* ({ mediaId, pciFlagAction, lastSelectedPlayer, filters }) {
    const res = yield call(platformApi.platformPutReq, {
      urlPath: `${entityType}/${mediaId}/pii-flag`, disableNotification: true, needResponse: true, data: { reason: 'PCI' },
    });
    if (res) {
      if (pciFlagAction === 'delete') {
        showToast('success', {
          task: 'Media deleted successfully',
        });
      } else {
        showToast('success', {
          task: 'Media flagged successfully',
        });
      }
      yield put({
        type: actions.HIDE_PLAYER,
      });
      yield put({
        type: communicationActions.FETCH_COMMUNICATIONS,
        filters,
      });

      if (lastSelectedPlayer) {
        yield put({
          type: communicationActions.GET_COMMUNICATION,
          data: lastSelectedPlayer
        });
      }
    } else {
      yield put({ type: actions.UPDATE_PII_FLAG_ERROR });
    }
  });
}

export function* changeMediaActionFlagSaga() {
  yield takeEvery(actions.CHANGE_MEDIA_ACTION_FLAG, function* ({ accountId, action }) {
    const res = yield call(platformApi.platformPutReq, {
      urlPath: `account/${accountId}/settings/pii-flag-action`, disableNotification: true, needResponse: true, data: action,
    });
    if (res) {
      showToast('success', {
        task: 'Flag successfully changed',
      });
      yield put({
        type: actions.FETCH_PII_FLAG_ACTION_SUCCESS, data: res.action,
      });
    } else {
      showToast('error', {
        task: 'Something went wrong, can not change status',
      });
      yield put({ type: actions.FETCH_PII_FLAG_ACTION_ERROR });
    }
  });
}

export function* exportTranscriptSaga() {
  yield takeEvery(actions.EXPORT_TRANSCRIPT_SUCCESS, function* ({ data }) {
    if (data) {
      showToast('success', {
        task: 'Exported transcript',
      });
    } else {
      showToast('error', {
        task: 'Something went wrong, failed to export',
      });
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchMediaJsonSaga),
    fork(fetchPciFlagSaga),
    fork(fetchPciFlagActionSaga),
    fork(updatePciFlagSaga),
    fork(changeMediaActionFlagSaga),
    fork(exportTranscriptSaga),
  ]);
}
