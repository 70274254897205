/**
 * @prettier
 */
import * as Yup from 'yup';
import validations from './validations';

const communicationModel = [
  {
    field: 'mediaId',
    primaryKey: true,
    server: true,
  },
  {
    field: 'configuration',
    required: true,
    defaultValue: JSON.stringify(
      {
        speechModel: {
          language: 'en-US',
          model: 'Europa',
          features: ['advancedPunctuation'],
        },
        priority: 'low',
        metrics: [
          {
            metricGroupName: 'overtalk',
          },
          {
            metricGroupName: 'sentiment',
          },
          {
            metricGroupName: 'talk-style-tone-and-volume',
          },
          {
            metricGroupName: 'talk-time-and-rate',
          },
        ],
        knowledge: {
          enableDiscovery: 'false',
          enableExternalDataSources: 'false',
        },
        categories: [
          {
            allCategories: 'true',
          },
        ],
        ingest: {
          channels: [
            {
              speakerName: 'Caller',
            },
            {
              speakerName: 'Agent',
            },
          ],
        },
        speakerSentiments: true,
        conversation: {
          verbNounPairsEnabled: true,
        },
      },
      null,
      '\t'
    ),
  },
  {
    field: 'mediaUrl',
  },
  {
    field: 'metadata',
    defaultValue: JSON.stringify(
      {
        title: '',
        extended: {},
      },
      null,
      '\t'
    ),
  },
  {
    field: 'dateCreated',
    type: 'date',
    server: true,
    defaultSort: true,
    sortDirection: 'desc',
  },
];

const communicationSchema = Yup.object().shape({
  configuration: Yup.string()
    .required('Required')
    .test(...validations.isValidJson),
  mediaUrl: Yup.string().when('fileMethod', {
    is: 'url',
    then: Yup.string()
      .required('Required')
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Must be a valid url'
      ),
  }),
  media: Yup.mixed().when('fileMethod', {
    is: 'upload',
    then: Yup.string().required('Required'),
  }),
  metadata: Yup.string()
    .required('Required')
    .test(...validations.isValidJson),
});

export { communicationModel, communicationSchema };
