const connectionActions = {
  FETCH_CONNECTIONS: 'FETCH_CONNECTIONS',
  FETCH_CONNECTIONS_SUCCESS: 'FETCH_CONNECTIONS_SUCCESS',
  FETCH_CONNECTIONS_FAILURE: 'FETCH_CONNECTIONS_FAILURE',
  SELECT_CONNECTION: 'SELECT_CONNECTION',
  ADD_CONNECTION: 'ADD_CONNECTION',
  ADD_CONNECTION_SUCCESS: 'ADD_CONNECTION_SUCCESS',
  EDIT_CONNECTION: 'EDIT_CONNECTION',
  EDIT_CONNECTION_SUCCESS: 'EDIT_CONNECTION_SUCCESS',
  PATCH_CONNECTION: 'PATCH_WAREHOUSE',
  GET_CONNECTION: 'GET_CONNECTION',
  DELETE__CONNECTION: 'DELETE__CONNECTION',
  CHANGE_CONNECTION: 'CHANGE_CONNECTION',
  CHANGE_CONNECTION_VIEW: 'CHANGE_CONNECTION_VIEW',
  CHANGE_CONNECTION_VIEW_SUCCESS: 'CHANGE_CONNECTION_VIEW_SUCCESS',
  CLEAR_CONNECTIONS: 'CLEAR_CONNECTIONS',
  SORT_CONNECTIONS: 'SORT_CONNECTIONS',
  TOGGLE_PCI_REDACTION_MODAL: 'TOGGLE_PCI_REDACTION_MODAL',
  FETCH_BULKUPLOADS: 'FETCH_BULKUPLOADS',
  FETCH_BULKUPLOADS_SUCCESS: 'FETCH_BULKUPLOADS_SUCCESS',
  FETCH_BULKUPLOADS_FAILURE: 'FETCH_BULKUPLOADS_FAILURE',
  LOADMORE_BULKUPLOADS: 'LOADMORE_BULKUPLOADS',
  TEST_CONFIGURATION: 'TEST_CONFIGURATION',
  TEST_CONFIGURATION_SUCCESS: 'TEST_CONFIGURATION_SUCCESS',
  ADD_AGENT_STATUS: 'ADD_AGENT_STATUS',

  sortConnections: (e) => ({
    type: connectionActions.SORT_CONNECTIONS,
    sort: e.sort,
  }),
  clearConnections: () => ({
    type: connectionActions.CLEAR_CONNECTIONS,
  }),
  fetchConnections: () => ({
    type: connectionActions.FETCH_CONNECTIONS,
  }),
  selectConnection: (selectedItem) => ({
    type: connectionActions.SELECT_CONNECTION,
    selectedItem,
  }),
  addConnection: (data, onFinish) => ({
    type: connectionActions.ADD_CONNECTION,
    data,
    onFinish,
  }),
  editConnection: (data, id, onFinish) => ({
    type: connectionActions.EDIT_CONNECTION,
    data,
    id,
    onFinish,
  }),
  patchConnection: (data) => ({
    type: connectionActions.PATCH_CONNECTION,
    data,
  }),
  getConnection: (data) => ({
    type: connectionActions.GET_CONNECTION,
    data,
  }),
  deleteConnection: (id) => ({
    type: connectionActions.DELETE__CONNECTION,
    id,
  }),
  changeConnectionView: (selectedItem) => ({
    type: connectionActions.CHANGE_CONNECTION_VIEW,
    selectedItem,
  }),
  togglePCIRedactionModal: (bool) => ({
    type: connectionActions.TOGGLE_PCI_REDACTION_MODAL,
    bool,
  }),
  fetchBulkUploads: () => ({
    type: connectionActions.FETCH_BULKUPLOADS,
  }),
  loadMoreBulkUploads: (data) => ({
    type: connectionActions.LOADMORE_BULKUPLOADS,
    data
  }),
  testConfiguration: (data, isForLiveConnection, onFinish, connectionType = '', isNewConnection = false, onSuccessValidation) =>
    ({
    type: connectionActions.TEST_CONFIGURATION,
    data,
    isForLiveConnection,
    onFinish,
    connectionType,
    isNewConnection,
    onSuccessValidation
  }),

};
export default connectionActions;
