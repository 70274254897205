import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import actions from './actions';
import api from '../../services';
import { prepDataSource } from '../../transform/dataSource';

const entityType = 'dataSources';

export function* fetchDataSourcesSaga() {
  yield takeEvery(actions.FETCH_DATASOURCES, function* () {
    const data = yield call(api.fetchAll, { entityType });
    yield put({ type: actions.FETCH_DATASOURCES_SUCCESS, data });
  });
}

export function* addDataSourceSaga() {
  yield takeEvery(actions.ADD_DATASOURCE, function* ({ data }) {
    const dataSource = yield call(api.createItem, { entityType, data });
    if (dataSource) {
      yield put({ type: actions.ADD_DATASOURCE_SUCCESS, selectedItem: dataSource });
      yield put({ type: actions.FETCH_DATASOURCES });
    }
  });
}

export function* patchDataSourceSaga() {
  yield takeEvery(actions.PATCH_DATASOURCE, function* ({ data, id }) {
    const dataSource = yield call(api.patchItem, { entityType, data, id });
    yield put({ type: actions.EDIT_DATASOURCE_SUCCESS, selectedItem: dataSource });
    yield put({ type: actions.FETCH_DATASOURCES });
  });
}

export function* editDataSourceSaga() {
  yield takeEvery(actions.EDIT_DATASOURCE, function* ({ data, id }) {
    const dataSource = yield call(api.editItem, { entityType, data: prepDataSource(data), id });
    yield put({ type: actions.EDIT_DATASOURCE_SUCCESS, selectedItem: dataSource });
    yield put({ type: actions.FETCH_DATASOURCES });
  });
}

export function* deleteDataSourceSaga() {
  yield takeEvery(actions.DELETE__DATASOURCE, function* () {
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchDataSourcesSaga),
    fork(addDataSourceSaga),
    fork(editDataSourceSaga),
    fork(patchDataSourceSaga),
    fork(deleteDataSourceSaga),
  ]);
}
