import { getBaseReducer } from 'utils';
import actions from './actions';
// import { tabGroupModel as model } from 'models/tabGroup';

const initState = {
  ...(getBaseReducer()), // model
  entityType: 'tabGroups',
};

export default function tabGroupReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_TABGROUPS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_TABGROUPS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_TABGROUPS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
      };
    case actions.SELECT_TABGROUP:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_TABGROUP_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_TABGROUP_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE_TABGROUP:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_TABGROUP_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    default:
      return state;
  }
}
