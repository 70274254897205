import { createSelector } from 'reselect';

export const getUsers = (state) => state.data?.map((user) => {
  const cloneUser = { ...user };
  cloneUser.fullName = `${user.firstName} ${user.lastName}`;
  cloneUser.key = user.id;
  return cloneUser;
});

export const getRoleFilters = (state) => state.roleFilters;
export const getSearchTerm = (state) => state.searchTerm;
export const getUser = (state) => state.user;
export const getActiveAccount = (state) => state.activeAccount;

export const getRolesFromList = createSelector([getUsers], (users) => users?.reduce((acc, u) => {
  u.roles?.forEach((role) => {
    acc[role] = acc[role] + 1 || 1;
  });
  return acc;
}, {}));

export const getUsersList = createSelector(
  [getUsers, getRoleFilters, getSearchTerm],
  (users, roleFilters, searchTerm) => {
    let finalUsers = users;
    
    finalUsers = roleFilters.length > 0
      ? finalUsers.filter((user) => roleFilters.some((rf) => user.roles.includes(rf)))
      : finalUsers;
    finalUsers = searchTerm
      ? finalUsers.filter((user) => {
        const values = [user.firstName, user.lastName, user.email];
        let flag = false;
        values
          .map((v) => v.toLowerCase())
          .forEach((v) => {
            if (v.includes(searchTerm.toLowerCase())) {
              flag = true;
            }
          });
        return flag;
      })
      : finalUsers;
    return finalUsers;
  },
);

export const getStartingTasks = createSelector([getUser], (user = '{}') => {
  let userPreferences = {};
  try {
    userPreferences = JSON.parse(user.preferences);
    return userPreferences?.getStarted || [];
  } catch (err) {
  }
  return [];
});

export const hasEditPermission = createSelector([getActiveAccount], ({ roles = [] } = {}) => {
  if (roles.length) {
    if (['SuperAdmin', 'AccountAdmin'].some((role) => roles.includes(role))) {
      return true;
    }
  }
  return false;
});
