import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';

import api from 'services';
import actions from './actions';
import { transformSchema } from '../../transform/schema';
import platformApi from '../../services/platform';

const entityType = 'mappings';

export function* fetchMappingsSaga() {
  yield takeEvery(actions.FETCH_MAPPINGS, function* () {
    const data = yield call(api.fetchAll, { entityType });
    if (data) {
      yield put({ type: actions.FETCH_MAPPINGS_SUCCESS, data });
    } else {
      yield put({ type: actions.FETCH_MAPPINGS_FAILURE });
    }
  });
}

export function* fetchMetadataSaga() {
  yield takeEvery(actions.FETCH_METADATA, function* () {
    const data = yield call(platformApi.fetchVBQL, { filters: { query: 'DESCRIBE media' } });
    if (data) {
      const metaDataList = (transformSchema(data).find((item) => item.id === 'metadata') || {}).items;
      yield put({ type: actions.FETCH_METADATA_SUCCESS, metaDataList });
    } else {
      yield put({ type: actions.FETCH_METADATA_FAILURE, metaDataList: null });
    }
  });
}

export function* fetchMappingSaga() {
  yield takeEvery(actions.FETCH_MAPPING, function* ({ id }) {
    const data = yield call(api.fetchItem, { entityType, id });
    if (data) {
      yield put({ type: actions.FETCH_MAPPING_SUCCESS, data });
    } else {
      yield put({ type: actions.FETCH_MAPPING_FAILURE });
    }
  });
}

export function* addMappingSaga() {
  yield takeEvery(actions.ADD_MAPPING, function* ({ data, onFinish }) {
    const mapping = yield call(api.createItem, { entityType, data });
    if (mapping?.id) {
      yield put({ type: actions.ADD_MAPPING_SUCCESS, selectedItem: mapping });
    } else {
      yield put({ type: actions.ADD_MAPPING_FAILURE });
    }
    onFinish?.();
  });
}

export function* editMappingSaga() {
  yield takeEvery(actions.EDIT_MAPPING, function* ({ data, id, onFinish }) {
    const mapping = yield call(api.editItem, { entityType, data, id });
    if (mapping?.id) {
      yield put({ type: actions.EDIT_MAPPING_SUCCESS, selectedItem: mapping });
    } else {
      yield put({ type: actions.EDIT_MAPPING_FAILURE });
    }
    onFinish?.();
  });
}

export function* deleteMappingSaga() {
  yield takeEvery(actions.DELETE_MAPPING, function* ({ id }) {
    yield call(api.deleteItem, { entityType, id });
  });
}

export function* importMappingsSaga() {
  yield takeEvery(actions.IMPORT_MAPPINGS, function* ({ data, mappings }) {
    const maps = yield call(api.createArray, { entityType, data, nestedPayload: false });
    if (maps) {
      yield put({ type: actions.IMPORT_MAPPINGS_SUCCESS, imported: null });
      yield put({ type: actions.FETCH_MAPPINGS });
      yield put(actions.fetchMetadata());
    } else {
      yield put({ type: actions.IMPORT_MAPPINGS_FAILURE });
    }
  });
}

export function* importCallDetailsMappingsSaga() {  
  yield takeEvery(actions.IMPORT_CALL_DETAILS_MAPPINGS, function* ({ data }) {
    const responses = yield data.map(d => call(platformApi.platformPostReq, {
        urlPath: 'metadata-mapping',
        data: d,
        entityType: 'mapping'
      }))
    const resArray = yield all(responses);
    if (resArray) {
      yield put(actions.importCallDetailMappingsSuccess());
      yield put(actions.fetchCallDetailsMetadataMappings());
      // yield put(actions.fetchMetadata());
    } else {
      yield put(actions.importCallDetailMappingsFailure());
    }
  });
}

export function* fetchCallDetailsMetadataSaga() {
  yield takeEvery(actions.FETCH_CALL_DETAILS_METADATA, function* () {
    const data = yield call(platformApi.fetchVBQL, { filters: { query: 'DESCRIBE media' } });
    if (data) {
      const callDetailsDataList = (transformSchema(data).find((item) => item.id === "callDetails") || {}).items;
      yield put(actions.fetchCallDetailsMetadataSuccess(callDetailsDataList));
    } else {
      yield put(actions.fetchCallDetailsMetadataFailure());
    }
  });
}

export function* fetchCallDetailsMetadataMappingsSaga() {
  yield takeEvery(actions.FETCH_CALL_DETAILS_METADATA_MAPPINGS, function* () {
    const data = yield call(platformApi.platformGetReq, {
      urlPath: 'metadata-mapping',
      disableNotification: true,
    });
    if (data) {
      yield put(actions.fetchCallDetailsMetadataMappingsSuccess(data.metadataMappings));
    } else {
      yield put(actions.fetchCallDetailsMetadataMappingsFailure([]));
    }
  });
}

export function* addCallDetailMappingSaga() {
  yield takeEvery(actions.ADD_CALL_DETAIL_MAPPING, function* ({ data }) {
    const res = yield call(platformApi.platformPostReq, {
      urlPath: 'metadata-mapping',
      data,
      entityType: 'mapping'
    });
    if (res) {
      yield put(actions.addCallDetailMappingSuccess(res));
      yield put(actions.fetchCallDetailsMetadataMappings());
    } else {
      yield put(actions.addCallDetailMappingFaiure([]));
    }
  });
}

export function* editCallDetailMappingSaga() {
  yield takeEvery(actions.EDIT_CALL_DETAIL_MAPPING, function* ({ data }) {
    const res = yield call(platformApi.platformPutReq, {
      urlPath: `metadata-mapping/${data.mappingId}`,
      entityType: 'mapping',
      data,
    });
    if (res) {
      yield put(actions.editCallDetailMappingSuccess(res));
      yield put(actions.fetchCallDetailsMetadataMappings())
    } else {
      yield put(actions.editCallDetailMappingFaiure([]));
    }
  });
}

export function* deleteCallDetailMappingSaga() {
  yield takeEvery(actions.DELETE_CALL_DETAIL_MAPPING, function* ({ mappingId }) {
    const res = yield call(platformApi.platformDelReq, {
      urlPath: `metadata-mapping/${mappingId}`,
      entityType: 'mapping',
    });
    yield put(actions.deleteCallMappingSuccess(res));
    yield put(actions.fetchCallDetailsMetadataMappings())
  
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchMappingsSaga),
    fork(fetchMappingSaga),
    fork(addMappingSaga),
    fork(editMappingSaga),
    fork(deleteMappingSaga),
    fork(importMappingsSaga),
    fork(fetchMetadataSaga),
    fork(fetchCallDetailsMetadataSaga),
    fork(fetchCallDetailsMetadataMappingsSaga),
    fork(addCallDetailMappingSaga),
    fork(editCallDetailMappingSaga),
    fork(deleteCallDetailMappingSaga),
    fork(importCallDetailsMappingsSaga)
  ]);
}
