import * as Yup from 'yup';
import fields from './fields';
import validations from './validations';

const streamModel = [
  // fields.accountid,
  fields.id,
  fields.name,
  fields.enabled,
  /*
  {
    field: 'type',
    type: 'enum',
    required: true,
    options: [
      {value: 'phone', label: 'Phone'},
      {value: 'other', label: 'Other'},
    ],
    defaultValue: 'phone',
  },
  */
  {
    field: 'key',
    required: true,
  },
  {
    field: 'secret',
    required: true,
  },
  {
    field: 'createdDate',
    type: 'date',
    server: true,
  },
  {
    field: 'updatedDate',
    type: 'date',
    server: true,
    defaultSort: true,
    sortDirection: 'desc',
  },
];

const streamSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .max(255, 'Name is limited to 255 characters')
    .test(...validations.stringWithSpaces)
    .test(...validations.trimSpaces),
});

export { streamModel, streamSchema };
