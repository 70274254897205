/** @prettier */
import { getBaseReducer } from 'utils';
import { accountModel as model } from 'models/account';
import { omit } from 'lodash';
import actions from './actions';
import { VBODashboardConstants, TEMP_TEXT_ID } from '../../screens/VBODashboards/constants';

const initState = {
  ...getBaseReducer(model),
  entityType: 'vboDashboards',
  source: [],
  selectedDashboard: {},
  dashboardCharts: [],
  chartsData: {},
  sidebarEditor: false,
  renameDashboardForm: false,
  isLoading: false,
  cloneToAccount: false,
  downloadDashboardReport: {},
  selectedChart: {},
  chartFullData: {},
  sidebarType: VBODashboardConstants.DASHBOARD_SIDEBAR_TYPE.CHART, // chart || dashboard
  newAddedChart: {},
  loadingEditSidebar: false,
  categoryCollections: [],
  filterData: {},
  dimensionValues: {},
  callsCount: [],
  callsList: [],
  categoryMeasures: {},
  addingNewChart: false,
  editChartType: {},
  callsFilterValue: {},
  updatingVboDashboard: false,
};

export default function vboDashboardReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_VBO_DASHBOARDS:
      return {
        ...state,
        init: false,
        isLoading: true,
      };
    case actions.FETCH_VBO_DASHBOARDS_SUCCESS:
      return {
        ...state,
        init: true,
        data: action.data.sort((a, b) => a.label.localeCompare(b.label)),
        isLoading: false,
      };
    case actions.FETCH_VBO_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        renameDashboardForm: false,
        selectedDashboard: action.data,
      };
    case actions.FETCH_VBO_DASHBOARD_CHARTS_SUCCESS:
      return {
        ...state,
        init: true,
        dashboardCharts: action.data,
      };
    case actions.CLEAR_CHART_DATA:
      return {
        ...state,
        chartsData: {
          ...state.chartsData,
          [action.chartId]: { ...state.chartsData[action.chartId], rows: [], totalRows: 0 },
        },
      };
    case actions.FETCH_VBO_DASHBOARD_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        chartsData: {
          ...state.chartsData,
          [action.data.id]: {
            ...action.data,
            isLoading: false,
          },
        },
      };
    case actions.VBO_DASHBOARD_CHARTS_DATA_LOADING:
      return {
        ...state,
        init: true,
        chartsData: {
          ...state.chartsData,
          [action.data.id]: {
            ...state.chartsData[action.data.id],
            isLoading: action.data.isLoading,
          },
        },
      };
    case actions.TOGGLE_DASHBOARD_EDITOR_SIDEBAR:
      return {
        ...state,
        selectedChart: {},
        chartFullData: {},
        sidebarEditor: action.data,
        sidebarType: action.sidebarType,
      };
    case actions.TOGGLE_RENAME_FORM:
      return {
        ...state,
        renameDashboardForm: !state.renameDashboardForm,
      };
    case actions.TOGGLE_CLONE_TO_ACCOUNT_POPUP:
      return {
        ...state,
        cloneToAccount: !state.cloneToAccount,
      };
    case actions.DOWNLOAD_REPORT:
      return {
        ...state,
        downloadDashboardReport: action.data,
      };
    case actions.CLEAR_DASHBOARD_STATE:
      return {
        ...state,
        downloadDashboardReport: {},
        selectedDashboard: {},
        dashboardCharts: [],
        chartsData: {},
        newAddedChart: {},
        categoryCollections: [],
        // want to retain filter settings between reports.
        // filterData: {},
        categoryMeasures: {},
        callsFilterValue: {},
      };
    case actions.SELECT_DASHBOARD_CHART:
      return {
        ...state,
        selectedChart: action.data,
      };
    case actions.FETCH_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        chartFullData: action.data,
      };
    case actions.ADD_NEW_CHART:
      return {
        ...state,
        addingNewChart: true,
      };
    case actions.ADD_NEW_CHART_SUCCESS:
      return {
        ...state,
        newAddedChart: action.data,
        addingNewChart: false,
      };
    case actions.LOADING_EDIT_SIDEBAR:
      return {
        ...state,
        loadingEditSidebar: action.data,
        chartFullData: action.chartData,
      };
    case actions.FETCH_CATEGORY_COLLECTIONS_SUCCESS:
      return {
        ...state,
        categoryCollections: action.data,
      };
    case actions.FETCH_DASHBOARD_FILTERS_SUCCESS:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          [action.data.filterName]: {
            label: action.data.filterLabel,
            type: action.data.filterType,
            data: action.data.data,
            selectedValue: action.data.selectedValue,
          },
        },
      };
    case actions.CLEAR_FILTER_STATE:
      return {
        ...state,
        filterData: omit(state.filterData, action.key),
      };
    case actions.CHANGE_SELECTED_FILTER:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          [action.filterName]: {
            ...state.filterData[action.filterName],
            selectedValue: action.value,
          },
        },
      };

    case actions.CHANGE_DATE_FILTER_VALUE:
      return {
        ...state,
        dateFilterValue: action.value,

        // callsFilterValue should be reset when date filter is changed
        callsFilterValue: {},
      };

    case actions.CHANGE_CALLS_FILTER_VALUE:
      return {
        ...state,
        callsFilterValue: {
          queryString: action.queryString,
          aggregation: action.aggregation,
          chartId: action.chartId,
        },
      };

    case actions.ADD_TEMP_CHART:
      return {
        ...state,
        dashboardCharts: [...state.dashboardCharts, action.data],
      };
    case actions.CHANGE_TEMP_TEXT_VALUE:
      return {
        ...state,
        textTempValue: action.data,
      };
    case actions.REMOVE_TEMP_CHART:
      return {
        ...state,
        dashboardCharts: state.dashboardCharts.filter((chart) => chart.id !== TEMP_TEXT_ID),
      };
    case actions.GET_CALL_COUNT_SUCCESS:
      return {
        ...state,
        callsCount: action.data,
      };
    case actions.GET_CALLS_LIST_SUCCESS:
      return {
        ...state,
        callsList: action.data,
      };

    case actions.SET_CALLS_COUNT_LOADING:
      return {
        ...state,
        callsFilterValue: { ...state.callsFilterValue, isCallCountLoading: action.data },
      };

    case actions.FETCH_MEASURE_COLLECTION_SUCCESS:
      return {
        ...state,
        categoryMeasures: {
          ...state.categoryMeasures,
          [action.category]: action.data,
        },
      };

    case actions.SET_MY_REPORTS_SELECTED_VIEW_FILTER:
      return {
        ...state,
        viewFilter: action.selectedViewFilter,
      };

    case actions.CLEAR_MY_REPORTS_SELECTED_VIEW_FILTER:
      localStorage.setItem('lastViewedList', JSON.stringify([]));
      return {
        ...state,
        viewFilter: null,
      };

    case actions.FETCH_MEASURES_SUCCESS:
      return {
        ...state,
        measuresFromBackEnd: action.data,
      };

    case actions.FETCH_DIMENSIONS_SUCCESS:
      return {
        ...state,
        dimensionsFromBackEnd: action.data,
      };
    case actions.CHANGE_EDIT_CHART_TYPE:
      return {
        ...state,
        editChartType: action.data,
      };
    case actions.FETCH_DASHBOARD_DIMENSION_VALUES_SUCCESS:
      return {
        ...state,
        dimensionValues: {
          ...state.dimensionValues,
          [action.data.filterName]: {
            label: action.data.filterLabel,
            type: action.data.filterType,
            data: action.data.data,
            selectedValue: action.data.selectedValue,
          },
        },
      };
    case actions.UPLOAD_TEMPLATE_IMAGE:
      return {
        ...state,
      };
    case actions.UPLOAD_TEMPLATE_IMAGE_SUCCESS:
      return {
        ...state,
        recentUploadedImageUrl: action.imageUrl,
      };
    case actions.SET_UPDATING_VBO_DASHBOARD:
      return {
        ...state,
        updatingVboDashboard: action.data,
      };
    default:
      return state;
  }
}
