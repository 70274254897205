const spottingGroupActions = {
  FETCH_SPOTTINGGROUPS: 'FETCH_SPOTTINGGROUPS',
  FETCH_SPOTTINGGROUPS_SUCCESS: 'FETCH_SPOTTINGGROUPS_SUCCESS',
  FETCH_SPOTTINGGROUPS_FAILURE: 'FETCH_SPOTTINGGROUPS_FAILURE',
  SELECT_SPOTTINGGROUP: 'SELECT_SPOTTINGGROUP',
  ADD_SPOTTINGGROUP: 'ADD_SPOTTINGGROUP',
  ADD_SPOTTINGGROUP_SUCCESS: 'ADD_SPOTTINGGROUP_SUCCESS',
  ADD_SPOTTINGGROUP_FAILURE: 'ADD_SPOTTINGGROUP_FAILURE',
  EDIT_SPOTTINGGROUP: 'EDIT_SPOTTINGGROUP',
  EDIT_SPOTTINGGROUP_SUCCESS: 'EDIT_SPOTTINGGROUP_SUCCESS',
  EDIT_SPOTTINGGROUP_FAILURE: 'EDIT_SPOTTINGGROUP_FAILURE',
  PATCH_SPOTTINGGROUP: 'PATCH_WAREHOUSE',
  GET_SPOTTINGGROUP: 'GET_SPOTTINGGROUP',
  DELETE__SPOTTINGGROUP: 'DELETE__SPOTTINGGROUP',
  CHANGE_SPOTTINGGROUP: 'CHANGE_SPOTTINGGROUP',
  CHANGE_SPOTTINGGROUP_VIEW: 'CHANGE_SPOTTINGGROUP_VIEW',
  CLEAR_SPOTTINGGROUPS: 'CLEAR_SPOTTINGGROUPS',
  SORT_SPOTTINGGROUPS: 'SORT_SPOTTINGGROUPS',
  sortSpottingGroups: (e) => ({
    type: spottingGroupActions.SORT_SPOTTINGGROUPS,
    sort: e.sort,
  }),
  clearSpottingGroups: (options) => ({
    type: spottingGroupActions.CLEAR_SPOTTINGGROUPS,
  }),
  fetchSpottingGroups: () => ({
    type: spottingGroupActions.FETCH_SPOTTINGGROUPS,
  }),
  selectSpottingGroup: (selectedItem) => ({
    type: spottingGroupActions.SELECT_SPOTTINGGROUP,
    selectedItem,
  }),
  addSpottingGroup: (data, onFinish) => ({
    type: spottingGroupActions.ADD_SPOTTINGGROUP,
    data,
    onFinish,
  }),
  editSpottingGroup: (data, id, onFinish) => ({
    type: spottingGroupActions.EDIT_SPOTTINGGROUP,
    data,
    id,
    onFinish,
  }),
  patchSpottingGroup: (data) => ({
    type: spottingGroupActions.PATCH_SPOTTINGGROUP,
    data,
  }),
  getSpottingGroup: (data) => ({
    type: spottingGroupActions.GET_SPOTTINGGROUP,
    data,
  }),
  deleteSpottingGroup: (id) => ({
    type: spottingGroupActions.DELETE__SPOTTINGGROUP,
    id,
  }),
  changeSpottingGroupView: (selectedItem) => ({
    type: spottingGroupActions.CHANGE_SPOTTINGGROUP_VIEW,
    selectedItem,
  }),
};
export default spottingGroupActions;
