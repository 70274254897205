import React from 'react';
import styled from 'styled-components';
import LoadingGif from '../../assets/images/Loading.gif';

const Loading = styled.div`
  text-align: center;
  margin-bottom: 20px;
  padding: 30px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default ({ children, size = 100, ...props }) => (
  <Loading {...props}>
    <img src={LoadingGif} alt="Loading..." style={{ width: size }} />
    <div>{children}</div>
  </Loading>
);
