import reduxUtils from '../reduxUtils';

const tabActions = {
  ...(reduxUtils.getActions('tabs')),
  ADD_TAB_FAILURE: 'ADD_TAB_FAILURE',
  DELETE_TAB_FAILURE: 'DELETE_TAB_FAILURE',
  DELETE_TAB_SUCCESS: 'DELETE_TAB_SUCCESS',
  SET_SELECT_LAST: 'SET_SELECT_LAST',
  EDIT_TAB_FAILURE: 'EDIT_TAB_FAILURE',
  deleteTabSuccess: (id) => ({
    type: tabActions.DELETE_TAB_SUCCESS,
    id,
  }),
  deleteTabFailure: () => ({
    type: tabActions.DELETE_TAB_FAILURE,
  }),
  setSelectLast: (data) => ({
    type: tabActions.SET_SELECT_LAST,
    data,
  }),
  addTabFailure: () => ({
    type: tabActions.ADD_TAB_FAILURE,
  }),
  editTabFailure: () => ({
    type: tabActions.EDIT_TAB_FAILURE,
  }),
};

export default tabActions;
