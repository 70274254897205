/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-expressions */
import {
  all, fork, call, put, takeEvery, select,
} from 'redux-saga/effects';
import api from 'services';
import {
  fetchMonthAgentCallAvoidanceSuccess,
  fetchMonthAgentCallAvoidanceFailure,
  fetchTrackingSilenceSuccess,
  fetchTrackingSilenceFailure,
  fetchAgentsDataSuccess,
  fetchAgentsDataFailure,
  fetchCallDriversDataSuccess,
  fetchTeamsSuccess,
  fetchTeamsFailure,
  fetchCallsSuccess,
} from './actions';
import {
  FETCH_AGENTS_DATA,
  FETCH_CALLS,
  FETCH_CALL_DRIVERS_DATA,
  FETCH_MONTH_AGENT_CALL_AVOIDANCE,
  FETCH_TEAMS,
  FETCH_TRACKING_SILENCE,
} from './constants';

export function* fetchMonthAgentCallAvoidanceSaga() {
  yield takeEvery(FETCH_MONTH_AGENT_CALL_AVOIDANCE, function* monthAgentCall({
    startDate,
    endDate,
    agentSupervisorId,
  }) {
    const res = yield call(api.get, {
      url: `scores/time-intervals?startDate=${startDate}&endDate=${endDate}&timeInterval=month${
        agentSupervisorId ? `&agentSupervisorId=${agentSupervisorId}` : ''
      }`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put(fetchMonthAgentCallAvoidanceSuccess(res.data));
    } else {
      yield put(fetchMonthAgentCallAvoidanceFailure());
    }
  });
}

export function* fetchTrackingSilenceSaga() {
  yield takeEvery(FETCH_TRACKING_SILENCE, function* fetchTrackingSilenceCall({
    startDate,
    endDate,
    agentSupervisorId,
  }) {
    const res = yield call(api.get, {
      url: `scores/time-intervals?startDate=${startDate}&endDate=${endDate}&timeInterval=day${
        agentSupervisorId ? `&agentSupervisorId=${agentSupervisorId}` : ''
      }`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put(fetchTrackingSilenceSuccess(res.data));
    } else {
      yield put(fetchTrackingSilenceFailure());
    }
  });
}

export function* fetchAgentsDataSaga() {
  yield takeEvery(FETCH_AGENTS_DATA, function* fetchAgentsData({
    startDate,
    endDate,
    agentSupervisorId,
  }) {
    const res = yield call(api.get, {
      url: `scores/agents?startDate=${startDate}&endDate=${endDate}${
        agentSupervisorId ? `&agentSupervisorId=${agentSupervisorId}` : ''
      }`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put(fetchAgentsDataSuccess(res.data));
    } else {
      yield put(fetchAgentsDataFailure());
    }
  });
}

export function* fetchCallDriversSaga() {
  yield takeEvery(FETCH_CALL_DRIVERS_DATA, function* fetchCallDrivers({
    startDate,
    endDate,
    agentSupervisorId,
  }) {
    const res = yield call(api.get, {
      url: `scores/call-drivers?startDate=${startDate}&endDate=${endDate}${
        agentSupervisorId ? `&agentSupervisorId=${agentSupervisorId}` : ''
      }`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put(fetchCallDriversDataSuccess(res.data));
    }
  });
}

export function* fetchTeamsSaga() {
  yield takeEvery(FETCH_TEAMS, function* fetchTeams({}) {
    const res = yield call(api.get, {
      url: 'score-filters?type=agentSupervisor',
      disableNotification: true,
    });
    if (res?.data) {
      const teams = res.data;
      yield put(fetchTeamsSuccess(teams));
    } else {
      yield put(fetchTeamsFailure());
    }
  });
}
export function* fetchCallsSaga() {
  yield takeEvery(FETCH_CALLS, function* fetchCalls({
    startDate,
    endDate,
    agentSupervisorId,
    from = 0,
  }) {
    const agents = yield select((state) => state.agentCallAvoidance.filters.agents);
    let url = `scores/calls?&startDate=${startDate}&endDate=${endDate}${
      agentSupervisorId ? `&agentSupervisorId=${agentSupervisorId}` : ''
    }`;
    if (agents.length) {
      url += `&agentIds=${agents.join(',')}`;
    }
    url += `&size=100&from=${from}`;
    const res = yield call(api.get, {
      url,
      disableNotification: true,
    });
    if (res?.data) {
      yield put(fetchCallsSuccess(res.data));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchMonthAgentCallAvoidanceSaga),
    fork(fetchTrackingSilenceSaga),
    fork(fetchAgentsDataSaga),
    fork(fetchCallDriversSaga),
    fork(fetchTeamsSaga),
    fork(fetchCallsSaga),
  ]);
}
