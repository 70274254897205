import { getBaseReducer } from 'utils';
import { connectionModel as model } from 'models/connection';
import { uploadWizardOptions } from 'screens/Connections/ConnectionsConstants';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  bulkUploads: { // TODO: might be better to have a separate bulk uploads saga
    ...(getBaseReducer(model)),
    totalItems: 0,
    shownItems: 0,
  },
  entityType: 'connections',
  vendorsUsed: [],
  pciRedaction: {
    show: false,
  },

};
const vendorsCanHaveMany = ['CUSTOM'];
const canHaveMany = (x) => vendorsCanHaveMany.indexOf(x) === -1;

export default function connectionReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_CONNECTIONS:
      return {
        ...state,
        data: [],
        bulkUploads: initState.bulkUploads,
        vendorsUsed: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_CONNECTIONS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_CONNECTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        vendorsUsed: action.vendorsUsed.filter(canHaveMany),
        init: true,
      };
    case actions.SELECT_CONNECTION:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_CONNECTION_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        vendorsUsed: [
          action.selectedItem.vendor,
          ...state.vendorsUsed,
        ].filter(canHaveMany),
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_CONNECTION_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__CONNECTION:
      return {
        ...state,
        data: action.data.filter((x) => x),
        vendorsUsed: action.data.filter((x) => x).map((x) => x.vendor).filter(canHaveMany),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_CONNECTION_VIEW_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
        s3ConfigErrors: { message: "" }
      };
    case actions.TOGGLE_PCI_REDACTION_MODAL:
      return {
        ...state,
        pciRedaction: {
          ...state.pciRedaction,
          show: action.bool,
        },
      };
    case actions.FETCH_BULKUPLOADS_SUCCESS:
      return {
        ...state,
        bulkUploads: {
          ...state.bulkUploads,
          data: action.data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)),
          init: true,
          totalItems: action.data.length,
          shownItems: uploadWizardOptions.defaultListCount,
        },
      };
    case actions.LOADMORE_BULKUPLOADS:
      return {
        ...state,
        bulkUploads: {
          ...state.bulkUploads,
          shownItems: action.data,
        },
      };

    default:
      return state;
  }
}
