/* istanbul ignore file */
import React, { Component } from 'react';
class Icon extends Component {
  getImage(name) {
    name = name.toLowerCase();
    switch (name) {
      case 'vb_default':
      case 'voicebase': return require('../../assets/images/logo/vb_logo_small.svg');
      case 'user': return require('../../assets/images/User.png');
      case 'user_white': return require('../../assets/images/User-white.png');
      case 'envelope': return require('../../assets/images/Envelope.png');
      case 'phone': return require('../../assets/images/Phone.png');
      case 'import_user': return require('../../assets/images/ImportUser.png');
      case 'magnifying_glass': return require('../../assets/images/MagnifyingGlass.png');
      case 'hard_drives': return require('../../assets/images/icons/HardDrives.svg');
      case 'magnifying_glass': return require('../../assets/images/MagnifyingGlass.png');
      default:
        return null;
    }
  }

  render() {
    const { name, style, className } = this.props;
    if (!name) { return null; }
    return <img src={this.getImage(name)} className={`icon ${className}`} style={style} alt="name" />;
  }
}

export default Icon;
