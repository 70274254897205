import { userModel as model } from 'models/user';
import { getBaseReducer } from 'utils';
import actions from './actions';

const initState = {
  ...getBaseReducer(model),
  entityType: 'users',
  errors: null,
  roleFilters: [],
  data: [],
  searchTerm: '',
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_USERS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_USERS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
      };
    case actions.SELECT_USER:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_USER_SUCCESS:
      return {
        ...state,
        data: [action.selectedItem, ...state.data],
        selectedItem: action.selectedItem,
        view: false,
        errors: null,
      };
    case actions.ADD_USER_ERRORS:
      return {
        ...state,
        errors: action.err,
      };
    case actions.EDIT_USER_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__USER:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_USER_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
        errors: null,
      };
    case actions.FILTER_USERS:
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    case actions.TOGGLE_ROLE_FILTERS:
      return {
        ...state,
        roleFilters: (function applyFilter() {
          const exists = state.roleFilters.includes(action.role);
          if (exists) {
            return state.roleFilters.filter((r) => r !== action.role);
          }
          return [...state.roleFilters, action.role];
        }()),
      };
    default:
      return state;
  }
}
