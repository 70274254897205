import {
  ADD_FILTER,
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  GET_AGENTS_STATUS,
  GET_AGENTS_STATUS_FAILURE,
  GET_AGENTS_STATUS_SUCCESS,
  UPDATE_AGENT,
  CLEAR_AGENT_STATUS,
} from './constants';

const initState = {
  loading: false,
  agents: [],
  agentsStatus: null,
  campaigns: [],
  filters: {
    name: '',
    userStatus: '',
  },
};

export default function statusReducer(state = initState, action) {
  switch (action.type) {
    case CLEAR_AGENT_STATUS:
      return {
        ...initState,
      };
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
        agents: [],
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.data.filter((user) => user.roles.includes('AccountAgent')),
      };

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        agents: [],
      };
    case ADD_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.filter },
      };
    case UPDATE_AGENT:
      return {
        ...state,
        agentsStatus: { ...state.agentsStatus, ...action.agent },
      };
    case GET_AGENTS_STATUS:
      return {
        ...state,
      };
    case GET_AGENTS_STATUS_SUCCESS:
      return {
        ...state,
        agentsStatus: action.data,
      };
    case GET_AGENTS_STATUS_FAILURE:
      return {
        ...state,
        agentsStatus: [],
      };
    case FETCH_CAMPAIGNS:
      return {
        ...state,
      };
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.data,
      };
    case FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaigns: [],
      };
    default:
      return state;
  }
}
