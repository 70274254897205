import { getBaseReducer } from 'utils';
import model from 'models/collection';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  entityType: 'collections',
  selectedItem: null,
};

export default function collectionReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_COLLECTIONS:
      return {
        ...state,
        data: [],
        selectedItem: null,
        init: false,
      };
    case actions.SORT_COLLECTIONS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_COLLECTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true,
      };
    case actions.SELECT_COLLECTION:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_COLLECTION_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_COLLECTION_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__COLLECTION:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_COLLECTION_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    default:
      return state;
  }
}
