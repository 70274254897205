/** @prettier */
import _ from 'lodash';
import { nestChildren } from 'utils';

const expandHierarchy = (result, x) => {
  const hierarchy = x.name.split(':');
  let currentId = '';
  let currentName = '';
  let parentId = '';
  hierarchy.forEach((h, i) => {
    parentId = currentId;
    currentId = i === 0 ? h : `${currentId}:${h}`;
    currentName = i === 0 ? h : `${currentName} / ${h}`;
    result.push({
      id: currentId,
      parent: parentId,
      hasContent: i === hierarchy.length - 1,
      level: i,
      name: currentName,
      text: h,
      // expanded: true,
      type: x.type, // @TODO: fix type nesting
    });
  });
  return result;
};

export const transformSchema = (data) => {
  if (!data) return [];
  const expanded = data.reduce((acc, curr) => expandHierarchy(acc, curr), []);
  const cleaned = _(expanded).uniqBy('id').orderBy('id').value();
  return nestChildren(cleaned, '', 'items');
};

export const getSchemaOptions = (data = []) => data.map((d) => d.name);
