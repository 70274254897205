import {
  all, takeEvery, fork, call, put, select, takeLatest,
} from 'redux-saga/effects';
import api from 'services';
import { transformChecklist, prepChecklist, sanitizeTempData } from 'transform/checklist';
import { cloneDeep } from 'lodash';
import { rePosition } from 'utils';
import checklistItemActions from 'redux/checklistItems/actions';
import actions from './actions';

const entityType = 'checklists';

export function* fetchChecklistsSaga() {
  yield takeLatest(actions.FETCH_CHECKLISTS, function* () {
    const data = yield call(api.fetchAll, {
      entityType,
    });
    if (data) {
      yield put({ type: actions.FETCH_CHECKLISTS_SUCCESS, data });
    }
  });
}

export function* fetchChecklistSaga() {
  yield takeEvery(actions.FETCH_CHECKLIST, function* ({ id }) {
    const data = yield call(api.fetchItem, {
      entityType,
      transform: transformChecklist,
      id,
    });
    if (data) {
      yield put({ type: actions.FETCH_CHECKLIST_SUCCESS, data });
    }
  });
}

export function* addChecklistSaga() {
  yield takeEvery(actions.ADD_CHECKLIST, function* ({ data, history }) {
    const checklist = yield call(api.createItem, {
      entityType,
      data,
    });
    if (checklist.id) {
      yield put({
        type: actions.ADD_CHECKLIST_SUCCESS,
        selectedItem: transformChecklist(checklist),
      });
      history.push(`/app/realtime/${checklist.id}`);
      yield put({ type: actions.FETCH_CHECKLISTS });
    }
  });
}

export function* saveChecklistSettingsSaga() {
  yield takeEvery(actions.SAVE_SETTINGS, function* ({
    checklist,
  }) {
    const res = yield call(api.put, {
      url: `checklists/${checklist.id}/settings`,
      data: sanitizeTempData(checklist),
      msg: 'Settings saved successfully',
    });
    if (res.data) {
      yield put(actions.saveSettingsSuccess(res.data));
      yield put({ type: actions.FETCH_CHECKLISTS });
    }
  });
}

export function* editChecklistSaga() {
  yield takeEvery(actions.EDIT_CHECKLIST, function* ({
    data: oldData,
    id,
    history,
    onFinish,
    navigate = true,
  }) {
    const data = sanitizeTempData(oldData);
    const checklist = yield call(api.editItem, {
      entityType,
      data: prepChecklist(data),
      id,
    });
    if (checklist) {
      yield put({
        type: actions.EDIT_CHECKLIST_SUCCESS,
        selectedItem: checklist,
      });
      yield put({ type: actions.FETCH_CHECKLISTS });
      navigate && history.push('/app/realtime');
      return;
    }
    onFinish();
  });
}

export function* updateChecklistItemsSaga() {
  yield takeEvery(actions.UPDATE_CHECKLIST_ITEMS, function* ({
    items,
    updateType: type,
  }) {
    const state = yield select();
    const checklist = state.Checklists.selectedItem;
    if (type === 'items') checklist.items = items;
    if (type === 'alerts') checklist.alerts = items;
    const data = yield call(api.editItem, {
      entityType,
      data: checklist,
      id: checklist.id,
    });
    yield put(
      data
        ? actions.updateChecklistItemsSuccess(data)
        : actions.updateChecklistItemsFailure(),
    );
  });
}

export function* deleteChecklistSaga() {
  yield takeEvery(actions.DELETE_CHECKLIST, function* ({ id }) {
    yield call(api.deleteItem, { entityType, id });
  });
}

export function* deleteChecklistItemSaga() {
  yield takeEvery(actions.DELETE_CHECKLIST_ITEM, function* ({ id }) {
    const res = yield call(api.deleteItem, {
      entityType: 'checklist-items',
      id,
    });
    yield put(
      res.status === 200
        ? actions.deleteChecklistItemSuccess(res)
        : actions.deleteChecklistItemFailure(),
    );
  });
}

export function* addChecklistAlertSaga() {
  yield takeEvery(actions.ADD_ALERT, function* ({ data }) {
    if (data.id === 'aTempID') {
      delete data.id;
    }
    const checklistAlert = yield call(api.createItem, {
      entityType: 'checklist-alerts',
      data,
    });
    if (checklistAlert) {
      yield put({
        type: actions.ADD_ALERT_SUCCESS,
        selectedAlert: checklistAlert,
      });
      yield put({ type: actions.FETCH_CHECKLIST, id: data.checklistId });
    }
  });
}

export function* addPopupSaga() {
  yield takeEvery(actions.ADD_POPUP, function* ({ data, isGlobal = false }) {
    let newData = { ...data };
    if (!isGlobal) {
      const {
        checklistId, id, categoryName, ...rest
      } = newData;
      newData = rest;
      newData.position = 1;
    } else {
      delete newData.id;
    }

    const checklistPopup = yield call(api.createItem, {
      entityType: isGlobal ? 'checklist-global-popups' : 'checklist-popups',
      data: newData,
    });

    if (checklistPopup) {
      yield put({
        type: actions.ADD_POPUP_SUCCESS,
        selectedPopup: checklistPopup,
        expandItemID: newData.checklistItemId,
        isGlobal,
      });
      yield put({ type: actions.FETCH_CHECKLIST, id: data.checklistId });
    }
  });
}

export function* updatePopupSaga() {
  yield takeEvery(actions.UPDATE_POPUP, function* ({ data, isGlobal }) {
    const { checklistId, ...rest } = data;
    const checklistPopup = yield call(api.editItem, {
      entityType: isGlobal ? 'checklist-global-popups' : 'checklist-popups',
      data: isGlobal ? data : rest,
      id: data.id,
    });
    if (checklistPopup) {
      yield put({
        type: actions.UPDATE_POPUP_SUCCESS,
        selectedPopup: checklistPopup,
        expandItemID: data.checklistItemId,
        isGlobal,
      });
      yield put({ type: actions.FETCH_CHECKLIST, id: data.checklistId });
    }
  });
}

export function* deleteAlertSaga() {
  yield takeEvery(actions.DELETE_ALERT, function* ({ id }) {
    const res = yield call(api.deleteItem, {
      entityType: 'checklist-alerts',
      id,
    });
    yield put(
      res.status === 200
        ? actions.deleteAlertSuccess(res)
        : actions.deleteAlertFailure(),
    );
  });
}

export function* deletePopupSaga() {
  yield takeEvery(actions.DELETE_POPUP, function* ({ id, isGlobal }) {
    const res = yield call(api.deleteItem, { entityType: isGlobal ? 'checklist-global-popups' : 'checklist-popups', id });
    yield put(
      res.status === 200
        ? actions.deletePopupSuccess(res)
        : actions.deletePopupFailure(),
    );
  });
}

export function* updateAlertSaga() {
  yield takeEvery(actions.UPDATE_ALERT, function* ({ data }) {
    const alertItem = yield call(api.editItem, {
      entityType: 'checklist-alerts',
      data,
      id: data.id,
    });
    yield put({ type: actions.UPDATE_ALERT_SUCCESS, selectedAlert: alertItem });
    yield put({ type: actions.FETCH_CHECKLIST, id: alertItem.checklistId });
  });
}

export function* fixServerPositioningSaga() {
  yield takeEvery(actions.SELECT_CHECKLIST, function* ({ selectedItem }) {
    if (selectedItem) {
      const clone = cloneDeep(selectedItem);
      clone.items = rePosition(clone.items);
      clone.alerts = rePosition(clone.alerts);
      const checklist = yield call(api.editItem, {
        entityType,
        data: prepChecklist(clone),
        id: clone.id,
        disableNotification: true,
      });
      if (checklist) {
        yield put({ type: actions.FIX_SERVER_POSITIONING_SUCCESS, checklist });
      }
    }
  });
}

export function* createTempPopupSaga() {
  yield takeEvery(checklistItemActions.CREATE_TEMP_POPUP, function* createTempPopup({ selectedChecklistItem }) {
    const selectedChecklist = yield select((state) => state.Checklists.selectedItem);
    const tempPopup = {
      id: 'tempID',
      name: 'New Popup',
      requireAll: true,
      type: 'SOME HARDCODED TYPES',
      categories: [],
      checklistItemId: selectedChecklistItem?.id,
    };
    const newselectedItem = {
      ...selectedChecklist,
      items: selectedChecklist.items.map((item) => ({
        ...item,
        expanded: item.id === selectedChecklistItem.id,
        popups: [
          ...(item.id === selectedChecklistItem.id ? [tempPopup] : []),
          ...((item.popups || []).filter((p) => p.id !== 'tempID')),
        ],
        children: [
          ...(item.id === selectedChecklistItem.id ? [tempPopup] : []),
          ...((item.popups || []).filter((p) => p.id !== 'tempID')),
        ],
      })),
    };

    yield put(actions.updateSelectedChecklist(newselectedItem));
  });
}

export function* duplicateChecklistSaga() {
  yield takeEvery(actions.DUPLICATE_CHECKLIST, function* ({ checklistId }) {
    const checklist = yield call(api.post, {
      url: 'checklists/clone',
      data: { id: checklistId },
      msg: 'Checklist duplicated successfully',
    });
    if (checklist) {
      yield put({
        type: actions.DUPLICATE_CHECKLIST_SUCCESS,
      });
      yield put({ type: actions.FETCH_CHECKLISTS });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchChecklistsSaga),
    fork(fetchChecklistSaga),
    fork(addChecklistSaga),
    fork(editChecklistSaga),
    fork(deleteChecklistSaga),
    fork(updateChecklistItemsSaga),
    fork(deleteChecklistItemSaga),
    fork(addChecklistAlertSaga),
    fork(deleteAlertSaga),
    fork(updateAlertSaga),
    fork(fixServerPositioningSaga),
    fork(deletePopupSaga),
    fork(addPopupSaga),
    fork(updatePopupSaga),
    fork(createTempPopupSaga),
    fork(saveChecklistSettingsSaga),
    fork(duplicateChecklistSaga),
  ]);
}
