import {
  all, takeEvery, fork, call, put, select,
} from 'redux-saga/effects';
import _ from 'lodash';

import api from 'services';
import { transformTabGroups } from 'transform/tabGroup';
import { Tab } from 'models/tabs';
import { TabGroup } from 'models/tabGroups';
import categoryActions from '../categories/actions';
import tabGroupActions from '../tabGroups/actions';
import actions from './actions';
import { getUniqueName } from '../../utils';
import interactionActions from '../interactions/actions';

const entityType = 'tabs';

function* fetchTabs() {
  const state = yield select();
  const transfer = _.get(state, 'Categories.workbench');
  const tabGroups = yield call(api.fetchAll, { entityType: 'tabGroups', transform: transformTabGroups });
  if (tabGroups) {
    if (tabGroups.length === 0) {
      // EA-2165
      // SuperAdmins need the vb account to create this first tabGroup
      const isAdmin = _.get(state, 'Profile.hasAdminPrivileges');
      let activeAccountIdOverride = null;
      if (isAdmin) {
        activeAccountIdOverride = _.get(state, 'Accounts.data', []).find((i) => i.rootAccount)?.value;
      }
      const newTabGroup = yield call(api.createItem, {
        entityType: 'tabGroups',
        ...(activeAccountIdOverride != null && { activeAccountIdOverride }),
        data: new TabGroup(),
        disableNotification: true,
      });
      newTabGroup.tabs = [];
      if (newTabGroup) tabGroups.push(newTabGroup);
    }

    const tabGroup = _.get(tabGroups, '[0]', {});
    const tabGroupId = _.get(tabGroup, 'id');
    const data = _.get(tabGroup, 'tabs', []);
    

    if (data.length === 0 && !transfer) {
      // Create new tabs for the first time
      const newTab = yield call(api.createItem, {
        entityType,
        data: new Tab(tabGroupId),
      });
      if (newTab) data.push(newTab);
    }
    if (data && !transfer) {
      yield put({ type: tabGroupActions.FETCH_TABGROUPS_SUCCESS, data: tabGroups });
      yield put({ type: actions.FETCH_TABS_SUCCESS, data });
      yield put(interactionActions.resetInteractionInstances())
    }
    return data;
  }
}

export function* fetchTabsSaga() {
  yield takeEvery(actions.FETCH_TABS, function* () {
    yield call(fetchTabs);
  });
}

export function* openCategoryInWorkbenchSaga() {
  yield takeEvery(categoryActions.OPEN_CATEGORY_IN_WORKBENCH, function* ({ category }) {
    const state = yield select();
    const init = _.get(state, 'tabs.init');
    const data = !init ? yield call(fetchTabs) : _.get(state, 'tabs.data');
    const tabGroupId = _.get(data, '[0].tabGroupId');
    const position = Math.max(...data.map((t) => t.position)) + 1;
    const { name, content } = category;
    const uniqueName = getUniqueName(data, name);
    const transferTab = yield call(api.createItem, {
      entityType,
      data: new Tab(tabGroupId, uniqueName, content, position),
      disableNotification: true,
    });
    yield put({ type: categoryActions.CLEAR_CATEGORY_IN_WORKBENCH });
    if (transferTab && data) {
      data.push(transferTab);
      yield put({ type: actions.FETCH_TABS_SUCCESS, data });
      yield put({ type: actions.SET_SELECT_LAST, data: true });
    }
  });
}

export function* addTabSaga() {
  yield takeEvery(actions.ADD_TAB, function* ({ data }) {
    const tab = yield call(api.createItem, { entityType, data });
    if (tab) {
      yield put({ type: actions.ADD_TAB_SUCCESS, selectedItem: tab });
      // yield put({ type: actions.FETCH_TABS });
    } else {
      yield put(actions.addTabFailure());
    }
  });
}

export function* patchTabSaga() {
  yield takeEvery(actions.PATCH_TAB, function* ({ data, id }) {
    const tab = yield call(api.patchItem, { entityType, data, id });
    yield put({ type: actions.EDIT_TAB_SUCCESS, selectedItem: tab });
    // yield put({ type: actions.FETCH_TABS });
  });
}

export function* editTabSaga() {
  yield takeEvery(actions.EDIT_TAB, function* ({ data, id }) {
    const tab = yield call(api.editItem, {
      entityType, data, id, disableNotification: true,
    });
    yield put({ type: actions.EDIT_TAB_SUCCESS, selectedItem: tab });
    // yield put({ type: actions.FETCH_TABS });
  });
}

export function* deleteTabSaga() {
  yield takeEvery(actions.DELETE_TAB, function* ({ id }) {
    const res = yield call(api.deleteItem, { entityType, id });
    if (res?.status === 200) {
      yield put(actions.deleteTabSuccess(id));
    } else {
      yield put(actions.deleteTabFailure());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchTabsSaga),
    fork(addTabSaga),
    fork(openCategoryInWorkbenchSaga),
    fork(editTabSaga),
    fork(patchTabSaga),
    fork(deleteTabSaga),
  ]);
}
