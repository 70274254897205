import {
  all,
  takeEvery,
  fork,
  call,
  put,
  take,
  select,
} from 'redux-saga/effects';
import api from 'services';
import _ from 'lodash';
import platformApi from 'services/platform';

import actions from './actions';

export function* getFlaggedMediaSaga() {
  yield takeEvery(actions.FETCH_FLAGGED_MEDIA, function* ({ lng, accountID }) {
    const flaggedMedia = yield call(platformApi.platformGetReq, {
      urlPath: 'audit',
      disableNotification: true,
    });
    if (flaggedMedia) {
      yield put({
        type: actions.FETCH_FLAGGED_MEDIA_SUCCESS,
        data: flaggedMedia.events,
      });
    } else {
      yield put({ type: actions.FETCH_FLAGGED_MEDIA_FAILURE });
    }
  });
}

//   export function* getPredictiveModelSaga() {
//     yield takeEvery(actions.GET_PREDICTIVE_MODEL, function* ({accountID}) {
//       const predictiveModels = yield call(platformApi.platformGetReq, { urlPath: 'definition/prediction/classifiers', activeAccountIdOverride: accountID, disableNotification: true });
//       if (predictiveModels) {
//         yield put({ type: actions.GET_PREDICTIVE_MODEL_SUCCESS, data: predictiveModels.classifiers });
//       } else {
//         yield put({ type: actions.GET_PREDICTIVE_MODEL_FAILURE });
//       }
//     });
//   }

export default function* rootSaga() {
  yield all([fork(getFlaggedMediaSaga)]);
}
