/* istanbul ignore file */
import themedefault from './themedefault';
import dark from './dark';
import lightTheme from './light';

const themes = {
  themedefault,
  dark,
  light: lightTheme,
};
export default themes;
