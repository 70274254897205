import React from 'react';
import { Helmet } from 'react-helmet';
import settings from '../../settings/api'

export default ({ version, date, env }) =>
(
  <Helmet>
    {settings.googleAnalytics.apiKey && <script async src={`https://www.googletagmanager.com/gtag/js?id=${settings.googleAnalytics.apiKey}`} />}
    <script type="module" async src={`${settings.tableau.eaUrl}/javascripts/api/tableau.embedding.3.latest.min.js`} ></script>
    <meta name="version" content={version} />
    <meta name="date" content={date} />
    <meta name="environment" content={env} />
  </Helmet>
)
  ;
