/** @prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import { showToast } from 'utils/toast';
import interactionsActions from 'redux/interactions/actions';
import api from 'services';
import platformApi from 'services/platform';
import {
  ADD_SEARCH,
  DELETE_SEARCH,
  EDIT_SEARCH,
  GET_SEARCH_LIST,
  SEARCH_CALLS,
  SEARCH_CALLS_FAILURE,
  SEARCH_CALLS_SUCCESS,
} from './constants';
import {
  addSearchFailure,
  addSearchSuccess,
  deleteSearchFailure,
  deleteSearchSuccess,
  editSearchFailure,
  editSearchSuccess,
  getSearchList,
  getSearchListFailure,
  getSearchListSuccess,
} from './actions';

export function* getSearchListSaga() {
  yield takeEvery(GET_SEARCH_LIST, function* () {
    const res = yield call(api.get, {
      url: 'call-search',
      disableNotification: true,
    });
    if (res?.status === 200) {
      yield put(getSearchListSuccess(res.data));
    } else {
      yield put(getSearchListFailure());
    }
  });
}

export function* addSearchSaga() {
  yield takeEvery(ADD_SEARCH, function* ({ data }) {
    delete data.id;
    const res = yield call(api.post, {
      url: 'call-search',
      data,
      msg: 'search saved successfully',
    });
    if (res?.status === 200) {
      yield put(addSearchSuccess(res.data));
      yield put(getSearchList());
    } else {
      yield put(addSearchFailure());
    }
  });
}

export function* editSearchSaga() {
  yield takeEvery(EDIT_SEARCH, function* ({ data }) {
    const res = yield call(api.put, {
      url: `call-search/${data.id}`,
      msg: 'Search edited successfully',
      data,
    });
    if (res?.status === 200) {
      yield put(editSearchSuccess(data));
      yield put(getSearchList());
    } else {
      yield put(editSearchFailure());
    }
  });
}

export function* deleteSearchSaga() {
  yield takeEvery(DELETE_SEARCH, function* ({ data: id }) {
    const res = yield call(api.delete, {
      url: `call-search/${id}`,
      msg: 'search deleted successfully',
    });
    if (res?.status === 200) {
      yield put(deleteSearchSuccess());
      yield put(getSearchList());
    } else {
      yield put(deleteSearchFailure());
    }
  });
}

export function* searchCallsSaga() {
  yield takeEvery(SEARCH_CALLS, function* ({ query, onFinish }) {
    if (query) {
      try {
        const results = yield call(platformApi.fetchVBQL, {
          filters: {
            // query: 'SELECT * FROM media',
            query,
          },
          path: 'data',
        });
        if (results) {
          let data = results.rows || [];
          const total = get(results, 'totalResults', 0);
          data = data.map((c) => {
            c.agent = c.callDetails?.agent?.fullName;
            return c;
          });
          data.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));

          if (!data.length) {
            showToast('success', { task: 'No results found' });
          }

          yield put({
            type: SEARCH_CALLS_SUCCESS,
            data: data || [],
            total,
          });
          yield put({
            type: interactionsActions.FETCH_INTERACTIONS_SUCCESS,
            data: data || [],
            total,
          });
          onFinish();
          // yield put(setCollapsedQueries(true));
        } else {
          yield put({ type: SEARCH_CALLS_FAILURE });
        }
      } catch {
        showToast('error', { task: "Couldn't load results" });
        yield put({ type: SEARCH_CALLS_FAILURE });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getSearchListSaga),
    fork(addSearchSaga),
    fork(editSearchSaga),
    fork(deleteSearchSaga),
    fork(searchCallsSaga),
  ]);
}
