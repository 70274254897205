/* eslint-disable linebreak-style */
const categoryLibraryActions = {
  FETCH_CATEGORY_LIBRARY: 'FETCH_CATEGORY_LIBRARY',
  FETCH_CATEGORY_LIBRARY_SUCCESS: 'FETCH_CATEGORY_LIBRARY_SUCCESS',
  FETCH_CATEGORY_LIBRARY_FAILURE: 'FETCH_CATEGORY_LIBRARY_FAILURE',
  IMPORT_DRAFT_CATEGORY: 'IMPORT_DRAFT_CATEGORY',
  IMPORT_DRAFT_CATEGORY_SUCCESS: 'IMPORT_DRAFT_CATEGORY_SUCCESS',
  IMPORT_DRAFT_CATEGORY_FAILURE: 'IMPORT_DRAFT_CATEGORY_SUCCESS',
  REAPPLY_JOB_CATEGORIZATION: 'REAPPLY_JOB_CATEGORIZATION',
  IMPORT_AND_REAPPLY_CATEGORY: 'IMPORT_AND_REAPPLY_CATEGORY',
  IMPORT_CATEGORY_OVERWRITE:'IMPORT_CATEGORY_OVERWRITE',
  ADD_CATEGORIES: 'ADD_CATEGORIES',
  SET_VIEW_CATEGORY_ADD_FORM: 'SET_VIEW_CATEGORY_ADD_FORM',
  SET_VIEW_CATEGORY_ADD_FORM_LOADING: 'SET_VIEW_CATEGORY_ADD_FORM_LOADING',
  DELETE_CATEGORIES: 'DELETE_CATEGORIES',

  reapplyJobCategorization: (data) => ({
    type: categoryLibraryActions.REAPPLY_JOB_CATEGORIZATION,
    data
  }),

  fetchCategoryLibrary: (data) => ({
    type: categoryLibraryActions.FETCH_CATEGORY_LIBRARY,
    data,
  }),
  importCategoryLibrary: (data, publish, date, cb) => ({
    type: categoryLibraryActions.IMPORT_DRAFT_CATEGORY,
    data,
    publish,
    date,
    cb
  }),
  importCategoryOverwrite: (data, flag) => ({
    type: categoryLibraryActions.IMPORT_CATEGORY_OVERWRITE,
    data,
    flag
  }),
  addCategories: (data) => ({
    type: categoryLibraryActions.ADD_CATEGORIES,
    data,
  }),
  setViewCategoryAddForm: (viewCategoryAddForm) => ({
    type: categoryLibraryActions.SET_VIEW_CATEGORY_ADD_FORM,
    viewCategoryAddForm,
  }),
  deleteCategories: (ids) => ({
    type: categoryLibraryActions.DELETE_CATEGORIES,
    ids,
  }),
};
export default categoryLibraryActions;
