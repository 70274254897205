/** @prettier */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import { Row, Col, Select } from 'antd';
import Subheading from 'components/UI/Subheading';
import Divider from 'components/UI/Divider';
import { InputWithIcon } from 'components-shared/inputs/InputWithIcon';
import { Active, CustomerTest, Headset, Internal, Trial } from 'assets/images/icons';
import { Error } from 'components/VBForm';
import { Tableau } from 'assets/images/icons/sidebar';
import { HeadingWrap, CheckboxWrap, CountInputsWrap } from './styles';

const { Option } = Select;
export const CONTRACT_CODE_VALUES = {
  INTERNAL: 'INTERNAL',
  DEVELOPER: 'DEVELOPER',
  STANDARD_PRICING: 'STANDARD_PRICING',
  RESELLER_PRICING: 'RESELLER_PRICING',
};
export const engagementWarningMessage = {
  billable:
    'NOTE: This will be considered a Billable Engagement. For internal/demo engagements, please select the appropriate engagement type',
  nonBillable:
    'NOTE: This account will not be billed. For billable engagements, please select the appropriate engagement type',
};
const AccountFormInputs = ({ values, editMode, setFieldValue, errors, allowSuperAdminAccess, embededTableauDashboradData }) => {
  const {
    name,
    siteId,
    skipAdminUser,
    adminFirstName,
    adminLastName,
    adminEmail,
    adminPhone,
    accountRepresentativeContactFirstName,
    accountRepresentativeContactLastName,
    accountRepresentativeContactEmail,
    techSameAs,
    technicalContactFirstName,
    technicalContactLastName,
    technicalContactEmail,
    technicalContactPhone,
    billingSameAs,
    billingContactFirstName,
    billingContactLastName,
    billingContactEmail,
    billingContactPhone,
    
  } = values;

  const [isStatusDdOpen, setIsStatusDdOpen] = useState(false);
  const accounts = useSelector((state) => state.Accounts);

  const [checked, setChecked] = useState(true);

  const handleCheckBoxChange = (e) => {
    setChecked(e.target.checked);
  };

  const contractCodes = [
    {
      label: 'Standard Pricing',
      value: CONTRACT_CODE_VALUES.STANDARD_PRICING,
      icon: (props) => <Active {...props} />,
    },
    {
      label: 'Internal',
      value: CONTRACT_CODE_VALUES.INTERNAL,
      icon: (props) => <Internal {...props} />,
    },
    {
      label: 'Developer',
      value: CONTRACT_CODE_VALUES.DEVELOPER,
      icon: (props) => <Trial {...props} />,
    },
    {
      label: 'Reseller Pricing',
      value: CONTRACT_CODE_VALUES.RESELLER_PRICING,
      icon: (props) => <CustomerTest {...props} />,
    },
  ];

  const Required = () => <span style={{ color: '#ff0000a6' }}> *</span>;

  const handleStatusChange = (contractCode) => {
    setFieldValue('contractCode', contractCode);
  };

  return (
    <>
      <InputWithIcon
        title="Brand Name"
        field="name"
        value={name}
        required
        // eslint-disable-next-line consistent-return
        validate={(value) => {
          if (!editMode && accounts.data.some((account) => account.name === value)) {
            return 'Duplicate Brand Name';
          }
        }}
      />

      <Row>
        <p className="input_label" style={{ margin: '0px 0px' }}>
          ENGAGEMENT TYPE <Required />
        </p>
        <Select
          value={values.contractCode}
          onChange={handleStatusChange}
          dropdownClassName="custom_ant_select account_type_select custom_dropdown dark-select"
          placeholder="Select engagement type"
          style={{
            width: '100%',
            height: '36px',
          }}
          onDropdownVisibleChange={(isOpen) => setIsStatusDdOpen(isOpen)}
        >
          {contractCodes.map((el) => {
            const Icon = el.icon;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Option key={el.value} value={el.value}>
                <Icon className="select_opt_icon__svg" />
                <span>{el.label}</span>
              </Option>
            );
          })}
        </Select>
        {errors.contractCode && <Error className="form-error">{errors.contractCode}</Error>}
      </Row>
      <InputWithIcon
        title="LIVEPERSON SITE ID (SALESFORCE BILLING SITE ID)"
        field="siteId"
        value={siteId}
        required={[CONTRACT_CODE_VALUES.STANDARD_PRICING, CONTRACT_CODE_VALUES.RESELLER_PRICING].includes(
          values.contractCode
        )}
      />
      <hr />
      {!editMode && (
        <>
          <HeadingWrap style={{ marginTop: 20 }}>
            <Subheading>Admin User</Subheading>
          </HeadingWrap>
          {(!skipAdminUser || editMode) && (
            <>
              <Row type="flex" gutter={[16, 0]}>
                <Col span={10}>
                  <InputWithIcon title="First Name" field="adminFirstName" value={adminFirstName} required />
                </Col>
                <Col span={14}>
                  <InputWithIcon title="Last Name" field="adminLastName" value={adminLastName} required />
                </Col>
                <Col span={14}>
                  <InputWithIcon title="Email" field="adminEmail" value={adminEmail} required />
                </Col>
                <Col span={10}>
                  <InputWithIcon title="Phone" field="adminPhone" value={adminPhone} required />
                </Col>
              </Row>
              <Divider />
            </>
          )}
        </>
      )}

      <>
        <HeadingWrap style={{ marginTop: 20 }}>
          <Subheading>Account Representative Contact</Subheading>
        </HeadingWrap>
        <>
          <Row type="flex" gutter={[16, 0]}>
            <Col span={10}>
              <InputWithIcon
                title="AR First Name"
                field="accountRepresentativeContactFirstName"
                value={accountRepresentativeContactFirstName}
              />
            </Col>
            <Col span={14}>
              <InputWithIcon
                title="AR Last Name"
                field="accountRepresentativeContactLastName"
                value={accountRepresentativeContactLastName}
              />
            </Col>
            <Col span={24}>
              <InputWithIcon
                title="AR Email"
                field="accountRepresentativeContactEmail"
                value={accountRepresentativeContactEmail}
              />
            </Col>
          </Row>
          <Divider />
        </>
      </>
      <HeadingWrap>
        <Subheading>Technical Contact</Subheading>
        {!editMode && (
          <CheckboxWrap className="checkbox-wrapper" htmlFor="techSameAs">
            <Field
              name="techSameAs"
              render={({ field, form }) => (
                <input
                  className="k-checkbox2 pointer"
                  style={{
                    float: 'left',
                    marginTop: '3px',
                  }}
                  type="checkbox"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <span>Same as {skipAdminUser ? 'me' : 'Admin User'}</span>
          </CheckboxWrap>
        )}
      </HeadingWrap>
      {(!techSameAs || editMode) && (
        <>
          <Row type="flex" gutter={[16, 0]}>
            <Col span={10}>
              <InputWithIcon
                title="First Name"
                field="technicalContactFirstName"
                value={technicalContactFirstName}
                required
              />
            </Col>
            <Col span={14}>
              <InputWithIcon
                title="Last Name"
                field="technicalContactLastName"
                value={technicalContactLastName}
                required
              />
            </Col>
            <Col span={14}>
              <InputWithIcon title="Email" field="technicalContactEmail" value={technicalContactEmail} required />
            </Col>
            <Col span={10}>
              <InputWithIcon title="Phone" field="technicalContactPhone" value={technicalContactPhone} required />
            </Col>
          </Row>
          <Divider />
        </>
      )}

      <HeadingWrap>
        <Subheading>Billing Contact</Subheading>
        <CheckboxWrap className="checkbox-wrapper" htmlFor="billingSameAs">
          <Field
            name="billingSameAs"
            render={({ field, form }) => (
              <input
                className="k-checkbox2 pointer"
                style={{
                  float: 'left',
                  marginTop: '3px',
                }}
                type="checkbox"
                checked={field.value}
                {...field}
              />
            )}
          />
          <span>Same as {skipAdminUser ? 'me' : 'Technical Contact'}</span>
        </CheckboxWrap>
      </HeadingWrap>
      {!billingSameAs && (
        <>
          <Row type="flex" gutter={[16, 0]}>
            <Col span={10}>
              <InputWithIcon
                title="First Name"
                field="billingContactFirstName"
                value={billingContactFirstName}
                required
              />
            </Col>
            <Col span={14}>
              <InputWithIcon title="Last Name" field="billingContactLastName" value={billingContactLastName} required />
            </Col>
            <Col span={14}>
              <InputWithIcon title="Email" field="billingContactEmail" value={billingContactEmail} required />
            </Col>
            <Col span={10}>
              <InputWithIcon title="Phone" field="billingContactPhone" value={billingContactPhone} required />
            </Col>
          </Row>
        </>
      )}

      {editMode && (
        <>
          <Divider />
          <Row type="flex">
            <p>Input the number of users by role.</p>
            <Col span={12}>
              {/*  ! Temporarily Removed Realtime feature from EA - https://voicebase.jira.com/browse/EA-4915, https://voicebase.jira.com/browse/EA-4932  */}
              {/* <CountInputsWrap className="count-input">
              <Col span={2}>
                <Headset className="md__svg" />
              </Col>
              <Col span={5}>
                <p style={{ margin: '0px 10px' }}>Agent</p>
              </Col>
              <Col span={3} style={{ marginTop: -18 }}>
                <InputWithIcon
                  field="AccountAgent"
                  value={values.AccountAgent}
                  type="number"
                  min={0}
                />
              </Col>
            </CountInputsWrap> */}
              <CountInputsWrap className="count-input">
                <Col span={2}>
                  <Tableau className="md__svg" />
                </Col>
                <Col span={5}>
                  <p style={{ margin: '0px 10px' }}>Tableau</p>
                </Col>
                <Col span={3} style={{ marginTop: -18 }}>
                  <InputWithIcon field="TableauAccess" value={values.TableauAccess} type="number" min={0} />
                </Col>
              </CountInputsWrap>
            </Col>
          </Row>
          {values.allowSuperAdminAccess && embededTableauDashboradData?.embeddedDashboardUrl !== undefined &&
            <Row>
              <p>Tableau Embedded URL.</p>
              <CountInputsWrap className="count-input">
                <Col span={2}>
                  <Tableau className="md__svg" />
                </Col>
                <Col style={{ marginTop: -18, width: '90%' }}>
                  <InputWithIcon
                    field="embeddedDashboardUrl"
                    value={values.embeddedDashboardUrl}
                  />
                </Col>
              </CountInputsWrap>
            </Row>
          }
          
        </>
      )}
    </>
  );
};

export default AccountFormInputs;
